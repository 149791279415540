import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  useTheme,
  TablePagination,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import { appColors } from "../../AppMeta/appColors";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useDispatch } from "react-redux";
import { UpdateFinalProductSKU } from "../../Redux/Actions/productsAction";
import { tokens } from "../../AppMeta/theme";

const data = [
  {
    product_details: {
      id: 1006,
      name: "New Dev Test Product#1",
      image: "",
      price: 0,
      amount: 0,
      refill: {
        sp: 100,
        mrp: 100,
        deposit: 200,
        refund_type: 100,
        refund_amount: 100,
      },
      sku_qty: [6],
      capacity: "100ml",
      hsn_code: "123456",
      material: "RUBBER",
      cgst_perc: 1.2,
      igst_perc: 1.2,
      life_span: 10000,
      sgst_perc: 1.2,
      created_at: "2024-02-03T08:48:29.997",
      order_type: 400,
      updated_at: "2024-02-03T08:48:30.021682",
      description: "This is a developer test product",
      booking_type: 1,
      max_book_qty: 1000,
      min_book_qty: 2,
      one_time_sale: {
        sp: 1,
        mrp: 1,
        deposit: 0,
        refund_type: 100,
        refund_amount: 0,
      },
    },
    crate_details: [
      {
        id: 6,
        name: "Plasticd",
        image: "",
        price: 180,
        capacity: 1,
        description: "You can return the glass bottle and earn the Rewards",
      },
    ],
  },
  {
    product_details: {
      id: 1005,
      name: "test sd",
      image: "",
      price: 0,
      amount: 0,
      refill: {
        sp: 1,
        mrp: 1,
        deposit: 1,
        refund_type: 100,
        refund_amount: 1,
      },
      sku_qty: [12],
      capacity: "12",
      hsn_code: "12133",
      material: "JPET M",
      cgst_perc: 12,
      igst_perc: 21,
      life_span: 1223222,
      sgst_perc: 12,
      created_at: "2024-02-02T12:58:55.512",
      order_type: 100,
      updated_at: "2024-02-02T12:58:55.527878",
      description: "cdsfweerewre dfsdf",
      booking_type: 1,
      max_book_qty: 12112,
      min_book_qty: 12,
      one_time_sale: {
        sp: 1,
        mrp: 1,
        deposit: 0,
        refund_type: 100,
        refund_amount: 0,
      },
    },
    crate_details: [
      {
        id: null,
        name: null,
        image: null,
        price: null,
        capacity: null,
        description: null,
      },
    ],
  },
  {
    product_details: {
      id: 1004,
      name: "BW_Bookwater 1 l Bottle",
      image: "uNBwW9aap.png",
      price: 0,
      amount: 0,
      refill: {
        sp: 5,
        mrp: 10,
        deposit: 10,
        refund_type: 100,
        refund_amount: 55,
      },
      sku_qty: [2],
      capacity: "1 litre",
      hsn_code: "70109",
      material: "BOTTLE",
      cgst_perc: 6,
      igst_perc: 6,
      life_span: 10,
      sgst_perc: 6,
      created_at: "2024-02-01T13:44:12.277",
      order_type: 400,
      updated_at: "2024-02-01T13:44:12.28599",
      description: "BW_Bookwater 1 l Bottle",
      booking_type: 3,
      max_book_qty: 999,
      min_book_qty: 2,
      one_time_sale: {
        sp: 15,
        mrp: 20,
        deposit: 10,
        refund_type: 100,
        refund_amount: 10,
      },
    },
    crate_details: [
      {
        id: null,
        name: null,
        image: null,
        price: null,
        capacity: null,
        description: null,
      },
    ],
  },
  {
    product_details: {
      id: 998,
      name: "test tests",
      image: "JZ-HVbOFb.png",
      price: 0,
      amount: 0,
      refill: {
        sp: 30,
        mrp: 50,
        deposit: 100,
        refund_type: 100,
        refund_amount: 200,
      },
      sku_qty: [2],
      capacity: "1 litre",
      hsn_code: "22344",
      material: "GLASS",
      cgst_perc: 4,
      igst_perc: 4,
      life_span: 20,
      sgst_perc: 4,
      created_at: "2024-02-01T11:52:19.919",
      order_type: 400,
      updated_at: "2024-02-01T11:52:19.927525",
      description: "asdassfdsaa sdfsada",
      booking_type: 1,
      max_book_qty: 122,
      min_book_qty: 1,
      one_time_sale: {
        sp: 1,
        mrp: 1,
        deposit: 1,
        refund_type: 100,
        refund_amount: 0,
      },
    },
    crate_details: [
      {
        id: null,
        name: null,
        image: null,
        price: null,
        capacity: null,
        description: null,
      },
    ],
  },
  {
    product_details: {
      id: 1001,
      name: "200 ML Steel Bottle (M)",
      image: "GaQWqvUYo.png",
      price: 0,
      amount: 0,
      refill: {
        sp: 40,
        mrp: 55,
        deposit: 100,
        refund_type: 100,
        refund_amount: 50,
      },
      sku_qty: [6],
      capacity: "200 ML",
      hsn_code: "080319",
      material: "GLASS",
      cgst_perc: 9,
      igst_perc: 9,
      life_span: 5,
      sgst_perc: 9,
      created_at: "2024-02-01T12:18:05.828",
      order_type: 400,
      updated_at: "2024-02-01T12:18:05.83822",
      description: "20 ML Steel Bottle",
      booking_type: 1,
      max_book_qty: 12,
      min_book_qty: 6,
      one_time_sale: {
        sp: 1,
        mrp: 1,
        deposit: 1,
        refund_type: 100,
        refund_amount: 0,
      },
    },
    crate_details: [
      {
        id: 27,
        name: "200 ML steel bottle (M)",
        image: "jKvKcduFr.png",
        price: 40,
        capacity: 6,
        description: "200 ML steel Bottle crate (M)",
      },
    ],
  },
  {
    product_details: {
      id: 1013,
      name: "add 7 ",
      image: "",
      price: 0,
      amount: 0,
      refill: {
        sp: 1,
        mrp: 1,
        deposit: 1,
        refund_type: 100,
        refund_amount: 1,
      },
      sku_qty: [5],
      capacity: "20 liter",
      hsn_code: "33434",
      material: "PET MATERIAL",
      cgst_perc: 2,
      igst_perc: 2,
      life_span: 4,
      sgst_perc: 2,
      created_at: "2024-02-05T05:12:41.225",
      order_type: 400,
      updated_at: "2024-02-05T05:12:41.236547",
      description: "To add product into system",
      booking_type: 1,
      max_book_qty: 5,
      min_book_qty: 2,
      one_time_sale: {
        sp: 1,
        mrp: 1,
        deposit: 0,
        refund_type: 100,
        refund_amount: 0,
      },
    },
    crate_details: [
      {
        id: null,
        name: null,
        image: null,
        price: null,
        capacity: null,
        description: null,
      },
    ],
  },
  {
    product_details: {
      id: 888,
      name: "Bookwater Can",
      image: "bHJ9tgNCZ.png",
      price: 110,
      amount: 110,
      refill: {
        sp: 15,
        mrp: 100,
        deposit: 10,
        refund_type: 100,
        refund_amount: 110,
      },
      sku_qty: [12],
      capacity: "20 liters",
      hsn_code: "13555",
      material: "PLASITIC",
      cgst_perc: 18,
      igst_perc: 10,
      life_span: 30,
      sgst_perc: 18,
      created_at: "2024-01-23T05:05:03.457",
      order_type: 400,
      updated_at: "2024-01-23T05:05:03.469281",
      description: "Sample Bookwater can",
      booking_type: 3,
      max_book_qty: 12,
      min_book_qty: 2,
      one_time_sale: {
        sp: 16,
        mrp: 150,
        deposit: 10,
        refund_type: 100,
        refund_amount: 110,
      },
    },
    crate_details: [
      {
        id: 22,
        name: "BW_common crate 500ml/1l",
        image: "",
        price: 100,
        capacity: 36,
        description: "common crate 500ml/1l",
      },
    ],
  },
  {
    product_details: {
      id: 910,
      name: "chennai 11",
      image: "jROJOdYCH.png",
      price: 120,
      amount: 120,
      refill: {
        sp: 17,
        mrp: 100,
        deposit: 10,
        refund_type: 100,
        refund_amount: 10,
      },
      sku_qty: [12],
      capacity: "20-liters",
      hsn_code: "67666",
      material: "PLASTIC",
      cgst_perc: 7,
      igst_perc: 2,
      life_span: 30,
      sgst_perc: 2,
      created_at: "2024-01-24T05:24:11.499",
      order_type: 400,
      updated_at: "2024-01-24T10:55:14",
      description: "gjhgfghjhgfghjjhg",
      booking_type: 3,
      max_book_qty: 7,
      min_book_qty: 2,
      one_time_sale: {
        sp: 81,
        mrp: 100,
        deposit: 20,
        refund_type: 100,
        refund_amount: 20,
      },
    },
    crate_details: [
      {
        id: 9,
        name: "Bookwater catrates",
        image: "",
        price: 124,
        capacity: 12,
        description: "Plastic premium crate",
      },
    ],
  },
  {
    product_details: {
      id: 889,
      name: "20 Liter ",
      image: "ymHvrMlju.png",
      price: 300,
      amount: 300,
      refill: {
        sp: 12,
        mrp: 122,
        deposit: 100,
        refund_type: 100,
        refund_amount: 12,
      },
      sku_qty: [33],
      capacity: "20 Liters",
      hsn_code: "33222",
      material: "PLASTIC",
      cgst_perc: 12,
      igst_perc: 1,
      life_span: 30,
      sgst_perc: 12,
      created_at: "2024-01-23T05:40:21.943",
      order_type: 400,
      updated_at: "2024-01-23T05:40:21.956189",
      description: "Sample product by gowtham",
      booking_type: 3,
      max_book_qty: 12,
      min_book_qty: 2,
      one_time_sale: {
        sp: 50,
        mrp: 150,
        deposit: 100,
        refund_type: 100,
        refund_amount: 13,
      },
    },
    crate_details: [
      {
        id: 22,
        name: "BW_common crate 500ml/1l",
        image: "",
        price: 100,
        capacity: 36,
        description: "common crate 500ml/1l",
      },
    ],
  },
  {
    product_details: {
      id: 186,
      name: "",
      image: "",
      price: 1,
      amount: 1,
      refill: {
        sp: 55,
        mrp: 75,
        deposit: 200,
        refund_type: 100,
        refund_amount: 200,
      },
      sku_qty: [1],
      capacity: "20_Liters",
      hsn_code: "",
      material: "COPPER",
      cgst_perc: 0,
      igst_perc: 0,
      life_span: 0,
      sgst_perc: 0,
      created_at: "2022-07-08T18:02:55",
      order_type: 0,
      updated_at: "2023-12-20T06:30:52.990656",
      description: "",
      booking_type: 1,
      max_book_qty: 1,
      min_book_qty: 1,
      one_time_sale: {
        sp: 0,
        mrp: 0,
        deposit: 0,
        refund_type: 100,
        refund_amount: 0,
      },
    },
    crate_details: [
      {
        id: null,
        name: null,
        image: null,
        price: null,
        capacity: null,
        description: null,
      },
    ],
  },
  {
    product_details: {
      id: 915,
      name: "",
      image: "",
      price: 200,
      amount: 200,
      refill: {
        sp: 55,
        mrp: 75,
        deposit: 200,
        refund_type: 100,
        refund_amount: 200,
      },
      sku_qty: [1],
      capacity: "20_Liters",
      hsn_code: "",
      material: "PET",
      cgst_perc: 0,
      igst_perc: 0,
      life_span: 30,
      sgst_perc: 0,
      created_at: "2024-01-24T18:33:23",
      order_type: 0,
      updated_at: "2024-02-01T11:48:45",
      description: "",
      booking_type: 1,
      max_book_qty: 1,
      min_book_qty: 1,
      one_time_sale: {
        sp: 0,
        mrp: 0,
        deposit: 0,
        refund_type: 100,
        refund_amount: 0,
      },
    },
    crate_details: [
      {
        id: null,
        name: null,
        image: null,
        price: null,
        capacity: null,
        description: null,
      },
    ],
  },
  {
    product_details: {
      id: 710,
      name: "",
      image: "",
      price: 25,
      amount: 25,
      refill: {
        sp: 55,
        mrp: 75,
        deposit: 200,
        refund_type: 100,
        refund_amount: 200,
      },
      sku_qty: [1],
      capacity: "20_Liters",
      hsn_code: "",
      material: "POLYCARBONATE",
      cgst_perc: 0,
      igst_perc: 0,
      life_span: 25,
      sgst_perc: 0,
      created_at: "2023-06-06T13:00:38",
      order_type: 0,
      updated_at: "2023-12-20T06:30:52.990656",
      description: "",
      booking_type: 1,
      max_book_qty: 1,
      min_book_qty: 1,
      one_time_sale: {
        sp: 0,
        mrp: 0,
        deposit: 0,
        refund_type: 100,
        refund_amount: 0,
      },
    },
    crate_details: [
      {
        id: null,
        name: null,
        image: null,
        price: null,
        capacity: null,
        description: null,
      },
    ],
  },
  {
    product_details: {
      id: 709,
      name: "",
      image: "",
      price: 30,
      amount: 30,
      refill: {
        sp: 55,
        mrp: 75,
        deposit: 200,
        refund_type: 100,
        refund_amount: 200,
      },
      sku_qty: [1],
      capacity: "20_Liters",
      hsn_code: "",
      material: "PVC",
      cgst_perc: 0,
      igst_perc: 0,
      life_span: 25,
      sgst_perc: 0,
      created_at: "2023-06-06T13:00:38",
      order_type: 0,
      updated_at: "2023-12-20T06:30:52.990656",
      description: "",
      booking_type: 1,
      max_book_qty: 1,
      min_book_qty: 1,
      one_time_sale: {
        sp: 0,
        mrp: 0,
        deposit: 0,
        refund_type: 100,
        refund_amount: 0,
      },
    },
    crate_details: [
      {
        id: null,
        name: null,
        image: null,
        price: null,
        capacity: null,
        description: null,
      },
    ],
  },
  {
    product_details: {
      id: 876,
      name: "20ml bottle",
      image: "CKADeZPa9.jpg",
      price: 122,
      amount: 122,
      refill: {
        sp: 55,
        mrp: 75,
        deposit: 200,
        refund_type: 100,
        refund_amount: 200,
      },
      sku_qty: [1],
      capacity: "20mls",
      hsn_code: "86777776",
      material: "GLASS",
      cgst_perc: 8,
      igst_perc: 6,
      life_span: 22,
      sgst_perc: 6,
      created_at: "2024-01-20T09:07:16.199",
      order_type: 400,
      updated_at: "2024-01-20T09:07:16.209225",
      description: "This is a sample product by vinoth",
      booking_type: 1,
      max_book_qty: 33,
      min_book_qty: 1,
      one_time_sale: {
        sp: 0,
        mrp: 0,
        deposit: 0,
        refund_type: 100,
        refund_amount: 0,
      },
    },
    crate_details: [
      {
        id: null,
        name: null,
        image: null,
        price: null,
        capacity: null,
        description: null,
      },
    ],
  },
  {
    product_details: {
      id: 1014,
      name: "add 8 ",
      image: "",
      price: 0,
      amount: 0,
      refill: {
        sp: 1,
        mrp: 1,
        deposit: 1,
        refund_type: 100,
        refund_amount: 1,
      },
      sku_qty: [5],
      capacity: "21 liter",
      hsn_code: "33434",
      material: "PET MATERIAL",
      cgst_perc: 2,
      igst_perc: 2,
      life_span: 4,
      sgst_perc: 2,
      created_at: "2024-02-05T05:13:02.573",
      order_type: 400,
      updated_at: "2024-02-05T05:13:02.58689",
      description: "To add product into system",
      booking_type: 1,
      max_book_qty: 5,
      min_book_qty: 2,
      one_time_sale: {
        sp: 1,
        mrp: 1,
        deposit: 0,
        refund_type: 100,
        refund_amount: 0,
      },
    },
    crate_details: [
      {
        id: null,
        name: null,
        image: null,
        price: null,
        capacity: null,
        description: null,
      },
    ],
  },
  {
    product_details: {
      id: 1015,
      name: "add 9 ",
      image: "",
      price: 0,
      amount: 0,
      refill: {
        sp: 1,
        mrp: 1,
        deposit: 1,
        refund_type: 100,
        refund_amount: 1,
      },
      sku_qty: [5],
      capacity: "22 liter",
      hsn_code: "33434",
      material: "PET MATERIAL",
      cgst_perc: 2,
      igst_perc: 2,
      life_span: 4,
      sgst_perc: 2,
      created_at: "2024-02-05T05:13:08.725",
      order_type: 400,
      updated_at: "2024-02-05T05:13:08.736467",
      description: "To add product into system",
      booking_type: 1,
      max_book_qty: 5,
      min_book_qty: 2,
      one_time_sale: {
        sp: 1,
        mrp: 1,
        deposit: 0,
        refund_type: 100,
        refund_amount: 0,
      },
    },
    crate_details: [
      {
        id: null,
        name: null,
        image: null,
        price: null,
        capacity: null,
        description: null,
      },
    ],
  },
  {
    product_details: {
      id: 1012,
      name: "add 6 ",
      image: "",
      price: 0,
      amount: 0,
      refill: {
        sp: 1,
        mrp: 1,
        deposit: 1,
        refund_type: 100,
        refund_amount: 1,
      },
      sku_qty: [5],
      capacity: "2 liter",
      hsn_code: "33434",
      material: "PET MATERIAL",
      cgst_perc: 2,
      igst_perc: 2,
      life_span: 4,
      sgst_perc: 2,
      created_at: "2024-02-05T05:12:26.619",
      order_type: 400,
      updated_at: "2024-02-05T05:12:26.657039",
      description: "To add product into system",
      booking_type: 1,
      max_book_qty: 5,
      min_book_qty: 2,
      one_time_sale: {
        sp: 1,
        mrp: 1,
        deposit: 0,
        refund_type: 100,
        refund_amount: 0,
      },
    },
    crate_details: [
      {
        id: null,
        name: null,
        image: null,
        price: null,
        capacity: null,
        description: null,
      },
    ],
  },
  {
    product_details: {
      id: 987,
      name: "One bottle down",
      image: "jZOkxY6YM.png",
      price: 0,
      amount: 0,
      refill: {
        sp: 5,
        mrp: 10,
        deposit: 20,
        refund_type: 100,
        refund_amount: 10,
      },
      sku_qty: [2],
      capacity: "2 liter",
      hsn_code: "70109000",
      material: "THERMOCOL",
      cgst_perc: 2,
      igst_perc: 3,
      life_span: 1,
      sgst_perc: 3,
      created_at: "2024-02-01T10:12:04.582",
      order_type: 400,
      updated_at: "2024-02-01T10:12:04.592995",
      description: "kuch add kiya jaye ",
      booking_type: 1,
      max_book_qty: 1,
      min_book_qty: 1,
      one_time_sale: {
        sp: 1,
        mrp: 1,
        deposit: 1,
        refund_type: 100,
        refund_amount: 1,
      },
    },
    crate_details: [
      {
        id: 13,
        name: "cratePremium",
        image: "zjJdLAT26.jpg",
        price: 123,
        capacity: 122,
        description: "wdgvfcdsfdcxsfdrfds",
      },
      {
        id: 17,
        name: "One bottle down crate",
        image: "KS4Kagr09.jpg",
        price: 200,
        capacity: 3,
        description: "kuch add kiya jaye",
      },
    ],
  },
  {
    product_details: {
      id: 1009,
      name: "Add 3",
      image: "",
      price: 0,
      amount: 0,
      refill: {
        sp: 1,
        mrp: 1,
        deposit: 2,
        refund_type: 100,
        refund_amount: 1,
      },
      sku_qty: [3],
      capacity: "3 pack",
      hsn_code: "34543",
      material: "PLASTIC",
      cgst_perc: 3,
      igst_perc: 3,
      life_span: 4,
      sgst_perc: 3,
      created_at: "2024-02-05T05:08:51.671",
      order_type: 400,
      updated_at: "2024-02-05T05:08:51.683678",
      description: "To add product into system",
      booking_type: 1,
      max_book_qty: 6,
      min_book_qty: 3,
      one_time_sale: {
        sp: 1,
        mrp: 1,
        deposit: 0,
        refund_type: 100,
        refund_amount: 0,
      },
    },
    crate_details: [
      {
        id: null,
        name: null,
        image: null,
        price: null,
        capacity: null,
        description: null,
      },
    ],
  },
  {
    product_details: {
      id: 1011,
      name: "add 5 ",
      image: "",
      price: 0,
      amount: 0,
      refill: {
        sp: 1,
        mrp: 1,
        deposit: 1,
        refund_type: 100,
        refund_amount: 1,
      },
      sku_qty: [5],
      capacity: "41 liter",
      hsn_code: "33434",
      material: "PET MATERIAL",
      cgst_perc: 2,
      igst_perc: 2,
      life_span: 4,
      sgst_perc: 2,
      created_at: "2024-02-05T05:12:13.665",
      order_type: 400,
      updated_at: "2024-02-05T05:12:13.678344",
      description: "To add product into system",
      booking_type: 1,
      max_book_qty: 5,
      min_book_qty: 2,
      one_time_sale: {
        sp: 1,
        mrp: 1,
        deposit: 0,
        refund_type: 100,
        refund_amount: 0,
      },
    },
    crate_details: [
      {
        id: null,
        name: null,
        image: null,
        price: null,
        capacity: null,
        description: null,
      },
    ],
  },
  {
    product_details: {
      id: 1010,
      name: "add 4 ",
      image: "",
      price: 0,
      amount: 0,
      refill: {
        sp: 1,
        mrp: 1,
        deposit: 1,
        refund_type: 100,
        refund_amount: 1,
      },
      sku_qty: [5],
      capacity: "4 liter",
      hsn_code: "33434",
      material: "PET MATERIAL",
      cgst_perc: 2,
      igst_perc: 2,
      life_span: 4,
      sgst_perc: 2,
      created_at: "2024-02-05T05:10:17.546",
      order_type: 400,
      updated_at: "2024-02-05T05:10:17.557914",
      description: "To add product into system",
      booking_type: 1,
      max_book_qty: 5,
      min_book_qty: 2,
      one_time_sale: {
        sp: 1,
        mrp: 1,
        deposit: 0,
        refund_type: 100,
        refund_amount: 0,
      },
    },
    crate_details: [
      {
        id: null,
        name: null,
        image: null,
        price: null,
        capacity: null,
        description: null,
      },
    ],
  },
  {
    product_details: {
      id: 1007,
      name: "add 1",
      image: "",
      price: 0,
      amount: 0,
      refill: {
        sp: 1,
        mrp: 1,
        deposit: 1,
        refund_type: 100,
        refund_amount: 1,
      },
      sku_qty: [2],
      capacity: "4 liter",
      hsn_code: "45345",
      material: "PLASTIC",
      cgst_perc: 4,
      igst_perc: 2,
      life_span: 3,
      sgst_perc: 3,
      created_at: "2024-02-05T05:05:48.604",
      order_type: 400,
      updated_at: "2024-02-05T05:05:48.617522",
      description: "just for add test",
      booking_type: 1,
      max_book_qty: 6,
      min_book_qty: 3,
      one_time_sale: {
        sp: 1,
        mrp: 1,
        deposit: 0,
        refund_type: 100,
        refund_amount: 0,
      },
    },
    crate_details: [
      {
        id: null,
        name: null,
        image: null,
        price: null,
        capacity: null,
        description: null,
      },
    ],
  },
  {
    product_details: {
      id: 995,
      name: "BW_Bookwater 500ml",
      image: "",
      price: 0,
      amount: 0,
      refill: {
        sp: 1,
        mrp: 1,
        deposit: 1,
        refund_type: 100,
        refund_amount: 0,
      },
      sku_qty: [12],
      capacity: "500ml",
      hsn_code: "701090",
      material: "BOTTLE",
      cgst_perc: 5,
      igst_perc: 5,
      life_span: 10,
      sgst_perc: 5,
      created_at: "2024-02-01T10:50:52.395",
      order_type: 400,
      updated_at: "2024-02-01T10:50:52.403186",
      description: "BW_Bookwater 500ml",
      booking_type: 2,
      max_book_qty: 999,
      min_book_qty: 2,
      one_time_sale: {
        sp: 5,
        mrp: 10,
        deposit: 10,
        refund_type: 100,
        refund_amount: 5,
      },
    },
    crate_details: [
      {
        id: null,
        name: null,
        image: null,
        price: null,
        capacity: null,
        description: null,
      },
    ],
  },
];

const SKUProducts = ({ allProducts }) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    console.log("allProducts")
    console.log(allProducts)
  }, [allProducts])
  const [openRows, setOpenRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);

  const [openEditDialog, setOpenEditDialog] = React.useState(false);

  const [saleType, setSaleType] = React.useState("refill");

  const [containerId, setContainerId] = React.useState("");
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [capacity, setCapacity] = React.useState("");
  const [material, setMaterial] = React.useState("");
  const [lifeSpan, setLifeSpan] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [skuQty, setSkuQty] = React.useState([]);
  const [image, setImage] = React.useState("");
  const [hsnCode, setHsnCode] = React.useState("");
  const [cgstPerc, setCgstPerc] = React.useState("");
  const [sgstPerc, setSgctPerc] = React.useState("");
  const [igstPerc, setIgstPerc] = React.useState("");
  const [minBookQty, setMinBookQty] = React.useState("");
  const [maxBookQty, setMaxBookQty] = React.useState("");
  const [deliveryCharges, setDeliveryCharges] = React.useState("");
  const [orderType, setOrderType] = React.useState("");
  const [bookingType, setBookingType] = React.useState("");
  const [mrpR, setMrpR] = React.useState("");
  const [spR, setSpR] = React.useState("");
  const [depositR, setDepositR] = React.useState("");
  const [refundTypeR, setRefundTypeR] = React.useState("");
  const [refundAmountR, setRefundAmountR] = React.useState("");
  const [mrpO, setMrpO] = React.useState("");
  const [spO, setSpO] = React.useState("");
  const [depositO, setDepositO] = React.useState("");
  const [refundTypeO, setRefundTypeO] = React.useState("");
  const [refundAmountO, setRefundAmountO] = React.useState("");
  const [isActive, setIsActive] = React.useState(true);
  const [itemCgst, setItemCgst] = useState("");
  const [itemSgst, setItemSgst] = useState("");
  const [error, setError] = React.useState("");
  const [error1, setError1] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [getOrders, setGetOrders] = React.useState([]);

  React.useEffect(() => {
    console.log("selectedRow");
    console.log(selectedRow);
    if (selectedRow) {
      setContainerId(selectedRow.product_details.id);
      setName(selectedRow.product_details.name);
      setDescription(selectedRow.product_details.description);
      setCapacity(selectedRow.product_details.capacity);
      setMaterial(selectedRow.product_details.material);
      setLifeSpan(selectedRow.product_details.life_span);
      setAmount(selectedRow.product_details.amount);
      setSkuQty(selectedRow.product_details.sku_qty);
      setImage(selectedRow.product_details.image);
      setHsnCode(selectedRow.product_details.hsn_code);
      setCgstPerc(selectedRow.product_details.cgst_perc);
      setSgctPerc(selectedRow.product_details.sgst_perc);
      setIgstPerc(selectedRow.product_details.igst_perc);
      setMinBookQty(selectedRow.product_details.min_book_qty);
      setMaxBookQty(selectedRow.product_details.max_book_qty);
      // setDeliveryCharges(selectedRow)
      setOrderType(selectedRow.product_details.order_type);
      setBookingType(selectedRow.product_details.booking_type);
      setMrpR(selectedRow.product_details.refill.mrp);
      setSpR(selectedRow.product_details.refill.sp);
      setDepositR(selectedRow.product_details.refill.deposit);
      setRefundTypeR(selectedRow.product_details.refill.refund_amount);
      setRefundAmountR(selectedRow.product_details.refill.refund_amount);
      setMrpO(selectedRow.product_details.one_time_sale.mrp);
      setSpO(selectedRow.product_details.one_time_sale.sp);
      setDepositO(selectedRow.product_details.one_time_sale.deposit);
      setRefundTypeO(selectedRow.product_details.one_time_sale.refund_type);
      setRefundAmountO(selectedRow.product_details.one_time_sale.refund_amount);
      setIsActive(true);
    }
  }, [selectedRow]);

  const handleClickSave = () => {
    const reqObj = {
      container_id: containerId,
      name: name,
      description: description,
      capacity: capacity,
      material: material,
      life_span: lifeSpan,
      amount: amount,
      sku_qty: skuQty,
      image: image,
      hsn_code: hsnCode,
      cgst_perc: cgstPerc,
      sgst_perc: sgstPerc,
      igst_perc: igstPerc,
      min_book_qty: minBookQty,
      max_book_qty: maxBookQty,
      delivery_charges: deliveryCharges,
      order_type: orderType,
      booking_type: bookingType,
      refill: {
        mrp: mrpR,
        sp: spR,
        deposit: depositR,
        refund_type: refundTypeR,
        refund_amount: refundAmountR,
      },
      one_time_sale: {
        mrp: mrpO,
        sp: spO,
        deposit: depositO,
        refund_type: refundTypeO,
        refund_amount: refundAmountO,
      },
      is_active: true,
    };
    console.log(reqObj);
    dispatch(UpdateFinalProductSKU(reqObj));
    setOpenEditDialog(false);
  };
  const handleToggle = (index) => {
    setOpenRows((prevOpenRows) => {
      const isOpen = prevOpenRows.includes(index);
      if (isOpen) {
        return prevOpenRows.filter((rowIndex) => rowIndex !== index);
      } else {
        return [...prevOpenRows, index];
      }
    });
  };

  const handleEditClick = (row) => {
    // Handle the edit button click, you can use selectedRow to get the data
    console.log("Edit clicked for row:", row);
    setOpenEditDialog(true);
    setSelectedRow(row);
  };

  React.useEffect(() => {});

  const handleChange = (event) => {
    setBookingType(event.target.value);
  };

  const handleItemCgstChange = (event) => {
    setItemCgst(event.target.value);
    if (event.target.value !== setItemSgst(event.target.value)) {
      setError("Values do not match");
    } else {
      setError("");
    }
    const newValue = event.target.value;
    if (!isNumeric(newValue) || parseInt(newValue, 10) > 99) {
      setError("Value must not be greater than two digit number");
    } else {
      setItemCgst(newValue);
      setError("");
    }
  };

  const isNumeric = (value) => {
    return /^\d{0,2}$/.test(value);
  };

  const disableSubmit = () => {
    return (
      error !== "" ||
      itemCgst === "" ||
      itemSgst === "" ||
      maxBookQty === "" ||
      minBookQty === ""
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeOrders = () => {
    setGetOrders(data);
  };

  const handleItemSgstChange = (event) => {
    setItemSgst(event.target.value);
    if (event.target.value !== setItemCgst(event.target.value)) {
      setError("Values do not match");
    } else {
      setError("");
    }
    const newValue = event.target.value;
    if (!isNumeric(newValue) || parseInt(newValue, 10) > 99) {
      setError("Value must be a number less than or equal to 99");
    } else {
      setItemSgst(newValue);
      setError("");
    }
  };

  const handleMinBookQtyChange = (event) => {
    const newMinBookQty = event.target.value;
    setMinBookQty(newMinBookQty);
    if (newMinBookQty > maxBookQty) {
      setError1(
        "Minimum book quantity cannot be greater than maximum book quantity"
      );
    } else {
      setError1("");
    }
  };

  const handleMaxBookQtyChange = (event) => {
    const newMaxBookQty = event.target.value;
    setMaxBookQty(newMaxBookQty);
    if (minBookQty > newMaxBookQty) {
      setError1(
        "Minimum book quantity cannot be greater than maximum book quantity"
      );
    } else {
      setError1("");
    }
  };
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getOrders.length) : 0;
  return (
    <>
      <TableContainer
        sx={{
          borderRadius: "10px",
        }}
        component={Paper}
      >
        <Table>
          <TableHead
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.primary[300],
              boxShadow: 2,
            }}
          >
            <TableRow>
              <TableCell />
              <TableCell
                sx={{
                  fontWeight: "bold",
                  textAlign: "start",
                  fontSize: { xs: "0.9rem", md: "1.1rem" },
                }}
              >
                Name
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  textAlign: "start",
                  fontSize: { xs: "0.9rem", md: "1.1rem" },
                }}
              >
                Capacity
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  textAlign: "start",
                  fontSize: { xs: "0.9rem", md: "1.1rem" },
                }}
              >
                Material
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  textAlign: "start",
                  fontSize: { xs: "0.9rem", md: "1.1rem" },
                }}
              >
                Life Span
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  textAlign: "start",
                  fontSize: { xs: "0.9rem", md: "1.1rem" },
                }}
              >
                Order Type
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  textAlign: "start",
                  fontSize: { xs: "0.9rem", md: "1.1rem" },
                }}
              >
                Image (Icon)
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  textAlign: "start",
                  fontSize: { xs: "0.9rem", md: "1.1rem" },
                }}
              >
                Edit
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? allProducts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : allProducts
            ).map((detail) => (
              <React.Fragment key={detail.product_details.id}>
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: { xs: "0.8rem", md: "0.9rem" },
                      backgroundColor: colors.primary[600],
                      textAlign: "start",
                    }}
                  >
                    <IconButton
                      aria-label="expand row" 
                      size="small"
                      onClick={() => handleToggle(detail)}
                    >
                      {openRows.includes(detail) ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { xs: "0.8rem", md: "0.9rem" },
                      backgroundColor: colors.primary[600],
                      textAlign: "start",
                    }}
                  >
                    {detail.product_details.name}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { xs: "0.8rem", md: "0.9rem" },
                      backgroundColor: colors.primary[600],
                      textAlign: "start",
                    }}
                  >
                    {detail.product_details.capacity}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { xs: "0.8rem", md: "0.9rem" },
                      backgroundColor: colors.primary[600],
                      textAlign: "start",
                    }}
                  >
                    {detail.product_details.material}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { xs: "0.8rem", md: "0.9rem" },
                      backgroundColor: colors.primary[600],
                      textAlign: "start",
                    }}
                  >
                    {detail.product_details.life_span}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { xs: "0.8rem", md: "0.9rem" },
                      backgroundColor: colors.primary[600],
                      textAlign: "start",
                    }}
                  >
                    {detail.product_details.order_type}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { xs: "0.8rem", md: "0.9rem" },
                      backgroundColor: colors.primary[600],
                      textAlign: "start",
                    }}
                  >
                    <VisibilityRoundedIcon />
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { xs: "0.8rem", md: "0.9rem" },
                      backgroundColor: colors.primary[600],
                      textAlign: "start",
                    }}
                  >
                    <Button
                      size="small"
                      color="secondary"
                      variant="contained"
                      onClick={() => handleEditClick(detail)}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={8}
                  >
                    <Collapse
                      in={openRows.includes(detail)}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box margin={1}>
                        <Table size="small" aria-label="purchases">
                          <TableBody>
                            <TableRow>
                              <TableCell>SKU qty</TableCell>
                              <TableCell>
                                {detail.product_details.sku_qty.join(", ")}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Max Book qty</TableCell>
                              <TableCell>
                                {detail.product_details.max_book_qty}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Min Book qty</TableCell>
                              <TableCell>
                                {detail.product_details.min_book_qty}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>HSN</TableCell>
                              <TableCell>
                                {detail.product_details.hsn_code}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>CGST %</TableCell>
                              <TableCell>
                                {detail.product_details.cgst_perc}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>SGST %</TableCell>
                              <TableCell>
                                {detail.product_details.sgst_perc}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>IGST %</TableCell>
                              <TableCell>
                                {detail.product_details.igst_perc}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={allProducts.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Dialog for editing */}
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <DialogTitle>Edit Product</DialogTitle>
        <DialogContent>
          {selectedRow && ( 
            <form>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                  marginTop: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <TextField
                    label="Name"
                    fullWidth
                    value={name}
                    size="small"
                    onChange={(e) => setName(e.target.value)}
                  />
                  <TextField
                    label="Capacity"
                    fullWidth
                    value={capacity}
                    size="small"
                    onChange={(e) => setCapacity(e.target.value)}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <TextField
                    label="Material"
                    fullWidth
                    value={material}
                    size="small"
                    onChange={(e) => setMaterial(e.target.value)}
                  />
                  <TextField
                    label="Life Span"
                    fullWidth
                    value={lifeSpan}
                    size="small"
                    onChange={(e) => setLifeSpan(e.target.value)}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <TextField
                    label="SKU Quantity"
                    fullWidth
                    value={skuQty[0]}
                    size="small"
                    type="number"
                    onChange={(e) => setSkuQty(e.target.value)}
                  />
                  <TextField
                    label="HSN Code"
                    fullWidth
                    value={hsnCode}
                    size="small"
                    onChange={(e) => setHsnCode(e.target.value)}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <TextField
                    label="CGST %"
                    fullWidth
                    size="small"
                    value={cgstPerc}
                    onChange={handleItemCgstChange}
                    error={error !== ""}
                    helperText={error}
                    type="number"
                  />
                  <TextField
                    label="SGST %"
                    fullWidth
                    size="small"
                    value={sgstPerc}
                    onChange={handleItemSgstChange}
                    error={error !== ""}
                    helperText={error}
                    type="number"
                    inputProps={{ maxlength: 2 }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <TextField
                    label="IGST %"
                    fullWidth
                    value={igstPerc}
                    size="small"
                  />
                  <TextField
                    label="Min Book Quantity"
                    fullWidth
                    size="small"
                    value={minBookQty}
                    error={error1 !== ""}
                    helperText={error1}
                    onChange={handleMinBookQtyChange}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <TextField
                    label="Max Book Quantity"
                    fullWidth
                    value={maxBookQty}
                    size="small"
                    type="number"
                    error={error1 !== ""}
                    helperText={error1}
                    onChange={handleMaxBookQtyChange}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "20px",
                    }}
                  >
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <InputLabel id="demo-select-small-label">
                        Order Type
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={orderType}
                        label="Order Type"
                        onChange={handleChange}
                      >
                        <MenuItem value={100}>Insta</MenuItem>
                        <MenuItem value={200}>Regualar</MenuItem>
                        <MenuItem value={300}>Subscription</MenuItem>
                        <MenuItem value={400}>All types</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  {/* <TextField
                    label="Delivery charges"
                    fullWidth
                    defaultValue={selectedRow}
                    size="small"
                  /> */}
                  <TextField
                    label="Description"
                    fullWidth
                    value={description}
                    size="small"
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={bookingType}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="Refill"
                      />
                      <FormControlLabel
                        value={2}
                        control={<Radio />}
                        label="One Time Sale"
                      />
                      <FormControlLabel
                        value={3}
                        control={<Radio />}
                        label="Both"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <TextField
                    label="MRP"
                    type="number"
                    fullWidth
                    value={mrpR}
                    size="small"
                    onChange={(e) => setMrpR(e.target.value)}
                  />
                  <TextField
                    label="Sale price"
                    fullWidth
                    value={spR}
                    size="small"
                    type="number"
                    onChange={(e) => setSpR(e.target.value)}
                  />
                  <TextField
                    label="Deposit"
                    fullWidth
                    value={depositR}
                    size="small"
                    type="number"
                    onChange={(e) => setDepositR(e.target.value)}
                  />
                  <TextField
                    label="Order Type"
                    fullWidth
                    value={refundTypeR}
                    size="small"
                    type="number"
                    onChange={(e) => setRefundTypeR(e.target.value)}
                  />
                  <TextField
                    label="Refund Amount"
                    fullWidth
                    value={refundAmountR}
                    size="small"
                    type="number"
                    onChange={(e) => setRefundAmountR(e.target.value)}
                  />
                </Box>

                {/* Add other text fields as needed */}
                <Button variant="contained" onClick={handleClickSave}>
                  Save
                </Button>
              </Box>
            </form>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SKUProducts;
