/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description List of organization details presented in form of table
 * @date 26/07/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import * as React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";
import EditOrgDialogBox from "./EditOrgDialogBox";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import CorporateFareRoundedIcon from "@mui/icons-material/CorporateFareRounded";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { appColors } from "../../../AppMeta/appColors";
import { FacilityInchargeDetails } from "./FacilityInchargeDetails";

export default function OrganizationDetailsList({
  organizationList,
  setAddOrganizationDialog,
  setIsAddFacility,
  isAddFacility,
  pageNumber,
  pageSize,
  isActive,
  ascOrDesc,
  lastPageNumber,
  setPageNumber,
  setPageSize,
  setIsActive,
  setAscOrDesc,
}) {
  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          columnHeader: {
            backgroundColor: "#040c45", // Set the desired background color here
            color: "white", // Set the desired text color here
            position: "sticky",
            marginTop: "0 !important",
          },
        },
      },
    },
  });
  // Required variables declared here
  const [editOrgDialog, setEditOrgDialog] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [addFacilityInchargeDialog,setAddFacilityInchargeDialog] = React.useState(false);
  const [facility,setFacility] = React.useState([]);

  React.useState(false);
  React.useEffect(() => { 
    console.log(organizationList);
  }, []);

  // If user clicks on any row in the table this function will set the selected row data in variable
  const handleRowClick = (row) => {
    console.log(row);
    setSelectedRow(row);
    setEditOrgDialog(true);
  };

  function createData(
    id,
    orgName,
    domain,
    areaName,
    pincode,
    state,
    district,
    isActive
  ) {
    return {
      id,
      orgName,
      domain,
      areaName,
      pincode,
      state,
      district,
      isActive,
    };
  }

  const rows = organizationList.map((item) =>
    createData(
      item.id,
      item.org_name,
      item.domain,
      item.details.area_name,
      item.details.pincode,
      item.details.state,
      item.details.district,
      item.is_active
    )
  );

  // Toggle button for filtering the active and inactive status
  const handleChangeStatus = (event, newAlignment) => {
    setIsActive(newAlignment);
  };
  // Toggle button for filtering the Ascending and descending data in the table
  const handleChangeAscDesc = (event, newAlignment) => {
    console.log(newAlignment);
    setAscOrDesc(newAlignment);
  };
  // Pagenumber will be incremented
  const pageNumberForward = () => {
    if (organizationList.length > 0) {
      setPageNumber(pageNumber + 1);
      // setSearchOrderFlag(true);
    }
  };
  // Pagenumber will be decremented
  const pageNumberBackward = () => {
    if (pageNumber - 1 > 0) {
      setPageNumber(pageNumber - 1);
      // setdisableBtn(false);
      // setSearchOrderFlag(true);
    }
  };

  // Custom toolbar for showing the filter option above the table
  const CustomToolbar = () => {
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
        }}
      >
        {/* Add your custom button here */}
        <Box
          sx={{
            padding: "10px",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          {/* <CustomButton
            variant="outlined"
            color="primary"
            label="Add New Organization"
            handleClick={() => {
              setAddOrganizationDialog(true);
              setIsAddFacility(false);
            }}
            endIcon={<CorporateFareRoundedIcon />}
          /> */}
          <CustomButton
            variant="outlined"
            color="primary"
            label="Add New Facility"
            handleClick={() => {
              setAddOrganizationDialog(true);
              setIsAddFacility(true);
            }}
            endIcon={<CorporateFareRoundedIcon />}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            // flexDirection: "column",
            flexWrap: "wrap",
            gap: "20px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box>
            <ToggleButtonGroup
              size="small"
              color="primary"
              value={isActive}
              exclusive
              onChange={handleChangeStatus}
              aria-label="Platform"
            >
              <ToggleButton value={true}>Active</ToggleButton>
              <ToggleButton value={false}>Inactive</ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box>
            {" "}
            <ToggleButtonGroup
              size="small"
              color="primary"
              value={ascOrDesc}
              exclusive
              onChange={handleChangeAscDesc}
              aria-label="Platform"
            >
              <ToggleButton value="ASC">Ascending</ToggleButton>
              <ToggleButton value="DESC">Descending</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
      </div>
    );
  };

  // Jsx starts here..
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Toolbar>
        <CustomToolbar />
      </Toolbar>

      {/* Table with organization data */}
      <Box sx={{ overflow: "auto", maxHeight: "600px" }}>
        <TableContainer
          component={Paper}
          sx={{ maxWidth: { xs: 350, lg: 1050 } }}
          size="small"
        >
          <Table aria-label="simple table" size="small">
            <TableHead sx={{ backgroundColor: appColors.secondary }}>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Facility Name</TableCell>
                <TableCell>Domain</TableCell>
                <TableCell>Area Name</TableCell>
                <TableCell>Pincode</TableCell>
                <TableCell>State</TableCell>
                <TableCell>District</TableCell>
                <TableCell>Active status</TableCell>
                <TableCell>Add organization</TableCell>
                <TableCell>Add Facility Incharge</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                  onClick={() => handleRowClick(row)}
                >
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell>{row.orgName}</TableCell>
                  <TableCell>{row.domain}</TableCell>
                  <TableCell>{row.areaName}</TableCell>
                  <TableCell>{row.pincode}</TableCell>
                  <TableCell>{row.state}</TableCell>
                  <TableCell>{row.district}</TableCell>
                  <TableCell>{row.isActive.toString()}</TableCell>
                  <TableCell>
                    <CustomButton
                      variant="outlined"
                      color="primary"
                      label="Add New Organization"
                      handleClick={(e) => {
                        e.stopPropagation();
                        setAddOrganizationDialog(true);
                        setIsAddFacility(false);
                      }}
                      endIcon={<CorporateFareRoundedIcon />}
                    />
                  </TableCell>
                  <TableCell>
                    <CustomButton
                      variant="outlined"
                      color="primary"
                      label="Add New Facility InCharge"
                      handleClick={(e) => {
                        e.stopPropagation();
                        setAddFacilityInchargeDialog(true);
                        setFacility(row)
                        setIsAddFacility(false);
                      }}
                      endIcon={<CorporateFareRoundedIcon />}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {/* Pagination and pageSize  */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          gap: "15px",
          padding: "10px",
          marginTop: "10px",
        }}
      >
        <Box>
          <Button
            sx={{
              cursor: "pointer",
            }}
            onClick={pageNumberBackward}
          >
            <ArrowBackIosIcon />
          </Button>
        </Box>
        <Typography
          variant="button"
          display="block"
          gutterBottom
          sx={{
            marginLeft: "10px",
            marginRight: "10px",
            backgroundColor: "#2D81FF",
            color: "white",
            padding: "5px",
            width: "40px",
            height: "35px",
            borderRadius: "15px",
            textAlign: "center",
          }}
        >
          {pageNumber}
        </Typography>
        <Box>
          <Button
            sx={{
              cursor: "pointer",
            }}
            onClick={pageNumberForward}
            disabled={pageNumber < lastPageNumber ? false : true}
          >
            <ArrowForwardIosIcon />
          </Button>
        </Box>
        <Box>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Page size</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={pageSize}
              label="Page size"
              onChange={(e) => {
                setPageSize(e.target.value);
                // setSearchOrderFlag(true);
              }}
            >
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={500}>500</MenuItem>
              <MenuItem value={1000}>1000</MenuItem>
            </Select>
          </FormControl>
        </Box> 
      </Box>
      {editOrgDialog && (
        <>
          <EditOrgDialogBox
            editOrgDialog={editOrgDialog}
            setEditOrgDialog={setEditOrgDialog}
            selectedRow={selectedRow}
          />
        </>
      )}
      {addFacilityInchargeDialog && (
        <>
        <FacilityInchargeDetails
        addFacilityInchargeDialog={addFacilityInchargeDialog}
        setAddFacilityInchargeDialog={setAddFacilityInchargeDialog}
       facility={facility}/>
        </>
      )

      }
      
    </Box>
    
  );
}
