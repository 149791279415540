import React from "react";
import OrgOrdersListCard from "../../Components/Cards/OrgBookingCards/OrgOrdersListCard";
import { Box, Grid } from "@mui/material";

const staticData = { 
  error: false,
  msg: "Successfully got the organization orders!",
  data: [
    {
      order_id: "BTORD-198",
      org_id: 30,
      facility_id: 28,
      order_type: 200,
      pincode: "603103",
      weekdays: '{"mon"}',
      booking_date: "2024-05-26T18:30:00.000Z",
      delivery_date: "2024-05-26T18:30:00.000Z",
      discount: null,
      final_price: "6.00",
      order_status: 100,
      payment_status: 100,
      delivery_status: 100,
      delivery_address: {
        area_name: "PORUR",
        pincode: "603103",
        state: "TAMIL NADU",
        district: "CHENNAI",
      },
      payment_type: 100,
      payment_method: "WALLET",
      updated_at: "2024-05-27T08:59:38.000Z",
      updated_by: "Lkgx_lbEM",
      products: [
        {
          name: "1litre Glass bottle",
          image: "",
          is_qr: true,
          is_crate: false,
          capacity: "750ml-3",
          material: "GLASS",
          quantity: 2,
          return: 0,
          consumer_received_filled: 0,
          consumer_submitted_empty: 0,
          sku_qty: 3,
          booking_type: 1,
          deposit: {
            price: 0,
            quantity: 2,
          },
          total_gst: {
            perc: 6,
            charges: 0.34,
          },
          extra_charges: {
            hsn_code: 0,
            price: 0,
            cgst: {
              perc: 1,
              price: 0,
            },
            sgst: {
              perc: 1,
              price: 0,
            },
          },
          product_price: 6,
          sub_total_price: 5.66,
          final_price: 6,
          hsn_code: "22110",
          cgst: {
            perc: 6,
            charges: 0,
          },
          sgst: {
            perc: 6,
            charges: 0,
          },
          igst: {
            perc: 6,
            charges: 0.34,
          },
        },
      ],
    },
    {
      order_id: "BTORD-211",
      org_id: 30,
      facility_id: 28,
      order_type: 200,
      pincode: "603103",
      weekdays: '{"mon"}',
      booking_date: "2024-05-26T18:30:00.000Z",
      delivery_date: "2024-05-26T18:30:00.000Z",
      discount: null,
      final_price: "3000.00",
      order_status: 100,
      payment_status: 100,
      delivery_status: 100,
      delivery_address: {
        area_name: "PORUR",
        pincode: "603103",
        state: "TAMIL NADU",
        district: "CHENNAI",
      },
      payment_type: 100,
      payment_method: "WALLET",
      updated_at: "2024-05-27T09:35:17.000Z",
      updated_by: "Lkgx_lbEM",
      products: [
        {
          name: "1litre Glass bottle",
          image: "",
          is_qr: true,
          is_crate: false,
          capacity: "750ml-3",
          material: "GLASS",
          quantity: 1000,
          return: 0,
          consumer_received_filled: 0,
          consumer_submitted_empty: 0,
          sku_qty: 3,
          booking_type: 1,
          deposit: {
            price: 0,
            quantity: 6015,
          },
          total_gst: {
            perc: 6,
            charges: 169.81,
          },
          extra_charges: {
            hsn_code: 0,
            price: 0,
            cgst: {
              perc: 1,
              price: 0,
            },
            sgst: {
              perc: 1,
              price: 0,
            },
          },
          product_price: 3000,
          sub_total_price: 2830.19,
          final_price: 3000,
          hsn_code: "22110",
          cgst: {
            perc: 6,
            charges: 0,
          },
          sgst: {
            perc: 6,
            charges: 0,
          },
          igst: {
            perc: 6,
            charges: 169.81,
          },
        },
      ],
    },
    {
      order_id: "BTORD-214",
      org_id: 30,
      facility_id: 28,
      order_type: 200,
      pincode: "603103",
      weekdays: '{"mon"}',
      booking_date: "2024-06-02T18:30:00.000Z",
      delivery_date: "2024-06-02T18:30:00.000Z",
      discount: null,
      final_price: "30.00",
      order_status: 100,
      payment_status: 100,
      delivery_status: 100,
      delivery_address: {
        area_name: "PORUR",
        pincode: "603103",
        state: "TAMIL NADU",
        district: "CHENNAI",
      },
      payment_type: 100,
      payment_method: "WALLET",
      updated_at: "2024-06-03T13:59:18.000Z",
      updated_by: "Lkgx_lbEM",
      products: [
        {
          name: "1litre Glass bottle",
          image: "",
          is_qr: true,
          is_crate: false,
          capacity: "750ml-3",
          material: "GLASS",
          quantity: 10,
          return: 0,
          consumer_received_filled: 0,
          consumer_submitted_empty: 0,
          sku_qty: 3,
          booking_type: 1,
          deposit: {
            price: 0,
            quantity: 6025,
          },
          total_gst: {
            perc: 6,
            charges: 1.7,
          },
          extra_charges: {
            hsn_code: 0,
            price: 0,
            cgst: {
              perc: 1,
              price: 0,
            },
            sgst: {
              perc: 1,
              price: 0,
            },
          },
          product_price: 30,
          sub_total_price: 28.3,
          final_price: 30,
          hsn_code: "22110",
          cgst: {
            perc: 6,
            charges: 0,
          },
          sgst: {
            perc: 6,
            charges: 0,
          },
          igst: {
            perc: 6,
            charges: 1.7,
          },
        },
      ],
    },
    {
      order_id: "BTORD-216",
      org_id: 30,
      facility_id: 28,
      order_type: 200,
      pincode: "603103",
      weekdays: '{"fri"}',
      booking_date: "2024-06-06T18:30:00.000Z",
      delivery_date: "2024-06-06T18:30:00.000Z",
      discount: null,
      final_price: "30.00",
      order_status: 100,
      payment_status: 100,
      delivery_status: 100,
      delivery_address: {
        area_name: "PORUR",
        pincode: "603103",
        state: "TAMIL NADU",
        district: "CHENNAI",
      },
      payment_type: 100,
      payment_method: "WALLET",
      updated_at: "2024-06-07T06:25:58.000Z",
      updated_by: "Lkgx_lbEM",
      products: [
        {
          name: "1litre Glass bottle",
          image: "",
          is_qr: true,
          is_crate: false,
          capacity: "750ml-3",
          material: "GLASS",
          quantity: 10,
          return: 0,
          consumer_received_filled: 0,
          consumer_submitted_empty: 0,
          sku_qty: 3,
          booking_type: 1,
          deposit: {
            price: 0,
            quantity: 10916956,
          },
          total_gst: {
            perc: 6,
            charges: 1.7,
          },
          extra_charges: {
            hsn_code: 0,
            price: 0,
            cgst: {
              perc: 1,
              price: 0,
            },
            sgst: {
              perc: 1,
              price: 0,
            },
          },
          product_price: 30,
          sub_total_price: 28.3,
          final_price: 30,
          hsn_code: "22110",
          cgst: {
            perc: 6,
            charges: 0,
          },
          sgst: {
            perc: 6,
            charges: 0,
          },
          igst: {
            perc: 6,
            charges: 1.7,
          },
        },
      ],
    },
    {
      order_id: "BTORD-225",
      org_id: 30,
      facility_id: 28,
      order_type: 200,
      pincode: "603103",
      weekdays: '{"fri"}',
      booking_date: "2024-06-06T18:30:00.000Z",
      delivery_date: "2024-06-06T18:30:00.000Z",
      discount: null,
      final_price: "30.00",
      order_status: 100,
      payment_status: 100,
      delivery_status: 100,
      delivery_address: {
        area_name: "PORUR",
        pincode: "603103",
        state: "TAMIL NADU",
        district: "CHENNAI",
      },
      payment_type: 100,
      payment_method: "WALLET",
      updated_at: "2024-06-07T13:30:51.000Z",
      updated_by: "fAE6gG79w",
      products: [
        {
          name: "1litre Glass bottle",
          image: "",
          is_qr: true,
          is_crate: false,
          capacity: "750ml-3",
          material: "GLASS",
          quantity: 10,
          return: 0,
          consumer_received_filled: 0,
          consumer_submitted_empty: 0,
          sku_qty: 3,
          booking_type: 1,
          deposit: {
            price: 0,
            quantity: 10,
          },
          total_gst: {
            perc: 6,
            charges: 1.7,
          },
          extra_charges: {
            hsn_code: 0,
            price: 0,
            cgst: {
              perc: 1,
              price: 0,
            },
            sgst: {
              perc: 1,
              price: 0,
            },
          },
          product_price: 30,
          sub_total_price: 28.3,
          final_price: 30,
          hsn_code: "22110",
          cgst: {
            perc: 6,
            charges: 0,
          },
          sgst: {
            perc: 6,
            charges: 0,
          },
          igst: {
            perc: 6,
            charges: 1.7,
          },
        },
      ],
    },
    {
      order_id: "BTORD-226",
      org_id: 30,
      facility_id: 28,
      order_type: 200,
      pincode: "603103",
      weekdays: '{"sat"}',
      booking_date: "2024-06-07T18:30:00.000Z",
      delivery_date: "2024-06-07T18:30:00.000Z",
      discount: null,
      final_price: "30.00",
      order_status: 100,
      payment_status: 100,
      delivery_status: 100,
      delivery_address: {
        area_name: "PORUR",
        pincode: "603103",
        state: "TAMIL NADU",
        district: "CHENNAI",
      },
      payment_type: 100,
      payment_method: "WALLET",
      updated_at: "2024-06-08T05:43:07.000Z",
      updated_by: "fAE6gG79w",
      products: [
        {
          name: "1litre Glass bottle",
          image: "",
          is_qr: true,
          is_crate: false,
          capacity: "750ml-3",
          material: "GLASS",
          quantity: 10,
          return: 0,
          consumer_received_filled: 0,
          consumer_submitted_empty: 0,
          sku_qty: 3,
          booking_type: 1,
          deposit: {
            price: 0,
            quantity: 10,
          },
          total_gst: {
            perc: 6,
            charges: 1.7,
          },
          extra_charges: {
            hsn_code: 0,
            price: 0,
            cgst: {
              perc: 1,
              price: 0,
            },
            sgst: {
              perc: 1,
              price: 0,
            },
          },
          product_price: 30,
          sub_total_price: 28.3,
          final_price: 30,
          hsn_code: "22110",
          cgst: {
            perc: 6,
            charges: 0,
          },
          sgst: {
            perc: 6,
            charges: 0,
          },
          igst: {
            perc: 6,
            charges: 1.7,
          },
        },
      ],
    },
    {
      order_id: "BTORD-227",
      org_id: 30,
      facility_id: 28,
      order_type: 200,
      pincode: "603103",
      weekdays: '{"sat"}',
      booking_date: "2024-06-07T18:30:00.000Z",
      delivery_date: "2024-06-07T18:30:00.000Z",
      discount: null,
      final_price: "30.00",
      order_status: 100,
      payment_status: 100,
      delivery_status: 100,
      delivery_address: {
        area_name: "PORUR",
        pincode: "603103",
        state: "TAMIL NADU",
        district: "CHENNAI",
      },
      payment_type: 100,
      payment_method: "WALLET",
      updated_at: "2024-06-08T05:48:24.000Z",
      updated_by: "fAE6gG79w",
      products: [
        {
          name: "1litre Glass bottle",
          image: "",
          is_qr: true,
          is_crate: false,
          capacity: "750ml-3",
          material: "GLASS",
          quantity: 10,
          return: 0,
          consumer_received_filled: 0,
          consumer_submitted_empty: 0,
          sku_qty: 3,
          booking_type: 1,
          deposit: {
            price: 0,
            quantity: 20,
          },
          total_gst: {
            perc: 6,
            charges: 1.7,
          },
          extra_charges: {
            hsn_code: 0,
            price: 0,
            cgst: {
              perc: 1,
              price: 0,
            },
            sgst: {
              perc: 1,
              price: 0,
            },
          },
          product_price: 30,
          sub_total_price: 28.3,
          final_price: 30,
          hsn_code: "22110",
          cgst: {
            perc: 6,
            charges: 0,
          },
          sgst: {
            perc: 6,
            charges: 0,
          },
          igst: {
            perc: 6,
            charges: 1.7,
          },
        },
      ],
    },
  ],
};

function OrganizationOrderList() {
  return (
    <Grid container spacing={2}>
      {staticData.data.map((order) => (
        <Grid item key={order.order_id}>
          <OrgOrdersListCard order={order} />
        </Grid>
      ))}
    </Grid>
  );
}

export default OrganizationOrderList;
