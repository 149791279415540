import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  Grid,
  AppBar,
  Toolbar,
  useTheme,
  Button,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Paper } from "@material-ui/core";
import TwentyLiter from "../../../../Assests/FOM/bookwater-story.png";
import OneLiter from "../../../../Assests/FOM/1L.jpg";
import FiveHundredLiter from "../../../../Assests/FOM/500 ml.jpg";
import { CustomCheckStockCard } from "../../../../Components/CustomComp/CustomCheckStockCard";
import { tokens } from "../../../../AppMeta/theme";

export function CheckStock({ open, close }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // 3.Functions
  const handleClose = () => {
    close(false);
  };

  return (
    <>
      <Dialog
         fullScreen={window.innerWidth < 600 ? true : false}
        open={open}
        onClose={close}
        PaperProps={{
          style: {
            backgroundColor: colors.primary[400],
          },
        }}
      >
        <Box>
        <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "20px",
            }}
          >
            <Typography variant="h3" ml="10px" mt="2px" fontWeight="bold">
              Stock
            </Typography>
            <Button
              color="error"
              size="small"
              sx={{ borderRadius: "20px" }}
              backgroundColor="red"
              variant="contained"
              onClick={handleClose}
            >
              <CloseIcon />
            </Button>
          </Box>
          <Divider />

          <DialogContent sx={{ overflow: "hidden" }}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={4}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "auto",
                    }}
                  >
                    <CustomCheckStockCard />
                  </Box>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "auto",
                    }}
                  >
                    <CustomCheckStockCard />
                  </Box>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "auto",
                    }}
                  >

                  </Box>
                </Grid>
              </Grid>
            
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
}
