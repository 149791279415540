import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { sideBarData } from "./sideBarData";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import Products from "../Products/Products";
import AccessManagementTabs1 from "../AccessManagement/AccessManagementTabs1";
import AuthenticationPage from "../Authentication/Authentication";
import FillingStation from "../FillingStation/FillingStation";
import {
  Menu,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import SettingsApplicationsRoundedIcon from "@mui/icons-material/SettingsApplicationsRounded";
import actionTypes from "../../Redux/actionTypes";
import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import bwLogo from "../../Assests/ImagesAndIcons/bwLogo.png";
import "./drawer.css";
import BookingToManufacturer from "../Orders/BookwaterBooking/BookingToManufacturer";
import Orders from "../Orders/Orders";
import Home from "../Home/Home";
import CodesManagement from "../CodesManagement/CodesManagement";
import StockManagement from "../StockManagement/StockManagement";
import CustomPriceForm from "../Settings/Settings";
import PurchaseProduct from "../Orders/PurchaseProduct/PurchaseProduct";
import Sku from "../StockKeepingUnit/Sku";
import { FacilityOrderManagement } from "../Orders/OrganizationBooking/FacilityOrderManagement/FacilityOrderManagement";

import { OrganisationOrderManagement } from "../Orders/OrganizationBooking/OrganisationOrderManagement/OrganisationOrderManagement";
import CheckoutMain from "../Orders/OrganizationBooking/OrganisationOrderManagement/CheckOutSku/CheckOutMain";
// import ApiList from "../Admin/ApiList.jsx";
const drawerWidth = 240;
const settings = [
  { title: "Profile", path: "/profile" },
  { title: "Account", path: "/account" },
  { title: "Dashboard", path: "/dashboard" },
  { title: "Logout", path: "/authentication" },
];
function DashboardSide(props) {
  const { window } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isLogout, setIsLogout] = React.useState(false);
  const [themeToggle, setThemeToggle] = React.useState("dark");

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [itemId, setItemId] = React.useState("");

  // Login verification

  const [dashboard, setDashboard] = React.useState([]);

  const isFacility = false;
  const isBookwater = false;

  React.useEffect(() => {
    if (isFacility) {
      setDashboard(sideBarData.facilityDashBoard);
    } else {
      setDashboard(sideBarData.bookWaterDashboard);
    }
  }, []);

  const handleThemeToggle = (event, newAlignment) => {
    console.log(newAlignment);
    setThemeToggle(newAlignment);
  };

  React.useEffect(() => {
    console.log(themeToggle);
    if (themeToggle) {
      props.setIsDarkMode(true);
    } else {
      props.setIsDarkMode(false);
    }
  }, [themeToggle]);

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNavigator = (title, path, id) => {
    console.log(`Title: ${title}`);
    console.log(`Path: ${path}`);
    console.log(`Id: ${id}`);
    history.push(path);
    setMobileOpen(false);
    setTitle(title);
    setItemId(id);
  };

  const drawer = (
    <div>
      {/* <Box className="drawer"> */}
      <Box
        // className="drawer"
        sx={
          {
            // background: "#152057",
            // height: "100vh",
          }
        }
      >
        <Box
          sx={{
            display: "flex",
            // flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
            //  background: "rgb(208,221,242)",
            //   backgroundImage:
            //    "radial-gradient(circle, rgba(208,221,242,1) 0%, rgba(148,187,233,1) 100%)",
          }}
        >
          <img
            src={bwLogo}
            alt="icon"
            style={{ height: "35px", width: "30px", marginTop: "5px" }}
          />
          <Typography
            variant="overline"
            display="block"
            gutterBottom
            sx={{
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: "bold",
              fontSize: "14px",
              textAlign: "center",
              padding: "5px",
              // color: "black",
            }}
          >
            BookWater
          </Typography>
        </Box>
      </Box>
      <List>
        {dashboard.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              onClick={() => handleNavigator(item.title, item.path, index)}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
        {/* {sideBarData.passDashboard.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              onClick={() => handleNavigator(item.title, item.path, index)}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))} */}
      </List>
      {/* </Box> */}
    </div>
  );

  const handleProfile = (title) => {
    if (title === "Logout") {
      setIsLogout(true);
      dispatch({
        type: actionTypes.SIGN_OUT,
      });
    } else {
      toast.error("Implementation in progress", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const container =
    window !== undefined ? () => window().document.body : undefined;

  if (isLogout) {
    return <Redirect to="authenticate" />;
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {title}
          </Typography>
          <Box sx={{}}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <SettingsApplicationsRoundedIcon sx={{ color: "white" }} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <>
                  <MenuItem
                    key={setting.title}
                    onClick={() => handleProfile(setting.title)}
                    // component={Link}
                    // to={setting.path}
                  >
                    <Typography textAlign="center">{setting.title}</Typography>
                  </MenuItem>
                </>
              ))}

              <Box sx={{ padding: "10px" }}>
                {/* <Button onClick={() => setIsDarkMode(!isDarkMode)}>
                    Theme
                  </Button> */}
                <ToggleButtonGroup
                  value={themeToggle}
                  exclusive
                  onChange={handleThemeToggle}
                  aria-label="text alignment"
                >
                  <ToggleButton value={true} aria-label="left aligned">
                    <DarkModeRoundedIcon />
                  </ToggleButton>
                  <ToggleButton value={false} aria-label="centered">
                    <LightModeRoundedIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <Typography textAlign="center" sx={{ fontWeight: "bold" }}>
                Version: 0.0.13
              </Typography>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          className="drawer"
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          className="drawer"
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Switch>
          <Route path="/authentication" exact component={AuthenticationPage} />
          <Route path="/dashboard/home" exact component={Home} />
          {/* <Route path="/dashboard/products" exact component={Products} /> */}
          <Route path="/dashboard/products" exact component={Sku} />
          {/* <Route path="/dashboard/products" exact component={PurchaseProduct} /> */}
          <Route
            path="/dashboard/user-management"
            exact
            component={AccessManagementTabs1}
          />
          <Route
            path="/dashboard/water-station"
            exact
            component={FillingStation}
          />
          <Route
            path="/dashboard/bookwater-order-management"
            exact
            component={BookingToManufacturer}
          />
          <Route
            path="/dashboard/facility-order-management"
            exact
            component={FacilityOrderManagement}
          />
          <Route
            path="/dashboard/organisation-order-management"
            exact
            component={OrganisationOrderManagement}
          />
          <Route path="/dashboard/checkout" exact component={CheckoutMain} />
          <Route
            path="/dashboard/codesManagement"
            exact
            component={CodesManagement}
          />
          <Route
            path="/dashboard/stock-management"
            exact
            component={StockManagement}
          />
          <Route path="/dashboard/settings" exact component={CustomPriceForm} />
        </Switch>
      </Box>
    </Box>
  );
}

export default DashboardSide;
