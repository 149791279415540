import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import { GetStockDetails } from "../../Redux/Actions/stockManagementAction";
import { appColors } from "../../AppMeta/appColors";

const data = [
  {
    user_id: "Lkgx_lbEM",
    product_id: "7",
    empty: "0",
    new: "0",
    filled: "0",
    expired: "0",
    physically_damaged: "0",
    qr_barcode_damaged: "0",
    deposited_qty: "0",
    undeposited_qty: "0",
    deposited_price: "0.00",
    withdrawl_price: "0.00",
    new_to_provide: "0",
    ongoing_order_qty: "0",
    returning_qty: "0",
    delivery_qty: "0",
    product_type: "TEST",
    product_name: "ATVG",
    product_price: "10.00",
    product_life_span: "10",
    product_detail: {
      material: "SDVD",
    },
    created_at: "2023-09-02 03:09:28 PM",
    updated_at: "2023-09-02 10:09:17 AM",
  },
];

export default function StockManagement() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    console.log("Get Stock API");
    dispatch(GetStockDetails());
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="stock table">
        <TableHead sx={{ backgroundColor: appColors.secondary }}>
          <TableRow>
            <TableCell>Product Name</TableCell>
            <TableCell>Product Type</TableCell>
            <TableCell>Product Price</TableCell>
            <TableCell>New</TableCell>
            <TableCell>Filled</TableCell>
            <TableCell>Expired</TableCell>
            <TableCell>Empty</TableCell>
            <TableCell>Product Details</TableCell>
            {/* Add more table headers for other fields you want to display */}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => (
            <TableRow key={item.product_id}>
              <TableCell>{item.product_name}</TableCell>
              <TableCell>{item.product_type}</TableCell>
              <TableCell>{item.product_price}</TableCell>
              <TableCell>{item.new}</TableCell>
              <TableCell>{item.filled}</TableCell>
              <TableCell>{item.expired}</TableCell>
              <TableCell>{item.empty}</TableCell>
              <TableCell>{item.product_detail.material}</TableCell>
              {/* Add more TableCell components for other fields you want to display */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
