import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import RolesManagement from "./Roles/RolesManagement";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import ApiManagement from "./ApiList/ApiManagement";
import ProvideAccessManager from "./ProvideAccess/ProvideAccessManager";
import OrganizationDetails from "./Organization/OrganizationDetails";
import AddUsers from "./AddUsersRolesOrganization/AddUsers";
import useMediaQuery from "@mui/material/useMediaQuery"; // Import useMediaQuery
import CustomInputTextField from "../../Components/CustomComp/CustomInputTextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OrganizationDetailsList from "./Organization/OrganizationDetailsList";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function AccessManagementTabs1() {
  const [manager, setManager] = React.useState(10);

  const theme = useTheme();

  // Use the useMediaQuery hook to detect small screens (max-width: 600px)
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChangeManager = (event) => {
    setManager(event.target.value);
  };

  React.useEffect(() => {
    console.log(isSmallScreen);
  }, [isSmallScreen]);
  return (
    <Box>
      <Box display="flex" justifyContent="center" margin="10px">
        <FormControl sx={{ width: "50%" }}>
          <InputLabel id="demo-simple-select-label">Manager</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={manager}
            label="Manager"
            onChange={handleChangeManager}
          >
            <MenuItem value={10}>Roles Management</MenuItem>
            <MenuItem value={20}>APIs Management</MenuItem>
            <MenuItem value={30}>Provide Access</MenuItem>
            <MenuItem value={40}>Facility</MenuItem>
            <MenuItem value={50}>Add users</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {manager === 10 && <RolesManagement />}
      {manager === 20 && <ApiManagement />}
      {manager === 30 && <ProvideAccessManager />}
      {manager === 40 && <OrganizationDetails />}
      {manager === 50 && <AddUsers />}

      {/* <Tabs
        value={value}
        onChange={handleChange}
        variant={isSmallScreen ? "scrollable" : "standard"}
        scrollButtons={isSmallScreen ? "auto" : "off"}
        aria-label="scrollable auto tabs example"
      >
        <Tab label="Roles Management" />
        <Tab label="APIs Management" />
        <Tab label="Provide Access" />
        <Tab label="Organization" />
        <Tab label="Add users" />
      </Tabs>
      <TabPanel value={value} index={0} dir={theme.direction}>
        <RolesManagement />
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <ApiManagement />
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        <ProvideAccessManager />
      </TabPanel>
      <TabPanel value={value} index={3} dir={theme.direction}>
        <OrganizationDetails />
      </TabPanel>
      <TabPanel value={value} index={4} dir={theme.direction}>
        <AddUsers />
      </TabPanel> */}
    </Box>
  );
}
