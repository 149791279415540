import { Box, Button, Grid } from "@mui/material";
import React from "react";
import CheckOutOverView from "./CheckOutOverView";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import PriceBreakUpCardSkuWeb from "../../../../../Components/Cards/OrgBookingCards/PriceBreakUpCard";
import { CheckOutAction } from "../../../../../Redux/Actions/checkOutAction";
import PaymentCard from "../../../../../Components/Cards/OrgBookingCards/PaymentCard";

export default function CheckOutMain() {
  const dispatch = useDispatch();
  const SkuSelectedProducts = useSelector((state) => state.skuBookingDetails);
  const checkoutDetails = useSelector((state) => state.checkOutDetails);

  const [invalidProducts, setInvalidProducts] = React.useState([]);
  const [currentDate, setCurrentDate] = React.useState(
    SkuSelectedProducts?.selectedProduct?.delivery_date
  );
  const [deliveryCharges, setDeliveryCharges] = React.useState(0);

  const [products, setProducts] = React.useState({
    products: [],
    booking_type: SkuSelectedProducts?.selectedProduct?.booking_type,
    org_id: 30,
    coupon_code: SkuSelectedProducts?.selectedProduct?.coupon_code,
    water_drops: SkuSelectedProducts?.selectedProduct?.water_drops,
    payment_method: SkuSelectedProducts?.selectedProduct?.payment_method,
    is_otp_less: SkuSelectedProducts?.selectedProduct?.is_otp_less,
    is_auto_apply_water_drops:
      SkuSelectedProducts?.selectedProduct?.is_auto_apply_water_drops,
    delivery_date: moment(currentDate).format("YYYY-MM-DD"),
    is_auto_apply_coupon:
      SkuSelectedProducts?.selectedProduct?.is_auto_apply_coupon,
    secondary_payment_method: "",
  });

  //useeffect to get valid products quantity as isvaild
  React.useEffect(() => {
    const products = checkoutDetails?.checkOutDetails?.valid_products;
    if (products !== undefined && products?.length > 0) {
      const invalid = products.filter(
        (product) => !product.quantity.is_valid || !product.return.is_valid
      );
      setInvalidProducts(invalid);
    }
  }, [checkoutDetails]);

  // //useeffect for hitting update api if data changes
  React.useEffect(() => {
    console.log("SkuSelectedProducts in main");
    console.log(SkuSelectedProducts);
    if (SkuSelectedProducts.selectedProduct?.products?.length > 0) {
      if (
        // (pageName && pageName === "subscribe") ||
        // (pageName && pageName.includes("editSubscribe"))
        false
      ) {
        // dispatch(SubscribeCheckOutAction(SkuSelectedProducts.selectedProduct));
      } else {
        dispatch(CheckOutAction(SkuSelectedProducts.selectedProduct));
      }
    }
  }, [SkuSelectedProducts.selectedProduct]);
  React.useEffect(() => {
    //function for getting selected quantity
    const ExtraCharges =
      checkoutDetails?.checkOutDetails?.valid_products?.reduce(
        (sum, product) => {
          if (product.extra_charges.price !== undefined) {
            sum += product.extra_charges.price;
          }
          return sum;
        },
        0
      );
    setDeliveryCharges(ExtraCharges);
  }, [checkoutDetails?.checkOutDetails]);
  return (
    <Box
      sx={{
        marginTop: { xs: "3.6rem" },
        paddingRight: "5px",
        paddingBottom: "20px",
        paddingLeft: "5px",
        marginBottom: { sm: "1rem", xs: "8rem" },
      }}
    >
      <Grid
        direction={{
          xs: "row",
          md: "row",
          sm: "row",
          position: "relative",
        }}
        justifyContent={{
          xs: "center",
          md: "center",
          sm: "center",
          lg: "space-between",
        }}
        alignItems={{
          xs: "center",
          md: "center",
          sm: "center",
          lg: "flex-start",
        }}
        container
        spacing={2}
      >
        <Grid item xs={12} lg={8}>
          <CheckOutOverView
            // primaryAddress={primaryAddress}
            // setchooseAddressDialog={setchooseAddressDialog}
            // setCurrentDate={setCurrentDate}
            // currentDate={currentDate}
            products={products}
            setProducts={setProducts}
            // setpromoCode={setpromoCode}
            // promoCode={promoCode}
            // setEnteredPayAmount={setEnteredPayAmount}
            // minimumWalletAmount={minimumWalletAmount}
            // setSubscribeObject={setSubscribeObject}
            // samedayDelivery={samedayDelivery}
            // setSameDayDelivery={setSameDayDelivery}
            // deliveryCharges={deliveryCharges}
            invalidProducts={invalidProducts}
          />
        </Grid>
        <Grid item xs={12} md={10} lg={4}>
          <PriceBreakUpCardSkuWeb deliveryCharges={deliveryCharges} />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PaymentCard />
        </Grid>
      </Grid>
    </Box>
  );
}
