/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import BIcon from "./../../Assets/Images/b_icon.png";
import * as Material from "@mui/material";

import useAxios from "../../../Hooks/useAxios";
import axios from "axios";

const PayByRazorPay = (props) => {
  const api = useAxios();
  console.log("----props here----");
  console.log(props);
  const dispatch = useDispatch();
  console.log("PayByRazorPay");
  console.log(props.orderId);
  const profileDetails = useSelector((state) => state.userProfile);
  const vbDetails = useSelector((state) => state.myAccounts);
  const discribe = {
    order_id: props.orderId ? props.orderId : "WLT",
    user_id: props.userId,
  };

  React.useEffect(() => {
    if (props.promoCode !== undefined && props.promoCode !== "") {
      discribe.promo_code = props.promoCode;
    }
  }, [props.promoCode]);

  const openPayModal = () => {
    const options = {
      // key: process.env.REACT_APP_BW_RAZORPAY_KEY,
      key: "rzp_test_dbgkGCz5DCmIKD",
      amount: 0, //  = INR 1
      name: "Book Water",
      order_id: "",
      description: JSON.stringify(discribe),
      // image: BIcon,/
      handler: function (response) {
        console.log("---------------payment.Success---------------");
        response.name = profileDetails?.user.fullname;
        response.phone = profileDetails?.user.phone;
        response.email = profileDetails?.user.email;
        response.order_id = props.orderId;
        console.log(response);
        props.handleRazorPayResponse({ error: false, response });
      },
      retry: {
        enabled: false,
        max_count: 1,
      },
      timeout: 600, //5 Minutes Razor Pay will close
      modal: {
        escape: false,
        ondismiss: function () {
          props.handleRazorPayClose();
        },
      },
      prefill: {
        name: "",
        contact: "",
        email: "",
      },
      notes: {
        address: "",
      },
      theme: {
        color: "#090342",
        hide_topbar: false,
      },
    };
    // var ret = false;

    api
      // .post("http://0.0.0.0:6008/POPM/razorpay/createOrder", {
      .post("/POPM/razorpay/createOrder", {
        amount: parseFloat(Math.round(props.amount)),
        // token: "",
      })
      .then((res) => {
        console.log("--- Response From Backend ----");
        console.log(res);
        options.order_id = res.data.order_id;
        options.amount = parseFloat(Math.round(props.amount)) * 100;
        console.log("-----options here-----");
        console.log(options);
        var rzp1 = new window.Razorpay(options);
        rzp1.open();
        rzp1.on("payment.failed", function (response) {
          console.log("---------------payment.Failed---------------");
          console.log(response);

          props.handleRazorPayResponse({ error: true, response });
        });
      });
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = process.env.REACT_APP_RAZORPAY_CHECKOUT_URL;
    script.async = true;
    document.body.appendChild(script);
    openPayModal();
  }, []);

  return <></>;
};

export default PayByRazorPay;
