/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description API management main component
 * @date 26/07/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries
import React from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CustomTypography from "../../../Components/CustomComp/CustomTypography";
import CustomInputTextField from "../../../Components/CustomComp/CustomInputTextField";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { GetRoles } from "../../../Redux/Actions/rolesAction";
import ApiList from "./ApiList";
import { AddApis, GetApis } from "../../../Redux/Actions/apisDetailsAction";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Dialog } from "@mui/material";
// import CustomTable from "../../Components/CustomComp/CustomTable";

const styles = {
  mainDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
    padding: "20px",
  },
};

function ApiManagement() {
  // Required variables declared here
  const dispatch = useDispatch();
  const apisData = useSelector((state) => state.apiListData);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(25);
  const [apiName, setApiName] = React.useState("");
  const [isActive, setIsActive] = React.useState(true);
  const [ascOrDesc, setAscOrDesc] = React.useState("ASC");
  const [lastPageNumber, setLastPageNumber] = React.useState(null);
  const [apiDescription, setApiDescription] = React.useState("");
  const [httpsMethod, setHttpsMethod] = React.useState("GET");
  const [listOfApis, setListOfApis] = React.useState([]);
  // Update Apis variables
  const [roleUpdateBtn, setRoleUpdateBtn] = React.useState(false);
  const [addNewApiDialog, setAddNewApiDialog] = React.useState(false);

  React.useEffect(() => {
    console.log(apisData);
    console.log(apisData.apisList.last_page_number);
    setLastPageNumber(apisData.apisList.last_page_number);
    if (apisData.error === false) {
      if (apisData.apisList?.data?.length > 0) {
        setListOfApis(apisData.apisList.data);
      }
    }
  }, [apisData]);

  // Hitting get API here to get the list
  React.useEffect(() => {
    // dispatch(GetRoles(pageNumber - 1, pageSize));
    dispatch(GetApis(pageNumber, pageSize, ascOrDesc));
  }, [pageSize]);

  // Handling API name change
  const handleChangeApiName = (event) => {
    const newValue = event.target.value;
    setApiName(newValue);
    console.log(newValue);
  };
  // Handling description change
  const handleChangeApiDescription = (event) => {
    const newValue = event.target.value;
    setApiDescription(newValue);
    console.log(newValue);
  };
  // Handling adding the new API by hitting the ADD API with request parameters
  const handleAddApiClick = () => {
    console.log("Add role");
    dispatch(AddApis(`${apiName}`, apiDescription, httpsMethod));
    setTimeout(() => {
      dispatch(GetApis());
    }, 1000);
  };

  return (
    <Box>
      {/* APIs List Table component call */}
      {listOfApis.length > 0 ? (
        <>
          <ApiList
            apisData={apisData}
            setAddNewApiDialog={setAddNewApiDialog}
            isActive={isActive}
            setIsActive={setIsActive}
            ascOrDesc={ascOrDesc}
            setAscOrDesc={setAscOrDesc}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageSize={pageSize}
            lastPageNumber={lastPageNumber}
          />
        </>
      ) : (
        ""
      )}
      {/* Dialog to add new api  */}
      <Dialog open={addNewApiDialog} onClose={() => setAddNewApiDialog(false)}>
        <Box style={styles.mainDiv}>
          <CustomTypography
            content="API Management"
            fontSize="18px"
            fontWeight="bold"
          />
          <CustomInputTextField
            placeholder="API Name"
            size="large"
            value={apiName}
            setValue={setApiName}
            handleChange={handleChangeApiName}
          />
          <CustomInputTextField
            placeholder="API Description"
            size="large"
            value={apiDescription}
            setValue={setApiDescription}
            handleChange={handleChangeApiDescription}
          />
          <FormControl sx={{ m: 1, minWidth: 230 }} size="medium">
            <InputLabel id="demo-select-small-label">Https Method</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={httpsMethod}
              label="Https Method"
              onChange={(event) => setHttpsMethod(event.target.value)}
            >
              <MenuItem value={"GET"}>GET</MenuItem>
              <MenuItem value={"PUT"}>PUT</MenuItem>
              <MenuItem value={"POST"}>POST</MenuItem>
            </Select>
          </FormControl>
          <CustomButton
            label="Add API"
            rounded={true}
            size="medium"
            variant="contained"
            handleClick={handleAddApiClick}
          />
        </Box>
      </Dialog>
    </Box>
  );
}

export default ApiManagement;
