import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  Chip,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateManufacturingOrders,
  GetManufacuringOrders,
  MftCompleteOrder,
  MftOrdersApproval,
} from "../../../Redux/Actions/manufacturingOrdersAction";
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
import { UploadFile } from "../../../Redux/Actions/fileHandlingActions";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { styled } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import MftOrderDetailsDialog from "./MftOrderDetailsDialog";
import {
  GenerateQR,
  GetGeneratedQR,
} from "../../../Redux/Actions/GenerateQrAction";
import RegenerateQrDialog from "./RegenerateQrDialog";
import { appColors } from "../../../AppMeta/appColors";
import QrBarcodeDetailsDialog from "./QrBarcodeDetailsDialog";
import CustomDialogMoreDetails from "../../../Components/CustomComp/CustomDialogMoreDetails";
import { toast } from "react-toastify";
import RecreateOrderDialog from "./RecreateOrderDialog";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SearchIcon from "@mui/icons-material/Search";
import OrderDetailsDialog from "./OrderDetailsDialog";
import EditOrderDialog from "./EditOrderDialog";
import { GetManuFacturer } from "../../../Redux/Actions/ManufacturersAction";
import { GetProductSku } from "../../../Redux/Actions/ProductComponentsAction";

// Sample data from your API response

const useStyles = makeStyles((theme) => ({
  smallButton: {
    padding: "5px 7px", // Customize padding to make it smaller
    fontSize: "0.60rem",
    // Reduce font size to make it smaller
  },
}));
export default function AllMftOrders({
  value,
  setValue,
  setMftName,
  setMftEmail,
  setMftPhone,
  setMftAreaName,
  setMftPincode,
  setNoOfItems,
  setProduct,
  setTotalPrice,
  pageNumber,
  pageSize,
  setPageNumber,
  setPageSize,
  lastPageNumber,
  setOrderIdSearch,
}) {
  const classes = useStyles();

  const mftOrderDetails = useSelector((state) => state.mftOrdersData);
  const productDetails = useSelector((state) => state.productComponentsData);

  const filesData = useSelector((state) => state.filesData);

  const dispatch = useDispatch();
  const [isPoApproved, setIsPoApproved] = React.useState(false);
  const [isPrApproved, setIsPrApproved] = React.useState(false);
  const [isOrderCompleted, setIsOrderCompleted] = React.useState(false);
  const [prFile, setPrFile] = React.useState("");
  const [uploadPrBtn, setUploadPrBtn] = React.useState(false);
  const [uploadedFileName, setUploadedFileName] = React.useState("");
  const [selectedOrderData, setSelectedOrderData] = React.useState(null);
  const [openMftOrderDialog, setOpenMftOrderDialog] = React.useState(false);

  const [openRegenerateQrDialog, setOpenRegenerateQrDialog] =
    React.useState(false);

  const [openQrDetailsDialog, setOpenQrDetailsDialog] = React.useState(false);
  const [generatedCodesList, setGeneratedCodesList] = React.useState([]);

  const [moreDetails, setMoreDetails] = React.useState({});
  const [selectedRow, setSelectedRow] = React.useState({});
  const [openCustomDialog, setOpenCustomDialog] = React.useState(false);
  const [openOrderDetailsDialog, setOpenOrderDetailsDialog] =
    React.useState(false);
  const [recreateOrderDialog, setRecreateOrderDialog] = React.useState(false);
  const [isProductAvailable, setIsProductAvailable] = React.useState(true);
  const [editOrderDialog, setEditOrderDialog] = React.useState(false);
  const [dataToEdit, setDataToEdit] = React.useState({});
  const [allProducts, setAllProducts] = React.useState([]);

  const prInputRef = React.useRef(null);

  React.useEffect(() => {
    dispatch(GetProductSku());
  }, []);
  React.useEffect(() => {
    console.log("Product details");
    console.log(productDetails);
    if (productDetails.error === false) {
      setAllProducts(productDetails?.products);
    }
  }, [productDetails]);
  React.useEffect(() => {
    dispatch(GetManuFacturer());
  }, []);
  React.useEffect(() => {
    console.log("mftOrderDetails");
    console.log(mftOrderDetails);
    mftOrderDetails?.mftOrders?.data?.map((order) => {
      if (order.order_status === "PO_APPROVED") {
        setIsPoApproved(true);
      } else if (order.order_status === "PR_APPROVED") {
        setIsPoApproved(true);
        setIsPrApproved(true);
      } else if (order.order_status === "COMPLETED") {
        setIsPoApproved(true);
        setIsPrApproved(true);
        setIsOrderCompleted(true);
      }
    });
  }, [mftOrderDetails]);

  const rows = mftOrderDetails?.mftOrders?.data; // Extract the actual data from the API response
  const pageNumberForward = () => {
    if (mftOrderDetails?.mftOrders?.data.length > 0) {
      setPageNumber(pageNumber + 1);
      // setSearchOrderFlag(true);
    }
  };
  const pageNumberBackward = () => {
    if (pageNumber - 1 > 0) {
      setPageNumber(pageNumber - 1);
      // setdisableBtn(false);
      // setSearchOrderFlag(true);
    }
  };
  const handleUploadPR = () => {
    prInputRef.current.click();
  };
  const handleChangePRUpload = (event) => {
    console.log("Selected PR file:", event.target.files[0]);
    setPrFile(event.target.files[0]);
    setUploadPrBtn(true);
  };

  React.useEffect(() => {
    console.log("Upload PR button useEffect");
    if (uploadPrBtn === true) {
      console.log("Hitting Upload API");
      dispatch(UploadFile(prFile));
      setUploadPrBtn(false);
    }
  }, [uploadPrBtn]);

  React.useEffect(() => {
    console.log("Files Data");
    console.log(filesData);
    if (filesData.error === false) {
      setUploadedFileName(filesData.fileName);
    }
  }, [filesData]);

  const handleGenerateQr = (orderId) => {
    console.log(`orderId ${orderId}`);
    const reqObj = {
      order_id: orderId,
    };
    dispatch(GenerateQR(reqObj));
    setTimeout(() => {
      dispatch(GetManufacuringOrders());
    }, 1000);
  };

  const handleRegenerateQr = (orderId) => {
    setOpenRegenerateQrDialog(true);
  };

  const handleClickShowMore = (details) => {
    console.log("details");
    console.log(details);
    setMoreDetails(details);
    // setOpenCustomDialog(true);
    setOpenOrderDetailsDialog(true);
  };
  const handleClickEditOrder = (details) => {
    console.log(details);
    setDataToEdit(details);
    setEditOrderDialog(true);
  };
  const handleRecreateOrder = (row) => {
    console.log("row recreate");
    console.log(row);
    setSelectedRow(row);
    setMoreDetails(row.mft_details);
    if (row.porduct_details.is_available) {
      setIsProductAvailable(true);
      setRecreateOrderDialog(true);
    } else {
      setIsProductAvailable(false);
      setRecreateOrderDialog(true);
    }
  };

  const handleChangeOrderId = (event) => {
    setOrderIdSearch(event.target.value);
    console.log(event.target.value);
  };

  const getColor = (orderType) => {
    switch (orderType) {
      case "400":
        return "#11f583"; // Processing Partner
      case "100":
        return "green"; // Purchase Product
      default:
        return "blue"; // Default color
    }
  };
  return (
    <Box>
      <Toolbar>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <Paper>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search Order ID"
              inputProps={{ "aria-label": "search google maps" }}
              onChange={handleChangeOrderId}
            />
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              // onClick={handleSearchOrderID}
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </Box>
      </Toolbar>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: appColors.secondary }}>
            <TableRow>
              <TableCell>Order ID</TableCell>
              <TableCell>Order Type</TableCell>
              <TableCell>Manufacturer</TableCell>
              {/* <TableCell>Product Type</TableCell> */}
              {/* <TableCell>Price</TableCell> */}
              {/* <TableCell>QR Generated?</TableCell> */}
              <TableCell>Order Status</TableCell>
              <TableCell>More Details</TableCell>
              <TableCell>Edit Order</TableCell>
              {/* <TableCell>Re-create Order</TableCell> */}
              {/* Add more headers as needed */}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => (
              <TableRow
                key={row.order_id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  cursor: "pointer",
                }}
                onClick={(event) => {
                  if (event.target.tagName !== "BUTTON") {
                    setSelectedOrderData(row);
                    // setOpenMftOrderDialog(true);
                  }
                }}
              >
                <TableCell component="th" scope="row">
                  {row.order_id}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ color: getColor(row.order_type) }}
                >
                  {row.order_type === "400"
                    ? "Processing Partner"
                    : "Purchase Product"}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.mft_details.details.name}
                </TableCell>
                {/* <TableCell component="th" scope="row">
                  {row.porduct_details.type}
                </TableCell> */}

                {/* <TableCell component="th" scope="row">
                  {row.price}
                </TableCell> */}
                {/* 
                <TableCell>
                  {row.mft_details?.qr_data?.is_qr_generated ? (
                    "YES"
                  ) : (
                    <Button
                      color="success"
                      variant="outlined"
                      size="small"
                      onClick={() => handleGenerateQr(row.order_id)}
                    >
                      Generate QR
                    </Button>
                  )}
                </TableCell> */}

                <TableCell>{row.order_status}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    size="small"
                    // onClick={() => handleClickShowMore(row.mft_details)}
                    onClick={() => handleClickShowMore(row)}
                  >
                    Show More
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    // onClick={() => handleClickShowMore(row.mft_details)}
                    onClick={() => handleClickEditOrder(row)}
                  >
                    Edit Order
                  </Button>
                </TableCell>
                {/* <TableCell> 
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={() => handleRecreateOrder(row)}
                  >
                    Re-create Order
                  </Button>
                </TableCell> */}
                {/* {isPrApproved === false && (
                <TableCell>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                    }}
                  >
                    <Box>
                      <Tooltip title="Upload Purchase Order" position="top">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleUploadPR}
                          edge="end"
                        >
                          <UploadRoundedIcon />
                        </IconButton>
                      </Tooltip>
                      <input
                        type="file"
                        ref={prInputRef}
                        style={{ display: "none" }}
                        onChange={handleChangePRUpload}
                      />
                    </Box>
                    <Box>
                      <Box>
                        <Chip
                          label={`Upload Success (${uploadedFileName})`}
                          variant="outlined"
                          onClick={() => setUploadedFileName("")}
                          icon={<CloseRoundedIcon />}
                          color="success"
                        />
                      </Box>
                    </Box>
                  </Box>
                </TableCell>
              )} */}

                {/* {uploadedFileName !== "" && (
                <TableCell>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                    }}
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      color="success"
                      className={classes.smallButton}
                      onClick={() => {
                        const reqObj = {
                          is_po: false,
                          is_approved: true,
                          order_id: row.order_id,
                          is_cancel_order: false,
                        };
                        dispatch(MftOrdersApproval(reqObj));
                        // Handle PO Approval button click here
                        // You can use the row's data for further processing
                      }}
                    >
                      Approve
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      className={classes.smallButton}
                      onClick={() => {
                        const reqObj = {
                          is_po: false,
                          is_approved: false,
                          order_id: "BWMFT009",
                          is_cancel_order: false,
                        };
                        dispatch(MftOrdersApproval(reqObj));
                        // Handle PO Approval button click here
                        // You can use the row's data for further processing
                      }}
                    >
                      Reject
                    </Button>
                  </Box>
                </TableCell>
              )} */}

                {/* {isPoApproved !== true && (
                <TableCell>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => {
                        const reqObj = {
                          is_po: true, 
                          is_approved: true,
                          order_id: row.order_id,
                          is_cancel_order: false,
                        };
                        dispatch(MftOrdersApproval(reqObj));
                        // Handle PO Approval button click here
                        // You can use the row's data for further processing
                      }}
                    >
                      Approve
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        const reqObj = {
                          is_po: true,
                          is_approved: false,
                          order_id: "BWMFT009",
                          is_cancel_order: false,
                        };
                        dispatch(MftOrdersApproval(reqObj));
                        // Handle PO Approval button click here
                        // You can use the row's data for further processing
                      }}
                    >
                      Reject
                    </Button>
                  </Box>
                </TableCell>
              )} */}

                {/* {isOrderCompleted !== true && (
                <TableCell>
                  {" "}
                  <Button
                    variant="outlined"
                    className={classes.smallButton}
                    color="success"
                    onClick={() => {
                      const reqObj = {
                        order_id: row.order_id,
                        is_complete: true,
                      };
                      dispatch(MftCompleteOrder(reqObj));
                    }}
                  >
                    Complete Order
                  </Button>
                </TableCell>
              )} */}

                {/* Add more cells as needed */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          gap: "15px",
          padding: "10px",
          marginTop: "10px",
        }}
      >
        <Box>
          <Button
            sx={{
              cursor: "pointer",
            }}
            onClick={pageNumberBackward}
          >
            <ArrowBackIosIcon />
          </Button>
        </Box>
        <Typography
          variant="button"
          display="block"
          gutterBottom
          sx={{
            marginLeft: "10px",
            marginRight: "10px",
            backgroundColor: "#2D81FF",
            color: "white",
            padding: "5px",
            width: "40px",
            height: "35px",
            borderRadius: "15px",
            textAlign: "center",
          }}
        >
          {pageNumber}
        </Typography>
        <Box>
          <Button
            sx={{
              cursor: "pointer",
            }}
            onClick={pageNumberForward}
            // disabled={pageNumber < lastPageNumber ? false : true}
          >
            <ArrowForwardIosIcon />
          </Button>
        </Box>
        <Box>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Page size</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={pageSize}
              label="Page size"
              onChange={(e) => {
                setPageSize(e.target.value);
                // setSearchOrderFlag(true);
              }}
            >
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={500}>500</MenuItem>
              <MenuItem value={1000}>1000</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      {editOrderDialog && (
        <>
          <EditOrderDialog
            editOrderDialog={editOrderDialog}
            setEditOrderDialog={setEditOrderDialog}
            dataToEdit={dataToEdit}
            allProducts={allProducts}
          />
        </>
      )}
      {openMftOrderDialog && (
        <>
          <MftOrderDetailsDialog
            openMftOrderDialog={openMftOrderDialog}
            setOpenMftOrderDialog={setOpenMftOrderDialog}
            selectedOrderData={selectedOrderData}
          />
        </>
      )}

      {openRegenerateQrDialog && (
        <>
          <RegenerateQrDialog
            openRegenerateQrDialog={openRegenerateQrDialog}
            setOpenRegenerateQrDialog={setOpenRegenerateQrDialog}
            generatedCodesList={generatedCodesList}
          />
        </>
      )}

      {openQrDetailsDialog && (
        <QrBarcodeDetailsDialog
          openQrDetailsDialog={openQrDetailsDialog}
          setOpenQrDetailsDialog={setOpenQrDetailsDialog}
        />
      )}

      {openCustomDialog && (
        <CustomDialogMoreDetails
          openCustomDialog={openCustomDialog}
          setOpenCustomDialog={setOpenCustomDialog}
          title={"Manufacurer Details"}
          moreDetails={moreDetails}
        />
      )}

      {openOrderDetailsDialog && (
        <OrderDetailsDialog
          openOrderDetailsDialog={openOrderDetailsDialog}
          setOpenOrderDetailsDialog={setOpenOrderDetailsDialog}
          moreDetails={moreDetails}
        />
      )}
      {recreateOrderDialog && (
        <>
          <RecreateOrderDialog
            recreateOrderDialog={recreateOrderDialog}
            setRecreateOrderDialog={setRecreateOrderDialog}
            value={value}
            setValue={setValue}
            moreDetails={moreDetails}
            selectedRow={selectedRow}
            setMftName={setMftName}
            setMftEmail={setMftEmail}
            setMftPhone={setMftPhone}
            setMftAreaName={setMftAreaName}
            setMftPincode={setMftPincode}
            isProductAvailable={isProductAvailable}
            setNoOfItems={setNoOfItems}
            setProduct={setProduct}
            setTotalPrice={setTotalPrice}
          />
        </>
      )}
    </Box>
  );
}
