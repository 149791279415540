import actionTypes from "../actionTypes";

const initialState = {
  error: false,
  msg: "",
  lines: [],
  iotDevices: [],
};

const linesReducer = (state = initialState, action) => {
  console.log(action);
  switch (action.type) {
    case actionTypes.ADD_LINK_FACILITY:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionTypes.ADD_IOT_FACILITY:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionTypes.GET_LINK_FACILITY:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        lines: action.payload.data,
      };
    case actionTypes.GET_IOT_DEVICES:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        iotDevices: action.payload.data,
      };
    case actionTypes.PUT_LINK_FACILITY:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    default:
      return state;
  }
};

export default linesReducer;
