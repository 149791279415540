import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Scanner from "../QrCodeScanner/Scanner";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import CustomNotifyDialog from "./CustomNotificationDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  ClearLinkingData,
  LinkQrBarcodes,
  RelinkQrBarcodes,
  ValidateQrBarcodesRelink,
} from "../../Redux/Actions/linkingQrBarcodesAction";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomScannerDialog({
  openScannerDialog,
  setOpenScannerDialog,
  currentScanned,
  setCurrentScanned,
  validData,
  setValidData,
  invalidData,
  setInvalidData,
  validDataToApi,
  setValidDataToApi,
}) {
  const dispatch = useDispatch();
  const linkingData = useSelector((state) => state.linkingData);

  const [value, setValue] = React.useState(0);
  const [codeId, setCodeId] = React.useState("");
  const [qrBarcodeId, setQrBarcodeId] = React.useState("");
  const [openNotifyDialog, setOpenNotifyDialog] = React.useState(false);

  const [selectedPosition, setSelectedPosition] = React.useState([]);

  const [selectedItems, setSelectedItems] = React.useState([]);
  const [showScannedPositionValid, setShowScannedPositionValid] =
    React.useState(false);
  const [showScannedPositionInvalid, setShowScannedPositionInvalid] =
    React.useState(false);
  
  React.useEffect(()=>{
    console.log("Linking Data");
    console.log(linkingData)
  })
  
    const handleClose = () => {
    setOpenScannerDialog(false);
  };


  const onNewScanResult = (decodedText, decodedResult) => {
    console.log("App [result]", decodedResult);
    console.log(decodedText);
    // setCurrentScanned((prev) => [...prev, decodedText]);
    const newScannedCode = { id: decodedText, id2: "" };

    setCurrentScanned((prev) => {
      // Check if the last object in the array has an empty 'id2'
      // If yes, update 'id2' with the current decodedText
      if (prev.length > 0 && prev[prev.length - 1].id2 === "") {
        prev[prev.length - 1].id2 = decodedText;
        return [...prev];
      }
      // If not, add a new object to the array with the current decodedText
      return [...prev, newScannedCode];
    });
    if (decodedResult.result.format.formatName === "QR_CODE") {
      //   setScannedQrCode(decodedText);
      //   console.log(scanCount);
    }
    if (decodedResult.result.format.formatName === "CODE_128") {
      //   setScannedBarCode(decodedText);
      //   console.log(scanCount);
    }
    // setDecodedResults((prev) => [...prev, decodedResult]);
    // setScanQrFlag(false);
    // setScanOption("scanner");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleQrBarcodeIdFromScanner = (event) => {
    console.log(event.target.value);
    setCodeId(event.target.value);
    setTimeout(() => {
      if (event.target.value !== qrBarcodeId) {
        setQrBarcodeId(event.target.value);
      } else {
        console.log("code not set");
      }
    }, 500);
  };

  React.useEffect(() => {
    if (qrBarcodeId !== "") {
      if (!currentScanned.includes(qrBarcodeId)) {
        // setCurrentScanned((prev) => [...prev, qrBarcodeId]);
        const newScannedCode = { id1: qrBarcodeId, id2: "" };
        setCurrentScanned((prev) => {
          // Check if the last object in the array has an empty 'id2'
          // If yes, update 'id2' with the current decodedText
          if (prev.length > 0 && prev[prev.length - 1].id2 === "") {
            prev[prev.length - 1].id2 = qrBarcodeId;
            return [...prev];
          }
          // If not, add a new object to the array with the current decodedText
          return [...prev, newScannedCode];
        });
      } else {
        console.log("Already scanned");
      }
    }
    setTimeout(() => {
      setQrBarcodeId("");
      setCodeId("");
    }, 500);
  }, [qrBarcodeId]);

  const handleLink = () => {
    setOpenNotifyDialog(true);
  };

  const handleConfirmLink = () => {
    console.log("Confirm Link");
    const reqObj = {
      is_relinking: false,
      data: currentScanned,
    };
    dispatch(ValidateQrBarcodesRelink(reqObj));
    setOpenNotifyDialog(false);
  };
  const handleCloseNotifyDialog = () => {
    setOpenNotifyDialog(false);
  };

  const handleLinkValidCans = () => {
    const reqObj = {
      data: validDataToApi,
    };
    dispatch(LinkQrBarcodes(reqObj));
    setTimeout(() => {
      setCurrentScanned([]);
      setValidData([]);
      setInvalidData([]);
      setValidDataToApi([]);
      localStorage.removeItem("valid_data");
      setOpenScannerDialog(false);
    }, 1000);
  };

  const handleReLinkValidCans = () => {
    const reqObj = {
      data: validDataToApi,
    };
    dispatch(RelinkQrBarcodes(reqObj));
    setTimeout(() => {
      setCurrentScanned([]);
      setValidData([]);
      setInvalidData([]);
      setValidDataToApi([]);
      setOpenScannerDialog(false);
    }, 1000);
  };

  const [deselectedItems, setDeselectedItems] = React.useState([]);

  React.useEffect(() => {
    setDeselectedItems(validData);
  }, [validData]);

  // const handleItemClick = (scannedPosition, item) => {
  //   // Toggle selection
  //   setSelectedPosition((prevSelectedItems) => {
  //     if (prevSelectedItems.includes(scannedPosition)) {
  //       // If already selected, remove from the array
  //       return prevSelectedItems.filter((item) => item !== scannedPosition);
  //     } else {
  //       // If not selected, add to the array
  //       return [...prevSelectedItems, scannedPosition];
  //     }
  //   });

  //   setSelectedItems((prevSelectedItems) => {
  //     if (
  //       prevSelectedItems.some(
  //         (selectedItem) =>
  //           selectedItem.scannedPosition === item.scannedPosition
  //       )
  //     ) {
  //       // If already selected, remove from the array
  //       return prevSelectedItems.filter(
  //         (selectedItem) =>
  //           selectedItem.scannedPosition !== item.scannedPosition
  //       );
  //     } else {
  //       // If not selected, add to the array
  //       setUnselectedItems((prevUnselectedItems) =>
  //         prevUnselectedItems.filter((unselectedItem) => unselectedItem.scannedPosition !== item.scannedPosition)
  //       );
  //       return [...prevSelectedItems, item];
  //     }
  //   });

  //   // Update unselected items
  //   // setUnselectedItems((prevUnselectedItems) =>
  //   //   prevUnselectedItems.filter(
  //   //     (unselectedItem) =>
  //   //       unselectedItem.scannedPosition !== item.scannedPosition
  //   //   )
  //   // );
  // };

  const handleItemClick = (scannedPosition, item) => {
    setSelectedPosition((prevSelectedPositions) => {
      // Toggle selection for scanned positions
      if (prevSelectedPositions.includes(scannedPosition)) {
        // If already selected, remove from the array
        return prevSelectedPositions.filter(
          (selectedPosition) => selectedPosition !== scannedPosition
        );
      } else {
        // If not selected, add to the array
        return [...prevSelectedPositions, scannedPosition];
      }
    });

    setSelectedItems((prevSelectedItems) => {
      // Toggle selection for items
      const isSelected = prevSelectedItems.some(
        (selectedItem) => selectedItem.scannedPosition === scannedPosition
      );

      if (isSelected) {
        // If already selected, remove from the array (deselect)
        setDeselectedItems((prevDeselectedItems) => {
          // Make sure it's not repeated in the deselected array
          return [
            ...prevDeselectedItems,
            ...(prevDeselectedItems.includes(item) ? [] : [item]),
          ];
        });

        return prevSelectedItems.filter(
          (selectedItem) => selectedItem.scannedPosition !== scannedPosition
        );
      } else {
        // If not selected, add to the array (select)
        setDeselectedItems((prevDeselectedItems) =>
          prevDeselectedItems.filter(
            (deselectedItem) =>
              deselectedItem.scannedPosition !== scannedPosition
          )
        );
        return [...prevSelectedItems, item];
      }
    });
  };

  React.useEffect(() => {
    console.log("selectedPosition");
    console.log(selectedPosition);
    console.log("selectedItems");
    console.log(selectedItems);
    console.log("deselectedItems");
    console.log(deselectedItems);
    setValidData(deselectedItems);
  }, [selectedPosition]);
  return (
    <div>
      <Dialog
        open={openScannerDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="disabled tabs example"
            >
              <Tab label="Camera" />
              <Tab label="Scanner Device" />
            </Tabs>
          </Box>
          {value === 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Scanner
                fps={10}
                qrbox={250}
                disableFlip={false}
                qrCodeSuccessCallback={onNewScanResult}
              />
              <Typography>Current Scanned: {currentScanned.length}</Typography>
              {linkingData?.validateData?.valid_data?.length &&
              currentScanned.length > 0 ? (
                <Card sx={{ minWidth: 275, backgroundColor: "#32a850" }}>
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <Typography>
                      Valid Cans Scanned: {validData?.length}
                    </Typography>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() =>
                        setShowScannedPositionValid(!showScannedPositionValid)
                      }
                      endIcon={
                        showScannedPositionValid ? (
                          <KeyboardArrowDownRoundedIcon />
                        ) : (
                          <KeyboardArrowUpRoundedIcon />
                        )
                      }
                    >
                      Show scanned position
                    </Button>
                    {showScannedPositionValid && (
                      <>
                        <Typography>Scanned Position</Typography>
                        <Grid container spacing={2}>
                          {linkingData?.validateData?.valid_data?.map(
                            (item) => (
                              <Grid item key={item.scannedPosition}>
                                <Paper
                                  style={{
                                    width: "50px", // Adjust the width and height as needed
                                    height: "50px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                    background: selectedPosition.includes(
                                      item.scannedPosition
                                    )
                                      ? "#a69595"
                                      : "black",
                                    opacity: selectedPosition.includes(
                                      item.scannedPosition
                                    )
                                      ? 0.7
                                      : 1,
                                  }}
                                  elevation={3}
                                  onClick={() =>
                                    handleItemClick(item.scannedPosition, item)
                                  }
                                >
                                  {item.scannedPosition}
                                </Paper>
                              </Grid>
                            )
                          )}
                        </Grid>
                      </>
                    )}

                    <Button
                      variant="contained"
                      onClick={handleLinkValidCans}
                      size="small"
                    >
                      Link valid cans
                    </Button>
                  </CardContent>
                </Card>
              ) : (
                ""
              )}

              {invalidData?.length ? (
                <Card sx={{ minWidth: 275, backgroundColor: "#a83232" }}>
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <Typography sx={{ textAlign: "center" }}>
                      Invalid Cans Scanned: {invalidData?.length}
                    </Typography>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() =>
                        setShowScannedPositionInvalid(
                          !showScannedPositionInvalid
                        )
                      }
                      endIcon={
                        showScannedPositionInvalid ? (
                          <KeyboardArrowDownRoundedIcon />
                        ) : (
                          <KeyboardArrowUpRoundedIcon />
                        )
                      }
                    >
                      Show scanned position
                    </Button>
                    {showScannedPositionInvalid && (
                      <>
                        {" "}
                        <Typography>Scanned Position</Typography>
                        <Grid container spacing={2}>
                          {invalidData.map((item) => (
                            <Grid item key={item.scannedPosition}>
                              <Paper
                                style={{
                                  width: "50px", // Adjust the width and height as needed
                                  height: "50px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                elevation={3}
                              >
                                {item.scannedPosition}
                              </Paper>
                            </Grid>
                          ))}
                        </Grid>
                      </>
                    )}
                  </CardContent>
                </Card>
              ) : (
                ""
              )}

              <Button
                onClick={() => {
                  setCurrentScanned([]);
                  setValidData([]);
                  setInvalidData([]);
                  setValidDataToApi([]);
                  localStorage.removeItem("valid_data");
                  dispatch(ClearLinkingData());
                }}
                variant="outlined"
                size="small"
              >
                Link From Start
              </Button>
            </Box>
          )}

          {value === 1 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <TextField
                onChange={handleQrBarcodeIdFromScanner}
                label="QR/Barcode:"
                value={codeId}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Typography>Current Scanned: {currentScanned.length}</Typography>

              {linkingData?.validateData?.valid_data?.length &&
              currentScanned.length !== 0 ? (
                <Card sx={{ minWidth: 275, backgroundColor: "#32a850" }}>
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <Typography>
                      Valid Cans Scanned: {validData?.length}
                    </Typography>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() =>
                        setShowScannedPositionValid(!showScannedPositionValid)
                      }
                      endIcon={
                        showScannedPositionValid ? (
                          <KeyboardArrowDownRoundedIcon />
                        ) : (
                          <KeyboardArrowUpRoundedIcon />
                        )
                      }
                    >
                      Show scanned position
                    </Button>
                    {showScannedPositionValid && (
                      <>
                        <Typography>Scanned Position</Typography>
                        <Grid container spacing={2}>
                          {linkingData?.validateData?.valid_data?.map(
                            (item) => (
                              <Grid item key={item.scannedPosition}>
                                <Paper
                                  style={{
                                    width: "50px", // Adjust the width and height as needed
                                    height: "50px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                    background: selectedPosition.includes(
                                      item.scannedPosition
                                    )
                                      ? "#a69595"
                                      : "black",
                                    opacity: selectedPosition.includes(
                                      item.scannedPosition
                                    )
                                      ? 0.7
                                      : 1,
                                  }}
                                  elevation={3}
                                  onClick={() =>
                                    handleItemClick(item.scannedPosition, item)
                                  }
                                >
                                  {item.scannedPosition}
                                </Paper>
                              </Grid>
                            )
                          )}
                        </Grid>
                      </>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={handleLinkValidCans}
                        size="small"
                      >
                        Link valid cans
                      </Button>
                      <Button
                        variant="contained"
                        onClick={handleReLinkValidCans}
                        size="small"
                      >
                        Re-Link valid cans
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              ) : (
                ""
              )}

              {invalidData?.length ? (
                <Card sx={{ minWidth: 275, backgroundColor: "#a83232" }}>
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <Typography sx={{ textAlign: "center" }}>
                      Invalid Cans Scanned: {invalidData?.length}
                    </Typography>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() =>
                        setShowScannedPositionInvalid(
                          !showScannedPositionInvalid
                        )
                      }
                      endIcon={
                        showScannedPositionInvalid ? (
                          <KeyboardArrowDownRoundedIcon />
                        ) : (
                          <KeyboardArrowUpRoundedIcon />
                        )
                      }
                    >
                      Show Scanned Position
                    </Button>
                    {showScannedPositionInvalid && (
                      <>
                        {" "}
                        <Typography>Scanned Position</Typography>
                        <Grid container spacing={2}>
                          {invalidData.map((item) => (
                            <Grid item key={item.scannedPosition}>
                              <Paper
                                style={{
                                  width: "50px", // Adjust the width and height as needed
                                  height: "50px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                elevation={3}
                              >
                                {item.scannedPosition}
                              </Paper>
                            </Grid>
                          ))}
                        </Grid>
                      </>
                    )}
                  </CardContent>
                </Card>
              ) : (
                ""
              )}

              <Button
                onClick={() => {
                  setCurrentScanned([]);
                  setValidData([]);
                  setInvalidData([]);
                  setValidDataToApi([]);
                }}
                variant="outlined"
                size="small"
              >
                Link From Start
              </Button>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={handleLink}>Verify</Button>
        </DialogActions>
      </Dialog>
      {openNotifyDialog && (
        <Dialog
          open={openNotifyDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
        >
          <DialogContent>
            <Typography>Do you want to verify the scanned cans?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseNotifyDialog}>Cancel</Button>
            <Button onClick={handleConfirmLink}>Yes</Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
