import useAxios from "../../Hooks/useAxios";
import { Dispatch } from "redux";
import actionType from "../actionTypes";

export const OutScanQrless = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/POPM/instock`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.OUTSCAN_QR_LESS,
          payload: response.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};
