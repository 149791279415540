import React, { useState } from "react";
import {
  AppBar,
  Container,
  CssBaseline,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  Tabs,
  Tab,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  useTheme,
  useMediaQuery,
  Icon,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from "@mui/icons-material/Add";
import { appColors } from "../../AppMeta/appColors";
import { useDispatch } from "react-redux";
import {
  AddComponentSku,
  AddRawMaterials,
  GetComponents,
  GetProductSku,
  GetRawMaterials,
} from "../../Redux/Actions/ProductComponentsAction";
import RawMaterialsTable from "./RawMaterialsTable";
import FinishedGoodsTable from "./FinishedGoodsTable";
import AllProductsTable from "../Orders/PurchaseProduct/AllProductsTable";
import {
  AddFinalProductSKU,
  AddProductsAction,
  GetFinalProductSKU,
} from "../../Redux/Actions/productsAction";
import FinalProductsTable from "./FinalProductsTable";
import SKUProducts from "./SKUProducts";
import AddSKUProduct from "./AddSKUProduct";
import Crates from "./Crates";
import AddCrates from "./AddCrates";
import PincodesAndAres from "./PincodesAndAreas";
import AddUpdatePicodeAreaSku from "./AddUpdatePicodeSku";
import AddFinalSkuProduct from "./AddFinalSkuProduct";
import { GetCratesSKU } from "../../Redux/Actions/addCratesAction";
import { act } from "react-dom/test-utils";
import { GetPincodeAreas } from "../../Redux/Actions/pincodeAreasAction";
import { tokens } from "../../AppMeta/theme";

const rawMaterialsData = [
  {
    id: 1,
    name: "Plastic for Can Manufacturing",
    quantity: 100,
    unit: "Kilograms",
    description: "Material for making cans",
  },
  {
    id: 2,
    name: "Metal for Cap Manufacturing",
    quantity: 50,
    unit: "Kilograms",
    description: "Material for making caps",
  },
  {
    id: 3,
    name: "Sticker Material",
    quantity: 200,
    unit: "Sheets",
    description: "Material for printing stickers",
  },
  {
    id: 4,
    name: "Ink for Printing",
    quantity: 5,
    unit: "Liters",
    description: "Ink for printing labels",
  },
  {
    id: 5,
    name: "Cardboard for Packaging",
    quantity: 150,
    unit: "Sheets",
    description: "Material for packaging",
  },
];

const finishedGoodsData = [
  {
    id: 1,
    name: "Can",
    materials: ["Plastic for Can Manufacturing"],
    description: "Finished can product",
    cgst: 5,
    sgst: 5,
    quantity: 0, // Add stock quantity
  },
  {
    id: 2,
    name: "Cap",
    materials: ["Metal for Cap Manufacturing"],
    description: "Finished cap product",
    cgst: 5,
    sgst: 5,
    quantity: 0, // Add stock quantity
  },
  {
    id: 3,
    name: "Sticker",
    materials: ["Sticker Material"],
    description: "Roll of printed stickers",
    cgst: 5,
    sgst: 5,
    quantity: 0, // Add stock quantity
  },
  {
    id: 4,
    name: "Ink Bottle",
    materials: ["Ink for Printing"],
    description: "Bottle of printing ink",
    cgst: 5,
    sgst: 5,
    quantity: 0, // Add stock quantity
  },
  {
    id: 5,
    name: "Cardboard Box",
    materials: ["Cardboard for Packaging"],
    description: "Packaging cardboard box",
    cgst: 5,
    sgst: 5,
    quantity: 0, // Add stock quantity
  },
];

const finalProductsData = [
  {
    id: 1,
    name: "20 Liter Bubble Top Can",
    type: "Can",
    materials: ["Can"],
    description: "Final product description",
    quantity: 30, // Add stock quantity
  },
  {
    id: 2,
    name: "Branded Cap Set",
    type: "Cap",
    materials: ["Cap", "Sticker Roll"],
    description: "Set of branded caps with stickers",
    quantity: 15, // Add stock quantity
  },
  {
    id: 3,
    name: "Stickered Can Pack",
    type: "Can",
    materials: ["Can", "Sticker Roll"],
    description: "Pack of cans with printed stickers",
    quantity: 25, // Add stock quantity
  },
  {
    id: 4,
    name: "Printing Kit",
    type: "Sticker",
    materials: ["Sticker Roll", "Ink Bottle"],
    description: "Kit for printing stickers",
    quantity: 18, // Add stock quantity
  },
  {
    id: 5,
    name: "Packaging Bundle",
    type: "Cardboard Box",
    materials: ["Cardboard Box"],
    description: "Bundle of packaging boxes",
    quantity: 35, // Add stock quantity
  },
];

// ... (previous code)

const SKUManagementApp = ({
  pageNumber,
  setPageNumber,
  pageSize,
  setPageSize,
  lastPageNumber,
  createdAtSort,
  available,
  setAvailable,
  setCreatedAtSort,
  allRawMaterials,
  allFinishedGoods,

  allProducts,
  productType,
  productName,
  productPrice,
  lifeSpan,
  productMaterial,
  setProductType,
  setProductName,
  setProductPrice,
  setLifeSpan,
  setProductMaterial,
  setIsConfirmed,
  isConfirmed,
  setSelectedProduct,
  allCrates,
  allPincodesAndAreas,
}) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [itemName, setItemName] = useState("");
  const [itemType, setItemType] = useState("");
  const [itemQuantity, setItemQuantity] = useState("");
  const [itemUnit, setItemUnit] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [itemCgst, setItemCgst] = useState("");
  const [itemSgst, setItemSgst] = useState("");
  const [itemMaterial, setItemMaterial] = useState("");
  const [itemMaterials, setItemMaterials] = useState([]);
  const [rawMaterials, setRawMaterials] = useState(rawMaterialsData);
  const [finishedGoods, setFinishedGoods] = useState(finishedGoodsData);
  const [finalProducts, setFinalProducts] = useState(finalProductsData);

  const [selectedItems, setSelectedItems] = useState([]);

  const [selectedRowData, setSelectedRowData] = useState(null);
  const [openPincodeDialog, setOpenPicodeDialog] = React.useState(false);
  const [currentRow, setCurrentRow] = React.useState({});
  const [error, setError] = React.useState("");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  React.useEffect(() => {
    dispatch(GetRawMaterials(pageNumber, pageSize, available, createdAtSort));
    dispatch(GetComponents(pageNumber, pageSize, available, createdAtSort));
    dispatch(
      GetFinalProductSKU(pageNumber, pageSize, available, createdAtSort)
    );
    dispatch(GetCratesSKU(pageNumber, pageSize, available, createdAtSort));
    dispatch(GetPincodeAreas(pageNumber, pageSize, available, createdAtSort));
    // dispatch(GetProductSku(pageNumber, pageSize, available, createdAtSort));
  }, [pageNumber, pageSize, available, createdAtSort]);

  React.useEffect(() => {
    console.log("active tab");
    console.log(activeTab);
  }, [activeTab]);

  React.useEffect(() => {}, []);
  const handleRowClick = (rowData) => {
    setSelectedRowData(rowData);
    setDrawerOpen(true);
  };
  React.useEffect(() => {
    setItemName("");
    setItemQuantity("");
    setItemUnit("");
    setItemDescription("");
    setItemCgst("");
    setItemSgst("");
    setItemMaterial("");
    setItemMaterials([]);
  }, [activeTab]);

  const lowStockThreshold = 20;

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleDrawerOpen = () => {
    if (activeTab !== 4) {
      setDrawerOpen(true);
    } else {
      setOpenPicodeDialog(true);
    }
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  React.useEffect(() => {
    console.log(itemMaterials);
    setSelectedItems([...selectedItems, ...itemMaterials]);
  }, [itemMaterials]);

  const handleItemCgstChange = (event) => {
    const newValue = event.target.value;
  };

  const handleItemSgstChange = (event) => {
    const newValue = event.target.value;
  };

  const isNumeric = (value) => {
    return /^\d{0,2}$/.test(value);
  };

  const disableSubmit = () => {
    return error !== "" || itemCgst === "" || itemSgst === "";
  };
  const handleAddItem = (event) => {
    if (activeTab === 0) {
      const reqObj = {
        name: itemName,
        details: {
          description: itemDescription,
          units: itemUnit,
          tax_details: {
            gst: {
              CGST: itemCgst,
              SGST: itemSgst,
            },
          },
        },
      };
      console.log(reqObj);
      dispatch(AddRawMaterials(reqObj));
    } else if (activeTab === 1) {
      const reqObj = {
        name: itemName,
        details: {
          description: itemDescription,
          material: itemMaterial,
          tax_details: {
            gst: {
              // CGST: itemCgst,
              // SGST: itemSgst,
              CGST: 5,
              SGST: 5,
            },
          },
        },
        raw_materials: itemMaterials.map((material) => ({
          id: material.id,
          quantity: parseInt(material.quantity),
          units: material.unit,
        })),
      };
      console.log(reqObj);
      dispatch(AddComponentSku(reqObj));
    } else if (activeTab === 2) {
      const reqObj = {
        type: itemType,
        name: itemName,
        price: 30,
        life_span: 1,
        details: {
          material: itemMaterial,
          tax_details: {
            gst: {
              CGST: itemCgst,
              SGST: itemSgst,
            },
          },
        },
        components: itemMaterials.map((material) => ({
          id: material.id,
          quantity: parseInt(material.quantity),
          name: material.name,
        })),
      };
      console.log(reqObj);
      dispatch(AddProductsAction(reqObj));
    }

    console.log(
      "Adding item:",
      itemName,
      itemQuantity,
      itemUnit,
      itemDescription,
      itemCgst,
      itemSgst,
      itemMaterials
    );
    const newItem = {
      id: Date.now(),
      name: itemName,
      quantity: itemQuantity,
      unit: itemUnit,
      description: itemDescription,
      cgst: itemCgst,
      sgst: itemSgst,
      materials: itemMaterials.map((material) => ({
        id: material.id,
        name: material.name,
        quantity: material.quantity,
        units: material.unit,
      })),
    };
    // console.log(newItem);
    switch (activeTab) {
      case 0:
        setRawMaterials([...rawMaterials, newItem]);
        break;
      case 1:
        setFinishedGoods([...finishedGoods, newItem]);
        break;
      case 2:
        setFinalProducts([...finalProducts, newItem]);
        break;
      default:
        break;
    }
    setItemName("");
    setItemQuantity("");
    setItemUnit("");
    setItemDescription("");
    setItemCgst("");
    setItemSgst("");
    setItemMaterials([]);
    setDrawerOpen(false);
  };

  const renderTable = () => {
    let data;
    switch (activeTab) {
      case 0:
        data = rawMaterialsData;
        break;
      case 1:
        data = finishedGoodsData;
        break;
      case 2:
        data = finalProductsData;
        break;
      case 3:
        data = finalProductsData;
        break;
      case 4:
        data = finalProductsData;
        break;
      default:
        return null;
    }

    return (
      <>
        {activeTab === 0 && (
          <RawMaterialsTable
            allRawMaterials={allRawMaterials}
            pageNumber={pageNumber}
            pageSize={pageSize}
            lastPageNumber={lastPageNumber}
            createdAtSort={createdAtSort}
            available={available}
          />
        )}

        {activeTab === 1 && (
          <FinishedGoodsTable allFinishedGoods={allFinishedGoods} />
        )}

        {/* {activeTab === 2 && <FinalProductsTable allProducts={allProducts}/>} */}
        {activeTab === 2 && <SKUProducts allProducts={allProducts} />}
        {activeTab === 3 && <Crates allCrates={allCrates} />}
        {activeTab === 4 && (
          <PincodesAndAres
            openPincodeDialog={openPincodeDialog}
            setOpenPicodeDialog={setOpenPicodeDialog}
            currentRow={currentRow}
            setCurrentRow={setCurrentRow}
            allPincodesAndAreas={allPincodesAndAreas}
            allProducts={allProducts}
          />
        )}
      </>
    );
  };

  const renderDrawerFields = () => {
    switch (activeTab) {
      case 0:
        return (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <TextField
                label="Item Names"
                variant="outlined"
                fullWidth
                value={itemName}
                onChange={(e) => setItemName(e.target.value)}
              />
              <TextField
                label="Unit"
                variant="outlined"
                fullWidth
                value={itemUnit}
                onChange={(e) => setItemUnit(e.target.value)}
              />
              <TextField
                label="Description"
                variant="outlined"
                fullWidth
                value={itemDescription}
                onChange={(e) => setItemDescription(e.target.value)}
              />
              <TextField
                type="number"
                label="CGST"
                variant="outlined"
                error={error !== ""}
                helperText={error}
                fullWidth
                value={itemCgst}
                onChange={(e) => setItemCgst(e.target.value)}
                inputProps={{ maxLength: 2, max: 99 }}
              />
              <TextField
                type="number"
                label="SGST"
                variant="outlined"
                fullWidth
                error={error !== ""}
                helperText={error}
                value={itemSgst}
                onChange={(e) => setItemSgst(e.target.value)}
                inputProps={{ maxLength: 2, max: 99 }}
              />
            </Box>
          </>
        );
      case 1:
        return (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <TextField
                label="Item Name"
                variant="outlined"
                fullWidth
                value={itemName}
                onChange={(e) => setItemName(e.target.value)}
              />
              <TextField
                label="Material"
                variant="outlined"
                fullWidth
                value={itemMaterial}
                onChange={(e) => setItemMaterial(e.target.value)}
              />
              <TextField
                label="Description"
                variant="outlined"
                fullWidth
                value={itemDescription}
                onChange={(e) => setItemDescription(e.target.value)}
              />
              <TextField
                label="CGST"
                variant="outlined"
                fullWidth
                value={itemCgst}
                onChange={(e) => setItemCgst(e.target.value)}
              />
              <TextField
                label="SGST"
                variant="outlined"
                fullWidth
                value={itemSgst}
                onChange={(e) => setItemSgst(e.target.value)}
              />
            </Box>
            {/* Selected items table */}
            {itemMaterials.length > 0 && (
              <TableContainer component={Paper} style={{ marginTop: "24px" }}>
                <Table>
                  <TableHead sx={{ backgroundColor: appColors.secondary }}>
                    <TableRow>
                      <TableCell>Selected Item</TableCell>
                      <TableCell>Unit</TableCell>
                      <TableCell>Quantity/Finished Good</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {itemMaterials.map((item) => (
                      <TableRow key={item.name} style={{ color: "green" }}>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.unit}</TableCell>
                        <TableCell>
                          <TextField
                            type="number"
                            value={item.quantity || 0}
                            onChange={(e) =>
                              handleQuantityChange(e.target.value, item.name)
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        );
      case 2:
        return (
          <>
            <AddFinalSkuProduct
              pageNumber={pageNumber}
              pageSize={pageSize}
              available={available}
              createdAtSort={createdAtSort}
            />
          </>
        );
      case 3:
        return (
          <>
            <AddCrates />
            {/* <AddFinalProductSKU /> */}
          </>
        );
      case 4:
        return (
          <>
            <AddUpdatePicodeAreaSku
              openPincodeDialog={openPincodeDialog}
              setOpenPicodeDialog={setOpenPicodeDialog}
              updatePincodeData={currentRow}
              setupdatePincodeData={setCurrentRow}
            />
          </>
        );
      default:
        return null;
    }
  };

  const handleQuantityChange = (quantity, materialName) => {
    setItemMaterials((prevMaterials) =>
      prevMaterials.map((material) =>
        material.name === materialName
          ? { ...material, quantity: quantity }
          : material
      )
    );
  };

  return (
    <div>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          marginRight: "15px",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={handleDrawerOpen}
          variant="contained"
          color="secondary"
          sx={{
            fontSize: { xs: "0.8rem", md: "1.1rem" },
            fontWeight: "bold",
          }}
          endIcon={<AddIcon />}
          size="small"
        >
          Add{" "}
          {activeTab === 0
            ? "Raw Material"
            : activeTab === 1
            ? "Finished Good"
            : activeTab === 2
            ? "Final Product"
            : activeTab === 3
            ? "Crates"
            : " pincodes and areas"}
        </Button>
      </Box>

      <AppBar
        position="sticky"
        sx={{
          backgroundColor: colors.greenAccent[600],
          color: colors.primary[200],
          marginTop: "10px",
        }}
      >
        <Toolbar>
          <Tabs
            variant="scrollable"
            allowScrollButtonsMobile
            value={activeTab}
            onChange={handleTabChange}
            aria-label="SKU Management Tabs"
            sx={{
              color: colors.primary[400],
              indicatorColor: colors.blueAccent[400],
            }}
          >
            <Tab
              sx={{
                fontSize: { xs: "0.9rem", md: "1.1rem" },
                fontWeight: "bold",
              }}
              label="Raw Materials"
            />
            <Tab
              sx={{
                fontSize: { xs: "0.9rem", md: "1.1rem" },
                fontWeight: "bold",
              }}
              label="Finished Goods (Components)"
            />
            <Tab
              sx={{
                fontSize: { xs: "0.9rem", md: "1.1rem" },
                fontWeight: "bold",
              }}
              label="Final Products"
            />
            <Tab
              sx={{
                fontSize: { xs: "0.9rem", md: "1.1rem" },
                fontWeight: "bold",
              }}
              label="Crates"
            />
            <Tab
              sx={{
                fontSize: { xs: "0.9rem", md: "1.1rem" },
                fontWeight: "bold",
              }}
              label="Pincodes and Areas"
            />
          </Tabs>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        PaperProps={{
          style: {
            width: isMobile ? "75%" : "500px",
            padding: "20px",
          },
        }}
      >
        <div>
          <Typography variant="h6" style={{ margin: "16px" }}>
            Add{" "}
            {activeTab === 0
              ? "Raw Material"
              : activeTab === 1
              ? "Finished Good"
              : activeTab === 2
              ? "Final Product"
              : activeTab === 3
              ? "Crates"
              : "Add pincodes and areas"}
          </Typography>
          <form style={{ margin: "16px" }}>
            {renderDrawerFields()}
            {activeTab !== 2 && activeTab !== 3 ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddItem}
                style={{ marginTop: "16px" }}
              >
                Add{" "}
                {activeTab === 0
                  ? "Raw Material"
                  : activeTab === 1
                  ? "Finished Good"
                  : activeTab === 2
                  ? "Final Product"
                  : activeTab === 3
                  ? "Crates"
                  : "Add pincodes and areas"}
              </Button>
            ) : (
              ""
            )}
          </form>
        </div>
      </Drawer>

      <Container style={{ marginTop: "24px" }}>{renderTable()}</Container>
    </div>
  );
};

export default SKUManagementApp;
