import { Reducer } from "redux";
import actionType from "../actionTypes";

const initialState = {
    error: false,
    msg: "",
    facilityOrder: [],
};
const facilityReducers = (state = initialState, action) => {
    console.log(action);
    switch (action.type) {
        case actionType.GET_FACILITY_ORDER:
            return {
                ...state,
                error: action.payload.error,
                msg: action.payload.msg,
                facilityOrder: action.payload.data,
            };
        case actionType.CREATE_FACILITY_ORDER:
            return {
                ...state,
                error: action.payload.error,
                msg: action.payload.msg,
            };
        case actionType.CANCEL_FACILITY_ORDER:
            return {
                ...state,
                error: action.payload.error,
                msg: action.payload.msg,
            };
        default:
            return state;
    }
};

export default facilityReducers;