import { Box, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import { tokens } from "../AppMeta/theme";

function Sample() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <>
      <Box m="10px">
        <Typography variant="h4" fontWeight="bold">
          Special Request
        </Typography>
      </Box>

      <Box
        ml="10px"
        mt="30px"
        mr="10px"
        boxShadow={2}
        borderRadius="20px"
        backgroundColor={colors.primary[400]}
      >
        <Grid container spacing={2}>
          <Grid item md={4} xs={4} sm={4}>
            <img
              alt=""
              style={{
                marginLeft: "10px",
              }}
              src=""
            />
          </Grid>
          <Grid item md={6} xs={2} sm={2}>
            <Box mb="5px"></Box>
          </Grid>
          <Grid item md={2} xs={2}></Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Sample;
