import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  card: {
    minWidth: 275,
    margin: "16px",
    padding: "16px",
  },
  title: {
    fontSize: 16,
    marginBottom: "8px",
  },
  detailItem: {
    marginTop: "8px",
    padding: "10px",
  },
});

const OrderDetailsCard = ({ orderDetails }) => {
  const classes = useStyles();
  console.log(orderDetails);
  const getOrderStatusTitle = (status) => {
    switch (status) {
      case "PO_APPROVED":
        return "Purchase Order Approved";
      case "PO_REJECTED":
        return "Purchase Order Rejected";
      case "PR_APPROVED":
        return "Purchase Request Approved";
      case "PR_REJECTED":
        return "Purchase Request Rejected";
      default:
        return "Order Information";
    }
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        {/* <Typography variant="h6" component="div" className={classes.title}>
          {getOrderStatusTitle(orderDetails.order_status)}
        </Typography> */}
        {/* Render corresponding details based on order status */}

        <div className={classes.detailItem}>
          <Typography color="textSecondary">
            {orderDetails.order_status === "PO_APPROVED" ||
            orderDetails.order_status === "COMPLETED" ||
            orderDetails.order_status === "PR_APPROVED" 
              ? "PO APPROVED BY"
              : "PO REJECTED BY"}
          </Typography>
          <Typography>
            {orderDetails.po_approved_by_details.fullname}
          </Typography>
          <Typography color="textSecondary">
            Email: {orderDetails.po_approved_by_details.email}
          </Typography>
          <Typography color="textSecondary">
            Phone: {orderDetails.po_approved_by_details.phone}
          </Typography>
        </div>

        <div className={classes.detailItem}>
          <Typography color="textSecondary">
            {orderDetails.order_status === "PR_APPROVED" ||
            orderDetails.order_status === "COMPLETED"
              ? "PR APPROVED BY"
              : "PR REJECTED BY"}
          </Typography>
          <Typography>
            {orderDetails.pr_approved_by_details.fullname}
          </Typography>
          <Typography color="textSecondary">
            Email: {orderDetails.pr_approved_by_details.email}
          </Typography>
          <Typography color="textSecondary">
            Phone: {orderDetails.pr_approved_by_details.phone}
          </Typography>
        </div>

        <div className={classes.detailItem}>
          <Typography color="textSecondary">ORDER INITIATED BY</Typography>
          <Typography>
            {orderDetails.order_initiated_by_details.fullname}
          </Typography>
          <Typography color="textSecondary">
            Email: {orderDetails.order_initiated_by_details.email}
          </Typography>
          <Typography color="textSecondary">
            Phone: {orderDetails.order_initiated_by_details.phone}
          </Typography>
        </div>

        <div className={classes.detailItem}>
          <Typography color="textSecondary">ORDER UPDATED BY</Typography>
          <Typography>
            {orderDetails.order_updated_by_details.fullname}
          </Typography>
          <Typography color="textSecondary">
            Email: {orderDetails.order_updated_by_details.email}
          </Typography>
          <Typography color="textSecondary">
            Phone: {orderDetails.order_updated_by_details.phone}
          </Typography>
        </div>

        <div className={classes.detailItem}>
          <Typography color="textSecondary">QR CODE STATUS</Typography>
          <Typography>
            Status:{" "}
            {orderDetails.qr_data.status ? "Generated" : "Yet to generate"}
          </Typography>
          <Typography color="textSecondary">
            Barcode Series: {orderDetails.qr_data.barCodeSeries}
          </Typography>
          <Typography color="textSecondary">
            QR Code Series: {orderDetails.qr_data.qrCodeSeries}
          </Typography>
        </div>

        {/* Add similar conditional rendering for other statuses */}
      </CardContent>
    </Card>
  );
};

export default OrderDetailsCard;
