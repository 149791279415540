import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CustomInputTextField from "../../Components/CustomComp/CustomInputTextField";
import appMeta from "../../AppMeta/appMeta";
import CustomButton from "../../Components/CustomComp/CustomButton";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  AddProductsAction,
  GetProducts,
} from "../../Redux/Actions/productsAction";
import {
  AddProductsSku,
  GetProductSku,
} from "../../Redux/Actions/ProductComponentsAction";

export default function AddProducts({
  pageNumber,
  lastPageNumber,
  pageSize,
  createdAtSort,
  available,
  setConfirmationDescription,
  isConfirmed,
  setIsConfirmed,
  setSelectedValues,
  selectedValues,
}) {
  const dispatch = useDispatch();
  // State Variables
  const [productType, setProductType] = React.useState("");

  const [productName, setProductName] = React.useState("");
  const [lifeSpan, setLifeSpan] = React.useState("");

  const [isValidLifeSpan, setIsValidLifeSpan] = React.useState(true);
  const [productPrice, setProductPrice] = React.useState("");

  const [isValidPrice, setIsValidPrice] = React.useState(true);
  const [material, setMaterial] = React.useState("");

  const handleAddProduct = () => {
    console.log("Add Product func");
    // setConfirmationDescription("Are you sure you want to add this product");
    const reqObj = {
      type: productType,
      name: productName,
      price: productPrice,
      life_span: lifeSpan,
      details: { material: material },
      components: selectedValues,
    };
    console.log(reqObj);
    if (
      reqObj.type !== "" &&
      reqObj.name !== "" &&
      reqObj.price !== "" &&
      reqObj.life_span !== "" &&
      reqObj.details.material !== ""
    ) {
      console.log("All fields are filled");
      // dispatch(AddProductsAction(reqObj));
      dispatch(AddProductsSku(reqObj));
      setTimeout(() => {
        dispatch(GetProductSku(pageNumber, pageSize, available, createdAtSort));
      }, 1000);
    } else {
      console.log("Please fill the mandatory fields");
      toast.error("Please fill the mandatory fields", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  React.useEffect(() => {
    if (isConfirmed) {
      handleAddProduct();
      setIsConfirmed(false);
    }
  }, [isConfirmed]);

  React.useEffect(() => {
    if (isConfirmed) {
      handleAddProduct();
    }
  }, [isConfirmed]);

  return (
    <Card sx={{ minWidth: 275, padding: "10px", margin: "20px" }}>
      <CardContent>
        <Box>
          <Typography
            variant="h5"
            component="div"
            sx={{ marginBottom: "20px" }}
          >
            ADD PRODUCT
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: { xs: "10px" },
              alignItems: "center",
              justifyContent: "space-evenly",
              padding: "10px",
            }}
          >
            <CustomInputTextField
              placeholder="Product type"
              isRequired={true}
              value={productType}
              setValue={setProductType}
            />
            <CustomInputTextField
              placeholder="Product name"
              isRequired={true}
              value={productName}
              setValue={setProductName}
            />
            <CustomInputTextField
              placeholder="Life span (No of uses)"
              isRequired={true}
              value={lifeSpan}
              setValue={setLifeSpan}
              type={appMeta.textFieldTypes.NUMBER}
              isValid={isValidLifeSpan}
              setIsValid={setIsValidLifeSpan}
            />
            <CustomInputTextField
              placeholder="Product price"
              isRequired={true}
              value={productPrice}
              setValue={setProductPrice}
              isValid={isValidPrice}
              setIsValid={setIsValidPrice}
              type={appMeta.textFieldTypes.NUMBER}
            />
            <CustomInputTextField
              placeholder="Material"
              isRequired={true}
              value={material}
              setValue={setMaterial}
            />
          </Box>
        </Box>
      </CardContent>
      {/* <Box>
        <CustomButton
          label="Add"
          variant="contained"
          handleClick={handleAddProduct}
        />
      </Box> */}
      {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </Card>
  );
}
