/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Dialog box for editing any particular role
 * @date 26/05/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import CustomInputTextField from "../../../Components/CustomComp/CustomInputTextField";
import { useDispatch } from "react-redux";
import { GetRoles, UpdateRoles } from "../../../Redux/Actions/rolesAction";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  FormControl,
  IconButton,
  Input,
  InputLabel,
  TextField,
  Tooltip,
} from "@mui/material";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import InputAdornment from "@mui/material/InputAdornment";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditRolesDialogBox({
  selectedRow,
  openEditRolesDialog,
  setOpenEditRolesDialog,
  setRoleUpdateBtn,
  pageNumber,
  pageSize,
  isActive,
  ascOrDesc,
  setIsUpdate,
  isUpdate,
  setOpenConfirmationDialog,
  isConfirmed,
  setIsConfirmed,
  setConfirmationDescription,
}) {
  const dispatch = useDispatch();

  console.log(selectedRow);
  const [roleName, setRoleName] = useState(selectedRow?.roleName);
  const [description, setDescription] = useState(selectedRow?.description);
  const [fullName, setFullName] = useState(selectedRow?.fullname);
  const [isActiveStatus, setIsActiveStatus] = useState(selectedRow?.isActive);
  const [isForceUpdate, setIsForceUpdate] = React.useState(false);

  const [isEditRoleName, setIsEditRoleName] = React.useState(false);
  const [isEditDescription, setIsEditDescription] = React.useState(false);
  const [email, setEmail] = useState(selectedRow?.email);
  const [phone, setPhone] = useState(selectedRow?.phone);

  const handleClose = () => {
    setOpenEditRolesDialog(false);
  };

  const handleUpdate = () => {
    setIsConfirmed(false);
    setIsUpdate(true);
    setOpenConfirmationDialog(true);
    setConfirmationDescription("Are you sure you want to update this role?");
    // const reqObj = {
    //   role_name: selectedRow?.roleName,
    //   is_force_update: isForceUpdate,
    //   new_role_name: isEditRoleName ? roleName : undefined,
    //   description: description,
    //   is_deleted: false,
    //   is_active: isActiveStatus,
    // };
    // dispatch(UpdateRoles(reqObj));
    // setTimeout(() => {
    //   dispatch(GetRoles(pageNumber, pageSize, isActive, ascOrDesc));
    // }, 1000);
    // setOpenEditRolesDialog(false);
    // setRoleUpdateBtn(true);
  };

  React.useEffect(() => {
    if (isConfirmed) {
      console.log("isConfirmed");
      if (isUpdate) {
        const reqObj = {
          role_name: selectedRow?.roleName,
          is_force_update: isForceUpdate,
          new_role_name: isEditRoleName ? roleName : undefined,
          description: description,
          is_deleted: false,
          is_active: isActiveStatus,
        };
        dispatch(UpdateRoles(reqObj));
        setTimeout(() => {
          dispatch(GetRoles(pageNumber, pageSize, isActive, ascOrDesc));
        }, 1000);
        setOpenEditRolesDialog(false);
        setOpenConfirmationDialog(false);
        setIsUpdate(false);
        setRoleUpdateBtn(true);
      }
    }
  }, [isConfirmed]);
  const handleChangeRoleName = (event) => {
    console.log(event.target.value);
    const newValue = event.target.value;
    setRoleName(newValue);
    console.log(newValue);
  };

  const handleChangeDescription = (event) => {
    const newValue = event.target.value;
    setDescription(newValue);
    console.log(newValue);
  };

  const handleChangeFullName = (event) => {
    const newValue = event.target.value;
    setFullName(newValue);
    console.log(newValue);
  };

  const handleChangeEmail = (event) => {
    const newValue = event.target.value;
    setEmail(newValue);
    console.log(newValue);
  };

  const handleChangePhone = (event) => {
    const newValue = event.target.value;
    setPhone(newValue);
    console.log(newValue);
  };

  const handleClickEditRoleName = () => {
    setIsEditRoleName(!isEditRoleName);
  };
  React.useEffect(() => {
    if (isEditDescription) {
      console.log("Edit description mode is on");
    } else {
      console.log("Edit description mode is off");
    }

    if (isEditRoleName) {
      console.log("Edit role name mode is on");
    } else {
      console.log("Edit role name mode is off");
    }
  }, [isEditDescription, isEditRoleName]);

  const handleClickEditDescription = () => {
    setIsEditDescription(!isEditDescription);
  };
  // React.useEffect(() => {
  //   if (isEditRoleName === true) {
  //     setIsEditRoleName(false);
  //   } else if (isEditRoleName === false) {
  //     setIsEditRoleName(true);
  //   }
  // }, [isEditRoleName]);
  return (
    <Box className="card">
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        gap={2}
        mb={2}
      ></Box>
      <Dialog
        open={openEditRolesDialog}
        onClose={handleClose}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <Box
          p={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={2}
        >
          <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
            <InputLabel htmlFor="standard-adornment-password">
              Role name
            </InputLabel>
            <Input
              id="standard-adornment-password"
              value={selectedRow?.roleName}
              disabled={true}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickEditRoleName}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    {isEditRoleName ? (
                      <Tooltip title="Cancel">
                        <CancelRoundedIcon />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Edit">
                        <EditRoundedIcon />
                      </Tooltip>
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {isEditRoleName && (
            <>
              {" "}
              <CustomInputTextField
                placeholder="New Role Name"
                size="large"
                value={roleName}
                setValue={setRoleName}
                handleChange={handleChangeRoleName}
              />
            </>
          )}
          <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
            <InputLabel htmlFor="standard-adornment-password">
              Description
            </InputLabel>
            <Input
              id="standard-adornment-password"
              value={selectedRow?.description}
              disabled={true}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickEditDescription}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    {isEditDescription ? (
                      <Tooltip title="Cancel">
                        <CancelRoundedIcon />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Edit">
                        <EditRoundedIcon />
                      </Tooltip>
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {isEditDescription && (
            <CustomInputTextField
              placeholder="New Description"
              size="large"
              value={description}
              setValue={setDescription}
              handleChange={handleChangeDescription}
            />
          )}
          {/* <CustomInputTextField
            placeholder="Full Name"
            size="large"
            value={fullName}
            handleChange={handleChangeFullName}
          />
          <CustomInputTextField 
            placeholder="Email"
            size="large"
            value={email}
            handleChange={handleChangeEmail}
          /> */}
          {/* <CustomInputTextField
            placeholder="Phone"
            size="large"
            value={phone}
            handleChange={handleChangePhone}
          /> */}
          {/* <CustomInputTextField
            placeholder="Active Status"
            size="large"
            handleChange={handleChangeActiveStatus}
          /> */}
          <FormControlLabel
            required
            control={
              <Checkbox
                checked={isActiveStatus}
                onChange={(event) => setIsActiveStatus(event.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={
              isActiveStatus
                ? "Active now, Uncheck to deactivate."
                : "This role will be no more active"
            }
          />
          <Box>
            <FormControlLabel
              required
              control={
                <Checkbox
                  checked={isForceUpdate}
                  onChange={(event) => setIsForceUpdate(event.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={
                isForceUpdate
                  ? "You are force updating"
                  : "Do you want to force update"
              }
            />
          </Box>
          <Box display="flex" mt={2}>
            <CustomButton
              handleClick={handleClose}
              label="Cancel"
              color="error"
              variant="text"
              size="medium"
            />

            <CustomButton
              handleClick={handleUpdate}
              label="Update"
              color="primary"
              variant="contained"
              size="medium"
            />
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
}
