/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Roles management main component with new creating roles
 * @date 26/05/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import React from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CustomTypography from "../../../Components/CustomComp/CustomTypography";
import CustomInputTextField from "../../../Components/CustomComp/CustomInputTextField";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { CreateRole, GetRoles } from "../../../Redux/Actions/rolesAction";
import RolesListTable from "./RolesListTable";
import ApiList from "../ApiList/ApiList";
import { GetApis } from "../../../Redux/Actions/apisDetailsAction";
import ApiManagement from "../ApiList/ApiManagement";
import { Dialog } from "@mui/material";
import CustomConfirmationDialog from "../../../Components/CustomComp/CustomConfirmationDialog";


// import CustomTable from "../../Components/CustomComp/CustomTable";

const styles = {
  mainDiv: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
    padding: "20px",
  },
};

function RolesManagement() {
  const dispatch = useDispatch();
  const rolesData = useSelector((state) => state.rolesData);
  const apisData = useSelector((state) => state.apiListData);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(25);
  const [isActive, setIsActive] = React.useState(true);
  const [ascOrDesc, setAscOrDesc] = React.useState("ASC");
  const [roleName, setRoleName] = React.useState("");
  const [roleDescription, setRoleDescription] = React.useState("");
  const [listOfRoles, setListOfRoles] = React.useState([]);
  const [listOfApis, setListOfApis] = React.useState([]);
  // Update roles variables
  const [roleUpdateBtn, setRoleUpdateBtn] = React.useState(false);

  const [addNewRoleDialog, setAddNewRoleDialog] = React.useState(false);
  const [lastPageNumber, setlastPageNumber] = React.useState(null);
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    React.useState(false);
  const [isConfirmed, setIsConfirmed] = React.useState(false);
  const [isAdd, setIsAdd] = React.useState(false);
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [confirmationDescription, setConfirmationDescription] =
    React.useState("");
  React.useEffect(() => {
    console.log(listOfRoles);
  }, [listOfRoles]);
  React.useEffect(() => {
    console.log(rolesData);
    if (rolesData.error === false) {
      setlastPageNumber(rolesData.rolesList.last_page_number);
      if (rolesData.rolesList?.data?.length > 0) {
        setListOfRoles(rolesData.rolesList.data);
      }
    }
  }, [rolesData]);

  React.useEffect(() => {
    console.log(apisData);
    if (apisData.error === false) {
      if (apisData.apisList?.data?.length > 0) {
        setListOfApis(apisData.apisList.data);
      }
    }
  }, [apisData]);

  React.useEffect(() => {
    // /roles?role_name&page_number=1&page_size=5&is_active=DESC&created_by_sort=ASC
    dispatch(GetRoles(pageNumber, pageSize, isActive, ascOrDesc));
    // dispatch(GetApis());
  }, [pageNumber, pageSize, isActive, ascOrDesc]);

  const handleChangeRoleName = (event) => {
    const newValue = event.target.value;
    setRoleName(newValue);
    console.log(newValue);
  };

  const handleChangeRoleDescription = (event) => {
    const newValue = event.target.value;
    setRoleDescription(newValue);
    console.log(newValue);
  };

  const handleAddRoleClick = () => {
    console.log("Add role");
    setIsAdd(true);
    setConfirmationDescription("Are you sure you want to add this role?");
    // dispatch(CreateRole(`${roleName}`, roleDescription));
    // setAddNewRoleDialog(false);
    // setTimeout(() => {
    //   dispatch(GetRoles(pageNumber, pageSize));
    // }, 1000);
    setOpenConfirmationDialog(true);
  };

  React.useEffect(() => {
    if (isConfirmed) {
      if (isAdd) {
        dispatch(CreateRole(`${roleName}`, roleDescription));
        setAddNewRoleDialog(false);
        setOpenConfirmationDialog(false);
        setIsAdd(false);
        setTimeout(() => {
          dispatch(GetRoles(pageNumber, pageSize));
        }, 1000);
      }
    }
  }, [isConfirmed]);

  const handleClose = () => {
    setAddNewRoleDialog(false);
  };

  React.useEffect(() => {
    console.log(addNewRoleDialog);
  }, [addNewRoleDialog]);

  return (
    <>
      <Dialog open={addNewRoleDialog} onClose={handleClose}>
        <Box >
          <CustomTypography
            content="Roles Management"
            fontSize="18px"
            fontWeight="bold"
          />
          <CustomInputTextField
            placeholder="Role Name"
            size="large"
            value={roleName}
            setValue={setRoleName}
            handleChange={handleChangeRoleName}
          />
          <CustomInputTextField
            placeholder="Role Description"
            size="large"
            value={roleDescription}
            setValue={setRoleDescription}
            handleChange={handleChangeRoleDescription}
          />
          <Box sx={{ display: "flex" }}>
            <CustomButton
              label="Cancel"
              size="medium"
              variant="text"
              color="error"
              handleClick={handleClose}
            />
            <CustomButton
              label="Add role"
              rounded={true}
              size="medium"
              variant="contained"
              handleClick={handleAddRoleClick}
            />
          </Box>
        </Box>
      </Dialog>

      <>
        {listOfRoles.length > 0 ? (
          <>
            {" "}
            <RolesListTable
              listOfRoles={listOfRoles}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              setPageSize={setPageSize}
              pageSize={pageSize}
              setAddNewRoleDialog={setAddNewRoleDialog}
              lastPageNumber={lastPageNumber}
              isActive={isActive}
              setIsActive={setIsActive}
              ascOrDesc={ascOrDesc}
              setAscOrDesc={setAscOrDesc}
              setIsUpdate={setIsUpdate}
              isUpdate={isUpdate}
              setOpenConfirmationDialog={setOpenConfirmationDialog}
              isConfirmed={isConfirmed}
              setConfirmationDescription={setConfirmationDescription}
              setIsConfirmed={setIsConfirmed}
            />

          </>
        ) : (
          ""
        )}

      </>
      {
        openConfirmationDialog && (
          <CustomConfirmationDialog
            openConfirmationDialog={openConfirmationDialog}
            setOpenConfirmationDialog={setOpenConfirmationDialog}
            description={confirmationDescription}
            setIsConfirmed={setIsConfirmed}
          />
        )
      }
    </>




  );
}

export default RolesManagement;
