import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Paper,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
} from "@mui/material";
import CustomConfirmationDialog from "../../../Components/CustomComp/CustomConfirmationDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  EditMftOrderAction,
  EditProcessingPartnerOrder,
} from "../../../Redux/Actions/purchaseProductsAction";

export default function EditOrderDialog({
  editOrderDialog,
  setEditOrderDialog,
  dataToEdit,
  allProducts,
}) {
  console.log(dataToEdit);
  console.log(allProducts);
  const dispatch = useDispatch();
  const manufacturerDetails = useSelector((state) => state.manufacturerData);
  const [editedMaterials, setEditedMaterials] = React.useState([]);
  const [isConfirmed, setIsConfirmed] = React.useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    React.useState(false);
  const [manufacturersList, setManufacturersList] = React.useState([]);

  const [manufacturerName, setManufacturerName] = React.useState("");
  const [finalProductRequired, setFinalProductRequired] = React.useState("");

  const [processingFee, setProcessingFee] = React.useState("");
  const [requiredQuantityOutCome, setRequiredQuantityOutCome] =
    React.useState("");

  React.useEffect(() => {
    if (dataToEdit.order_type === "400") {
      setProcessingFee(dataToEdit.final_product[0].processing_fee);
      setRequiredQuantityOutCome(dataToEdit.final_product[0].quantity_outcome);
    }
  }, [dataToEdit]);

  // React.useEffect(() => {
  //   const initialEditedMaterials = dataToEdit.components.map((material) => ({
  //     quantity: material.quantity,
  //     price: material.price,
  //     manufacturer: dataToEdit.mft_details,
  //   }));
  //   setEditedMaterials(initialEditedMaterials);
  // }, [dataToEdit]);

  React.useEffect(() => {
    console.log("manufacturerDetails");
    console.log(manufacturerDetails);
    if (manufacturerDetails.error === false) {
      setManufacturersList(manufacturerDetails?.manufacturers?.data);
    }
  }, [manufacturerDetails]);

  React.useEffect(() => {
    console.log("manufacturersList");
    console.log(manufacturersList);
    manufacturersList?.map((manufacturer) => {
      console.log("check");
      console.log(dataToEdit.mft_details.id === manufacturer.id);
      if (dataToEdit.mft_details.id === manufacturer.id) {
        setManufacturerName(manufacturer.id);
      }
    });
  }, [manufacturersList]);
  React.useEffect(() => {
    console.log("allProducts");
    console.log(allProducts);
    allProducts?.map((product) => {
      console.log("check");
      if (dataToEdit.order_type === "400") {
        console.log(
          dataToEdit?.final_product[0]?.product_details[0].id ===
            product.product_id
        );
        if (
          dataToEdit?.final_product[0]?.product_details[0].id ===
          product.product_id
        ) {
          setFinalProductRequired(product.product_id);
        }
      }
    });
  }, [allProducts]);

  React.useEffect(() => {
    console.log("manufacturer name");
    console.log(manufacturerName);
  }, [manufacturerName]);

  React.useEffect(() => {
    if (dataToEdit.order_type !== "400") {
      const initialEditedMaterials = dataToEdit.components.map((material) => ({
        id: material.id,
        quantity: material.quantity,
        price: material.price,
      }));
      setEditedMaterials(initialEditedMaterials);
    } else if (dataToEdit.order_type === "400") {
      const initialEditedMaterials = dataToEdit.components.map((material) => ({
        id: material.id,
        quantity: material.quantity,
      }));
      setEditedMaterials(initialEditedMaterials);
    }
  }, [dataToEdit]);

  const handleQuantityChange = (index, value) => {
    const updatedMaterials = [...editedMaterials];
    // Ensure that editedMaterials[index] is defined
    if (updatedMaterials[index]) {
      updatedMaterials[index].quantity = value;
      setEditedMaterials(updatedMaterials);
    }
  };

  const handlePriceChange = (index, value) => {
    const updatedMaterials = [...editedMaterials];
    // Ensure that editedMaterials[index] is defined
    if (updatedMaterials[index]) {
      updatedMaterials[index].price = value;
      setEditedMaterials(updatedMaterials);
    }
  };
  const handleClose = () => {
    setEditOrderDialog(false);
  };

  React.useEffect(() => {
    console.log("EditedMaterials");
    console.log(editedMaterials);
  }, [editedMaterials]);

  const handleClickEdit = () => {
    if (dataToEdit.order_type !== "400") {
      const reqObj = {
        order_id: dataToEdit.order_id,
        order_type: 300,
        mft_id: dataToEdit.mft_details.id,
        order: { product_details: editedMaterials },
      };
      console.log(reqObj);
    } else if (dataToEdit.order_type === "400") {
      const reqObj = {
        order_id: dataToEdit.order_id,
        order_type: 400,
        mft_id: manufacturerName,
        order: {
          final_product: finalProductRequired,
          quantity_outcome: requiredQuantityOutCome,
          processing_fee: processingFee,
          raw_materials: editedMaterials,
        },
      };
      console.log(reqObj);
    }
    setOpenConfirmationDialog(true);
  };

  React.useEffect(() => {
    if (dataToEdit.order_type !== "400") {
      const reqObj = {
        order_id: dataToEdit.order_id,
        order_type: 300,
        mft_id: manufacturerName,
        order: { product_details: editedMaterials },
      };
      console.log(reqObj);
      if (isConfirmed) {
        dispatch(EditMftOrderAction(reqObj));
        setOpenConfirmationDialog(false);
        setIsConfirmed(false);
      }
    } else if (dataToEdit.order_type === "400") {
      const reqObj = {
        order_id: dataToEdit.order_id,
        order_type: 400,
        mft_id: manufacturerName,
        order: {
          final_product: finalProductRequired,
          quantity_outcome: requiredQuantityOutCome,
          processing_fee: processingFee,
          raw_materials: editedMaterials,
        },
      };
      console.log(reqObj);
      if (isConfirmed) {
        // dispatch(EditProcessingPartnerOrder(reqObj));
        setOpenConfirmationDialog(false);
        setIsConfirmed(false);
      }
    }
  }, [isConfirmed]);

  const handleManufacturerChange = (index, value) => {
    const updatedMaterials = [...editedMaterials];
    // Ensure that editedMaterials[index] is defined
    if (updatedMaterials[index]) {
      updatedMaterials[index].manufacturer = value;
      setEditedMaterials(updatedMaterials);
    }
  };
  const handleChangeReqOutcome = (event) => {
    setRequiredQuantityOutCome(event.target.value);
  };

  const handleChangeProcessingFee = (event) => {
    setProcessingFee(event.target.value);
  };

  return (
    <React.Fragment>
      <Dialog
        open={editOrderDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "bold",
            padding: "10px",
          }}
        >
          Edit Order
        </Typography>

        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              padding: "10px",
            }}
          >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Manufacturer
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={manufacturerName}
                label="Manufacturer"
                onChange={(event) => setManufacturerName(event.target.value)}
              >
                {manufacturersList?.map((manufacturer) => {
                  return (
                    <MenuItem value={manufacturer.id}>
                      {manufacturer.details.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {dataToEdit.order_type === "400" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <Box>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Required Final Product
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={finalProductRequired}
                      label="Required Final Product"
                      onChange={(event) =>
                        setFinalProductRequired(event.target.value)
                      }
                    >
                      {allProducts?.map((product) => {
                        return (
                          <MenuItem value={product.product_id}>
                            {product.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  <TextField
                    label="Required Outcome Quantity"
                    size="small"
                    type="number"
                    value={requiredQuantityOutCome}
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={handleChangeReqOutcome}
                  />
                </Box>

                <Box>
                  <TextField
                    label="Processing fee / Quantity"
                    size="small"
                    type="number"
                    value={processingFee}
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={handleChangeProcessingFee}
                  />
                </Box>
              </Box>
            )}
          </Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Quantity Required</TableCell>
                  {dataToEdit.order_type !== "400" && (
                    <TableCell>Price / Quantity</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataToEdit.components.map((material, index) => (
                  <TableRow key={material.id}>
                    {dataToEdit.order_type === "400" && (
                      <TableCell>{material.raw_materials[0].name}</TableCell>
                    )}
                    {dataToEdit.order_type !== "400" && (
                      <TableCell>{material.name}</TableCell>
                    )}
                    <TableCell>
                      <TextField
                        type="number"
                        value={
                          editedMaterials[index]?.quantity || material.quantity
                        }
                        onChange={(e) =>
                          handleQuantityChange(index, e.target.value)
                        }
                      />
                    </TableCell>
                    {dataToEdit.order_type !== "400" && (
                      <TableCell>
                        <TextField
                          type="number"
                          value={
                            editedMaterials[index]?.price || material.price
                          }
                          onChange={(e) =>
                            handlePriceChange(index, e.target.value)
                          }
                        />
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={handleClickEdit} autoFocus variant="contained">
            Edit
          </Button>
        </DialogActions>
      </Dialog>
      {openConfirmationDialog && (
        <CustomConfirmationDialog
          setOpenConfirmationDialog={setOpenConfirmationDialog}
          openConfirmationDialog={openConfirmationDialog}
          setIsConfirmed={setIsConfirmed}
          description={"Are you want to edit this order?"}
        />
      )}
    </React.Fragment>
  );
}
