/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Actions for getting , creating , and updating the roles details
 * @date 26/05/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import axios from "axios";
// import useAxios from "../../Hooks/useAxios";
import useAxios from "../../Hooks/useAxios";
import { Dispatch } from "redux";
import { toast } from "react-toastify";

import actionType from "../actionTypes";

export const AddAccessForRoles = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  console.log(api);
  return async (dispatch) => {
    try {
      let response = await api.post(`/PSCM/roles/access`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.ADD_ACCESS_ROLE,
          payload: response.data,
        });
        if (response.data.error === false) {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else if (response.data.error === true) {
          toast.error(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};

export const GetAccessForRoles = (
  pageNumber1,
  pageSize1,
  ascOrDesc1,
  lastPageNumber1
) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(
        `/PSCM/roles/access?created_at_sort=${ascOrDesc1}&page_number=${pageNumber1}&page_size=${pageSize1}`
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_ACCESS_ROLE,
          payload: response.data,
        });
        if (response.data.error === false) {
          // toast.success(response.data.msg, {
          //   position: "top-right",
          //   autoClose: 1000,
          //   hideProgressBar: true,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "light",
          // });
        } else if (response.data.error === true) {
          toast.error(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};

export const EditAccessForRoles = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  console.log(reqObj);
  console.log(api);
  return async (dispatch) => {
    try {
      let response = await api.put(`/PSCM/roles/access`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.EDIT_ACCESS_ROLE,
          payload: response.data,
        });
        if (response.data.error === false) {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else if (response.data.error === true) {
          toast.error(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};
