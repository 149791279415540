import * as React from "react";
import { Box, stepConnectorClasses, StepConnector, StepLabel, } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ProductPurchase from "./ProductPurchase";
import { styled } from "@mui/styles";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import OrderIcon from "@mui/icons-material/ListAlt";
import ChecklistIcon from "@mui/icons-material/ChecklistOutlined";
import NavigationIcon from "@mui/icons-material/Navigation";
import PriceBreakUp from "./PriceBreakUp";
import { useDispatch, useSelector } from "react-redux";
import { ValidateOrder } from "../../Redux/Actions/purchaseProductsAction";
import { toast } from "react-toastify";
import ProcessingPartnerOrderView from "./ProcessingPartnerOrderView";
import PropTypes from "prop-types";
const steps = [
  "Select Product and Components",
  "Order Over View",
  // "Order over view",
];

const ColorlibStepIcon = (props) => {
  const { active, completed, className } = props;

  const icons = {
    1: <OrderIcon />,
    2: <ChecklistIcon />
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
};

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,

  icon: PropTypes.node,
};

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient(180deg, rgba(39,181,242,1) 0%, rgba(39,181,242,1) 77%)",

    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient(180deg, rgba(39,181,242,1) 0%, rgba(39,181,242,1) 77%)",
  }),
}));

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`&.${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient(180deg, rgba(39,181,242,1) 0%, rgba(39,181,242,1) 77%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`&.${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient(180deg, rgba(39,181,242,1) 0%, rgba(39,181,242,1) 77%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

export default function PurchaseProductStepper({
  allProducts,
  manufacturersList,
  allRawMaterials,
  allFinishedGoods,
  rawMaterialsStock,
}) {
  const dispatch = useDispatch();
  const purchaseProductDetails = useSelector(
    (state) => state.purchaseProductData
  );
  const [orderValidation, setOrderValidation] = React.useState([]);

  const [
    processingPartnerOrderValidation,
    setProcessingPartnerOrderValidation,
  ] = React.useState([]);

  React.useEffect(() => {
    console.log("purchaseProductDetails");
    console.log(purchaseProductDetails);
    if (purchaseProductDetails.error === false) {
      setOrderValidation(purchaseProductDetails?.validated);
      setProcessingPartnerOrderValidation(
        purchaseProductDetails?.validatedProcessingPartner
      );
    }
  }, [purchaseProductDetails]);

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const [selectedData, setSelectedData] = React.useState([]);
  const [hitValidateApi, setHitValidateApi] = React.useState(false);
  const [orderType, setOrderType] = React.useState("100");
  const [productType, setProductType] = React.useState("300");

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
    // console.log(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  React.useEffect(() => {
    console.log("active step");
    console.log(activeStep);
    if (activeStep === 1) {
      setHitValidateApi(true);
    } else {
      setHitValidateApi(false);
    }
  }, [activeStep]);

  React.useEffect(() => {
    console.log("selectedData");
    console.log(selectedData);
    if (orderType === "100") {
      if (selectedData.length) {
        const reqObj = {
          order_type: parseInt(productType),
          orders: selectedData,
        };
        console.log("reqobj");
        console.log(reqObj);
        dispatch(ValidateOrder(reqObj));
        setTimeout(() => {
          toast.success("Successfully added to cart", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }, 1000);
      }
    }
  }, [selectedData]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box m="50px">
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Box>


      <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {activeStep === 0 && (
              <>
                <Box sx={{ marginTop: "20px" }}>
                  <ProductPurchase
                    allProducts={allProducts}
                    manufacturersList={manufacturersList}
                    activeStep={activeStep}
                    hitValidateApi={hitValidateApi}
                    selectedData={selectedData}
                    setSelectedData={setSelectedData}
                    allRawMaterials={allRawMaterials}
                    allFinishedGoods={allFinishedGoods}
                    orderType={orderType}
                    setOrderType={setOrderType}
                    productType={productType}
                    setProductType={setProductType}
                    rawMaterialsStock={rawMaterialsStock}
                    setActiveStep={setActiveStep}
                  />
                </Box>
              </>
            )}

            {activeStep === 1 && orderType === "100" ? (
              <>
                <Box sx={{ marginTop: "20px" }}>
                  <PriceBreakUp
                    orderValidation={orderValidation}
                    selectedData={selectedData}
                    productType={productType}
                  />
                </Box>
              </>
            ) : (
              ""
            )}

            {activeStep === 1 && orderType === "200" ? (
              <>
                <Box sx={{ marginTop: "20px" }}>
                  <ProcessingPartnerOrderView
                    selectedData={selectedData}
                    processingPartnerOrderValidation={
                      processingPartnerOrderValidation
                    }
                  />
                </Box>
              </>
            ) : (
              ""
            )}
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              {/* <Button onClick={handleNext} sx={{ mr: 1 }}>
                Next  
              </Button> */}
              {/* <Box
                sx={{
                  position: "fixed",
                  bottom: 16,
                  right: 16,
                  zIndex: 1000,
                  // Set a high z-index to ensure the button appears above other elements
                }}
              >
                <Fab variant="extended" onClick={handleNext}>
                  <NavigationIcon sx={{ mr: 1 }} />
                  
                </Fab>
              </Box> */}
              {/* {activeStep !== steps.length &&
                (completed[activeStep] ? (
                  <Typography
                    variant="caption"
                    sx={{ display: "inline-block" }}
                  >
                    Step {activeStep + 1} already completed
                  </Typography>
                ) : (
                  <Button onClick={handleComplete}>
                    {completedSteps() === totalSteps() - 1
                      ? "Finish"
                      : "Complete Step"}
                  </Button>
                ))} */}
            </Box>
          </React.Fragment>
        )}
      </div>
    </Box>
  );
}
