import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TextField,
  useTheme,
  TablePagination,
} from "@mui/material";
import { appColors } from "../../AppMeta/appColors";
import { Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import { SettingsSuggestRounded } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import CustomConfirmationDialog from "../../Components/CustomComp/CustomConfirmationDialog";
import {
  GetRawMaterials,
  UpdateRawMaterial,
} from "../../Redux/Actions/ProductComponentsAction";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useState } from "react";
import { tokens } from "../../AppMeta/theme";

const RawMaterialsTable = ({
  allRawMaterials,
  pageNumber,
  pageSize,
  lastPageNumber,
  createdAtSort,
  available,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const [editRMDialog, setEditRMDialog] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [getOrders, setGetOrders] = React.useState([]);
  const [name, setName] = React.useState("");
  const [itemDescription, setItemDescription] = React.useState("");
  const [unit, setUnit] = React.useState("");
  const [cgst, setCgst] = React.useState("");
  const [sgst, setSgst] = React.useState("");
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [itemCgst, setItemCgst] = useState("");
  const [itemSgst, setItemSgst] = useState("");
  const [error, setError] = React.useState("");
  const [minBookQty, setMinBookQty] = React.useState("");
  const [maxBookQty, setMaxBookQty] = React.useState("");

  const [openConfirmationDialog, setOpenConfirmationDialog] =
    React.useState(false);
  const [description, setDescription] = React.useState("");
  const [isConfirmed, setIsConfirmed] = React.useState(false);

  const [nameSearch, setNameSearch] = React.useState("");
  const [unitSearch, setUnitSearch] = React.useState("");

  const [availableFilter, setAvailableFilter] = React.useState("all");

  const handleItemCgstChange = (event) => {
    setItemCgst(event.target.value);
    if (event.target.value !== setItemSgst(event.target.value)) {
      setError("Values do not match");
    } else {
      setError("");
    }
    const newValue = event.target.value;
    if (!isNumeric(newValue) || parseInt(newValue, 10) > 99) {
      setError("Value must not be greater than two digit number");
    } else {
      setItemCgst(newValue);
      setError("");
    }
  };
  React.useEffect(() => {
    console.log("All Raw Materials");
    console.log(allRawMaterials);
  }, []);

  const isNumeric = (value) => {
    return /^\d{0,2}$/.test(value);
  };

  const disableSubmit = () => {
    return error !== "" || itemCgst === "" || itemSgst === "";
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeOrders = () => {
    setGetOrders(allRawMaterials);
  };

  const handleItemSgstChange = (event) => {
    setItemSgst(event.target.value);
    if (event.target.value !== setItemCgst(event.target.value)) {
      setError("Values do not match");
    } else {
      setError("");
    }
    const newValue = event.target.value;
    if (!isNumeric(newValue) || parseInt(newValue, 10) > 99) {
      setError("Value must be a number less than or equal to 99");
    } else {
      setItemSgst(newValue);
      setError("");
    }
  };

  const handleFilterChange = (event) => {
    setAvailableFilter(event.target.value);
  };

  const handleClickRow = (material) => {
    setSelectedRow(material);
    setEditRMDialog(true);
  };

  React.useEffect(() => {
    setName(selectedRow?.name);
    setItemDescription(selectedRow?.details.description);
    setUnit(selectedRow?.details.units);
    setCgst(selectedRow?.details.tax_details.gst.CGST);
    setSgst(selectedRow?.details.tax_details.gst.SGST);
  }, [selectedRow]);

  const handleUpdate = () => {
    setDescription("Do you want to edit the raw material details?");
    setOpenConfirmationDialog(true);
  };

  React.useEffect(() => {
    if (isConfirmed) {
      const reqObj = {
        id: selectedRow.id,
        name: name,
        is_force_update: true,
        is_available: true,
        details: {
          description: itemDescription,
          units: unit,
          tax_details: {
            gst: {
              CGST: cgst,
              SGST: sgst,
            },
          },
        },
      };
      dispatch(UpdateRawMaterial(reqObj));
      setTimeout(() => {
        dispatch(
          GetRawMaterials(pageNumber, pageSize, available, createdAtSort)
        );
      }, 1000);
      setOpenConfirmationDialog(false);
      setEditRMDialog(false);
    }
  }, [isConfirmed]);

  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const handleChangeDescription = (event) => {
    setItemDescription(event.target.value);
  };

  const handleChangeUnit = (event) => {
    setUnit(event.target.value);
  };

  const handleChangeCgst = (event) => {
    setCgst(event.target.value);
  };

  const handleChangeSgst = (event) => {
    setSgst(event.target.value);
  };

  const handleSearch = () => {
    // Implement your search logic here
    // Dispatch an action to fetch filtered data based on nameSearch and unitSearch
    console.log("Search by Name:", nameSearch);
    dispatch(
      GetRawMaterials(
        pageNumber,
        pageSize,
        available,
        createdAtSort,
        nameSearch,
        unitSearch
      )
    );
    console.log("Search by Unit:", unitSearch);
  };

  const handleFilter = () => {
    console.log("handle filter");
    dispatch(
      GetRawMaterials(
        pageNumber,
        pageSize,
        available,
        createdAtSort,
        nameSearch,
        unitSearch,
        availableFilter
      )
    );
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getOrders.length) : 0;
  return (
    <>
      <Box>
        <TableContainer
          sx={{
            borderRadius: "10px",
          }}
        >
          <Table>
            <TableHead
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.primary[300],
                boxShadow: 2,
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    textAlign: "start",
                    fontSize: { xs: "0.9rem", md: "1.1rem" },
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    textAlign: "start",
                    fontSize: { xs: "0.9rem", md: "1.1rem" },
                  }}
                >
                  Description
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    textAlign: "start",
                    fontSize: { xs: "0.9rem", md: "1.1rem" },
                  }}
                >
                  Units
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    textAlign: "start",
                    fontSize: { xs: "0.9rem", md: "1.1rem" },
                  }}
                >
                  CGST
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    textAlign: "start",
                    fontSize: { xs: "0.9rem", md: "1.1rem" },
                  }}
                >
                  SGST
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    textAlign: "start",
                    fontSize: { xs: "0.9rem", md: "1.1rem" },
                  }}
                >
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {" "}
              {(rowsPerPage > 0
                ? allRawMaterials.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                : allRawMaterials
              ).map((material) => (
                <TableRow
                  key={material.id}
                  onChange={handleChangeOrders}
                  onClick={() => handleClickRow(material)}
                >
                  <TableCell
                    sx={{
                      fontSize: { xs: "0.8rem", md: "0.9rem" },
                      backgroundColor: colors.primary[600],
                      textAlign: "start",
                    }}
                  >
                    {material.name}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { xs: "0.8rem", md: "0.9rem" },
                      textAlign: "start",
                      backgroundColor: colors.primary[600],
                    }}
                  >
                    {material.details.description}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { xs: "0.8rem", md: "0.9rem" },
                      textAlign: "start",
                      backgroundColor: colors.primary[600],
                    }}
                  >
                    {material.units}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { xs: "0.8rem", md: "0.9rem" },
                      backgroundColor: colors.primary[600],
                      textAlign: "start",
                    }}
                  >
                    {material.details.tax_details.gst.CGST}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { xs: "0.8rem", md: "0.9rem" },
                      backgroundColor: colors.primary[600],
                      textAlign: "start",
                    }}
                  >
                    {material.details.tax_details.gst.SGST}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { xs: "0.8rem", md: "0.9rem" },
                      backgroundColor: colors.primary[600],
                      textAlign: "start",
                    }}
                  >
                    {material.is_available ? "Available" : "Unavailable"}
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 41 * emptyRows }}>
                  <TableCell colSpan={3} aria-hidden />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={allRawMaterials.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>

      <Dialog open={editRMDialog} onClose={() => setEditRMDialog(false)}>
        <DialogTitle>Edit Raw Material Details</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              padding: "10px",
            }}
          >
            <TextField
              label="Name"
              name="name"
              value={name}
              onChange={handleChangeName}
              fullWidth
            />
            <TextField
              label="Description"
              name="Description"
              value={itemDescription}
              onChange={handleChangeDescription}
              fullWidth
            />
            <TextField
              label="Unit"
              name="Unit"
              value={unit}
              onChange={handleChangeUnit}
              fullWidth
            />
            <TextField
              label="CGST"
              name="CGST"
              fullWidth
              value={itemCgst}
              size="small"
              onChange={handleItemCgstChange}
              error={error !== ""}
              helperText={error}
              type="number"
            />
            <TextField
              label="SGST"
              name="SGST"
              value={itemSgst}
              onChange={handleItemSgstChange}
              fullWidth
              error={error !== ""}
              helperText={error}
              type="number"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "10px",
              padding: "10px",
            }}
          >
            <Button
              disabled={disableSubmit()}
              variant="contained"
              color="primary"
              onClick={handleUpdate}
            >
              Update
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setEditRMDialog(false)}
            >
              Close
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      {openConfirmationDialog && (
        <CustomConfirmationDialog
          openConfirmationDialog={openConfirmationDialog}
          setOpenConfirmationDialog={setOpenConfirmationDialog}
          description={description}
          setIsConfirmed={setIsConfirmed}
        />
      )}
    </>
  );
};

export default RawMaterialsTable;
