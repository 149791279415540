import React from "react";
import {
  TextField,
  Box,
  Paper,
  Typography,
  FormControl,
  InputLabelSelect,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import { useDispatch } from "react-redux";
import { AddIotFacility } from "../../../Redux/Actions/addFacilityWFSFillingAction";

export function AddIOTDevices() {
  //1.useState Hooks
  const dispatch = useDispatch();
  const [selectFacility, setSelectFacility] = React.useState("");
  const [flagPrint, setFlagPrint] = React.useState(false);
  const [deviceNumber, setDeviceNumber] = React.useState("");

  //2.useEffect
  React.useEffect(() => {
    if (flagPrint) {
      console.log(selectFacility);
      console.log(deviceNumber);
      let reqObj = {
        facility: selectFacility,
        deviceNumber: deviceNumber,
      };
      console.log(reqObj);
      setFlagPrint(false);
    }
  }, [selectFacility, deviceNumber, flagPrint]);

  //3.Functions
  const handleClickAddIot = () => {
    console.log(selectFacility);
    console.log(deviceNumber);
    let reqObj = {
      device_id: deviceNumber,
    };
    console.log(reqObj);
    dispatch(AddIotFacility(reqObj));
  };

  return (
    <>
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <Paper elevation={23}>
            <Box sx={{ padding: "10px", minWidth: 150 }}>
              <TextField
                id="outlined-basic"
                label="Enter Device ID"
                variant="outlined"
                value={deviceNumber}
                onChange={(e) => setDeviceNumber(e.target.value)}
              />
            </Box>
            <Box sx={{ padding: "10px" }}>
              <CustomButton
                label="Add IOT"
                variant="contained"
                handleClick={handleClickAddIot}
              />
            </Box>
          </Paper>
        </Box>
      </>
    </>
  );
}
