import { Box, Typography, Divider, Button, useTheme } from "@mui/material";
import CurrencyRupee from "@mui/icons-material/CurrencyRupee";
import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { tokens } from "../../../../AppMeta/theme";
import { useDispatch } from "react-redux";
import {
  CreateFacilityAction,
  GetFacilityAction,
} from "../../../../Redux/Actions/facilityAction";

export function PriceDetails({
  priceDetails,
  returnCount,
  selectedProduct,
  selectedCrates,
  count,
  totalPrize,
  handleCloseNewOrder,
  price,
  productCgst,
  productSgst,
  totalCratesPrize,
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [totalProductPrize, setProductPrize] = React.useState(0);

  React.useEffect(() => {
    setProductPrize(
      (priceDetails.total_product_price * (productCgst + productSgst)) / 100 +
        priceDetails.total_product_price
    );
  }, []);

  const handleClickPlaceOrder = () => {
    const reqObj = {
      container_master_id: selectedProduct.product_details.id,
      container_material: selectedProduct.product_details.material,
      container_capacity: selectedProduct.product_details.capacity,
      order_qty: count,
      return_qty: returnCount,
      payment_type: 100,
      payment_method: 0,
      po_file: "",
      crates: Object.keys(selectedCrates).map((crateId) => {
        const crate = selectedCrates[crateId];
        console.log("crate");
        console.log(crate);
        return {
          name: crate.name,
          order_qty: crate.quantity,
        };
      }),
    };

    console.log("reqObj");
    console.log(reqObj);
    dispatch(CreateFacilityAction(reqObj));

    setTimeout(() => {
      dispatch(GetFacilityAction());
    }, 1000);
    handleCloseNewOrder();
    // toast.success("Order Placed", {
    //   position: "top-right",
    //   autoClose: 1000,
    //   hideProgressBar: true,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "light",
    // });
  };

  return (
    // <>
    //   <Box
    //   >
    //      <Box
    //       sx={{
    //         alignItems: "center",
    //         justifyContent: "center",
    //         display: "flex",
    //       }}
    //     >
    //       <Typography
    //         sx={{
    //           textAlign: "center",
    //           margin: "20px",
    //           fontSize: { xs: "1rem", md: "1.5rem" },
    //           fontWeight: "bold",
    //         }}
    //       >
    //         Price Details
    //       </Typography>
    //     </Box>
    //     <Box sx={{ padding: "10px" }}>
    //       <Box
    //         sx={{
    //           justifyContent: "space-between",
    //           alignSelf: "flex-end",
    //           display: "flex",
    //         }}
    //       >
    //         <Typography
    //           sx={{ fontWeight: "bold",  fontSize: {xs:"0.8rem",md:"1.2rem"}, }}
    //           variant="subtitle1"
    //         >
    //           Total Products Price
    //         </Typography>

    //         <Box
    //           sx={{
    //             alignSelf: "flex-end",
    //             display: "flex",
    //           }}
    //         >
    //           <CurrencyRupee sx={{ marginTop: "5px" }} />
    //           <Typography
    //             sx={{
    //               fontSize: {xs:"0.8rem",md:"1.2rem"},
    //               marginTop: "5px",
    //               textAlign: "right",
    //               fontWeight: "bold",
    //             }}
    //           >
    //             {priceDetails.total_product_price}
    //           </Typography>
    //         </Box>
    //       </Box>
    //       <Box
    //         sx={{
    //           display: "flex",
    //           justifyContent: "space-between",
    //         }}
    //       >
    //         <Typography sx={{ fontSize: {xs:"0.7rem",md:"1.1rem"} }} variant="subtitle1">
    //           Total Products Quantity
    //         </Typography>
    //         <Typography
    //           sx={{ fontWeight: "bold", fontSize: {xs:"0.7rem",md:"1.1rem"} }}
    //           variant="subtitle1"
    //         >
    //           {priceDetails.order_qty}
    //         </Typography>
    //       </Box>
    //     </Box>

    //     {priceDetails.crates.map((crate) => (
    //       <React.Fragment key={crate.id}>
    //         <Box sx={{ padding: "10px" }}>
    //           <Box
    //             sx={{
    //               justifyContent: "space-between",
    //               alignSelf: "flex-end",
    //               display: "flex",
    //             }}
    //           >
    //             <Typography
    //               sx={{ fontWeight: "bold",  fontSize: {xs:"0.8rem",md:"1.2rem"} }}
    //               variant="subtitle1"
    //             >
    //               Total Crates Price
    //             </Typography>

    //             <Box
    //               sx={{
    //                 alignSelf: "flex-end",
    //                 display: "flex",
    //               }}
    //             >
    //               <CurrencyRupee sx={{ marginTop: "5px" }} />
    //               <Typography
    //                 sx={{
    //                   fontSize: {xs:"0.8rem",md:"1.2rem"},
    //                   marginTop: "5px",
    //                   textAlign: "right",
    //                   fontWeight: "bold",
    //                 }}
    //               >
    //                 {crate.total_price}
    //               </Typography>
    //             </Box>
    //           </Box>
    //           <Box
    //             sx={{
    //               display: "flex",
    //               justifyContent: "space-between",
    //             }}
    //           >
    //             <Typography sx={{ fontSize: {xs:"0.7rem",md:"1.1rem"} }} variant="subtitle1">
    //               Total Crates Quantity
    //             </Typography>
    //             <Typography
    //               sx={{ fontWeight: "bold",  fontSize: {xs:"0.7rem",md:"1.1rem"} }}
    //               variant="subtitle1"
    //             >
    //               {crate.order_qty}
    //             </Typography>
    //           </Box>
    //         </Box>

    //         <Divider />
    //         <Box
    //           sx={{
    //             display: "flex",
    //             justifyContent: "space-between",
    //             margin: "10px",
    //           }}
    //         >
    //           <Typography
    //             sx={{ fontSize: "20px", fontWeight: "bold" }}
    //             variant="subtitle1"
    //           >
    //             GRAND TOTAL
    //           </Typography>
    //           <Box sx={{display:"flex"}}>
    //             <CurrencyRupee/>
    //           <Typography
    //             sx={{ fontWeight: "bold", fontSize: "20px" }}
    //             variant="subtitle1"
    //           >
    //             {priceDetails.total_product_price + crate.total_price}
    //           </Typography>
    //           </Box>
    //         </Box>
    //       </React.Fragment>
    //     ))}

    //     <Box
    //       sx={{
    //         width: "100%",
    //         height: "10%",
    //         backgroundColor: "#27b5f2",
    //       }}
    //     >
    //       <Button
    //         sx={{
    //           display: "flex",
    //           justifyContent: "center",
    //           alignItems: "center",
    //           width: "100%",
    //           height: "100%",
    //           border: "2px solid black",
    //           "&:hover": {
    //             backgroundColor: "white", //
    //             color: "black", //
    //           },
    //           borderRadius: "10px",
    //           color: "white",
    //         }}
    //         onClick={handleClickPlaceOrder}
    //       >
    //         <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
    //           Place Order
    //         </Typography>
    //       </Button>
    //     </Box>
    //   </Box>
    // </>
    <Box
      sx={{
        marginRight: "20px",
      }}
    >
      <Typography variant="h5" ml="5px" fontWeight="bold">
        Price Breakup
      </Typography>
     
      <Box
        height="300px"
        width="300px"
        backgroundColor={colors.primary[400]}
        borderRadius="10px"
      >
        <Box
          backgroundColor={colors.primary[400]}
          height="100px"
          m="10px"
          borderRadius="10px"
        >
          <Box
            display="flex"
            p="10px 10px 0px 10px"
            justifyContent="space-between"
          >
            <Typography variant="h5">Total Products Prize</Typography>
            <Typography variant="h5"> &#8377;{totalProductPrize}</Typography>
          </Box>
          <Box
            display="flex"
            p="10px 10px 0px 10px"
            justifyContent="space-between"
          >
            <Typography variant="h6">
              {count} * &#8377;{price}
            </Typography>
            <Typography variant="h6">
              &#8377;{priceDetails.total_product_price}
            </Typography>
          </Box>
          <Box
            display="flex"
            p="0px 10px 0px 10px"
            justifyContent="space-between"
          >
            <Typography variant="h6">CGST</Typography>
            <Typography variant="h6">{productCgst}&#x25;</Typography>
          </Box>
          <Box
            display="flex"
            p="0px 10px 0px 10px"
            justifyContent="space-between"
          >
            <Typography variant="h6">SGST</Typography>
            <Typography variant="h6">{productSgst}&#x25;</Typography>
          </Box>

          <Divider />
          {/* <Box
            display="flex"
            p="10px 10px 0px 10px"
            justifyContent="space-between"
          >
            <Typography variant="h5" fontWeight="bold">
              Grand Total
            </Typography>
            <Typography variant="h5" fontWeight="bold">
              &#8377;560
            </Typography>
          </Box> */}
          {/* <Box display="flex" p="0px 10px 0px 10px" justifyContent="space-between">
            <Typography variant='h6' color={colors.blueAccent[500]}>Discount</Typography>
            <Typography variant='h6' color={colors.blueAccent[500]}>&#8377;70</Typography>
          </Box> */}
          {/* <Box
            display="flex"
            p="10px 10px 0px 10px"
            justifyContent="space-between"
          >
            <Typography variant="h5" fontWeight="bold">
              To Pay
            </Typography>
            <Typography variant="h5" fontWeight="bold">
              &#8377;560
            </Typography>
          </Box> */}
        </Box>

        <Box
          backgroundColor={colors.primary[400]}
          height="100px"
          m="10px"
          borderRadius="10px"
        >
          <Box
            display="flex"
            p="10px 10px 0px 10px"
            justifyContent="space-between"
          >
            <Typography variant="h5">Total Crates Prize</Typography>
            <Typography variant="h5"> &#8377;{totalProductPrize}</Typography>
          </Box>
          {priceDetails.crates.map((crate, idx) => {
            return (
              <Box key={idx}>
                <Box
                  display="flex"
                  p="10px 10px 0px 10px"
                  justifyContent="space-between"
                >
                  <Typography variant="h6">
                    {crate.order_qty} * &#8377;{crate.price}
                  </Typography>
                  <Typography variant="h6">
                    &#8377;{crate.total_price}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  p="0px 10px 0px 10px"
                  justifyContent="space-between"
                >
                  <Typography variant="h6">CGST</Typography>
                  <Typography variant="h6">{productCgst}&#x25;</Typography>
                </Box>
                <Box
                  display="flex"
                  p="0px 10px 0px 10px"
                  justifyContent="space-between"
                >
                  <Typography variant="h6">CGST</Typography>
                  <Typography variant="h6">{productCgst}&#x25;</Typography>
                </Box>
              </Box>
            );
          })}

          {/* <Box
            display="flex"
            p="0px 10px 0px 10px"
            justifyContent="space-between"
          >
            <Typography variant="h6">CGST</Typography>
            <Typography variant="h6">{productCgst}&#x25;</Typography>
          </Box>
          <Box
            display="flex"
            p="0px 10px 0px 10px"
            justifyContent="space-between"
          >
            <Typography variant="h6">SGST</Typography>
            <Typography variant="h6">{productSgst}&#x25;</Typography>
          </Box> */}

          <Divider />
          <Box
            display="flex"
            p="10px 10px 0px 10px"
            justifyContent="space-between"
          >
            <Typography variant="h5" fontWeight="bold">
              Grand Total
            </Typography>
            <Typography variant="h5" fontWeight="bold">
              &#8377;560
            </Typography>
          </Box>
          {/* <Box display="flex" p="0px 10px 0px 10px" justifyContent="space-between">
            <Typography variant='h6' color={colors.blueAccent[500]}>Discount</Typography>
            <Typography variant='h6' color={colors.blueAccent[500]}>&#8377;70</Typography>
          </Box> */}
          <Box
            display="flex"
            p="10px 10px 0px 10px"
            justifyContent="space-between"
          >
            <Typography variant="h5" fontWeight="bold">
              To Pay
            </Typography>
            <Typography variant="h5" fontWeight="bold">
              &#8377;560
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        backgroundColor={colors.blueAccent[400]}
        width="300px"
        mt="10px"
        height="200"
        boxShadow={2}
        borderRadius="10px"
        onClick={handleClickPlaceOrder}
      >
        <Button color="primary">Place An Order</Button>
      </Box>
    </Box>
  );
}
