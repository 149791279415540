import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Box } from "@mui/material";

export default function FilterDialog({
  isFilterOpen,
  setIsFilterOpen,
  isActive,
  setIsActive,
  ascOrDesc,
  setAscOrDesc,
}) {
  const [open, setOpen] = React.useState(false);

  const handleChange = (event, newAlignment) => {
    setIsActive(newAlignment);
  };
  const handleChangeAscDesc = (event, newAlignment) => {
    setAscOrDesc(newAlignment);
  };
  const handleClose = () => {
    setIsFilterOpen(false);
  };

  return (
    <div>
      <Dialog
        open={isFilterOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Filter</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <Box>
            <ToggleButtonGroup
              color="primary"
              value={isActive}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
            >
              <ToggleButton value={true}>Active</ToggleButton>
              <ToggleButton value={false}>Inactive</ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box>
            {" "}
            <ToggleButtonGroup
              color="primary"
              value={ascOrDesc}
              exclusive
              onChange={handleChangeAscDesc}
              aria-label="Platform"
            >
              <ToggleButton value="ASC">Ascending</ToggleButton>
              <ToggleButton value="DESC">Descending</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          {/* <Button onClick={handleFilter} autoFocus>
            Filter
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
