/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Tahir Shaik
 * @description BookWater App Theme  Should Be declared Here and will use in entire app
 * @date 26/05/23
 * @version 0.0.1 - Initial Release
 */

const appTheme = {
    primaryColor: "primary",
    secondaryColor: "secondary",
    buttonTextSize: ".8rem",
    textSize: "18px",
    textHeadingSize: "20px",
    fontSizeSmall: ".8rem",
    fontSizeMedium: ".9rem",
    fontSizeLarge: "1rem",
    fontSizexl: "1.2rem",
    fontSizexs: ".7rem",
  };
  
  export default appTheme;
  