/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Add users main component to show user details and edit details.
 * @date 26/07/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries
import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  Paper,
  Box,
  Dialog,
  Menu,
  Chip,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";

import { useDispatch, useSelector } from "react-redux";
import {
  AddUserRolesDomain,
  GetUserRolesDomainDetails,
} from "../../../Redux/Actions/addUsersDomainRolesAction";
import UserDetailsTable from "./UserDetailsTable";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { GetOrganizationList } from "../../../Redux/Actions/organizationAction";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    // flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "30px",
    gap: "10px",
    // gap: theme.spacing(2),
    maxWidth: "400px",
    margin: "0 auto",
  },
  // button: {
  //   alignSelf: "flex-end",
  // },
}));

const AddUsers = () => {
  const dispatch = useDispatch();
  const rolesData = useSelector((state) => state.rolesData);

  const addUserRolesDomainDetails = useSelector(
    (state) => state.addUserRolesDomainDetails
  );
  const organisationDetails = useSelector((state) => state.organizationData);
  const classes = useStyles();
  const [formValues, setFormValues] = useState({
    roleName: "",
    organizationName: "",
    fullName: "",
    email: "",
    phone: "",
  });

  // Required variabled declared here
  const [addUserDialog, setAddUserDialog] = React.useState(false);
  const [userDetailsList, setUserDetailsList] = React.useState([]);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(25);
  const [ascOrDesc, setAscOrDesc] = React.useState("ASC");
  const [isActive, setIsActive] = React.useState(true);
  const [lastPageNumber, setLastPageNumber] = React.useState(null);
  const [password, setPassword] = React.useState("");
  const [allFacility, setAllFacility] = React.useState([]);
  const [facilityName, setFacilityName] = React.useState("");
  const [organisationName, setOrganisationName] = React.useState("");
  const [organisationId, setOrganisationId] = React.useState("");

  // For checking the validation for textfield variables
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const [invalidPhone, setInvalidPhone] = React.useState(false);

  const [rolesList, setRolesList] = React.useState([]);
  const [lastMore, setLastMore] = React.useState(null);
  const [selectedRole, setSelectedRole] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hasMoreData, setHasMoreData] = React.useState(true);
  const [getMoreRoles, setGetMoreRoles] = React.useState(1);

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  React.useEffect(() => {
    console.log(rolesData);
    if (rolesData.error === false) {
      if (rolesData?.rolesList?.data?.length > 0) {
        console.log(rolesData.rolesList.data);
        // setRolesList(rolesData.rolesList.data);
        setLastMore(rolesData.rolesList.last_page_number);
        setRolesList((prevRolesList) =>
          prevRolesList
            ? [...prevRolesList, ...rolesData.rolesList.data]
            : rolesData.rolesList.data
        );
      }
    }
  }, [rolesData]);

  // Hitting the GET Roles API to show all the role for the user to select
  React.useEffect(() => {
    dispatch(
      GetUserRolesDomainDetails(pageNumber, pageSize, ascOrDesc, isActive)
    );
  }, [pageNumber, pageSize, ascOrDesc, isActive]);

  // To access the roles reducer store data
  React.useEffect(() => {
    console.log(addUserRolesDomainDetails);
    if (addUserRolesDomainDetails.error === false) {
      setLastPageNumber(addUserRolesDomainDetails.userDetails.last_page_number);
      if (addUserRolesDomainDetails?.userDetails?.data?.length > 0) {
        setUserDetailsList(addUserRolesDomainDetails.userDetails.data);
      }
    }
  }, [addUserRolesDomainDetails]);

  React.useEffect(() => {
    console.log("Orgs Details");
    console.log(organisationDetails);
    setAllFacility(organisationDetails.organizationList);
  }, [organisationDetails]);

  React.useEffect(() => {
    dispatch(GetOrganizationList());
  }, []);

  React.useEffect(() => {
    setAllFacility(organisationDetails.organizationList);
  }, [organisationDetails]);

  React.useEffect(() => {
    console.log("allFacility");
    console.log(allFacility);
    allFacility.map((data) => {
      setOrganisationName(data.org_name);
      setOrganisationId(data.id);
    });
  }, [organisationName, organisationId]);

  // Checking the validation for email and phone number
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPhoneNumber = (phone) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  // Common handle change function for textfields for adding users
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name);
    // Email validation check
    if (name === "email") {
      if (!isValidEmail(value)) {
        console.log("Invalid email");
        setInvalidEmail(true);
      } else {
        setInvalidEmail(false);
      }
    }
    // Phone number validation check
    if (name === "phone") {
      if (!isValidPhoneNumber(value)) {
        console.log("Invalid phone Number");
        setInvalidPhone(true);
      } else {
        setInvalidPhone(false);
      }
    }
    // Setting the formvalues
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  // This function will hit the Add User API with Request object required
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log(formValues);
    const reqObj = {
      role_name: selectedRole,
      // org_name: formValues.organizationName,
      org_name: organisationName,
      fullname: formValues.fullName,
      email: formValues.email,
      phone: formValues.phone,
      password: password,
      organisation_id: organisationId,
      organisation_name: organisationName,
    };
    const req2 = {
      role_name: "BW-SUPERADMIN",
      org_name: "SONY MUSIC",
      email: "abdul@sony.com",
      phone: "8943247239",
      fullname: "abdul123",
      password: "Sony@123456",
      org_id: 30,
    };
    const reqObj1 = {
      role_name: "BW-SUPERADMIN",
      org_name: "DLF",
      email: "abdul@dlf.com",
      phone: "8943247237",
      fullname: "abdul",
      password: "Dlf@123456",
    };
    dispatch(AddUserRolesDomain(reqObj));
    setAddUserDialog(false);
    setTimeout(() => {
      dispatch(GetUserRolesDomainDetails());
    }, 1000);
  };

  const handleOpenDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };
  const handleLoadMore = () => {
    setGetMoreRoles(getMoreRoles + 1);
    // fetchMoreData(nextPage);
  };

  //  Jsx starts here..
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "20px",
      }}
    >
      <Dialog
        open={addUserDialog}
        onClose={() => setAddUserDialog(false)}
        sx={{ borderRadius: "30px" }}
      >
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyItems: "center",
            gap: "10px",
            padding: "40px",
          }}
          onSubmit={handleSubmit}
        >
          <Box
            sx={{
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <Box sx={{ minWidth: 120 }}>
              <Button onClick={handleOpenDropdown} variant="outlined">
                Select Role Name
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseDropdown}
              >
                {rolesList?.map((list) => (
                  <MenuItem
                    key={list.role_name}
                    onClick={() => {
                      handleCloseDropdown();
                      setSelectedRole(list.role_name);
                    }}
                  >
                    {list.role_name}
                  </MenuItem>
                ))}
                {hasMoreData && (
                  <MenuItem
                    onClick={handleLoadMore}
                    sx={{ fontWeight: "bold" }}
                    disabled={getMoreRoles < lastMore ? false : true}
                  >
                    More <ExpandMoreRoundedIcon />
                  </MenuItem>
                )}
              </Menu>
            </Box>
            {selectedRole !== "" && (
              <Box>
                <Chip
                  label={`Selected Role Name: ${selectedRole}`}
                  variant="filled"
                  color="success"
                  sx={{ fontWeight: "bold" }}
                />
              </Box>
            )}
          </Box>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">
              Organisation Name
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Facility Name"
              value={facilityName}
              onChange={(e) => setFacilityName(e.target.value)}
            >
              {allFacility?.map((data) => {
                return (
                  <MenuItem value={data.org_name}>{data.org_name}</MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            label="Full Name"
            name="fullName"
            value={formValues.fullName}
            onChange={handleChange}
            required
          />
          <TextField
            label="Email"
            name="email"
            type="email"
            helperText={invalidEmail === true ? "Enter Valid Email" : ""}
            error={invalidEmail}
            value={formValues.email}
            onChange={handleChange}
            required
          />

          <TextField
            label="Phone"
            name="phone"
            helperText={invalidPhone ? "Enter Valid Phone Number" : ""}
            error={invalidPhone}
            value={formValues.phone}
            onChange={handleChange}
            required
          />
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              value={formValues.password}
              onChange={(e) => setPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>

          <Box sx={{ display: "flex", padding: "10px" }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.button}
              disabled={invalidEmail || invalidPhone}
            >
              Submit
            </Button>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CustomButton
                label="Cancel"
                handleClick={() => setAddUserDialog(false)}
                variant="text"
                color="error"
              />
            </Box>
          </Box>
        </form>
      </Dialog>
      {/* User list table component called here */}
      <UserDetailsTable
        userDetailsList={userDetailsList}
        pageNumber={pageNumber}
        pageSize={pageSize}
        ascOrDesc={ascOrDesc}
        isActive={isActive}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        setAscOrDesc={setAscOrDesc}
        setIsActive={setIsActive}
        setAddUserDialog={setAddUserDialog}
        lastPageNumber={lastPageNumber}
      />
    </Box>
  );
};

export default AddUsers;
