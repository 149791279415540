import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import moment from "moment";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Scanner from "../../../../Components/QrCodeScanner/Scanner";
import { tokens } from "../../../../AppMeta/theme";
import BookWaterLogo from "../../../../Assests/Images/BwIcon.png";
import { Close, Opacity } from "@mui/icons-material";

function ConsumerScanner({ open, close }) {
  const [openDailog, setOpenDialog] = React.useState(false);

  const onNewScanResult = (decodedText, decodedResult) => {
    console.log("App [result]", decodedResult);
    if (decodedResult) {
      setOpenDialog(true);
    }
  };
  const handleClose = () => {
    setOpenDialog(false);
  };
  const qualityData = [
    {
      name: "Container Capacity:",
      data: "20L",
    },
    {
      name: "Container Material:",
      data: `Plastic`,
    },

    {
      name: "TDS Range:",
      data: `50`,
    },
    {
      name: "PH Range:",
      data: `8`,
    },
    {
      name: " Mfg Date:",
      data: "22/05/2024",
    },
    {
      name: "Expiry Date:",
      data: "22/06/2024",
    },
    {
      name: " Cans Reused Count:",
      data: "5",
    },
  ];

  const handleEyeIcon = () => {
    //handleQualityReport(data.qrData.quality_report);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <>
      {!openDailog && (
        <Dialog
          open={open}
          onClose={close}
          PaperProps={{
            sx: {
              background: "black",
              borderRadius: "10px",
            },
          }}
          fullScreen
        >
          <DialogTitle>Camera</DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Scanner
                fps={100}
                qrbox={250}
                disableFlip={false}
                qrCodeSuccessCallback={onNewScanResult}
              />
            </Box>
          </DialogContent>
        </Dialog>
      )}

      {openDailog && (
        <Box>
          {true ? (
            <Box sx={{ height: "100px", overflowY: "scroll" }}>
              <Dialog
                open={openDailog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                  sx: {
                    borderRadius: "20px",
                    background:
                      "linear-gradient(322deg, rgba(255,255,255,1) 8%, rgba(153,220,249,1) 21%, rgba(239,249,254,1) 76%, rgba(125,210,248,1) 97%, rgba(39,181,242,1) 100%, rgba(148,218,249,1) 100%)",
                  },
                }}
              >
                <DialogTitle
                  id="alert-dialog-title"
                  sx={{
                    backgroundImage: colors.primary[600],
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                    }}
                  >
                    {" "}
                    <img
                      src={BookWaterLogo}
                      style={{ width: "100px", height: "40px" }}
                      alt=""
                    ></img>
                    <Typography
                      display="block"
                      gutterBottom
                      sx={{
                        marginTop: "10px",
                        marginLeft: "10px",
                        fontSize: "19px",
                        color: colors.primary[400],
                        fontWeight: "bold",
                      }}
                    >
                      Water Quality Details
                    </Typography>
                  </Box>
                </DialogTitle>
                <Divider sx={{ color: "black" }} />
                <DialogContent>
                  <DialogContentText
                    id="alert-dialog-description"
                    sx={{ color: colors.primary[400] }}
                  >
                    {" "}
                    {qualityData.map((qtyData, key) => {
                      return (
                        <Box key={key} sx={{ display: "flex", gap: "10px" }}>
                          <Box>
                            <Typography
                              variant="overline"
                              fontWeight="bold"
                              display="block"
                              fontSize="15px"
                              color="black"
                              gutterBottom
                            >
                              {qtyData.name}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              color: colors.primary[400],
                              fontWeight: "bold",
                            }}
                          >
                            <Typography
                              variant="overline"
                              display="block"
                              fontSize="15px"
                              gutterBottom
                            >
                              {qtyData.data}{" "}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                    <Box
                      sx={{
                        display: "flex",
                        gap: "20px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      <Button
                        size="large"
                        sx={{ color: colors.primary[400], fontWeight: "bold" }}
                        startIcon={<RemoveRedEyeIcon />}
                        onClick={handleEyeIcon}
                      >
                        View Quality Report
                      </Button>
                    </Box>
                  </DialogContentText>
                </DialogContent>
                <DialogActions
                  sx={{
                    backgroundImage: colors.primary[400],
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {/* <Button onClick={handleClose}>Disagree</Button> */}
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    size="small"
                    startIcon={<Close />}
                    sx={{ backgroundColor: colors.redAccent[400] }}
                  >
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Alert severity="warning">Please Scan Valid QR code</Alert>
              <Button
                onClick={handleClose}
                variant="contained"
                sx={{ marginTop: "10px", backgroundColor: colors.primary[400] }}
              >
                Close
              </Button>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}

export default ConsumerScanner;
