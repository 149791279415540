/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Dialog box for editing any organization details
 * @date 26/07/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  Box,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Tooltip,
} from "@mui/material";
import { useDispatch } from "react-redux";
// import { UpdateOrganization } from "../../Redux/Actions/organizationAction";
import {
  CreateOrganization,
  GetOrganizationList,
  UpdateOrganization,
} from "../../../Redux/Actions/organizationAction";
import CustomInputTextField from "../../../Components/CustomComp/CustomInputTextField";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import appMeta from "../../../AppMeta/appMeta";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditOrgDialogBox({ 
  editOrgDialog,
  setEditOrgDialog,
  selectedRow,
}) {
  // Required variable declared
  const dispatch = useDispatch();
  const [orgId, setOrgId] = React.useState(selectedRow?.id);
  const [orgName, setOrgName] = React.useState(selectedRow?.orgName);
  const [isForceUpdate, setIsForceUpdate] = React.useState(false);
  const [domain, setDomain] = React.useState(selectedRow?.domain);
  const [isActive, setIsActive] = React.useState(selectedRow?.isActive);
  const [isDeleted, setIsDeleted] = React.useState(false);

  const [isEditOrgId, setIsEditOrgId] = React.useState(false);
  const [isEditOrgName, setIsEditOrgName] = React.useState(false);
  const [isEditOrgDomain, setIsEditOrgDomain] = React.useState(false);

  const [isValidOrgId, setIsValidOrgId] = React.useState(true);

  const handleClose = () => {
    setEditOrgDialog(false);
  };
  // Handling the change in organization ID
  const handleChangeOrgId = (event) => {
    const newValue = event.target.value;
    setOrgId(newValue);
    console.log(newValue);
  };
  // Handling the change in Organization Name
  const handleChangeOrgName = (event) => {
    const newValue = event.target.value;
    setOrgName(newValue);
    console.log(newValue);
  };
  // Handling the change in Domain
  const handleChangeDomain = (event) => {
    const newValue = event.target.value;
    setDomain(newValue);
    console.log(newValue);
  };
  // This function will call the Edit organization API call with request object
  const handleConfirmEdit = () => {
    const reqObj = {
      org_id: orgId,
      org_name: selectedRow?.orgName,
      is_force_update: isForceUpdate,
      domain: selectedRow?.domain,
      new_domain: isEditOrgDomain ? domain : undefined,
      nrew_org_name: isEditOrgName ? orgName : undefined,
      is_active: isActive,
      is_deleted: isDeleted,
    };
    dispatch(UpdateOrganization(reqObj));
    setTimeout(() => {
      dispatch(GetOrganizationList());
      setEditOrgDialog(false);
    }, 1000);
  };

  // Toggle button for editing the fields
  const handleClickEditOrgId = () => {
    setIsEditOrgId(!isEditOrgId);
  };

  const handleClickEditOrgName = () => {
    setIsEditOrgName(!isEditOrgName);
  };

  const handleClickEditOrgDomain = () => {
    setIsEditOrgDomain(!isEditOrgDomain);
  };

  return (
    <div>
      <Dialog
        open={editOrgDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle>Edit Organization</DialogTitle> */}
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
            <InputLabel htmlFor="standard-adornment-password">
              Organization Id
            </InputLabel>
            <Input
              id="standard-adornment-password"
              value={selectedRow?.id}
              disabled={true}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickEditOrgId}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    {isEditOrgId ? (
                      <Tooltip title="Cancel" placement="top">
                        <CancelRoundedIcon />
                      </Tooltip>
                    ) : ( 
                      <Tooltip title="Edit" placement="top">
                        <EditRoundedIcon />
                      </Tooltip>
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {isEditOrgId && (
            <CustomInputTextField
              placeholder="Organization Id"
              size="medium"
              value={orgId}
              setValue={setOrgId}
              isHintRequired={true}
              isValid={isValidOrgId}
              setIsValid={setIsValidOrgId}
              type={appMeta.textFieldTypes.NUMBER}
              handleChange={handleChangeOrgId}
            />
          )}

          <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
            <InputLabel htmlFor="standard-adornment-password">
              Organization Name
            </InputLabel>
            <Input
              id="standard-adornment-password"
              value={selectedRow?.orgName}
              disabled={true}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickEditOrgName}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    {isEditOrgName ? (
                      <Tooltip title="Cancel" placement="top">
                        <CancelRoundedIcon />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Edit" placement="top">
                        <EditRoundedIcon />
                      </Tooltip>
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {isEditOrgName && (
            <CustomInputTextField
              placeholder="Organization Name"
              size="medium"
              value={orgName}
              setValue={setOrgName}
              handleChange={handleChangeOrgName}
            />
          )}

          <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
            <InputLabel htmlFor="standard-adornment-password">
              Organization Domain
            </InputLabel>
            <Input
              id="standard-adornment-password"
              value={selectedRow?.domain}
              disabled={true}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickEditOrgDomain}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    {isEditOrgDomain ? (
                      <Tooltip title="Cancel" placement="top">
                        <CancelRoundedIcon />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Edit" placement="top">
                        <EditRoundedIcon />
                      </Tooltip>
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {isEditOrgDomain && (
            <CustomInputTextField
              placeholder="Organization Domain"
              size="medium"
              value={domain}
              setValue={setDomain}
              handleChange={handleChangeDomain}
            />
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              //   alignItems: "center",
              //   justifyContent: "center",
            }}
          >
            <FormControlLabel
              required
              control={
                <Checkbox
                  checked={isForceUpdate}
                  onChange={(event) => setIsForceUpdate(event.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={
                isForceUpdate
                  ? "You are force updating"
                  : "Do you want to force update"
              }
            />
            <FormControlLabel
              required
              control={
                <Checkbox
                  checked={isActive}
                  onChange={(event) => setIsActive(event.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={
                isActive
                  ? "Active now, Uncheck to deactivate."
                  : "This organization is no more active"
              }
            />
            <FormControlLabel
              required
              control={
                <Checkbox
                  checked={isDeleted}
                  onChange={(event) => setIsDeleted(event.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={
                isDeleted
                  ? "This organization will be deleted"
                  : "Check to  delete this organization"
              }
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <CustomButton
            handleClick={handleClose}
            label="Cancel"
            color="error"
            variant="text"
          />
          <CustomButton
            handleClick={handleConfirmEdit}
            label="Confirm"
            color="primary"
            variant="contained"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
