/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Dialog box for editing any particular API
 * @date 26/05/23
 * @version 0.0.1 - Initial Release
 */
// Importing the Libraries

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import CustomInputTextField from "../../../Components/CustomComp/CustomInputTextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  Box,
  IconButton,
  Input,
  InputAdornment,
  Tooltip,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useDispatch } from "react-redux";
import { UpdateApis } from "../../../Redux/Actions/apisDetailsAction";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditApiDialogBox({
  editApiDialog,
  setEditApiDialog,
  selectedRow,
}) {
  const dispatch = useDispatch();
  console.log(selectedRow);
  // Required variables declared here
  const [apiName, setApiName] = React.useState(selectedRow?.api_name);
  const [isForceUpdate, setIsForceUpdate] = React.useState(false);
  const [httpsMethod, setHttpsMethod] = React.useState(
    selectedRow?.https_method
  );
  const [description, setDescription] = React.useState(
    selectedRow?.description
  );
  const [isDeleted, setIsDeleted] = React.useState(selectedRow?.is_deleted);
  const [isEditApiName, setIsEditApiName] = React.useState(false);
  const [isEditDescription, setIsEditDescription] = React.useState(false);
  const [isEditHttpsMethod, setIsEditHttpsMethod] = React.useState(false);

  const handleClose = () => {
    setEditApiDialog(false);
  };

  // Handling the API Name change
  const handleChangeApiName = (event) => {
    console.log(event.target.value);
    setApiName(event.target.value);
  };
  // Handling the HttpsMethod Change
  const handleChangeHttpsMethod = (event) => {
    console.log(event.target.value);
    setHttpsMethod(event.target.value);
  };

  // Handling the description change
  const handleChangeDescription = (event) => {
    console.log(event.target.value);
    setDescription(event.target.value);
  };

  // This function has a API call for Editing the API with reqObj
  const handleEditApiClick = () => {
    const reqobj = {
      api_name: selectedRow.api_name,
      is_force_update: isForceUpdate,
      new_api_name: isEditApiName ? apiName : undefined,
      https_method: httpsMethod,
      description: description,
      is_deleted: isDeleted,
    };
    dispatch(UpdateApis(reqobj));
  };

  // Toggle for editing the api name
  const handleClickEditApiName = () => {
    setIsEditApiName(!isEditApiName);
  };
  // Toggle for editing the description
  const handleClickEditDescription = () => {
    setIsEditDescription(!isEditDescription);
  };
  // Toggle for Editing HttpsMethod
  const handleClickEditHttpsMethod = () => {
    setIsEditHttpsMethod(!isEditHttpsMethod);
  };

  // Jsx starts here..
  return (
    <div display="flex" flexWrap="wrap" justifyContent="center" gap={2} mb={2}>
      <Dialog
        open={editApiDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle>Edit API</DialogTitle> */}
        {/* <Typography>Edit API</Typography> */}
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
            <InputLabel htmlFor="standard-adornment-password">
              API Name
            </InputLabel>
            <Input
              id="standard-adornment-password"
              value={selectedRow?.api_name}
              disabled={true}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickEditApiName}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    {isEditApiName ? (
                      <Tooltip title="Cancel" placement="top">
                        <CancelRoundedIcon />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Edit" placement="top">
                        <EditRoundedIcon />
                      </Tooltip>
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {isEditApiName && (
            <>
              {" "}
              <CustomInputTextField
                placeholder="API Name"
                value={apiName}
                setValue={setApiName}
                handleChange={handleChangeApiName}
              />
            </>
          )}
          <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
            <InputLabel htmlFor="standard-adornment-password">
              Description
            </InputLabel>
            <Input
              id="standard-adornment-password"
              value={selectedRow?.description}
              disabled={true}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickEditDescription}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    {isEditDescription ? (
                      <Tooltip title="Cancel" placement="top">
                        <CancelRoundedIcon />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Edit" placement="top">
                        <EditRoundedIcon />
                      </Tooltip>
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          {isEditDescription && (
            <CustomInputTextField
              placeholder="Description"
              value={description}
              setValue={setDescription}
              handleChange={handleChangeDescription}
            />
          )}
          {/* <CustomInputTextField   
            placeholder="Deleted"
            value={isDeleted}   
            handleChange={(e) => setIsDeleted(e.target.value)}
          /> */}
          <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
            <InputLabel htmlFor="standard-adornment-password">
              Https Method
            </InputLabel>
            <Input
              id="standard-adornment-password"
              value={selectedRow?.https_method}
              disabled={true}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickEditHttpsMethod}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    {isEditHttpsMethod ? (
                      <Tooltip title="Cancel" placement="top">
                        <CancelRoundedIcon />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Edit" placement="top">
                        <EditRoundedIcon />
                      </Tooltip>
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {isEditHttpsMethod && (
            <FormControl sx={{ m: 1, minWidth: 230 }} size="medium">
              <InputLabel id="demo-select-small-label">Https Method</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={httpsMethod}
                label="Https Method"
                onChange={handleChangeHttpsMethod}
              >
                <MenuItem value={"GET"}>GET</MenuItem>
                <MenuItem value={"PUT"}>PUT</MenuItem>
                <MenuItem value={"POST"}>POST</MenuItem>
              </Select>
            </FormControl>
          )}

          <Box>
            <FormControlLabel
              required
              control={
                <Checkbox
                  checked={isForceUpdate}
                  onChange={(event) => setIsForceUpdate(event.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={
                isForceUpdate
                  ? "You are force updating"
                  : "Do you want to force update"
              }
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <CustomButton
            handleClick={handleClose}
            label="Cancel"
            color="error"
            variant="text"
            size="medium"
          />
          <CustomButton
            handleClick={handleEditApiClick}
            label="Confirm"
            color="primary"
            variant="contained"
            size="medium"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
