import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {
  Box,
  Chip,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import ScannerRoundedIcon from "@mui/icons-material/ScannerRounded";
import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
import { toast } from "react-toastify";
import { Html5Qrcode } from "html5-qrcode";
import Scanner from "../QrCodeScanner/Scanner";
// import Html5QrcodePlugin1 from "../../Pages/wfsHome/HtmlQrcodePlugin1";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Required Props for this custom scanner dialog box
// {
//   setOpenScanningDialog;
//   openScanningDialog;
//   scannedQrBarcode;
//   setScannedQrBarcode;
//   currentScanned;
//   setCurrentScanned;
//   scanQtyMax;
//   scanOrderId;
//   scannedCount;
//   setScannedCount;
//   setRequestObject;
//   setOpenLinkFromStartDialog;
// }

export default function ScanningDialog(props) {
  const scanLimit = 5;
  const [scanOption, setScanOption] = React.useState("scanner");
  const [qrBarcodeId, setQrBarcodeId] = React.useState("");
  const handleClose = () => {
    setScanOption("scanner");
    props.setOpenScanningDialog(false);
  };

  // Scanning option change function
  const handleSelectScanOption = (event) => {
    console.log("-- handleSelectScanOption --");
    console.log(event.target.value);
    setScanOption(event.target.value);
  };

  //   Handling QR/Barcode from scanner
  const handleQrBarcodeIdFromScanner = (event) => {
    console.log(event.target.value);
    setQrBarcodeId(event.target.value);

    setTimeout(() => {
      if (event.target.value !== props.scannedQrBarcode) {
        console.log("set code for bulk");
        props.setScannedQrBarcode(event.target.value);
      } else {
        console.log("---code not set for bulk ---");
      }
    }, 500);
  };

  // Handling camera scanned codes
  const handleCameraScannedCode = (decodedText) => {
    console.log("decodedText");
    if (decodedText !== "") {
      props.setScannedQrBarcode(decodedText);
    }
  };
  // If any QR/barcode scanned it will come to this useEffect function
  // If any 
  React.useEffect(() => {
    console.log("Scanned QR/Barcode code");
    console.log(props.scannedQrBarcode);
    console.log("scan qty max", props.scanQtyMax);
    const newOrderId = props.scanOrderId;
    console.log("scan order Id", newOrderId);

    // Check if scanned QR/barcode is in the expired list
    console.log("props.validateExpired")
    console.log(props.validateExpired)
    console.log(
      props.validateExpired?.some(
        (code) =>
          code.qr_code === props.scannedQrBarcode ||
          code.bar_code === props.scannedQrBarcode
      )
    );
    const isExpired = props.validateExpired?.some(
      (code) =>
        code.qr_code === props.scannedQrBarcode ||
        code.bar_code === props.scannedQrBarcode
        
    );
    if (isExpired) {
      toast.error("Can expired", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      if (props.isBatch) {
        setTimeout(() => {
          props.setScannedQrBarcode("");
          setQrBarcodeId("");
        }, 200);

        return;
      }
    }
    const condition =
      props.scannedQrBarcode && props.scannedQrBarcode.length > 0 && newOrderId
        ? true
        : false;
    console.log("condition");
    console.log(condition);
    if (condition) {
      let qrCodeList =
        props.currentScanned &&
        Object.keys(props.currentScanned).length &&
        props.currentScanned[newOrderId]
          ? props.currentScanned[newOrderId]
          : [];
      console.log(qrCodeList);
      if (qrCodeList.length && qrCodeList.includes(props.scannedQrBarcode)) {
        toast.error(`Can is scanned already!`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        console.log("qrCodeList.length <= props.scanQtyMax: ");
        console.log(qrCodeList.length <= props.scanQtyMax);
        if (qrCodeList.length < props.scanQtyMax) {
          qrCodeList = [...qrCodeList, props.scannedQrBarcode];
          props.setScannedCount(qrCodeList.length);
          let localStorageData = {};
          localStorageData[newOrderId] = qrCodeList;
          console.log("localStorageData: ");
          console.log(localStorageData);
          props.setCurrentScanned(() => localStorageData);

          toast.success("Successfully Scanned!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          console.log("WFS Order limit reached!");
          toast.error(`Scan limit reached, please click submit button`, {
            position: "top-right",
            autoClose: 3500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    }
    setTimeout(() => {
      props.setScannedQrBarcode("");
      setQrBarcodeId("");
    }, 200);
  }, [props.scannedQrBarcode]);

  const loadData = () => {
    console.log("Change in order id");
    // Assume `props.orderId` is the current order ID we need to check against
    const storedData = localStorage.getItem("current_scanned_codes");
    const data = storedData ? JSON.parse(storedData) : {};
    console.log("storedData");
    console.log(storedData);
    console.log(props.scanOrderId);
    console.log(data);
    console.log(data[props.scanOrderId]);

    // Check if the current order ID from props matches the one stored in local storage
    if (data && data[props.scanOrderId]) {
      console.log("matching");
      props.setCurrentScanned(data);
      props.setScannedCount(data[props.scanOrderId].length);
    } else {
      // If not matching or not existing, reset the scanned codes
      console.log("not matching");
      props.setCurrentScanned({});
      props.setScannedCount(0);
      // localStorage.setItem("current_scanned_codes", {});
      if (localStorage.getItem("current_scanned_codes")) {
        localStorage.removeItem("current_scanned_codes");
      }
    }
  };
  React.useEffect(() => {
    if (props.scanOrderId) {
      loadData();
    }
  }, [props.scanOrderId]);
  React.useEffect(() => {
    console.log("currentScanned");
    console.log(props.currentScanned);
    console.log("currentScanned && Object.keys(currentScanned).length: ");
    console.log(
      props.currentScanned && Object.keys(props.currentScanned).length
    );
    if (props.currentScanned && Object.keys(props.currentScanned).length) {
      console.log("Setting the data in local storage!");
      console.log(props.currentScanned);
      props.setScannedCount(props.currentScanned[props.scanOrderId].length);
      localStorage.setItem(
        "current_scanned_codes",
        JSON.stringify(props.currentScanned)
      );
    }
  }, [props.currentScanned]);

  const handleClickSubmit = () => {
    const reqObj = {
      order_id: props.scanOrderId,
      data: props.currentScanned[props.scanOrderId]?.map((item) => ({
        id: item,
      })),
    };
    console.log(reqObj);
    props.setRequestObject(reqObj);
    setScanOption("scanner");
  };

  React.useEffect(() => {
    console.log("scannedCount");
    if (props.scannedCount === scanLimit) {
      console.log("scan limit reached");
      handleClickSubmit();
    }
  }, [props.scannedCount]);

  return (
    <React.Fragment>
      <Dialog
        disableBackdropClick={true}
        open={props.openScanningDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <Box
            sx={{
              marginTop: "15px",
              padding: "10px",
              marginBottom: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <Box>
              <ToggleButtonGroup
                color="primary"
                value={scanOption}
                exclusive
                onChange={handleSelectScanOption}
                aria-label="Platform"
              >
                <ToggleButton value="scanner">
                  Scanner <ScannerRoundedIcon />
                </ToggleButton>
                <ToggleButton value="camera">
                  Camera <CameraAltRoundedIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Box>
              {scanOption === "scanner" && (
                <TextField
                  onChange={handleQrBarcodeIdFromScanner} 
                  label="QR/Barcode:"
                  value={qrBarcodeId}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}

              {scanOption === "camera" && (
                <Scanner
                  fps={1}
                  qrbox={200}
                  disableFlip={false}
                  qrCodeSuccessCallback={handleCameraScannedCode}
                />
              )}
            </Box>
            <Box>
              <Chip
                label={`Scanned Cans Count: ${props.scannedCount} / ${props.scanQtyMax}`}
                color="success"
              />
            </Box>
            <Box>
              <Button
                onClick={handleClickSubmit}
                variant="contained"
                size="small"
              >
                Submit
              </Button>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                onClick={() => props.setOpenLinkFromStartDialog(true)}
              >
                Reset Scan
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="small"
                color="error"
                onClick={handleClose}
                variant="contained"
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
