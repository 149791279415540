/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Organization details main component
 * @date 26/07/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import React from "react";
import { useDispatch, useSelector } from "react-redux";
// import {
//   CreateOrganization,
//   GetOrganizationList,
// } from "../../Redux/Actions/organizationAction";
import {
  CreateOrganization,
  GetOrganizationList,
} from "../../../Redux/Actions/organizationAction";
import OrganizationDetilsList from "./OrganizationDetailsList";
import {
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
import OrganizationDetailsList from "./OrganizationDetailsList";
import CustomInputTextField from "../../../Components/CustomComp/CustomInputTextField";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import CustomTypography from "../../../Components/CustomComp/CustomTypography";
import appMeta from "../../../AppMeta/appMeta";
import { toast } from "react-toastify";
import { EventNote } from "@mui/icons-material";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { GetRoles } from "../../../Redux/Actions/rolesAction";
import {
  UploadFile,
  getFiles,
} from "../../../Redux/Actions/fileHandlingActions";
import GetAppRoundedIcon from "@mui/icons-material/GetAppRounded";

const styles = {
  mainDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
    padding: "20px",
  },
};

function OrganizationDetails() {
  const organizationDetails = useSelector((state) => state.organizationData);

  const facilityIconRef = React.useRef(null);
  const facilityImgRef = React.useRef(null);
  //File Upload Variables
  const [facilityIconFile, setFacilityIconFile] = React.useState("");
  const [facilityIconUrl, setFacilityIconUrl] = React.useState("");
  const [facilityIconUploadBtn, setFacilityIconUploadBtn] =
    React.useState(false);
  const [facilityIconFlag, setFacilityIconFlag] = React.useState(false);

  const [facilityImgFile, setFacilityImgFile] = React.useState("");
  const [facilityImgUrl, setFacilityImgUrl] = React.useState("");
  const [facilityImgUploadBtn, setFacilityImgUploadBtn] = React.useState(false);
  const [facilityImgFlag, setFacilityImgFlag] = React.useState(false);
  // Required variables declared here
  const dispatch = useDispatch();
  const rolesData = useSelector((state) => state.rolesData);
  const filesDetails = useSelector((state) => state.filesData);

  const [organizationList, setOrganizationList] = React.useState([]);
  const [listOfRoles, setListOfRoles] = React.useState([]);
  const [organizationName, setOrganizationName] = React.useState("");
  const [facilityName, setFacilityName] = React.useState("");
  const [facilityId, setFacilityId] = React.useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hasMoreData, setHasMoreData] = React.useState(true);
  const [getMoreRoles, setGetMoreRoles] = React.useState(1);
  const [selectedRole, setSelectedRole] = React.useState("");
  const [lastMore, setLastMore] = React.useState(null);

  const [isValid, setIsValid] = React.useState(true);
  const [roleName, setRoleName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [domain, setDomain] = React.useState("");
  const [areaName, setAreaName] = React.useState("");
  const [pincode, setPincode] = React.useState(null);
  const [state, setState] = React.useState("");
  const [district, setDistrict] = React.useState("");
  const [addOrganizationDialog, setAddOrganizationDialog] =
    React.useState(false);
  const [isAddFacility, setIsAddFacility] = React.useState(false);
  const [isValidPincode, setIsValidPincode] = React.useState(true);

  // Pagination and filter variables
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(25);
  const [isActive, setIsActive] = React.useState(true);
  const [ascOrDesc, setAscOrDesc] = React.useState("ASC");
  const [lastPageNumber, setlastPageNumber] = React.useState(null);

  // Accessesing the organization redux store to get organization data
  React.useEffect(() => {
    console.log(organizationDetails);
    if (organizationDetails.error === false) {
      if (organizationDetails?.organizationList?.length > 0) {
        console.log("getting it");
        setOrganizationList(organizationDetails.organizationList);
      }
    }
  }, [organizationDetails]);

  // Accessessing the roles redux store

  React.useEffect(() => {
    console.log("roles Data");
    console.log(rolesData);
  }, [rolesData]);

  React.useEffect(() => {
    console.log(rolesData);
    if (rolesData.error === false) {
      setlastPageNumber(rolesData.rolesList.last_page_number);
      if (rolesData.rolesList?.data?.length > 0) {
        setLastMore(rolesData.rolesList.last_page_number);
        setListOfRoles((prevRolesList) =>
          prevRolesList
            ? [...prevRolesList, ...rolesData.rolesList.data]
            : rolesData.rolesList.data
        );
      }
    }
  }, [rolesData]);

  React.useEffect(() => {
    dispatch(GetRoles(getMoreRoles, 25, true, "ASC"));
  }, [getMoreRoles]);
  // API call for getting the organization details
  React.useEffect(() => {
    dispatch(GetOrganizationList(pageNumber, pageSize, isActive, ascOrDesc));
  }, [pageNumber, pageSize, isActive, ascOrDesc]);

  // Handling organization name change
  const handleChangeOrganizationName = (event) => {
    const newValue = event.target.value;
    setOrganizationName(newValue);
    console.log(newValue);
  };

  // Handling facility name change
  // const handleChangeFacilityName = (event) => {
  //   const newValue = event.target.value;
  //   setFacilityName(newValue);
  //   console.log(newValue);
  // };

  // Handling domain name change
  const handleChangeDomain = (event) => {
    const newValue = event.target.value;
    setDomain(newValue);
    console.log(newValue);
  };
  // Handling area name change
  const handleChangeAreaName = (event) => {
    const newValue = event.target.value;
    setAreaName(newValue);
    console.log(newValue);
  };
  // Handling email name change
  const handleChangeEmail = (event) => {
    const newValue = event.target.value;
    setEmail(newValue);
    console.log(newValue);
  };
  // Handling pincode change
  const handleChangePincode = (event) => {
    const newValue = event.target.value;
    setPincode(newValue);
    console.log(newValue);
  };
  // Handling state change
  const handleChangeState = (event) => {
    const newValue = event.target.value;
    setState(newValue);
    console.log(newValue);
  };
  // Handling district name change
  const handleChangeDistrict = (event) => {
    const newValue = event.target.value;
    setDistrict(newValue);
    console.log(newValue);
  };

  const handleUploadFacilityClick = () => {
    facilityIconRef.current.click();
  };
  const handleUploadFacilityClickImg = () => {
    facilityImgRef.current.click();
  };

  // This function will add new organization with API call will req Obj and validation check
  const handleAddOrganizationClick = () => {
    const reqObj = {
      org_name: organizationName,
      domain: domain,
      details: {
        area_name: areaName,
        pincode: pincode,
        state: state,
        district: district,
      },
    };
    const reqObj1 = {
      name: organizationName,
      domain: domain,
      facility: {
        facility_name: facilityName,
        facility_id: facilityId,
      },
      details: {
        area_name: areaName,
        pincode: pincode,
        state: state,
        district: district,
      },
      user_details: {
        role_name: selectedRole,
        email: email,
      },
      facilityImg: facilityImgFile,
      facilityIcon: facilityIconFile,
    };

    const reqObj2 = {
      name: organizationName,
      domain: domain,
      details: {
        area_name: areaName,
        pincode: pincode,
        state: state,
        district: district,
      },
      user_details: {
        role_name: selectedRole,
        email: email,
      },
    };
    console.log(reqObj1);
    if (isValidPincode) {
      if (isAddFacility === false) {
        dispatch(CreateOrganization(reqObj1));
      } else if (isAddFacility === true) {
        dispatch(CreateOrganization(reqObj2));
      }
      // dispatch(CreateOrganization(reqObj));
    } else {
      toast.error("Enter Proper Pincode", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setTimeout(() => {
      dispatch(GetOrganizationList(pageNumber, pageSize, isActive, ascOrDesc));
    }, 1000);
  };

  React.useEffect(() => {
    setOrganizationName("");
    setFacilityName("");
    setFacilityId("");
    setAnchorEl(null);
    setHasMoreData(true);
    setGetMoreRoles(1);
    setSelectedRole("");
    setLastMore(null);
    setIsValid(true);
    setRoleName("");
    setEmail("");
    setDomain("");
    setAreaName("");
    setPincode("");
    setState("");
    setDistrict("");
  }, [isAddFacility]);

  const handleClose = () => {
    setAddOrganizationDialog(false);
    setFacilityImgUrl("");
    setFacilityIconUrl("");
  };

  const handleChangeFacilityName = (event) => {
    console.log(event.target.value);
    setFacilityName(event.target.value);
  };
  // const handleChangeFacilityId = (event) => {
  //   setFacilityId(event.target.value);
  // };
  const handleChangeRoleName = (event) => {
    setRoleName(event.target.value);
  };
  const handleOpenDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };
  const handleLoadMore = () => {
    setGetMoreRoles(getMoreRoles + 1);
    // fetchMoreData(nextPage);
  };

  const handleChangeFacilityIcon = (event) => {
    console.log("Selected Facility Icon :", event.target.files[0]);
    setFacilityIconFile(event.target.files[0]);
    setFacilityIconUploadBtn(true);
  };

  const handleChangeFacilityImg = (event) => {
    console.log("Selected Facility Icon :", event.target.files[0]);
    setFacilityImgFile(event.target.files[0]);
    setFacilityImgUploadBtn(true);
  };

  React.useEffect(() => {
    organizationList.map((data) => {
      if (facilityName === data.org_name) {
        setFacilityId(data.id);
      }
    });
  }, [facilityName]);

  React.useEffect(() => {
    if (facilityIconUploadBtn) {
      console.log("facility file upload");
      dispatch(UploadFile(facilityIconFile));
      setFacilityIconUploadBtn(false);
      setFacilityIconFlag(true);
    }
  }, [facilityIconFile]);

  React.useEffect(() => {
    console.log(`facilityIconUrl ${facilityIconUrl}`);
  }, [facilityIconUrl]);

  React.useEffect(() => {
    console.log("fileDetails");
    console.log(filesDetails);
    if (facilityIconFlag) {
      console.log("setting facility url");
      console.log(filesDetails.fileName);
      setFacilityIconUrl(filesDetails.fileName);
      setFacilityIconFlag(false);
    }
  }, [filesDetails]);

  React.useEffect(() => {
    if (facilityImgUploadBtn) {
      console.log("facility file upload");
      dispatch(UploadFile(facilityImgFile));
      setFacilityImgUploadBtn(false);
      setFacilityImgFlag(true);
    }
  }, [facilityImgFile]);

  React.useEffect(() => {
    console.log(`facilityImgUrl ${facilityImgUrl}`);
  }, [facilityImgUrl]);

  React.useEffect(() => {
    console.log("fileDetails");
    console.log(filesDetails);
    if (facilityImgFlag) {
      console.log("setting facility url");
      console.log(filesDetails.fileName);
      setFacilityImgUrl(filesDetails.fileName);
      setFacilityImgFlag(false);
    }
  }, [filesDetails]);
  return (
    <div>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap={2}
        padding="30px"
      >
        <Dialog open={addOrganizationDialog} onClose={handleClose}>
          <>
            {" "}
            <CustomTypography
              content="Facility Management"
              fontSize="18px"
              fontWeight="bold"
              textAlign="center"
            />
            <Card sx={{ width: "fit-content" }}>
              <Box style={styles.mainDiv}>
                <CustomInputTextField
                  placeholder="Facility Name"
                  size="large"
                  value={organizationName}
                  setValue={setOrganizationName}
                  type={appMeta.textFieldTypes.TEXT}
                  handleChange={handleChangeOrganizationName}
                />
                <CustomInputTextField
                  placeholder="Facility Domain"
                  size="large"
                  value={domain}
                  setValue={setDomain}
                  type={appMeta.textFieldTypes.TEXT}
                  handleChange={handleChangeDomain}
                />
              </Box>
              {isAddFacility === false && (
                <Box style={styles.mainDiv}>
                  <Box sx={{ minWidth: 230 }}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">
                        Facility name
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={facilityName}
                        label="Facility Name"
                        onChange={handleChangeFacilityName}
                      >
                        {organizationList?.map((data) => {
                          return (
                            <MenuItem value={data.org_name}>
                              {data.org_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                  {/* <Box sx={{ minWidth: 230 }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Facility ID
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={facilityId}
                      label="Facility ID"    
                      onChange={handleChangeFacilityId}
                    >
                      {organizationList.map((data) => {
                        return <MenuItem value={data.id}>{data.id}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Box> */}
                </Box>
              )}

              <Box style={styles.mainDiv}>
                <Box sx={{ minWidth: 120 }}>
                  <Button onClick={handleOpenDropdown} variant="outlined">
                    Select Role Name
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseDropdown}
                  >
                    {listOfRoles?.map((list) => (
                      <MenuItem
                        key={list.role_name}
                        onClick={() => {
                          handleCloseDropdown();
                          setSelectedRole(list.role_name);
                        }}
                      >
                        {list.role_name}
                      </MenuItem>
                    ))}
                    {hasMoreData && (
                      <MenuItem
                        onClick={handleLoadMore}
                        sx={{ fontWeight: "bold" }}
                        disabled={getMoreRoles < lastMore ? false : true}
                      >
                        More <ExpandMoreRoundedIcon />
                      </MenuItem>
                    )}
                  </Menu>
                </Box>
                {selectedRole !== "" && (
                  <Box>
                    <Chip
                      label={`Selected Role Name: ${selectedRole}`}
                      variant="filled"
                      color="success"
                      sx={{ fontWeight: "bold" }}
                    />
                  </Box>
                )}

                <Box>
                  <CustomInputTextField
                    placeholder="Email"
                    size="large"
                    value={email}
                    setValue={setEmail}
                    type={appMeta.textFieldTypes.EMAIL}
                    isHintRequired={true}
                    isValid={isValid}
                    setIsValid={setIsValid}
                  />
                </Box>
              </Box>
              <Box style={styles.mainDiv}>
                <CustomInputTextField
                  placeholder="Area Name"
                  size="large"
                  value={areaName}
                  setValue={setAreaName}
                  handleChange={handleChangeAreaName}
                />
                <CustomInputTextField
                  placeholder="Pincode"
                  size="large"
                  value={pincode}
                  setValue={setPincode}
                  handleChange={handleChangePincode}
                  type={appMeta.textFieldTypes.PINCODE}
                  isValid={isValidPincode}
                  setIsValid={setIsValidPincode}
                  isHintRequired={true}
                />
              </Box>
              <Box style={styles.mainDiv}>
                <CustomInputTextField
                  placeholder="State"
                  size="large"
                  value={state}
                  setValue={setState}
                  handleChange={handleChangeState}
                />
                <CustomInputTextField
                  placeholder="District"
                  size="large"
                  value={district}
                  setValue={setDistrict}
                  handleChange={handleChangeDistrict}
                />
              </Box>
              <Box sx={{ display: "flex" }}>
                <Paper
                  elevation={23}
                  sx={{
                    padding: "10px",
                    marginLeft: "40px",
                    marginRight: "10px",
                  }}
                >
                  <Box>
                    <Typography>FACILITY ICON UPLOAD</Typography>
                    <Tooltip title="Upload Icon File" position="top">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleUploadFacilityClick}
                          edge="end"
                        >
                          <UploadRoundedIcon />
                        </IconButton>
                        {facilityIconUrl !== "" && (
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => dispatch(getFiles(facilityIconUrl))}
                            edge="end"
                          >
                            <GetAppRoundedIcon />
                          </IconButton>
                        )}
                      </Box>
                    </Tooltip>
                    {facilityIconUrl !== "" && (
                      <Chip label="Upload Success" color="success" />
                    )}
                    <input
                      type="file"
                      ref={facilityIconRef}
                      style={{ display: "none" }}
                      onChange={handleChangeFacilityIcon}
                    />
                  </Box>
                </Paper>
                <Paper elevation={23} sx={{ padding: "10px" }}>
                  <Box>
                    <Typography>FACILITY IMAGE UPLOAD</Typography>
                    <Tooltip title="Upload Icon File" position="top">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleUploadFacilityClickImg}
                          edge="end"
                        >
                          <UploadRoundedIcon />
                        </IconButton>
                        {facilityImgUrl !== "" && (
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => dispatch(getFiles(facilityImgUrl))}
                            edge="end"
                          >
                            <GetAppRoundedIcon />
                          </IconButton>
                        )}
                      </Box>
                    </Tooltip>
                    {facilityImgUrl !== "" && (
                      <Chip label="Upload Success" color="success" />
                    )}
                    <input
                      type="file"
                      ref={facilityImgRef}
                      style={{ display: "none" }}
                      onChange={handleChangeFacilityImg}
                    />
                  </Box>
                </Paper>
              </Box>
              <Box
                sx={{
                  padding: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CustomButton
                  label="Add Organization"
                  rounded={true}
                  size="medium"
                  handleClick={handleAddOrganizationClick}
                  variant="contained"
                />
              </Box>
            </Card>
          </>
        </Dialog>

        {/* Organization table component call */}
        {organizationList.length > 0 && (
          <>
            {" "}
            <OrganizationDetailsList
              organizationList={organizationList}
              addOrganizationDialog={addOrganizationDialog}
              setAddOrganizationDialog={setAddOrganizationDialog}
              isAddFacility={isAddFacility}
              setIsAddFacility={setIsAddFacility}
              pageNumber={pageNumber}
              pageSize={pageSize}
              isActive={isActive}
              ascOrDesc={ascOrDesc}
              lastPageNumber={lastPageNumber}
              setPageNumber={setPageNumber}
              setPageSize={setPageSize}
              setIsActive={setIsActive}
              setAscOrDesc={setAscOrDesc}
            />
          </>
        )}
      </Box>
    </div>
  );
}

export default OrganizationDetails;
