import actionTypes from "../actionTypes";

const initialState = {
  error: false,
  msg: "",
  lines: [],
}; 

const facilityInchargeReducer = (state = initialState, action) => {
  console.log(action);
  switch (action.type) {
    case actionTypes.ADD_FACILITY_INCHARGE:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
      
    default:
      return state;
  }
};

export default facilityInchargeReducer;
