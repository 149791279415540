/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Authentication action  for login and signUp
 * @date 26/05/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import axios from "axios";
import { useDispatch } from "react-redux";
import actionType from "../actionTypes";
import appConstants from "../../AppMeta/appConstants";
import { toast } from "react-toastify";
/**
 * This Function will perform the Log-in action, where users enter their details and login to BookWater
 * @param {email_phone, password, storage_type}
 * @returns {Dispatch the Response (JWT Token and error, msg to Reducer)}
 */
const url = "https://paasstage.bookwater.com";
// const url = "http://localhost:6008";

export const signup = (request) => {
  let storage_type = false;
  let authMsgType = appConstants.appMessageType.ERROR;

  return (dispatch) => {
    axios
      .post(`${url}/PSCM/signup/user`, request)
      .then((res) => {
        if (!res.data.error) {
          if (storage_type === true) {
            localStorage.setItem("token", res.data.tkn);
          } else {
            sessionStorage.setItem("token", res.data.tkn);
          }
          dispatch({
            type: actionType.SIGN_UP,
            payload: res.data,
          });
          authMsgType = appConstants.appMessageType.SUCCESS;
        } else {
          authMsgType = appConstants.appMessageType.ERROR;
        }
        toast.success(authMsgType, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: actionType.SIGN_UP,
          payload: error.response.data,
        });
        toast.error("", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };
};

export const logIn = (reqObj) => {
  let storage_type = false;
  let authMsgType = appConstants.appMessageType.ERROR;

  return (dispatch) => {
    axios
      .post(`${url}/PSCM/login/user`, reqObj)
      .then((res) => {
        console.log(res);
        if (!res.data.error) {
          if (storage_type === true) {
            localStorage.setItem("token", res.data.token);
          } else {
            sessionStorage.setItem("token", res.data.token);
          }
          dispatch({
            type: actionType.LOGIN,
            payload: res.data,
          });
          authMsgType = appConstants.appMessageType.SUCCESS;
        } else {
          authMsgType = appConstants.appMessageType.ERROR;
        }
        // toast.success(res.data.msg, {
        //   position: "top-right",
        //   autoClose: 1000,
        //   hideProgressBar: true,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        // });
      })
      .catch((error) => {
        console.log(error);
        // dispatch({
        //   type: actionType.LOGIN,
        //   payload: res.data,
        // });
        toast.error(error.response.data.msg, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };
};

export const ResetPasswordEmailVerify = (email, storage) => {
  //   let api = useAxios();
  console.log(email);

  return (dispatch) => {
    const errorObj = {
      error: true,
    };
    axios
      .post(`${url}/PSCM/reset/password`, { email })
      .then((res) => {
        // console.log("forgotPasswordUserVerify Response:\n" + res);
        if (!res.data.error) {
          sessionStorage.setItem("checksum", res.data.checksum);
          toast.dark("Verification Code Sent Successfully", {
            position: "bottom-right",
            autoClose: 5000,
            type: "success",
          });
          dispatch({
            type: actionType.RESET_PASSWORD_VERIFY,
            payload: res.data,
          });
        } else {
          toast.dark(res.data.msg, {
            position: "bottom-right",
            autoClose: 5000,
            type: "error",
          });
        }
      })
      .catch((error) => {
        // console.log("forgotPasswordUserVerify Error:\n" + error);
        error = error + "";
        if (error.includes("400")) {
          console.log("Verify Catch if block");
          dispatch({
            type: actionType.RESET_PASSWORD_VERIFY,
            payload: errorObj,
          });
          toast.dark("User Not Registered", {
            position: "bottom-right",
            autoClose: 5000,
            type: "error",
          });
        } else {
          toast.dark("Un-Handled Error, please try after sometime", {
            position: "bottom-right",
            autoClose: 5000,
            type: "error",
          });
        }
      });
  };
};

export const ForgotPasswordNewPassword = (new_password, verify_otp) => {
  const checksum = sessionStorage.getItem("checksum");
  return (dispatch) => {
    axios
      .put(`${url}/PSCM/reset/password/verify`, {
        checksum,
        new_password,
        verify_otp,
      })
      .then((res) => {
        if (!res.data.error) {
          sessionStorage.removeItem("checksum");
          toast.dark(res.data.msg, {
            position: "bottom-right",
            autoClose: 5000,
            type: "success",
          });
          dispatch({
            type: actionType.FORGOT_PASSWORD_USER_VERIFY_OTP,
            payload: res.data,
          });
        } else {
          toast.dark(res.data.msg, {
            position: "bottom-right",
            autoClose: 5000,
            type: "error",
          });
        }
      })
      .catch((error) => {
        toast.dark("Error in verifying please try again", {
          position: "bottom-right",
          autoClose: 5000,
          type: "error",
        });
      });
  };
};
