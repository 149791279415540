// import { toast } from "react-toastify";
// import jwtDecode from "jwt-decode";
/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "../actionTypes";

const initialState = {
  error: true,
  msg: "",
  checkOutDetails: {},
  checkOutError: true,
  updateError: true,
  loading: false,
  pageLoading: false,
  checkOutData: {},
};

const CheckOutReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.CHECKOUT_PRODUCTS_ACTION:
      return {
        ...state,
        error: action.payload.data.error,
        checkOutError: action.payload.data.error,
        msg: action.payload.data.msg,
        checkOutDetails: action.payload.data.data,
        loading: false,
        checkOutData: action.payload.data,
      };
    case actionType.CLEAR_PENDING_ORDER_DATA:
      return { ...state, checkOutData: {} };
    case actionType.UPDATE_CHECKOUT_ERROR_FALSE:
      return { ...state, updateError: false };
    case actionType.FETCH_DATA_CHECKOUT_START:
      return { ...state, loading: true, pageLoading: true };
    case actionType.RESET_UPDATE_ORDER_ERROR:
      return { ...state, updateError: true };
    case actionType.SET_LOADING_FALSE_AFTER_DELAY:
      return {
        ...state,
        pageLoading: false, // Set loading to false after one second
      };
    case actionType.ADD_CHECKOUT_REQUIRED_QTY:
      const productAddId = action.payload.data.product_id;
      return {
        ...state,
        updateError: action.payload.error,
        checkOutDetails: {
          ...state.checkOutDetails,
          valid_products: (state?.checkOutDetails?.valid_products || []).map(
            (item) => {
              const selectedQty = item.quantity?.selected || 0;
              const maxDistStock =
                parseInt(item.quantity?.existing_max_dist_stock) || 300;
              const returnQty = item?.return?.selected || 0;
              const maxReturn = item?.return?.max_return || 0;
              if (
                (item.product_id === productAddId &&
                  selectedQty < maxDistStock) ||
                (maxDistStock === 0 &&
                  item.product_id === productAddId &&
                  item.quantity?.is_valid === true)
              ) {
                if (
                  maxReturn !== undefined &&
                  maxReturn > 0 &&
                  parseInt(selectedQty * item?.sku_qty) < parseInt(maxReturn) &&
                  parseInt(selectedQty * item?.sku_qty) +
                    parseInt(item?.sku_qty) <=
                    parseInt(maxReturn)
                ) {
                  return {
                    ...item,
                    quantity: {
                      ...item.quantity,
                      selected: selectedQty + 1,
                    },
                    return: {
                      ...item.return,
                      selected: returnQty + item?.sku_qty,
                    },
                  };
                } else if (
                  maxReturn !== undefined &&
                  maxReturn > 0 &&
                  parseInt(maxReturn) - parseInt(selectedQty * item?.sku_qty) <
                    item?.sku_qty &&
                  parseInt(maxReturn) - parseInt(selectedQty * item?.sku_qty) >
                    0
                ) {
                  return {
                    ...item,
                    quantity: {
                      ...item.quantity,
                      selected: selectedQty + 1,
                    },
                    return: {
                      ...item.return,
                      selected:
                        returnQty +
                        parseInt(maxReturn) -
                        parseInt(selectedQty * item.sku_qty),
                    },
                  };
                } else {
                  return {
                    ...item,
                    quantity: {
                      ...item.quantity,
                      selected: selectedQty + 1,
                    },
                  };
                }
              }
              return item;
            }
          ),
        },
      };
    case actionType.REDUSE_CHECKOUT_REQUIRED_QTY:
      const productReduceId = action.payload.data.product_id;
      return {
        ...state,
        updateError: action.payload.error,
        checkOutDetails: {
          ...state.checkOutDetails,
          valid_products: (state?.checkOutDetails?.valid_products || []).map(
            (item) => {
              const returnQty = item.return?.selected || 0;
              const selectedQty = item.quantity?.selected || 0;
              const maxReturnStock = item.return?.max_return || 0;
              if (
                item.product_id === productReduceId 
              ) {
                let newQuantity = selectedQty - 1 || 0;
                if (
                  maxReturnStock !== undefined &&
                  parseInt(newQuantity * item.sku_qty) <= parseInt(returnQty) &&
                  parseInt(returnQty) <= parseInt(maxReturnStock)
                ) {
                  return {
                    ...item,
                    quantity: {
                      ...item.quantity,
                      selected: newQuantity,
                    },
                    return: {
                      ...item.return,
                      selected: newQuantity * item.sku_qty,
                    },
                  };
                } else {
                  return {
                    ...item,
                    quantity: {
                      ...item.quantity,
                      selected: newQuantity,
                    },
                  };
                }
              }
              // Default return statement
              return item;
            }
          ),
        },
      };
    case actionType.ADD_CHECKOUT_RETURN_QTY:
      const ReturnId = action.payload.data.product_id;
      return {
        ...state,
        updateError: action.payload.error,
        checkOutDetails: {
          ...state.checkOutDetails,
          valid_products: (state?.checkOutDetails?.valid_products || []).map(
            (item) => {
              const selectedQty = item.return?.selected || 0;
              const maxDistStock = item.return?.max_return || 0;
              if (
                item.product_id === ReturnId &&
                selectedQty < item?.quantity?.selected * item.sku_qty &&
                maxDistStock > selectedQty
              ) {
                return {
                  ...item,
                  return: {
                    ...item.return,
                    selected: selectedQty + 1,
                  },
                };
              }
              return item;
            }
          ),
        },
      };

    case actionType.REDUSE_CHECKOUT_RETURN_QTY:
      const ReturnReduseId = action.payload.data.product_id;
      return {
        ...state,
        updateError: action.payload.error,
        checkOutDetails: {
          ...state.checkOutDetails,
          valid_products: (state?.checkOutDetails?.valid_products || []).map(
            (item) => {
              const selectedQty = item.return?.selected || 0;
              const maxDistStock = item.return?.max_return_in_packs || 0;
              if (item.product_id === ReturnReduseId && selectedQty > 0) {
                return {
                  ...item,
                  return: {
                    ...item.return,
                    selected: selectedQty - 1,
                  },
                };
              }
              return item;
            }
          ),
        },
      };
    default:
      return state;
  }
};

export default CheckOutReducer;
