/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Custom Button which is used all over the app.
 * @date 26/05/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import React from "react";
import Button from "@mui/material/Button";

const CustomButton = ({
  label,
  color,
  variant, 
  size = "medium",
  handleClick,
  endIcon,
}) => {
  return (
    <div className="card flex justify-content-center">
      <Button
        color={color}
        variant={variant}
        size={size}
        onClick={handleClick}
        endIcon={endIcon}
      >
        {label}
      </Button>
    </div>
  );
};

export default CustomButton;
