import useAxios from "../../Hooks/useAxios";
import { Dispatch } from "redux";
import actionType from "../actionTypes";
import { toast } from "react-toastify";

export const CheckOutAction = (reqObj) => {
  // console.log("SearchAvailability: " + pincode);
  let api = useAxios();
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  return async (dispatch) => {
    dispatch({ type: actionType.FETCH_DATA_CHECKOUT_START });
    try {
      let response = await api.post(`/POPM/products/cart/update`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.CHECKOUT_PRODUCTS_ACTION,
          payload: { data: response.data, statusFlag: true },
        });
      } else {
        dispatch({
          type: actionType.CHECKOUT_PRODUCTS_ACTION,
          payload: { data: response.data, statusFlag: true },
        });
      }
      // Use setTimeout to delay setting loading to false by one second
      setTimeout(() => {
        dispatch({ type: actionType.SET_LOADING_FALSE_AFTER_DELAY });
      }, 300); // 1000 milliseconds = 1 second
    } catch (error) {
      dispatch({
        type: actionType.CHECKOUT_PRODUCTS_ACTION,
        payload: { data: error?.response?.data, statusFlag: true },
      });
      setTimeout(() => {
        dispatch({ type: actionType.SET_LOADING_FALSE_AFTER_DELAY });
      }, 300);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

//function for Reuired qty
export const AddCheckOutRequiredQty = (data) => ({
    type: actionType.ADD_CHECKOUT_REQUIRED_QTY,
    payload: { data: data, error: false },
  });
  
  //function for Redusing qty
  export const ReduseCheckOutRequiredQty = (data) => ({
    type: actionType.REDUSE_CHECKOUT_REQUIRED_QTY,
    payload: { data: data, error: false },
  });
  
  //function for Reuired qty
  export const AddCheckOutReturnQty = (data) => ({
    type: actionType.ADD_CHECKOUT_RETURN_QTY,
    payload: { data: data, error: false },
  });
  
  //function for Redusing qty
  export const ReduseCheckOutReturnQty = (data) => ({
    type: actionType.REDUSE_CHECKOUT_RETURN_QTY,
    payload: { data: data, error: false },
  });
  
  //function for Redusing qty
  export const ResetUpdateOrderError = (data) => ({
    type: actionType.RESET_UPDATE_ORDER_ERROR,
  });