/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React from "react";

import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";
import deleteButton from "../../../Assests/newMobileIcon/DeleteButton.svg";
import { useDispatch, useSelector } from "react-redux";
// import {
//   CheckOutActionLoading,
//   SubscribeCheckOutActionLoading,
// } from "../../../Redux/actions/checkOutAction";

// import {
//   DeleteAllProductQty,
//   GetProductsByAddress,
// } from "../../../Redux/Actions/skuBookingAction";
// import CustomConsole from "../../../coustomConsole";
// import DeleteProductInfoCard from "../../DialogBoxes/info/deleteProductInfo";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Typography } from "@mui/material";
import appColors from "../../../Assests/AppTheme/appColors";
import { CheckOutAction } from "../../../Redux/Actions/checkOutAction";


export default function CheckOutCardSku({ pageName, setCheckOutUi }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const SkuProductDetails = useSelector((state) => state.skuBookingDetails);
  const checkOutDetails = useSelector((state) => state.checkOutDetails);
  const SkuSelectedProducts = useSelector((state) => state.skuBookingDetails);
  const [openDeleteDialog, setOpenDeleteDilog] = React.useState(false);
  const [checkOutButtonClick, setCheckOutButtonClick] = React.useState(false);
  const productDetails = useSelector(
    (state) => state.skuBookingDetails.productDetails
  );
  //function for handling routing operation
  const handleCheckOutRoute = () => {
    if (pageName === "subscribe") {
      dispatch();
      // SubscribeCheckOutActionLoading(SkuSelectedProducts.selectedProduct)
      setCheckOutButtonClick(true);
    } else {
      dispatch(CheckOutAction(SkuSelectedProducts.selectedProduct));
      setCheckOutButtonClick(true);
    }
  };

  //function for getting selected total price
  const TotalPrice = productDetails?.products?.reduce((sum, product) => {
    if (
      product.quantity !== undefined &&
      product.min_book_qty !== undefined &&
      product.booking_type !== undefined &&
      product.refill !== undefined &&
      product.refill.sp !== undefined &&
      product.one_time_sale !== undefined &&
      product.one_time_sale.sp !== undefined &&
      product.sku_qty !== undefined &&
      product.sku_qty[0] !== undefined
    ) {
      if (
        (product.quantity >= product.min_book_qty &&
          product.booking_type === 1 &&
          SkuSelectedProducts.selectedProduct.booking_type === 1) ||
        (product.quantity >= product.min_book_qty &&
          product.booking_type === 3 &&
          SkuSelectedProducts.selectedProduct.booking_type === 1)
      ) {
        sum += product.quantity * product.refill.sp * product.sku_qty[0];
      } else if (
        (product.quantity >= product.min_book_qty &&
          product.booking_type === 2 &&
          SkuSelectedProducts.selectedProduct.booking_type === 2) ||
        (product.quantity >= product.min_book_qty &&
          product.booking_type === 3 &&
          SkuSelectedProducts.selectedProduct.booking_type === 2)
      ) {
        sum += product.quantity * product.one_time_sale.sp * product.sku_qty[0];
      }
    } else {
      console.log("Invalid product data:", product);
    }
    return sum;
  }, 0);

  //function for getting selected quantity
  const TotalQuantity = productDetails?.products?.reduce((sum, product) => {
    if (
      product.quantity !== undefined &&
      product.quantity >= product.min_book_qty
    ) {
      sum += product.quantity;
    }
    return sum;
  }, 0);

  //const handel delete button to get product list
  const handelDeleteButton = () => {
    // dispatch(DeleteAllProductQty());
    setOpenDeleteDilog(false);
  };

  React.useEffect(() => {
    if (
      checkOutDetails?.pageLoading === false &&
      checkOutDetails?.loading === false &&
      checkOutDetails?.checkOutDetails !== undefined &&
      checkOutDetails?.checkOutDetails?.valid_products?.length > 0 &&
      checkOutButtonClick
    ) {
      history.push(`/dashboard/checkout?pageName=${pageName}`);
      setCheckOutButtonClick(false);
    }
  }, [checkOutDetails?.loading, checkOutDetails?.pageLoading]);

  console.log(pageName);
  return (
    <>
      <Box
        sx={{
          height: "4rem",
          boxShadow: 10,
          borderRadius: "1rem",
          bgcolor: appColors.cardColor,
          width: "90%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            padding: ".7rem .3rem 0 .5rem",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{
                color: appColors.darkGray,
                fontWeight: "bold",
                fontSize: ".9rem",
              }}
            >
              Added
            </Typography>
            <Typography
              sx={{
                color: appColors.darkGray,
                fontWeight: "bold",
                fontSize: ".9rem",
              }}
            >
              Product(s)
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              borderLeft: "2px solid gray",
            }}
          >
            <Typography
              sx={{
                color: appColors.secondaryText,
                fontWeight: "700",
                fontSize: "1.3rem",
              }}
            >
              &nbsp;{TotalQuantity}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <LoadingButton
              variant="contained"
              size="medium"
              loading={checkOutDetails?.loading}
              sx={{
                color: appColors.white,
                bgcolor: appColors.textColorNew,
                borderRadius: ".5rem",
                fontSize: ".8rem",
              }}
              onClick={handleCheckOutRoute}
            >
              Checkout
            </LoadingButton>
          </Box>
          <Box
            onClick={() => setOpenDeleteDilog(true)}
            sx={{ display: "flex", flexDirection: "column", cursor: "pointer" }}
          >
            <img
              alt="delete"
              src={deleteButton}
              style={{ pointer: "curser", height: "2.3rem" }}
            />
          </Box>
        </Box>
      </Box>
      {/* <DeleteProductInfoCard
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDilog={setOpenDeleteDilog}
        handelDeleteButton={handelDeleteButton}
      /> */}
    </>
  );
}
