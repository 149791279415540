/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Actions for getting , creating , and updating the product components
 * @date 11/12/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries
import axios from "axios";
// import useAxios from "../../Hooks/useAxios";
import useAxios from "../../Hooks/useAxios";
import { Dispatch } from "redux";
import { toast } from "react-toastify";

import actionType from "../actionTypes";

// Products actions
export const AddProductsSku = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  console.log(reqObj);
  return async (dispatch) => {
    try {
      let response = await api.post(`/PSCM/sku/product`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.ADD_PRODUCT,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};

export const UpdateProduct = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(`/PSCM/sku/product`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.UPDATE_PRODUCT,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};

export const GetProductSku = (
  page_number,
  page_size,
  is_active,
  created_at_sort
) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  let reqObj = {};
  if (
    page_size === undefined ||
    is_active === undefined ||
    created_at_sort === undefined
  ) {
    reqObj = {};
  } else {
    reqObj = {
      page_number,
      page_size,
      is_active,
      created_at_sort,
    };
  }
  return async (dispatch) => {
    try {
      let response = await api.get(
        `/PSCM/sku/products?` + new URLSearchParams(reqObj)
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_PRODUCT,
          payload: response.data,
        });
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};

// Components actions
export const AddComponentSku = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  console.log(reqObj);
  return async (dispatch) => {
    try {
      let response = await api.post(`/PSCM/sku/component`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.ADD_COMPONENT,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};

export const GetComponents = (
  page_number,
  page_size,
  is_active,
  created_at_sort,
  name
) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  console.log(page_number, page_size, is_active, created_at_sort, name);
  let api = useAxios();
  let reqObj = {};
  if (
    page_size === undefined ||
    is_active === undefined ||
    created_at_sort === undefined ||
    name === undefined
  ) {
    reqObj = {};
  } else {
    reqObj = {
      page_number,
      page_size,
      is_active,
      created_at_sort,
      name,
    };
  }
  console.log("req b4 api call");
  console.log(reqObj);
  return async (dispatch) => {
    try {
      let response = await api.get(
        `/PSCM/sku/component?` + new URLSearchParams(reqObj)
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_COMPONENTS,
          payload: response.data,
        });
      }
    } catch (error) {
      console.log(error);
      // toast.error(error.response.data.msg, {
      //   position: "top-right",
      //   autoClose: 1000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  };
};

export const UpdateComponents = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(`/PSCM/sku/component`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.UPDATE_COMPONENTS,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};

// get components by product id

export const GetComponentsForProduct = (
  product_id,
  page_number,
  page_size,
  is_active,
  created_at_sort
) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  let reqObj = {};
  if (
    page_size === undefined ||
    is_active === undefined ||
    created_at_sort === undefined
  ) {
    reqObj = { product_id };
  } else {
    reqObj = {
      product_id,
      page_number,
      page_size,
      is_active,
      created_at_sort,
    };
  }
  return async (dispatch) => {
    try {
      let response = await api.get(
        `/PSCM/sku/products?` + new URLSearchParams(reqObj)
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_COMPONENTS_FOR_PRODUCT,
          payload: response.data,
        });
      }
    } catch (error) {
      console.log(error);
      // toast.error(error.response.data.msg, {
      //   position: "top-right",
      //   autoClose: 1000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  };
};

// Raw materials

export const AddRawMaterials = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  console.log(reqObj);
  return async (dispatch) => {
    try {
      let response = await api.post(`/PSCM/sku/raw_material`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.ADD_RAW_MATERIAL,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};

export const GetRawMaterials = (
  page_number,
  page_size,
  is_active,
  created_at_sort,
  name,
  units,
  is_available
) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  let reqObj = {};
  if (
    page_size === undefined ||
    is_active === undefined ||
    created_at_sort === undefined ||
    name === undefined ||
    units === undefined ||
    is_available === undefined
  ) {
    reqObj = {};
  } else {
    reqObj = {
      page_number,
      page_size,
      is_active,
      created_at_sort,
      name,
      units,
      is_available,
    };
  }
  return async (dispatch) => {
    try {
      let response = await api.get(
        `/PSCM/sku/raw_material?` + new URLSearchParams(reqObj)
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_RAW_MATERIAL,
          payload: response.data,
        });
      }
    } catch (error) {
      console.log(error);
      // toast.error(error.response.data.msg, {
      //   position: "top-right",
      //   autoClose: 1000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  };
};

export const UpdateRawMaterial = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(`/PSCM/sku/raw_material`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.UPDATE_RAW_MATERIAL,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};
