import { Box, Grid } from "@mui/material";
import React from "react";
import DeliveryAddress from "./Components/DeliveryAddress";
import DeliverDate from "./Components/DeliverDate";
import PriceBreakup from "./Components/PriceBreakup";

function CheckOutStepper1() {
  return (
    <Grid container spacing={2}>
      <Grid item md={4} sm={6} xs={12}>
        <DeliveryAddress />
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <DeliverDate />
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <PriceBreakup />
      </Grid>
    </Grid>
  );
}

export default CheckOutStepper1;
