import { Box, Card, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import { tokens } from "../../AppMeta/theme";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import actionTypes from "../../Redux/actionTypes";

function Menu() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isLogout, setIsLogout] = React.useState(false);

  const handleProfile = (title) => {
    if (title === "Logout") {
      setIsLogout(true);
      dispatch({
        type: actionTypes.SIGN_OUT,
      });
    }
  }
  if (isLogout) {
    return <Redirect to="authentication" />;
  }
  return (
    <Box color={colors.basic.body_backGround} margin="10px">
      <Grid item xs={12} sx={{ marginTop: "1rem", marginBottom: ".5rem" }}>
        <Typography
          color={colors.basic.primary_test_color}
          sx={{
            fontWeight: "600",
            fontSize: "16px",
          }}
        >
          Personal
        </Typography>
        <Grid item xs={12} sx={{ marginTop: "10px" }}>
          <Card
            elevation={3}
            sx={{ background: colors.basic.card_color, borderRadius: "10px" }}
          >
            <Box sx={{ padding: "1rem" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "flex-end",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    alignItems: "center",
                    marginLeft: ".7rem",
                  }}
                >
                  <img src={colors.mobileIcon.profileIcon} />
                  <Typography
                    sx={{
                      fontSize: ".8rem",
                      color: colors.basic.primary_test_color,
                      fontWeight: "bold",
                      marginTop: ".3rem",
                    }}
                    align="center"
                  >
                    Profile
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  <img src={colors.mobileIcon.addressIcon} alt="" />
                  <Typography
                    sx={{
                      fontSize: ".8rem",
                      color: colors.basic.primary_test_color,
                      fontWeight: "bold",
                      marginTop: ".3rem",
                    }}
                    align="center"
                  >
                    Address
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  <img src={colors.mobileIcon.bankIcon} alt="" />
                  <Typography
                    sx={{
                      fontSize: ".8rem",
                      color: colors.basic.primary_test_color,
                      fontWeight: "bold",
                      marginTop: ".3rem",
                    }}
                    align="center"
                  >
                    Bank
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  <img src={colors.mobileIcon.depositIcon} alt="" />
                  <Typography
                    sx={{
                      fontSize: ".8rem",
                      color: colors.basic.primary_test_color,
                      fontWeight: "bold",
                      marginTop: ".3rem",
                    }}
                    align="center"
                  >
                    Deposit
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} sx={{ marginTop: "1rem", marginBottom: ".5rem" }}>
          <Typography
            color={colors.basic.primary_test_color}
            sx={{
              fontWeight: "600",
              fontSize: "16px",
            }}
          >
            Orders & Transactions
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Card
            elevation={3}
            sx={{ background: colors.basic.card_color, borderRadius: "10px" }}
          >
            <Box sx={{ padding: "1rem" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "flex-end",
                }}
              >
                <Box sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}>
                  <img src={colors.mobileIcon.myOrders} alt="" />
                  <Typography sx={{
                    fontSize: ".8rem",
                    color: colors.basic.primary_test_color,
                    fontWeight: "bold",
                    marginTop: ".3rem",
                  }} align="center">
                    My Orders
                  </Typography>
                </Box>
                <Box sx={{
                  flexDirection: "column",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}>
                  <img src={colors.mobileIcon.subcribeIcon} alt="" />
                  <Typography sx={{
                    fontSize: ".8rem",
                    color: colors.basic.primary_test_color,
                    fontWeight: "bold",
                    marginTop: ".3rem",
                  }} align="center">Subscribe</Typography>
                </Box>

                <Box sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  alignItems: "center",
                  marginRight: ".5rem"
                }}>
                  <img src={colors.mobileIcon.transaction} alt="" />
                  <Typography sx={{
                    fontSize: ".8rem",
                    color: colors.basic.primary_test_color,
                    fontWeight: "bold",
                    marginTop: ".3rem",
                  }}>Transaction</Typography>
                </Box>
                <Box sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  alignItems: "center",
                  marginRight: ".5rem"
                }}>
                  <img src={colors.mobileIcon.menuOtp} alt="" />
                  <Typography sx={{
                    fontSize: ".8rem",
                    color: colors.basic.primary_test_color,
                    fontWeight: "bold",
                    marginTop: ".3rem",
                  }} align="center">
                    OTP
                  </Typography>
                </Box>



              </Box>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} sx={{ marginTop: "1rem", marginBottom: ".5rem" }}>
          <Typography color={colors.basic.primary_test_color}
            sx={{
              fontWeight: "600",
              fontSize: "16px",
            }}>
            Others
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Card elevation={3}
            sx={{ background: colors.basic.card_color, borderRadius: "10px" }}>
            <Box sx={{ padding: "1rem" }}>
              <Box sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "flex-end",
              }}>
                <Box sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}>
                  <img src={colors.mobileIcon.referIcon} alt="" />
                  <Typography color={colors.basic.primary_test_color} sx={{
                    fontSize: ".8rem",

                    fontWeight: "bold",
                    marginTop: ".3rem",
                  }} align="center">
                    Refer
                  </Typography>
                </Box>
                <Box sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}>
                  <img src={colors.mobileIcon.supportIcon} alt="" />
                  <Typography color={colors.basic.primary_test_color} sx={{
                    fontSize: ".8rem",

                    fontWeight: "bold",
                    marginTop: ".3rem",
                  }} align="center">
                    Support
                  </Typography>
                </Box>
                <Box sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}>
                  <img src={colors.mobileIcon.legalIcon} alt="" />
                  <Typography color={colors.basic.primary_test_color} sx={{
                    fontSize: ".8rem",

                    fontWeight: "bold",
                    marginTop: ".3rem",
                  }} align="center">
                    Legal
                  </Typography>
                </Box>
                <Box sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
                  onClick={() => handleProfile("Logout")}>
                  <img src={colors.mobileIcon.logoutIcon} alt="" />
                  <Typography color={colors.basic.primary_test_color} sx={{
                    fontSize: ".8rem",

                    fontWeight: "bold",
                    marginTop: ".3rem",
                  }} align="center">
                    Logout
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Menu;
