/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Reducers for creating, updating and adding roles list
 * @date 26/05/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import { Reducer } from "redux";
import actionType from "../actionTypes";

const initialState = {
  error: false,
  msg: "",
  components: [],
  data: [],
  componentsForProduct: [],
  rawMaterial: [],
};

const productComponentsReducer = (state = initialState, action) => {
  // console.log(`action type: ${action.type}`);
  console.log(action);
  switch (action.type) {
    case actionType.ADD_PRODUCT:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.GET_PRODUCT:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        products: action.payload.data,
      };
    case actionType.UPDATE_PRODUCT:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        // wfsList: action.payload.wfsList,
      };
    case actionType.ADD_COMPONENT:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.GET_COMPONENTS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        components: action.payload.data,
      };
    case actionType.UPDATE_COMPONENTS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        // wfsList: action.payload.wfsList,
      };
    case actionType.GET_COMPONENTS_FOR_PRODUCT:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        componentsForProduct: action.payload.data,
        // wfsList: action.payload.wfsList,
      };
    case actionType.ADD_RAW_MATERIAL:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.GET_RAW_MATERIAL:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        rawMaterial: action.payload.data,
      };
    case actionType.UPDATE_RAW_MATERIAL:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        // wfsList: action.payload.wfsList,
      };
    default:
      return state;
  }
};

export default productComponentsReducer;
