import React, { createContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Box, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { appColors } from "../../AppMeta/appColors";
import CustomDialogMoreDetailsWaterFilling from "../../Components/CustomComp/CustomDialogMoreDetailsWaterFilling";
import { EditDialogBatch } from "./EditDialogBatch";
import {
  GetCreateBatch,
  StartBatch,
} from "../../Redux/Actions/adddCreateBatchAction";
import { useDispatch } from "react-redux";
import { CancelDialogBatch } from "./CancelDialogBatch";
import CustomConfirmationDialog from "../../Components/CustomComp/CustomConfirmationDialog";
import CustomScannerDialog from "../../Components/CustomComp/CustomScannerDialog";
import { ScannerDialog } from "./ScannerDialog";
import { tokens } from "../../AppMeta/theme";
import BadgeIcon from "@mui/icons-material/Badge";

export const UserContext = createContext();

export default function BatchDetailsTable({ batchList }) {
  const data = [
    {
      facility_batch_master_id: 1,
      batch_id: "WFSB00002",
      quantity: 2,
      facility_id: 1,
      is_batch_started: true,
      is_batch_stopped: true,
      batch_start_time: "2024-03-05T05:01:47.808Z",
      batch_stop_time: "2024-03-05T05:01:47.808Z",
      updated_by: "Lkgx_lbEM",
      updated_at: "2024-03-05T05:01:47.808Z",
      created_at: "2024-03-05T05:01:47.808Z",
      container_capacity: "20 Liter",
      container_material: "PET NEW",
      line_id: "PDW1WFS4",
      linked_container_qty: 0,
      is_settled: false,
      reason: "",
      created_by: "Lkgx_lbEM",
      updated_user_details: {
        fullname: "tests updates",
        phone: "9003806524",
        email: "test@bookwater.com",
        is_active: true,
        is_deleted: false,
      },
      created_user_details: {
        fullname: "tests updates",
        phone: "9003806524",
        email: "test@bookwater.com",
        is_active: true,
        is_deleted: false,
      },
    },
    {
      facility_batch_master_id: 10,
      batch_id: "WFSB00003",
      quantity: 5,
      facility_id: 1,
      is_batch_started: true,
      is_batch_stopped: true,
      batch_start_time: "2024-03-05T07:28:24.000Z",
      batch_stop_time: "2024-03-05T07:28:24.000Z",
      updated_by: "Lkgx_lbEM",
      updated_at: "2024-03-05T14:05:59.000Z",
      created_at: "2024-03-05T07:28:24.000Z",
      container_capacity: "20 Liter",
      container_material: "PET NEW",
      line_id: "PDW1WFS4",
      linked_container_qty: 0,
      is_settled: false,
      reason: "Placed by mistake",
      created_by: "Lkgx_lbEM",
      updated_user_details: {
        fullname: "tests updates",
        phone: "9003806524",
        email: "test@bookwater.com",
        is_active: true,
        is_deleted: false,
      },
      created_user_details: {
        fullname: "tests updates",
        phone: "9003806524",
        email: "test@bookwater.com",
        is_active: true,
        is_deleted: false,
      },
    },
  ];

  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const wfsDetails = useSelector((state) => state.wfsDetails);
  const batchDetails = useSelector((state) => state.batchData);
  console.log(wfsDetails);
  console.log(batchList);

  //1.useState
  const [openCustomDialog, setOpenCustomDialog] = React.useState(false);
  const [moreDetails, setMoreDetails] = React.useState("");
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState(null);
  const [cancelDialogOpen, setCancelDialogOpen] = React.useState(false);
  const [batchData, setBatchData] = React.useState([]);
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    React.useState(false);
  const [openLinkDialog, setOpenLinkDialog] = React.useState(false);
  const [currentScanned, setCurrentScanned] = React.useState(
    JSON.parse(localStorage.getItem("current_scanned_codes"))
  );
  const [validData, setValidData] = React.useState([]);
  const [invalidData, setInvalidData] = React.useState([]);
  const [isConfirmed, setIsConfirmed] = React.useState(false);
  const [selectedBatchId, setSelectedBatchId] = React.useState("");
  //2.useEffect Hook
  React.useEffect(() => {
    if (currentScanned?.length) {
      localStorage.setItem(
        "current_scanned_codes",
        JSON.stringify(currentScanned)
      );
    }
    // const current_scanned_stored = JSON.parse(localStorage.getItem("current_scanned_codes")) || []
  }, [currentScanned]);
  React.useEffect(() => {
    // dispatch(GetCreateBatch()).then(() => {
    //   console.log("Batch Data");
    //   console.log(batchDetails);
    // });
    dispatch(GetCreateBatch());
  }, []);

  React.useEffect(() => {
    console.log("batchDetails");
    console.log(batchDetails);
    setBatchData(batchDetails?.batchData);
  }, [batchDetails]);
  React.useEffect(() => {
    if (isConfirmed && selectedBatchId !== "") {
      const reqObj = {
        batch_id: selectedBatchId,
      };
      console.log("ReqObj");
      console.log(reqObj);
      dispatch(StartBatch(reqObj));
      setOpenConfirmationDialog(false);
    }
  }, [isConfirmed, selectedBatchId]);

  const handleClose = () => {
    setEditDialogOpen(false);
    setCancelDialogOpen(false);
    //setCurrentItem(null); // Clear the current item when dialog is closed
  };
  const handleEditClick = (details) => {
    console.log("Details");
    console.log(details);
    setEditDialogOpen(true);
    setCurrentItem(details);
  };

  const handleCancelClick = (details) => {
    console.log("Cancel");
    console.log(cancelDialogOpen);
    setCancelDialogOpen(true);
    setCurrentItem(details);
  };

  const handleShowMore = (details) => {
    console.log("details");
    console.log(details);
    setOpenCustomDialog(true);
    setMoreDetails(details);
  };
  const handleStartBatch = (batch) => {
    console.log("batch");
    console.log(batch);
    setSelectedBatchId(batch.batch_id);
    setOpenConfirmationDialog(true);
  };

  const handleStopBatch = () => {
    setOpenConfirmationDialog(true);
  };
  const handleLinking = () => {
    setOpenLinkDialog(true);
  };
  return (
    <Box m="10px">
      <TableContainer
        sx={{
          borderRadius: "10px",
        }}
      >
        <Table>
          <TableHead
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.primary[300],
              boxShadow: 2,
            }}
          >
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  textAlign: "start",
                  fontSize: { xs: "0.9rem", md: "1.1rem" },
                }}
              >
                Batch ID
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  textAlign: "start",
                  fontSize: { xs: "0.9rem", md: "1.1rem" },
                }}
              >
                Batch Quantity
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  textAlign: "start",
                  fontSize: { xs: "0.9rem", md: "1.1rem" },
                }}
              >
                Is Batch Started
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  textAlign: "start",
                  fontSize: { xs: "0.9rem", md: "1.1rem" },
                }}
              >
                Is Batch Stopped
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  textAlign: "start",
                  fontSize: { xs: "0.9rem", md: "1.1rem" },
                }}
              >
                Container Capacity
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  textAlign: "start",
                  fontSize: { xs: "0.9rem", md: "1.1rem" },
                }}
              >
                Container Material
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  textAlign: "start",
                  fontSize: { xs: "0.9rem", md: "1.1rem" },
                }}
              >
                Link
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  textAlign: "start",
                  fontSize: { xs: "0.9rem", md: "1.1rem" },
                }}
              >
                Batch Status
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  textAlign: "start",
                  fontSize: { xs: "0.9rem", md: "1.1rem" },
                }}
              >
                More Details
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  textAlign: "start",
                  fontSize: { xs: "0.9rem", md: "1.1rem" },
                }}
              >
                Edit
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {batchData?.map((batch) => (
              <TableRow key={batch.facility_batch_master_id}>
                <TableCell
                  sx={{
                    fontSize: { xs: "0.8rem", md: "0.9rem" },
                    backgroundColor: colors.primary[600],
                    textAlign: "start",
                  }}
                >
                  {batch.batch_id}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: { xs: "0.8rem", md: "0.9rem" },
                    backgroundColor: colors.primary[600],
                    textAlign: "start",
                  }}
                >
                  {batch.quantity}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: { xs: "0.8rem", md: "0.9rem" },
                    backgroundColor: colors.primary[600],
                    textAlign: "start",
                  }}
                >
                  {batch.is_batch_started ? "Yes" : "No"}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: { xs: "0.8rem", md: "0.9rem" },
                    backgroundColor: colors.primary[600],
                    textAlign: "start",
                  }}
                >
                  {batch.is_batch_stopped ? "Yes" : "No"}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: { xs: "0.8rem", md: "0.9rem" },
                    backgroundColor: colors.primary[600],
                    textAlign: "start",
                  }}
                >
                  {batch.container_capacity}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: { xs: "0.8rem", md: "0.9rem" },
                    backgroundColor: colors.primary[600],
                    textAlign: "start",
                  }}
                >
                  {batch.container_material}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: { xs: "0.8rem", md: "0.9rem" },
                    backgroundColor: colors.primary[600],
                    textAlign: "start",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <Button
                      color="secondary"
                      style={{ backgroundColor: "#ffffff", color: "#000000" }}
                      variant="contained"
                      size="small"
                      value={openLinkDialog}
                      onClick={handleLinking}
                    >
                      LINK
                    </Button>
                  </Box>
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: { xs: "0.8rem", md: "0.9rem" },
                    backgroundColor: colors.primary[600],
                    textAlign: "start",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    {batch.is_batch_started === false && (
                      <Button
                        color="success"
                        variant="contained"
                        size="small"
                        onClick={() => handleStartBatch(batch)}
                      >
                        START
                      </Button>
                    )}

                    {batch.is_batch_stopped === false && (
                      <Button
                        color="error"
                        variant="outlined"
                        size="small"
                        onClick={() => handleStopBatch(batch)}
                      >
                        STOP
                      </Button>
                    )}
                  </Box>
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: { xs: "0.8rem", md: "0.9rem" },
                    backgroundColor: colors.primary[600],
                    textAlign: "start",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={() => {
                      handleShowMore(batch);
                    }}
                  >
                    Show More
                  </Button>
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: { xs: "0.8rem", md: "0.9rem" },
                    backgroundColor: colors.primary[600],
                    textAlign: "start",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <Button
                      color="secondary"
                      variant="contained"
                      size="small"
                      onClick={() => handleEditClick(batch)}
                    >
                      Edit
                    </Button>
                    <Button
                      color="error"
                      variant="outlined"
                      size="small"
                      onClick={handleCancelClick}
                    >
                      Cancel
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {openCustomDialog && (
        <CustomDialogMoreDetailsWaterFilling
          openCustomDialog={openCustomDialog}
          setOpenCustomDialog={setOpenCustomDialog}
          title="More details"
          moreDetails={moreDetails}
        />
      )}
      {editDialogOpen && (
        <EditDialogBatch
          open={editDialogOpen}
          setEditDialogOpen={setEditDialogOpen}
          onClose={handleClose}
          item={currentItem}
        />
      )}
      {cancelDialogOpen && (
        <CancelDialogBatch
          open={cancelDialogOpen}
          onClose={handleClose}
          setCancelDialogOpen={setCancelDialogOpen}
          item={currentItem}
        />
      )}
      {openConfirmationDialog && (
        <CustomConfirmationDialog
          openConfirmationDialog={openConfirmationDialog}
          setOpenConfirmationDialog={setOpenConfirmationDialog}
          description={"Are you sure want to START batch?"}
          setIsConfirmed={setIsConfirmed}
        />
      )}

      {openConfirmationDialog && (
        <CustomConfirmationDialog
          openConfirmationDialog={openConfirmationDialog}
          setOpenConfirmationDialog={setOpenConfirmationDialog}
          description={"Are you sure want to STOP batch?"}
          setIsConfirmed={setIsConfirmed}
        />
      )}
      {openLinkDialog && (
        <ScannerDialog
          open={openLinkDialog}
          setOpenLinkDialog={setOpenLinkDialog}
          currentScanned={currentScanned}
          setCurrentScanned={setCurrentScanned}
          validData={validData}
          invalidData={invalidData}
          setInvalidData={setInvalidData}
          setValidData={setValidData}
        />
      )}
    </Box>
  );
}
