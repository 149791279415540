/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Custom typography which is used all over the order
 * @date 26/05/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries
import React from "react";
import Typography from "@mui/material/Typography";

const CustomTypography = ({ content, fontSize, fontWeight, textAlign }) => {
  return (
    <div className="card flex justify-content-center">
      <Typography variant="body1" style={{ fontSize, fontWeight, textAlign }}>
        {content}
      </Typography>
    </div>
  );
};

export default CustomTypography;
