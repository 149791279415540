import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RecreateOrderDialog({
  recreateOrderDialog,
  setRecreateOrderDialog,
  value,
  setValue,
  moreDetails,
  selectedRow,
  setMftName,
  setMftEmail,
  setMftPhone,
  setMftAreaName,
  setMftPincode,
  isProductAvailable,
  setNoOfItems,
  setProduct,
  setTotalPrice,
}) { 

  console.log("moreDetails");
  console.log(moreDetails);
  console.log(isProductAvailable);
  console.log(selectedRow); 
  const handleClose = () => {
    setRecreateOrderDialog(false); 
  }; 
 
  const handleAgree = () => {
    setMftName(moreDetails.mft_name); 
    setMftEmail(moreDetails.email);
    setMftPhone(moreDetails.phone);
    setMftAreaName(moreDetails.area_name);
    setMftPincode(moreDetails.pincode);
    setNoOfItems(parseInt(selectedRow.quantity));
    setProduct(selectedRow.porduct_details.product_id.toString());
    setTotalPrice(selectedRow.price);
    setValue(1);
  };

  return (
    <div>
      <Dialog
        open={recreateOrderDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {isProductAvailable ? (
            <span style={{ color: "green", fontWeight: "bold" }}>
              This product is available
            </span>
          ) : (
            <span style={{ color: "red", fontWeight: "bold" }}>
              "This product is not available Now"
            </span>
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {isProductAvailable
              ? "Do you want to create order with the same manufacturing details and product?"
              : "Do you want to create order with the same manufacturing details with new product?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleAgree}>Agree</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
