import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Paper,
  Typography,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { EmptyCanOrder } from "../Orders/OrganizationBooking/EmptyCanOrder";
import { useDispatch, useSelector } from "react-redux";
import { GetOrganizationList } from "../../Redux/Actions/organizationAction";

const CustomPriceForm = () => {
  const dispatch = useDispatch();

  const organizationDetails = useSelector((state) => state.organizationData);

  //1.useState
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [customPrice, setCustomPrice] = useState("");
  const [allFacilities, setAllFacilities] = useState([]);

  //2.useEffect
  useEffect(() => {
    dispatch(GetOrganizationList());
  }, []);
  useEffect(() => {
    console.log("Organization Details");
    console.log(organizationDetails);
    setAllFacilities(organizationDetails.organizationList.data);
  }, [organizationDetails]);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setSelectedItem("");
    setCustomPrice("");
  };

  const handleItemChange = (event) => {
    setSelectedItem(event.target.value);
  };

  const handlePriceChange = (event) => {
    setCustomPrice(event.target.value);
  };

  const handleSave = () => {
    // Implement your logic to save custom price
    console.log(
      `Category: ${selectedCategory}, Item: ${selectedItem}, Custom Price: ${customPrice}`
    );
  };

  return (
    <>
      <div style={{ marginBottom: "20px" }}>
        <Paper
          style={{
            padding: "16px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" style={{ marginBottom: "16px" }}>
            Set Custom Price
          </Typography>

          <FormControl style={{ width: "100%", marginBottom: "16px" }}>
            <InputLabel>Category</InputLabel>
            <Select
              value={selectedCategory}
              onChange={handleCategoryChange}
              label="Category"
              style={{ width: "100%" }}
            >
              {["Facility", "Organization"].map((category, index) => (
                <MenuItem key={index} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {selectedCategory && (
            <>
              <FormControl style={{ width: "100%", marginBottom: "16px" }}>
                <InputLabel>{`${selectedCategory} Name`}</InputLabel>
                <Select
                  value={selectedItem}
                  onChange={handleItemChange}
                  label={`${selectedCategory} Name`}
                  style={{ width: "100%" }}
                >
                  {selectedCategory === "Facility"
                    ? allFacilities.map((facility) => (
                        <MenuItem key={facility.id} value={facility.org_name}>
                          {facility.org_name}
                        </MenuItem>
                      ))
                    : [
                        "Organization 1",
                        "Organization 2",
                        "Organization 3",
                      ].map((organization, index) => (
                        <MenuItem key={index} value={organization}>
                          {organization}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>

              <TextField
                label="Custom Price"
                type="number"
                value={customPrice}
                onChange={handlePriceChange}
                fullWidth
                style={{ marginBottom: "16px" }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                style={{ width: "100%" }}
              >
                Save Custom Price
              </Button>
            </>
          )}
        </Paper>
      </div>

      <div>
        <Paper
          style={{
            padding: "16px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" style={{ marginBottom: "16px" }}>
            Payment Settings
          </Typography>
          <FormControl style={{ width: "100%", marginBottom: "16px" }}>
            <InputLabel>Facility Name</InputLabel>
            <Select style={{ width: "100%" }}>
              {allFacilities?.map((facility) => {
                return (
                  <MenuItem key={facility.id} value={facility.org_name}>
                    {facility.org_name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue=""
              name="radio-buttons-group"
            >
              <FormControlLabel
                value=""
                control={<Radio />}
                label="Allow Only Postpaid"
              />
              <FormControlLabel
                value="male"
                control={<Radio />}
                label="Allow Only Prepaid"
              />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label="Allow Both"
              />
            </RadioGroup>
          </FormControl>
          <Button variant="contained" color="primary" style={{ width: "100%" }}>
            Save
          </Button>
        </Paper>
      </div>
    </>
  );
};

export default CustomPriceForm;
