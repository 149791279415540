import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Select,
  MenuItem,
  IconButton,
  TextField,
  Checkbox,
  Button,
  useTheme,
  FormControl,
} from "@mui/material";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useSelector } from "react-redux";
import { appColors } from "../../AppMeta/appColors";
import { tokens } from "../../AppMeta/theme";

const dummyData = [
  {
    componentName: "Component A",
    materialName: "Material 1",
    area: "Area 1",
    manufacturer: "Manufacturer 1",
  },
  {
    componentName: "Component B",
    materialName: "Material 2",
    area: "Area 2",
    manufacturer: "Manufacturer 2",
  },
  {
    componentName: "Component C",
    materialName: "Material 3",
    area: "Area 1",
    manufacturer: "Manufacturer 1",
  },
  {
    componentName: "Component D",
    materialName: "Material 1",
    area: "Area 3",
    manufacturer: "Manufacturer 3",
  },
  {
    componentName: "Component E",
    materialName: "Material 2",
    area: "Area 2",
    manufacturer: "Manufacturer 2",
  },
];

const datas = {
  error: false,
  msg: "Successfully got the Details!",
  data: [
    {
      product_details: {
        id: 71,
        name: "20 Liter Water Can",
        image: "",
        price: 70,
        amount: 70,
        refill: {
          sp: 1,
          mrp: 1,
          deposit: 0,
          refund_type: 100,
          refund_amount: 0,
        },
        sku_qty: [12],
        capacity: "20_Liters",
        hsn_code: "125466",
        material: "PET MATERIAL",
        cgst_perc: 6,
        igst_perc: 2,
        life_span: 1000,
        sgst_perc: 6,
        created_at: "15:50:39.052949",
        order_type: 100,
        updated_at: "2024-04-29T15:50:39.052949",
        description: "20 liter water can",
        booking_type: 1,
        max_book_qty: 1,
        min_book_qty: 1,
        one_time_sale: {
          sp: 1,
          mrp: 1,
          deposit: 0,
          refund_type: 100,
          refund_amount: 0,
        },
        facility_price: null,
      },
      crate_details: [
        {
          id: null,
          name: null,
          image: null,
          price: null,
          capacity: null,
          description: null,
        },
      ],
    },
    {
      product_details: {
        id: 74,
        name: "20 Liter Water Can",
        image: "",
        price: 70,
        amount: 70,
        refill: {
          sp: 1,
          mrp: 1,
          deposit: 0,
          refund_type: 100,
          refund_amount: 0,
        },
        sku_qty: [12],
        capacity: "20_Liters",
        hsn_code: "125466",
        material: "PET MATERIALS",
        cgst_perc: 6,
        igst_perc: 2,
        life_span: 1000,
        sgst_perc: 6,
        created_at: "14:39:36.173621",
        order_type: 100,
        updated_at: "2024-05-03T14:39:36.173621",
        description: "20 liter water can",
        booking_type: 1,
        max_book_qty: 1,
        min_book_qty: 1,
        one_time_sale: {
          sp: 1,
          mrp: 1,
          deposit: 0,
          refund_type: 100,
          refund_amount: 0,
        },
        facility_price: null,
      },
      crate_details: [
        {
          id: null,
          name: null,
          image: null,
          price: null,
          capacity: null,
          description: null,
        },
      ],
    },
    {
      product_details: {
        id: 64,
        name: "ALUMINUM Bottle",
        image: "",
        price: 1,
        amount: 1,
        refill: {
          sp: 1,
          mrp: 1,
          deposit: 1,
          refund_type: 100,
          refund_amount: 1,
        },
        sku_qty: [3],
        capacity: "300 ml",
        hsn_code: "66787",
        material: "ALUMINUM",
        cgst_perc: 9,
        igst_perc: 9,
        life_span: 15,
        sgst_perc: 9,
        created_at: "11:26:12.927201",
        order_type: 400,
        updated_at: "2024-03-08T11:26:12.927201",
        description: "Aluminum bottle ",
        booking_type: 2,
        max_book_qty: 50,
        min_book_qty: 2,
        one_time_sale: {
          sp: 40,
          mrp: 50,
          deposit: 180,
          refund_type: 100,
          refund_amount: 150,
        },
        facility_price: {
          deposit_price: 50,
        },
      },
      crate_details: [
        {
          id: 27,
          name: "Aluminium bottle crate",
          image: "",
          price: 120,
          capacity: 3,
          description: "Aluminium bottle crate",
        },
      ],
    },
    {
      product_details: {
        id: 63,
        name: "500 ML Steel Bottle(M)",
        image: "",
        price: 1,
        amount: 1,
        refill: {
          sp: 1,
          mrp: 1,
          deposit: 1,
          refund_type: 100,
          refund_amount: 1,
        },
        sku_qty: [3],
        capacity: "500 ML",
        hsn_code: "22110",
        material: "STEEL",
        cgst_perc: 9,
        igst_perc: 9,
        life_span: 5,
        sgst_perc: 9,
        created_at: "11:19:42.610985",
        order_type: 100,
        updated_at: "2024-03-08T11:19:42.610985",
        description: "500 ML Steel Bottle",
        booking_type: 2,
        max_book_qty: 10,
        min_book_qty: 5,
        one_time_sale: {
          sp: 1,
          mrp: 1,
          deposit: 1,
          refund_type: 100,
          refund_amount: 0,
        },
        facility_price: {
          deposit_price: 80,
        },
      },
      crate_details: [
        {
          id: 26,
          name: "500 ML Steel Bottle Crate (M)",
          image: "",
          price: 40,
          capacity: 4,
          description: "500 ML steel bottle crate",
        },
      ],
    },
    {
      product_details: {
        id: 62,
        name: "1litre Glass bottle",
        image: "",
        price: 1,
        amount: 1,
        refill: {
          sp: 1,
          mrp: 1,
          deposit: 1,
          refund_type: 100,
          refund_amount: 1,
        },
        sku_qty: [3],
        capacity: "750ml-3",
        hsn_code: "22110",
        material: "GLASS",
        cgst_perc: 6,
        igst_perc: 6,
        life_span: 5,
        sgst_perc: 6,
        created_at: "11:17:29.449964",
        order_type: 100,
        updated_at: "2024-03-08T11:17:29.449964",
        description: "This is a 1litre glass bottle",
        booking_type: 2,
        max_book_qty: 99,
        min_book_qty: 1,
        one_time_sale: {
          sp: 1,
          mrp: 1,
          deposit: 1,
          refund_type: 100,
          refund_amount: 0,
        },
        facility_price: {
          deposit_price: 65,
        },
      },
      crate_details: [
        {
          id: 25,
          name: "1litre Glass bottle crate",
          image: "",
          price: 50,
          capacity: 3,
          description: "This is a 1litre glass bottle",
        },
      ],
    },
  ],
};

const FinalProductsList = ({
  manufacturersList,
  selectedProduct,
  activeStep,
  hitValidateApi,
  setSelectedData,
  selectedData,
  allProducts,
  setActiveStep
}) => {
  const productDetails = useSelector((state) => state.productComponentsData);
  const purchaseProductDetails = useSelector(
    (state) => state.purchaseProductData
  );

  const skuFinalProducts = useSelector((state) => state.productData)

  const [productId, setProductId] = React.useState("")
  React.useEffect(() => {
    console.log("skuFinalProducts in FinalProductsList")
    console.log(skuFinalProducts)
  }, [skuFinalProducts])

  React.useEffect(() => {
    console.log("purchaseProductDetails");
    console.log(purchaseProductDetails);
  }, [purchaseProductDetails]);
  const [areas, setAreas] = useState(["Area 1", "Area 2", "Area 3"]);
  const [manufacturers, setManufacturers] = useState([
    "Manufacturer 1",
    "Manufacturer 2",
    "Manufacturer 3",
  ]);

  const [componentsForProducts, setComponentsForProducts] = React.useState([]);

  // const [quantities, setQuantities] = useState(
  //   Array(componentsForProducts?.length).fill("0")
  // );
  const [quantities, setQuantities] = useState(
    Array(allProducts?.length).fill("0")
  );

  const [prices, setPrices] = useState(Array(allProducts?.length).fill("0"));

  const [selectedRows, setSelectedRows] = useState([]);
  // const [selectedData, setSelectedData] = useState([]);

  const [selectedProducts, setSelectedProducts] = React.useState({});
  React.useEffect(() => {
    console.log("productDetails");
    console.log(productDetails);
    // console.log(productDetails?.componentsForProduct?.data?.components);

    if (productDetails.error === false && selectedProduct !== "") {
      console.log(productDetails.componentsForProduct.data);

      // setComponentsForProducts(productDetails.componentsForProduct.data);
      const componentsArray =
        productDetails?.componentsForProduct?.data?.reduce(
          (componentsArray, product) => {
            if (product.components && Array.isArray(product.components)) {
              componentsArray.push(...product.components);
            }
            return componentsArray;
          },
          []
        );
      setComponentsForProducts(componentsArray);
    }
  }, [productDetails]);

  React.useEffect(() => {
    allProducts.map((item) => {
      console.log("Item");
      console.log(item);
    });
  }, []);

  React.useState(() => {
    console.log("SelectedProducts");
    console.log(selectedProducts);
  }, [selectedProducts]);

  React.useEffect(() => {
    console.log("allProducts");
    console.log(allProducts);
  }, [allProducts]);

  React.useEffect(() => {
    console.log("Manufacture list");

    console.log(manufacturersList);
  }, []);

  const handleRowSelect = (index) => {
    const newSelectedRows = [...selectedRows];
    if (newSelectedRows.includes(index)) {
      newSelectedRows.splice(newSelectedRows.indexOf(index), 1);
    } else {
      newSelectedRows.push(index);
    }
    setSelectedRows(newSelectedRows);
  };

  React.useEffect(() => {
    skuFinalProducts.finalProduct.map((row) => {
      console.log("row");
      console.log(row);
      setProductId(row.product_details.id)

    });
  }, [productId]);

  const handleAreaChange = (event, index) => {
    const updatedData = [...componentsForProducts];
    updatedData[index].area = event.target.value;
    setAreas([...new Set([...areas, event.target.value])]);
  };

  const handleManufacturerChange = (event, index) => {
    const updatedData = [...skuFinalProducts.finalProduct];
    console.log(updatedData);
    updatedData[index].manufacturer = event.target.value;
    setManufacturers([...new Set([...manufacturers, event.target.value])]);
  };

  const handleQuantityChange = (event, index) => {
    const updatedQuantities = [...quantities];
    updatedQuantities[index] = event.target.value;
    setQuantities(updatedQuantities);
  };

  const handlePriceChange = (event, index) => {
    const updatedPrices = [...prices];
    updatedPrices[index] = event.target.value;
    setPrices(updatedPrices);
  };

  const handleQuantityIncrement = (index) => {
    const updatedQuantities = [...quantities];
    updatedQuantities[index] = String(
      parseInt(updatedQuantities[index], 10) + 1
    );
    setQuantities(updatedQuantities);
  };

  const handleQuantityDecrement = (index) => {
    const updatedQuantities = [...quantities];
    if (parseInt(updatedQuantities[index], 10) > 0) {
      updatedQuantities[index] = String(
        parseInt(updatedQuantities[index], 10) - 1
      );
      setQuantities(updatedQuantities);
    }
  };

  React.useEffect(() => {
    console.log("hitValidateApi");
    console.log(hitValidateApi);
  }, [hitValidateApi]);




  const handleAddToCart = () => {
    console.log("selectedRows");
    console.log(selectedRows);
    setActiveStep(1)
    const selectedDataItems = selectedRows.map((index) => {
      return {
        id: skuFinalProducts.finalProduct[index].product_details.id,
        mft_id: skuFinalProducts.
          finalProduct[index].manufacturer,
        quantity: quantities[index],
        price: prices[index],
      };
    });
    console.log("selectedDataItems");
    console.log(selectedDataItems);
    // const groupedData = groupByProduct(selectedDataItems);
    const groupedData = groupByManufacturerId(selectedDataItems);
    // Do something with the grouped data, like adding it to the cart
    console.log(groupedData);
    setSelectedData(groupedData);
  };



  function groupByManufacturerId(inputArray) {
    const groupedByMftId = inputArray.reduce((accumulator, current) => {
      const mftId = current.mft_id;

      if (!accumulator[mftId]) {
        accumulator[mftId] = {
          mft_id: parseInt(mftId, 10),
          product_details: [],
        };
      }

      accumulator[mftId].product_details.push({
        id: parseInt(current.id, 10),
        quantity: parseInt(current.quantity, 10),
        price: parseFloat(current.price),
      });

      return accumulator;
    }, {});

    return Object.values(groupedByMftId);
  }

  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  return (
    <Box p={2}>
      <TableContainer sx={{
        borderRadius: "10px",
      }} component={Paper}>
        <Table>
          <TableHead sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.primary[300],
            boxShadow: 2,
          }}>
            <TableRow>
              <TableCell sx={{
                fontWeight: "bold",
                textAlign: "start",
                fontSize: { xs: "0.9rem", md: "1.1rem" },
              }}>Select</TableCell>
              <TableCell sx={{
                fontWeight: "bold",
                textAlign: "start",
                fontSize: { xs: "0.9rem", md: "1.1rem" },
              }}>Final Product Name</TableCell>
              {/* <TableCell>Current Stock</TableCell> */}
              {/* <TableCell>Units</TableCell> */}
              <TableCell sx={{
                fontWeight: "bold",
                textAlign: "start",
                fontSize: { xs: "0.9rem", md: "1.1rem" },
              }}>Required Quantity</TableCell>
              <TableCell sx={{
                fontWeight: "bold",
                textAlign: "start",
                fontSize: { xs: "0.9rem", md: "1.1rem" },
              }}>Price / Quantity</TableCell>
              {/* <TableCell>Area</TableCell> */}
              <TableCell sx={{
                fontWeight: "bold",
                textAlign: "start",
                fontSize: { xs: "0.9rem", md: "1.1rem" },
              }}>Manufacturer</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {/* {allProducts.length && ( */}
              <>
                {" "}
                {skuFinalProducts.
                  finalProduct
                  .map((row, index) => (
                    <React.Fragment key={index}>
                      <TableRow>
                        <TableCell sx={{
                          fontSize: { xs: "0.8rem", md: "0.9rem" },
                          backgroundColor: colors.primary[600],
                          textAlign: "start",
                        }}>
                          <Checkbox
                            checked={selectedRows.includes(index)}
                            onChange={() => handleRowSelect(index)}
                          />
                        </TableCell>

                        <TableCell sx={{
                          fontSize: { xs: "0.8rem", md: "0.9rem" },
                          backgroundColor: colors.primary[600],
                          textAlign: "start",
                        }}>{row.product_details.name}</TableCell>
                        {/* <TableCell>
                        <IconButton
                          color="primary"
                          onClick={() => handleQuantityDecrement(index)}
                        >
                          <RemoveIcon />
                        </IconButton>
                        <TextField
                          size="small"
                          type="number"
                          value={quantities[index]}
                          InputProps={{ inputProps: { min: 0 } }}
                          onChange={(event) =>
                            handleQuantityChange(event, index)
                          }
                        />
                        <IconButton
                          color="primary"
                          onClick={() => handleQuantityIncrement(index)}
                        >
                          <AddIcon />
                        </IconButton>
                      </TableCell> */}
                        <TableCell sx={{
                          fontSize: { xs: "0.8rem", md: "0.9rem" },
                          backgroundColor: colors.primary[600],
                          textAlign: "start",
                        }}>
                          <TextField
                            size="small"
                            type="number"
                            value={quantities[index]}
                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(event) =>
                              handleQuantityChange(event, index)
                            }
                          />
                        </TableCell>

                        <TableCell sx={{
                          fontSize: { xs: "0.8rem", md: "0.9rem" },
                          backgroundColor: colors.primary[600],
                          textAlign: "start",
                        }}>
                          <TextField
                            type="number"
                            size="small"
                            value={prices[index]}
                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(event) => handlePriceChange(event, index)}
                          />
                        </TableCell>
                        <TableCell sx={{
                          fontSize: { xs: "0.8rem", md: "0.9rem" },
                          backgroundColor: colors.primary[600],
                          textAlign: "start",
                        }}>
                          <FormControl fullWidth variant="filled">
                            <Select
                              value={row.manufacturer}
                              onChange={(event) =>
                                handleManufacturerChange(event, index)
                              }
                            >
                              {manufacturersList.map((manufacturer) => (
                                <MenuItem
                                  key={manufacturer.id}
                                  value={manufacturer.id}
                                >
                                  {manufacturer.details.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
              </>
            </>
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{
        position: "fixed",
        bottom: 16,
        right: 16,
        zIndex: 1000,
        // Set a high z-index to ensure the button appears above other elements
      }} display="flex" justifyContent="flex-end" mt={2}>
        <Button sx={{
          fontWeight: "bold",
          fontSize: "20px"
        }} startIcon={<ShoppingCartIcon />} size="large" variant="contained" color="secondary" onClick={handleAddToCart}>
          Add to Cart
        </Button>
      </Box>
      {/* <Box mt={2}>
        <Typography variant="h6">Selected Data</Typography>
        <pre>{JSON.stringify(selectedData, null, 2)}</pre>
      </Box> */}
    </Box >
  );
};

export default FinalProductsList;
