import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import BadgeIcon from "@mui/icons-material/Badge";
import CloseIcon from "@mui/icons-material/Close";
import CustomInputTextField from "../../Components/CustomComp/CustomInputTextField";
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";
import {
  Card,
  CardContent,
  Chip,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Menu,
  Dialog,
  useTheme,
} from "@mui/material";
import CustomButton from "../../Components/CustomComp/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateWFS,
  CreateWFSLines,
  CreateWfsBatch,
  GetBatchDetails,
  GetWFS,
  GetWFSLines,
} from "../../Redux/Actions/wfsDetailsAction";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import AllWfsList from "./AllWfsList";
import appMeta from "../../AppMeta/appMeta";
import { UploadFile, getFiles } from "../../Redux/Actions/fileHandlingActions";
import AddIotDevices from "./IotDevices/AddIotDevices";
import IotDevices from "./IotDevices/IotDevices";
import GetAppRoundedIcon from "@mui/icons-material/GetAppRounded";
import WfsLinesTable from "./WfsLinesTable";
import BatchDetailsTable from "./BatchDetailsTable";
import { AddLinesFacilty } from "./LinkingAndAddingFacilitiesAndIOTDevices/AddLinesFacilty";
import { LinkIOTtoFacilityFillingLines } from "./LinkingAndAddingFacilitiesAndIOTDevices/LinkIOTtoFacilityFillingLines";
import { AddIOTDevices } from "./LinkingAndAddingFacilitiesAndIOTDevices/AddIOTDevices";
import { GetOrganizationList } from "../../Redux/Actions/organizationAction";
import { GetFinalProductSKU } from "../../Redux/Actions/productsAction";
import {
  ConnectingAirportsOutlined,
  ConstructionOutlined,
  SelectAllSharp,
} from "@mui/icons-material";
import { GetLinesFacility } from "../../Redux/Actions/addFacilityWFSFillingAction";
import {
  AddCreateBatch,
  GetCreateBatch,
} from "../../Redux/Actions/adddCreateBatchAction";
import { tokens } from "../../AppMeta/theme";

const steps = [
  "Onboard filling station",
  "Add Filling Lines",
  "Add IoT Device",
  "Facility Lines And IOT Devices",
  // "Create an ad",
];

const datam = {
  lineIds: ["PDW1WFS1", "PDW1WFS2", "PDW1WFS3", "PDW1WFS4", "PDW1WFS5"],
  iotDeviceNumbers: [null, null, null, "DEVICE49", "DEVICE28"],
};

export default function FillingStation() {
  const dispatch = useDispatch();
  const organisationDetails = useSelector((state) => state.organizationData);
  const wfsDetails = useSelector((state) => state.wfsDetails);
  const filesDetails = useSelector((state) => state.filesData);
  const productDetails = useSelector((state) => state.productData);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [alignment, setAlignment] = React.useState("Existing filling stations");
  const [finalProducts, setFinalProducts] = React.useState([]);
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  //   Textfield variables
  const [fillingStationName, setFillingStationName] = React.useState("");
  const [isFillingStationNameValid, setIsFillingStationNameValid] =
    React.useState(true);
  const [area, setArea] = React.useState("");
  const [isAreaValid, setIsAreaValid] = React.useState(true);
  const [phone, setPhone] = React.useState("");
  const [isPhoneValid, setIsPhoneValid] = React.useState(true);
  const [pincode, setPincode] = React.useState("");
  const [isPincodeValid, setIsPincodeValid] = React.useState(true);
  const [email, setEmail] = React.useState("");

  const [isEmailValid, setIsEmailValid] = React.useState(true);

  const [password, setPassword] = React.useState("");
  const [roleName, setRoleName] = React.useState("SUPER-ADMIN");

  const [orgName, setOrgName] = React.useState("");

  const [ownerName, setOwnerName] = React.useState("");
  const [isOwnerNameValid, setIsOwnerNameValid] = React.useState(true);
  const [gstNumber, setGstNumber] = React.useState("");
  const [panNumber, setPanNumber] = React.useState("");
  const [fassaiNumber, setFassaiNumber] = React.useState("");
  const [isiNumber, setIsiNumber] = React.useState("");
  const [aadhaarNumber, setAadhaarNumber] = React.useState("");
  const [allFacilities, setAllFacilities] = React.useState([]);

  //   File uploads variables
  const [gstFile, setGstFile] = React.useState("");
  const [gstUrl, setGstUrl] = React.useState("");
  const [gstUploadBtn, setGstUploadBtn] = React.useState(false);
  const [gstFileFlag, setGstFileFlag] = React.useState(false);

  const [panFile, setPanFile] = React.useState("");
  const [panUrl, setPanUrl] = React.useState("");
  const [panUploadBtn, setPanUploadBtn] = React.useState(false);
  const [panFileFlag, setPanFileFlag] = React.useState(false);

  const [aadhaarFile, setAadhaarFile] = React.useState("");
  const [aadhaarUrl, setAadhaarUrl] = React.useState("");
  const [aadhaarUploadBtn, setAadhaarUploadBtn] = React.useState(false);
  const [aadhaarFileFlag, setAadhaarFileFlag] = React.useState(false);
  const [selectedProductId, setSelectedProductId] = React.useState(null);
  const [fssaiFile, setFssaiFile] = React.useState("");
  const [fssaiUrl, setFssaiUrl] = React.useState("");
  const [fssaiUploadBtn, setFssaiUploadBtn] = React.useState(false);
  const [fssaiFileFlag, setFssaiFileFlag] = React.useState(false);

  const [isiFile, setIsiFile] = React.useState("");
  const [isiUrl, setIsiUrl] = React.useState("");
  const [isiUploadBtn, setIsiUploadBtn] = React.useState(false);
  const [isiFileFlag, setIsiFileFlag] = React.useState(false);

  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(25);
  const [lastPageNumber, setLastPageNumber] = React.useState(null);
  const [isActiveFilter, setIsActiveFilter] = React.useState(true);
  const [ascDesc, setAscDesc] = React.useState("ASC");

  const [value, setValue] = React.useState("one");
  const [addValue, setAddValue] = React.useState("1");

  const [newWfsId, setNewWfsId] = React.useState("");
  const [selectFacility, setSelectFacility] = React.useState("");

  // Batch details

  const [wfsId, setWfsId] = React.useState("");
  const [products, setProducts] = React.useState([]);
  const [lineId, setLineId] = React.useState("");
  const [quantity, setQuantity] = React.useState("");
  const [isQuantityValid, setIsQuantityValid] = React.useState(true);
  const [batchList, setBatchList] = React.useState([]);
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeTabs1 = (event, newValue) => {
    setAddValue(newValue);
  };
  // GET API Call

  React.useEffect(() => {
    dispatch(GetWFS(pageNumber, pageSize, isActiveFilter, ascDesc));
    dispatch(GetFinalProductSKU());
  }, [isActiveFilter, ascDesc, pageNumber, pageSize]);
  React.useEffect(() => {
    dispatch(GetOrganizationList());
    console.log("organisation");
    console.log(organisationDetails);
  }, []);

  React.useEffect(() => {
    dispatch(GetFinalProductSKU());
  }, []);

  // React.useEffect(() => {
  //   console.log("selectFacility");
  //   console.log(selectFacility);

  //   dispatch(GetLinesFacility(selectFacility));
  // }, [selectFacility]);

  // Accessing wfs data from store

  React.useEffect(() => {
    console.log(wfsDetails);
    if (wfsDetails.error === false) {
      setBatchList(wfsDetails?.wfsbatchList?.data);
      setLastPageNumber(wfsDetails?.wfsList?.lastPageNumber);
      if (wfsDetails.new_wfs_id !== "" && wfsDetails.new_wfs_id !== null) {
        setNewWfsId(wfsDetails.new_wfs_id);
        setActiveStep(1);
      }
    }
  }, [wfsDetails]);

  React.useEffect(() => {
    console.log(newWfsId);
    if (newWfsId !== "") {
      dispatch(GetWFSLines(newWfsId));
      dispatch(GetBatchDetails(newWfsId));
    }
  }, [newWfsId]);

  //Organisation Deatils
  React.useEffect(() => {
    console.log("organisationDetails");
    console.log(organisationDetails);
    setAllFacilities(organisationDetails.organizationList);
  }, [organisationDetails]);

  React.useEffect(() => {
    console.log("Product Data");
    console.log(productDetails);
    // setProducts(productDetails.finalProduct.product_details);
    // productDetails?.finalProduct?.map((product) => {
    //   setFinalProducts(product.product_details);
    // });
    setFinalProducts(productDetails?.finalProduct);
  }, [productDetails]);

  React.useEffect(() => {
    console.log("products");
    console.log(products);
  }, [products]);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;

    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  React.useEffect(() => {
    console.log(activeStep);
  }, [activeStep]);

  //   ref variable for input uploads
  const gstInputRef = React.useRef(null);
  const panInputRef = React.useRef(null);
  const aadhaarInputRef = React.useRef(null);
  const fassaiInputRef = React.useRef(null);
  const isiInputRef = React.useRef(null);

  //   Handling upload file clicks here
  const handleUploadGstClick = () => {
    gstInputRef.current.click();
  };
  const handleUploadPanClick = () => {
    panInputRef.current.click();
  };
  const handleUploadAadhaarClick = () => {
    aadhaarInputRef.current.click();
  };
  const handleUploadFassaiClick = () => {
    fassaiInputRef.current.click();
  };
  const handleUploadIsiClick = () => {
    isiInputRef.current.click();
  };

  // React.useEffect(() => {
  //   if (gstFile !== "") {
  //     dispatch(UploadFile(gstFile));
  //   }
  // }, [gstFile]);
  //   Handling changing function when user tries to upload files
  const handleChangeGstUpload = (event) => {
    console.log("Selected GST file:", event.target.files[0]);
    setGstFile(event.target.files[0]);
    setGstUploadBtn(true);
  };

  const handleChangePanUpload = (event) => {
    console.log("Selected PAN file:", event.target.files[0]);
    setPanFile(event.target.files[0]);
    setPanUploadBtn(true);
  };

  const handleChangeAadhaarUpload = (event) => {
    console.log("Selected Aadhaar file:", event.target.files[0]);
    setAadhaarFile(event.target.files[0]);
    setAadhaarUploadBtn(true);
  };
  const handleChangeFassaiUpload = (event) => {
    console.log("Selected FSSAI file:", event.target.files[0]);
    setFssaiFile(event.target.files[0]);
    setFssaiUploadBtn(true);
  };
  const handleChangeIsiUpload = (event) => {
    console.log("Selected Isi file:", event.target.files[0]);
    setIsiFile(event.target.files[0]);
    setIsiUploadBtn(true);
  };

  React.useEffect(() => {
    if (gstUploadBtn) {
      console.log("gst file upload");
      dispatch(UploadFile(gstFile));
      setGstUploadBtn(false);
      setGstFileFlag(true);
    } else if (panUploadBtn) {
      console.log("pan file upload");
      dispatch(UploadFile(panFile));
      setPanUploadBtn(false);
      setPanFileFlag(true);
    } else if (aadhaarUploadBtn) {
      console.log("Aadhaar file upload");
      dispatch(UploadFile(aadhaarFile));
      setAadhaarUploadBtn(false);
      setAadhaarFileFlag(true);
    } else if (fssaiUploadBtn) {
      console.log("Fssai file upload");
      dispatch(UploadFile(fssaiFile));
      setFssaiUploadBtn(false);
      setFssaiFileFlag(true);
    } else if (isiUploadBtn) {
      console.log("isi file upload");
      dispatch(UploadFile(isiFile));
      setIsiUploadBtn(false);
      setIsiFileFlag(true);
    }
  }, [gstFile, panFile, aadhaarFile, fssaiFile, isiFile]);

  React.useEffect(() => {
    console.log(`gstUrl ${gstUrl}`);
    console.log(`panUrl ${panUrl}`);
    console.log(`aadhaarUrl ${aadhaarUrl}`);
    console.log(`fssaiUrl ${fssaiUrl}`);
    console.log(`isiUrl ${isiUrl}`);
  }, [gstUrl, panUrl, aadhaarUrl, fssaiUrl, isiUrl]);

  React.useEffect(() => {
    console.log("filesDetails");
    console.log(filesDetails);
    if (gstFileFlag) {
      console.log("setting gst url");
      console.log(filesDetails.fileName);
      setGstUrl(filesDetails.fileName);
      setGstFileFlag(false);
    } else if (panFileFlag) {
      console.log("setting pan url");
      setPanUrl(filesDetails.fileName);
      setPanFileFlag(false);
    } else if (aadhaarFileFlag) {
      console.log("setting aadhaar url");
      setAadhaarUrl(filesDetails.fileName);
      setAadhaarFileFlag(false);
    } else if (fssaiFileFlag) {
      console.log("setting fssai url");
      setFssaiUrl(filesDetails.fileName);
      setFssaiFileFlag(false);
    } else if (isiFileFlag) {
      console.log("setting isi url");
      setIsiUrl(filesDetails.fileName);
      setIsiFileFlag(false);
    }
  }, [filesDetails]);

  React.useEffect(() => {
    // setFinalProducts(data1?.data);
  }, []);

  // React.useEffect(() => {
  //   setSelectedProduct(
  //     finalProducts?.filter(
  //       (item) => item.product_details?.id === selectedProductId
  //     )?.[0]
  //   );
  // }, [selectedProductId]);

  const handleCreateWFS = () => {
    const reqObj = {
      wfs_name: fillingStationName,
      area: area,
      pincode: pincode,
      wfs_owner: ownerName,
      gst_number: gstNumber,
      pan_number: panNumber,
      aadhaar_number: aadhaarNumber,
      fssai_number: fassaiNumber,
      isi_number: isiNumber,
      is_active: true,
      gst_file_url: gstUrl,
      pan_file_url: panUrl,
      aadhaar_file_url: aadhaarUrl,
      fssai_file_url: fssaiUrl,
      isi_file_url: isiUrl,
      email: email,
      org_name: orgName,
      role_name: roleName,
      password: password,
      phone: phone,
    };
    console.log(reqObj);
    const valuesFilled = Object.values(reqObj).every(
      (value) => value !== "" && value !== null
    );
    console.log(valuesFilled);
    dispatch(CreateWFS(reqObj));
    setTimeout(() => {
      // dispatch(GetWFS(pageNumber, pageSize, isActiveFilter, ascDesc));
    }, 1000);
  };

  const handleAddLines = () => {
    console.log("Handling Add Lines click");
    const reqObj = {
      wfs_id: parseInt(newWfsId),
    };
    dispatch(CreateWFSLines(reqObj));
    setTimeout(() => {
      dispatch(GetWFSLines(newWfsId));
    }, 1200);
  };

  const handleCreateBatch = () => {
    console.log("Handling creating batch click");
    const reqObj = {
      facility_id: selectFacility,
      material: material,
      capacity: capacity,
      quantity: quantity,
      line_id: lineId,
    };
    console.log("Req Obj");
    console.log(reqObj);
    dispatch(AddCreateBatch(reqObj));
  };

  // React.useEffect(() => {
  //   console.log(wfsId);
  //   if (wfsId !== "") {
  //     const reqObj = {
  //       wfs_id: wfsId,
  //     };
  //     dispatch(GetWFSLines(reqObj.wfs_id));
  //   }
  // }, [wfsId]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [material, setMaterial] = React.useState("");
  const [capacity, setCapacity] = React.useState("");

  const handleChangeProduct = (event) => {
    console.log(event.target.value);
    setSelectedProductId(event.target.value);
  };

  React.useEffect(() => {
    console.log(`Capacity: ${capacity}`);
    console.log(`Material: ${material}`);
  }, [capacity, material]);

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Header name="Water Station" />
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
          padding: "10px",
          marginBottom: "10px",
          width: "100%",
        }}
      >
        <Tabs
          variant="scrollable"
          value={value}
          onChange={handleChangeTabs}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab
            sx={{
              fontSize: { xs: "1.1rem", md: "1.3rem" },
              fontWeight: "bold",
            }}
            value="one"
            label="Create batch for filling"
          />
          <Tab
            sx={{
              fontSize: { xs: "1.1rem", md: "1.3rem" },
              fontWeight: "bold",
            }}
            value="two"
            label="Add filling station"
          />
        </Tabs>
      </Box>

      {value === "one" && (
        <>
          <CreateBatchForFilling
            selectFacility={selectFacility}
            setSelectFacility={setSelectFacility}
            allFacilities={allFacilities}
            selectedProductId={selectedProductId}
            setSelectedProductId={setSelectedProductId}
            finalProducts={finalProducts}
            lineId={lineId}
            setLineId={setLineId}
            quantity={quantity}
            setQuantity={setQuantity}
            isQuantityValid={isQuantityValid}
            setIsQuantityValid={setIsQuantityValid}
            handleCreateBatch={handleCreateBatch}
            batchList={batchList}
          />
        </>
      )}
      {value === "two" && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
            marginBottom: "10px",
          }}
        >
          <Tabs
            value={addValue}
            onChange={handleChangeTabs1}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
            sx={{
              color: colors.primary[400],
              indicatorColor: colors.blueAccent[400],
            }}
          >
            <Tab
              sx={{
                fontSize: { xs: "0.8rem", md: "1.1rem" },
                fontWeight: "bold",
              }}
              value="1"
              label="Add Lines To Facility"
            />
            <Tab
              sx={{
                fontSize: { xs: "0.8rem", md: "1.1rem" },
                fontWeight: "bold",
              }}
              value="2"
              label="Add IOT Device"
            />
            <Tab
              sx={{
                fontSize: { xs: "0.8rem", md: "1.1rem" },
                fontWeight: "bold",
              }}
              value="3"
              label="Link IOT To Facility Filling Lines"
            />
          </Tabs>
        </Box>
      )}

      {addValue === "1" && <AddLinesFacilty allFacilities={allFacilities} />}

      {addValue === "2" && <AddIOTDevices />}

      {addValue === "3" && (
        <LinkIOTtoFacilityFillingLines allFacilities={allFacilities} />
      )}
    </Box>
  );
}

const CreateBatchForFilling = ({
  selectFacility,
  setSelectFacility,
  allFacilities,
  selectedProductId,
  setSelectedProductId,
  finalProducts,
  lineId,
  setLineId,
  quantity,
  setQuantity,
  isQuantityValid,
  setIsQuantityValid,
  handleCreateBatch,
  batchList,
}) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleClose = () => {
    setOpenDialog(!openDialog);
  };

  return (
    <>
      <Box m="20px">
        <Box mr="10px" display="flex" justifyContent="flex-end">
          <Button
            color="secondary"
            endIcon={<BadgeIcon />}
            size="large"
            variant="contained"
            onClick={handleClose}
            sx={{ borderRadius: "10px" }}
          >
            Create Batch For Filling
          </Button>
        </Box>

        <BatchDetailsTable batchList={batchList} />
      </Box>

      {openDialog && (
        <OpenDialogCreateBatch
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          handleClose={handleClose}
          selectFacility={selectFacility}
          setSelectFacility={setSelectFacility}
          allFacilities={allFacilities}
          selectedProductId={selectedProductId}
          setSelectedProductId={setSelectedProductId}
          finalProducts={finalProducts}
          lineId={lineId}
          setLineId={setLineId}
          quantity={quantity}
          setQuantity={setQuantity}
          isQuantityValid={isQuantityValid}
          setIsQuantityValid={setIsQuantityValid}
          handleCreateBatch={handleCreateBatch}
        />
      )}
    </>
  );
};

const OpenDialogCreateBatch = ({
  openDialog,
  setOpenDialog,
  handleClose,
  selectFacility,
  setSelectFacility,
  allFacilities,
  selectedProductId,
  setSelectedProductId,
  finalProducts,
  lineId,
  setLineId,
  quantity,
  setQuantity,
  isQuantityValid,
  setIsQuantityValid,
  handleCreateBatch,
}) => {
  const dispatch = useDispatch();
  const [lineIds, setLineIds] = React.useState([]);
  const [productDetails, setProductDetails] = React.useState({
    material: "",
    capacity: "",
  });
  const addFillingLinesFacilities = useSelector(
    (state) => state.addFillingLinesFacilities
  );
  React.useEffect(() => {
    console.log("addFillingLinesFacilities");
    console.log(addFillingLinesFacilities);
    if (addFillingLinesFacilities.error === false) {
      setLineIds(addFillingLinesFacilities.lines.lineIds);
    }
  }, [addFillingLinesFacilities]);
  React.useEffect(() => {
    console.log("selectFacility");
    console.log(selectFacility);
    if (selectFacility !== "") {
      dispatch(GetLinesFacility(selectFacility));
    }
  }, [selectFacility]);

  const handleChangeProduct = (event) => {
    const productId = event.target.value;
    setSelectedProductId(productId);
    const selectedProduct = finalProducts.find(
      (item) => item.product_details.id === productId
    );
    if (selectedProduct) {
      setProductDetails({
        material: selectedProduct.product_details.material,
        capacity: selectedProduct.product_details.capacity,
      });
    }
  };

  React.useEffect(() => {
    console.log("productDetails");
    console.log(productDetails);
  }, [productDetails]);

  const handleCreateBatchClick = () => {
    const reqObj = {
      facility_id: selectFacility,
      material: productDetails.material,
      capacity: productDetails.capacity,
      quantity: quantity,
      line_id: lineId,
    };
    console.log("reqObj");
    console.log(reqObj);
    dispatch(AddCreateBatch(reqObj));
    setOpenDialog(false);
    setTimeout(() => {
      dispatch(GetCreateBatch(reqObj));
    }, 1000);
  };

  return (
    <Dialog open={openDialog} onClose={handleClose}>
      <Box p="10px" display="flex" boxShadow={3} justifyContent="space-between">
        <Typography fontWeight="bold" m="10px" sx={{ fontSize: "15px" }}>
          Create Batch For Filling
        </Typography>
        <IconButton edge="start" color="inherit" aria-label="close">
          <CloseIcon onClick={handleClose} />
        </IconButton>
      </Box>
      <Paper elevation={23}>
        <Box sx={{ padding: "20px" }}>
          <Box sx={{ padding: "10px", minWidth: 150 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Facility
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectFacility}
                label="Select Facility"
                onChange={(event) => setSelectFacility(event.target.value)}
              >
                {allFacilities?.map((facility) => {
                  return (
                    <MenuItem key={facility.id} value={facility.id}>
                      <Typography
                        style={{ fontWeight: "bold", fontSize: "20px" }}
                      >
                        {facility.org_name}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ padding: "10px", minWidth: 150 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Products
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Products"
                value={selectedProductId}
                onChange={handleChangeProduct}
              >
                {finalProducts?.map((item) => {
                  return (
                    <MenuItem
                      key={item?.product_details?.id}
                      value={item?.product_details?.id}
                    >
                      <Box>
                        <Typography
                          style={{ fontWeight: "bold", fontSize: "20px" }}
                        >
                          {item?.product_details?.name}
                        </Typography>
                        <Typography>
                          Capacity: {item?.product_details?.capacity} /
                          Material: {item?.product_details?.material}
                        </Typography>
                      </Box>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ padding: "10px", minWidth: 150 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Line ID
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Line ID"
                value={lineId}
                onChange={(e) => setLineId(e.target.value)}
              >
                {lineIds?.map((line) => {
                  return (
                    <MenuItem key={line} value={line}>
                      <Typography
                        style={{ fontWeight: "bold", fontSize: "20px" }}
                      >
                        {line}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ padding: "10px" }}>
            <CustomInputTextField
              placeholder="Quantity"
              value={quantity}
              setValue={setQuantity}
              isValid={isQuantityValid}
              setIsValid={setIsQuantityValid}
              type={appMeta.textFieldTypes.NUMBER}
            />
            {/* <TextField
              size="small"
              value={quantity}
              onChange={(event) => setQuantity(event.target.value)}
            /> */}
          </Box>
          <Box sx={{ padding: "10px" }}>
            <CustomButton
              label="Create Batch"
              handleClick={handleCreateBatchClick}
              variant="contained"
            />
          </Box>
        </Box>
      </Paper>
    </Dialog>
  );
};

const Header = ({ name }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box>
      <Typography
        color={colors.grey[100]}
        fontWeight="bold"
        m="15px"
        variant="h2"
      >
        {name}
      </Typography>
    </Box>
  );
};
