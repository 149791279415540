/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Showing the list of roles in form of table
 * @date 26/05/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import * as React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import EditRolesDialogBox from "./EditRolesDialogBox";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  TablePagination,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import PersonAddRoundedIcon from "@mui/icons-material/PersonAddRounded";

//
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AppBar, { appBarClasses } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import { toast } from "react-toastify";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useDispatch } from "react-redux";
import { GetRoles } from "../../../Redux/Actions/rolesAction";
import FilterDialog from "../FilterDialog";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import { appColors } from "../../../AppMeta/appColors";
import CustomDialogMoreDetails from "../../../Components/CustomComp/CustomDialogMoreDetails";
import { tokens } from "../../../AppMeta/theme";
export default function RolesListTable({
  listOfRoles,
  setAddNewRoleDialog,
  lastPageNumber,
  pageNumber,
  setPageNumber,
  setPageSize,
  pageSize,
  ascOrDesc,
  setAscOrDesc,
  setIsActive,
  isActive,
  setIsUpdate,
  isUpdate,
  setOpenConfirmationDialog,
  isConfirmed,
  setIsConfirmed,
  setConfirmationDescription,
}) {
  const dispatch = useDispatch();
  const [openCustomDialog, setOpenCustomDialog] = React.useState(false);
  const [moreDetails, setMoreDetails] = React.useState({});
  const [currentMode, setCurrentMode] = React.useState(
    localStorage.getItem("theme")
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [getOrders, setGetOrders] = React.useState([]);

  const [isFilterOpen, setIsFilterOpen] = React.useState(false);


  React.useEffect(() => {
    console.log(localStorage);
    console.log(currentMode);
  }, [currentMode, localStorage]);

  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          columnHeader: {
            backgroundColor: "#5c6bc0", // Set the desired background color here
            // color: "blue", // Set the desired text color here
            position: "sticky",
            marginTop: "0 !important",
          },
        },
      },
    },
  });
  console.log(listOfRoles);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [openEditRolesDialog, setOpenEditRolesDialog] = React.useState(false);
  const [roleUpdateBtn, setRoleUpdateBtn] = React.useState(false);

  // const rows = listOfRoles.map((item, index) => ({
  //   id: index + 1,
  //   ...item,
  // }));

  // Function to extract data and create rows for the table
  function createData(
    roleName,
    description,
    isActive,
    isDeleted,
    fullname,
    phone,
    email
  ) {
    return {
      roleName,
      description,
      isActive,
      isDeleted,
      fullname,
      phone,
      email,
    };
  }
  const rows = listOfRoles.map((item) =>
    createData(
      item.role_name,
      item.description,
      item.is_active,
      item.is_deleted,
      item.fullname,
      item.phone,
      item.email
    )
  );
  const handleRowClick = (row, isButtonClicked) => {
    setSelectedRow(row);
    // setMoreDetails(row);
    console.log(isButtonClicked);
    console.log(row);
    if (isButtonClicked) {
      setOpenCustomDialog(true);
    } else {
      setOpenEditRolesDialog(true);
    }
  };
  const handleShowMore = (details) => {
    console.log(details);
    // console.log(generatedByDetails);
    setOpenCustomDialog(true);
    setMoreDetails(details);
  };
  const handleChangeStatus = (event, newAlignment) => {
    setIsActive(newAlignment);
  };
  const handleChangeAscDesc = (event, newAlignment) => {
    setAscOrDesc(newAlignment);
  };

  // Popover compact
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const theme1 = useTheme();
  const colors = tokens(theme1.palette.mode)


  const handleShowMoreClick = () => {
    setOpenCustomDialog(true);
  };
  const CustomToolbar = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
        }}
      >
        <Box sx={{ padding: "10px" }}>
          <CustomButton
            variant="outlined"
            color="primary"
            label="Add new role"
            handleClick={() => setAddNewRoleDialog(true)}
            endIcon={<PersonAddRoundedIcon />}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            // flexDirection: "column",
            gap: "20px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box>
            <ToggleButtonGroup
              size="small"
              color="primary"
              value={isActive}
              exclusive
              onChange={handleChangeStatus}
              aria-label="Platform"
            >
              <ToggleButton value={true}>Active</ToggleButton>
              <ToggleButton value={false}>Inactive</ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box>
            {" "}
            <ToggleButtonGroup
              size="small"
              color="primary"
              value={ascOrDesc}
              exclusive
              onChange={handleChangeAscDesc}
              aria-label="Platform"
            >
              <ToggleButton value="ASC">Ascending</ToggleButton>
              <ToggleButton value="DESC">Descending</ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box>
            {/* <CustomButton
              label="Show More Details"
              variant="outlined"
              handleClick={handleShowMoreClick}
            /> */}
          </Box>
        </Box>
      </Box>
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getOrders.length) : 0;
  return (
    <Box
    // sx={{
    //   display: "flex",
    //   flexWrap: "wrap",
    //   flexDirection: "column",
    //   alignItems: "center",
    //   justifyContent: "center",
    //   // width: "90vw",
    // }}
    >
      {/* <Toolbar>
        <CustomToolbar />
      </Toolbar> */}

      <Box
        sx={{
          display: "flex",


          justifyContent: "flex-end",
          padding: "10px",
        }}
      >
        <Box marginRight="10px">
          <Button
            variant="contained"
            color="secondary"
            label="Add new role"
            handleClick={() => setAddNewRoleDialog(true)}
            endIcon={<PersonAddRoundedIcon />}
          >Add new role</Button>
        </Box>
      </Box>
      <Box sx={{ padding: "20px" }}>
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "10px",
          }}

        >
          <Table
            aria-label="simple table"
            size="small"
          // stickyHeader={true}
          >
            <TableHead sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.primary[300],
              boxShadow: 2,
            }}>
              <TableRow>
                <TableCell sx={{
                  fontWeight: "bold",
                  textAlign: "start",
                  fontSize: { xs: "0.9rem", md: "1.1rem" },
                }}>Role Name</TableCell>
                <TableCell sx={{
                  fontWeight: "bold",
                  textAlign: "start",
                  fontSize: { xs: "0.9rem", md: "1.1rem" },
                }}>Description</TableCell>
                <TableCell sx={{
                  fontWeight: "bold",
                  textAlign: "start",
                  fontSize: { xs: "0.9rem", md: "1.1rem" },
                }}>Status</TableCell>
                <TableCell sx={{
                  fontWeight: "bold",
                  textAlign: "start",
                  fontSize: { xs: "0.9rem", md: "1.1rem" },
                }}>More details</TableCell>

                {/* <TableCell size="small">Fullname</TableCell>
              <TableCell size="small">Phone</TableCell> 
              <TableCell size="small">Email</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? rows.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ) : rows).map((row) => (
                  <TableRow
                    key={row.roleName}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                    onClick={() => handleRowClick(row, false)}
                  >
                    <TableCell sx={{
                      fontSize: { xs: "0.8rem", md: "0.9rem" },
                      backgroundColor: colors.primary[600],
                      textAlign: "start",
                    }}>
                      {row.roleName}
                    </TableCell>
                    <TableCell sx={{
                      fontSize: { xs: "0.8rem", md: "0.9rem" },
                      backgroundColor: colors.primary[600],
                      textAlign: "start",
                    }}>{row.description}</TableCell>
                    <TableCell sx={{
                      fontSize: { xs: "0.8rem", md: "0.9rem" },
                      backgroundColor: colors.primary[600],
                      textAlign: "start",
                    }}>
                      {row.isActive === true ? "Active" : "Inactive"}
                    </TableCell>
                    <TableCell sx={{
                      fontSize: { xs: "0.8rem", md: "0.9rem" },
                      backgroundColor: colors.primary[600],
                      textAlign: "start",
                    }}>
                      <Button
                        variant="contained"
                        color="info"

                        onClick={(e) => {
                          e.stopPropagation();
                          handleRowClick(row, true);
                        }}
                      >
                        Show More
                      </Button>
                    </TableCell>
                    {/* <TableCell>{row.fullname}</TableCell>
                <TableCell>{row.phone}</TableCell>
                <TableCell>{row.email}</TableCell> */}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>

      {
        openEditRolesDialog && (
          <EditRolesDialogBox
            selectedRow={selectedRow}
            setOpenEditRolesDialog={setOpenEditRolesDialog}
            openEditRolesDialog={openEditRolesDialog}
            pageNumber={pageNumber}
            pageSize={pageSize}
            isActive={isActive}
            ascOrDesc={ascOrDesc}
            setIsUpdate={setIsUpdate}
            setOpenConfirmationDialog={setOpenConfirmationDialog}
            isConfirmed={isConfirmed}
            isUpdate={isUpdate}
            setConfirmationDescription={setConfirmationDescription}
            setRoleUpdateBtn={setRoleUpdateBtn}
            setIsConfirmed={setIsConfirmed}
          />
        )
      }
      {/* <h1>Hello World</h1>
      <table>
        <th>
          <td>Test</td>
          <td>Test2</td>
          <td>Test3</td>
        </th>
        <tr>
          <td>1sd</td>
          <td>2sd</td>
          <td>3sd</td>
        </tr>
        <tr>
          <td>1sd</td>
          <td>2sd</td>
          <td>3sd</td>
        </tr>
        <tr>
          <td>1sd</td>
          <td>2sd</td>
          <td>3sd</td>
        </tr>
        <tr>
          <td>1sd</td>
          <td>2sd</td>
          <td>3sd</td>
        </tr>
        <tr>
          <td>1sd</td>
          <td>2sd</td>
          <td>3sd</td>
        </tr>
        <tr>
          <td>1sd</td>
          <td>2sd</td>
          <td>3sd</td>
        </tr>
      </table> */}
      {
        isFilterOpen && (
          <FilterDialog
            setIsFilterOpen={setIsFilterOpen}
            isFilterOpen={isFilterOpen}
            isActive={isActive}
            setIsActive={setIsActive}
            ascOrDesc={ascOrDesc}
            setAscOrDesc={setAscOrDesc}
          />
        )
      }

      {
        openCustomDialog && (
          <CustomDialogMoreDetails
            openCustomDialog={openCustomDialog}
            setOpenCustomDialog={setOpenCustomDialog}
            title="More details"
            moreDetails={moreDetails}
          />
        )
      }
    </Box >
  );
}
