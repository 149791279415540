/**
 * @author Pravesh
 * @description All Meta/Constats Constants which are mentioned for Local
 * @date 01/06/23
 * @version 0.0.1 - Initial Release
 * @param {}
 */

const appMeta = {
  textFieldTypes: {
    PINCODE: 100,
    FULL_NAME: 200,
    EMAIL: 300,
    PASSWORD: 400,
    SERVICE_AREA: 500,
    PHONE: 600,
    EMAIL_PHONE: 700,
    TEXT: 800,
    NUMBER: 900,
  },
};

export default appMeta;
