/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Reducers for Login and signup
 * @date 26/05/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries
import actionType from "../actionTypes";

const initialState = {
  error: true,
  isLoading: true,
  role_id: "",
  org_id: "",
  token: localStorage.getItem("token"),
  verifyError: true,
  changePasswordError: true,
};

const authenticationReducer = (state = initialState, action) => {
  console.log(action);
  switch (action.type) {
    case actionType.SIGN_UP:
      return {
        ...state,
        error: action.payload.error,
        data: action.payload.data,
      };
    case actionType.LOGIN:
      return {
        ...state,
        error: action.payload.error,
        token: action.payload.token,
        role_id: action.payload.role_id,
        org_id: action.payload.org_id,
      };
    case actionType.RESET_PASSWORD_VERIFY:
      return {
        ...state,
        verifyError: action.payload.error,
        isLoading: false,
      };
    case actionType.FORGOT_PASSWORD_USER_VERIFY_OTP:
      return {
        ...state,
        changePasswordError: action.payload.error,
        isLoading: false,
      };
    case actionType.SIGN_OUT:
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");

    default:
      return state;
  }
};

export default authenticationReducer;
