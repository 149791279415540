import { Reducer } from "redux";
import actionType from "../actionTypes";

const initialState = {
  error: false,
  msg: "",
  batchData: [],
};

const createBatchReducer = (state = initialState, action) => {
  // console.log(`action type: ${action.type}`);
  console.log(action);
  switch (action.type) {
    case actionType.ADD_CREATE_BATCH:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.GET_CREATE_BATCH:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        batchData: action.payload.data,
      };
    case actionType.PUT_CREATE_BATCH:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.CANCEL_CREATE_BATCH:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.START_BATCH:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.STOP_BATCH:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };

    default:
      return state;
  }
};

export default createBatchReducer;
