/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Reducers for creating, updating and adding roles list 
 * @date 26/05/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import { Reducer } from "redux";
import actionType from "../actionTypes";

const initialState = {
  role_name: "",
  description: "",
  rolesList: [],
  error: false,
  msg: "",
};

const rolesReducer = (state = initialState, action) => {
  // console.log(`action type: ${action.type}`);
  console.log(action);
  switch (action.type) {
    case actionType.ADD_ROLE:
      return {
        ...state,
        role_name: action.payload.role_name,
        description: action.payload.description,
      };
    case actionType.GET_ROLES:
      return {
        ...state,
        rolesList: action.payload.data,
      };
    case actionType.UPDATE_ROLES:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    default:
      return state;
  }
};

export default rolesReducer;
