/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Dashboard with app bar and routing the main component from here
 * @date 26/05/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Link,
  Switch,
  Route,
  useHistory,
  Redirect,
} from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import { pink } from "@mui/material/colors";

import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import SettingsApplicationsRoundedIcon from "@mui/icons-material/SettingsApplicationsRounded";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import bwLogo from "../../Assests/ImagesAndIcons/bwLogo.png";
// import RolesManagement from "../Roles/RolesManagement";
import RolesManagement from "../AccessManagement/Roles/RolesManagement";
import AccessManagement from "../AccessManagement/AccessManagement";
import AuthenticationPage from "../Authentication/Authentication";
import { useDispatch } from "react-redux";
import actionTypes from "../../Redux/actionTypes";
// import OrganizationDetails from "../Organization/OrganizationDetails";

import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useThemeContext } from "../../AppMeta/ThemeContext";
import { toast } from "react-toastify";
import AccessManagementTabs from "../AccessManagement/AccessManagementTabs";
import AccessManagementTabs1 from "../AccessManagement/AccessManagementTabs1";
import { appColors } from "../../AppMeta/appColors";
import FillingStation from "../FillingStation/FillingStation";
import GenerateQRBarcode from "../Home/GenerateQRBarcode";
// import BookingToManufacturer from "../Home/BookingToManufacturer/BookingToManufacturer";
import NewCansStockBW from "../Home/BookingToManufacturer/NewCanStockBW";
import Products from "../Products/Products";

// import AccessManagementTabs2 from "../AccessManagement/AccessManagementTabs2";

const pages = [
  { title: "Home", path: "/" },
  { title: "Access Management", path: "/access-management" },
  { title: "Filling Station", path: "filling-station" },
  { title: "Products", path: "products" },
  // { title: "Organization", path: "/organization" },
];

const settings = [
  { title: "Profile", path: "/profile" },
  { title: "Account", path: "/account" },
  { title: "Dashboard", path: "/dashboard" },
  { title: "Logout", path: "/authentication" },
];

function Dashboard({ isDarkMode, setIsDarkMode }) {
  // const { isDarkMode, setIsDarkMode } = useThemeContext();

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isLogout, setIsLogout] = React.useState(false);
  const [title, setTitle] = React.useState("Home");
  const [themeToggle, setThemeToggle] = React.useState("dark");

  const dispatch = useDispatch();
  const history = useHistory();
  const handleThemeToggle = (event, newAlignment) => {
    console.log(newAlignment);
    setThemeToggle(newAlignment);
  };
  React.useEffect(() => {
    console.log(themeToggle);
    if (themeToggle) {
      setIsDarkMode(true);
    } else {
      setIsDarkMode(false);
    }
  }, [themeToggle]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    console.log("Check");
  };

  const handleNavigation = (title) => {
    // history.push("/access-management");
    console.log(title);
    setTitle(title);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleProfile = (title) => {
    if (title === "Logout") {
      setIsLogout(true);
      dispatch({
        type: actionTypes.SIGN_OUT,
      });
    } else {
      toast.error("Implementation in progress", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  if (isLogout) {
    return <Redirect to="authenticate" />;
  }

  return (
    <Box>
      <Router>
        <AppBar position="static" sx={{ backgroundColor: appColors.primary }}>
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <img
                alt="BW-LOGO"
                src={bwLogo}
                style={{ height: "50px", weigth: "10px" }}
              />
              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  {pages.map((page) => (
                    <MenuItem
                      key={page.title}
                      onClick={handleCloseNavMenu}
                      component={Link}
                      to={page.path}
                    >
                      <Typography textAlign="center">{page.title}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              {/* <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} /> */}

              <Box
                sx={{
                  flexGrow: 1,
                  display: {
                    xs: "none",
                    md: "flex",
                    gap: "20px",
                    marginLeft: "20px",
                  },
                }}
              >
                {pages.map((page) => (
                  <Button
                    variant="outlined"
                    key={page.title}
                    component={Link}
                    to={page.path}
                    onClick={() => handleNavigation(page.title)}
                    sx={{
                      my: 2,
                      color: page.title === title ? "black" : "white",
                      display: "block",
                      backgroundColor: page.title === title ? "white" : "",
                    }}
                  >
                    {page.title}
                  </Button>
                ))}
              </Box>

              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <SettingsApplicationsRoundedIcon sx={{ color: "white" }} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <>
                      <MenuItem
                        key={setting.title}
                        onClick={() => handleProfile(setting.title)}
                        // component={Link}
                        // to={setting.path}
                      >
                        <Typography textAlign="center">
                          {setting.title}
                        </Typography>
                      </MenuItem>
                    </>
                  ))}

                  <Box sx={{ padding: "10px" }}>
                    {/* <Button onClick={() => setIsDarkMode(!isDarkMode)}>
                    Theme
                  </Button> */}
                    <ToggleButtonGroup
                      value={themeToggle}
                      exclusive
                      onChange={handleThemeToggle}
                      aria-label="text alignment"
                    >
                      <ToggleButton value={true} aria-label="left aligned">
                        <DarkModeRoundedIcon />
                      </ToggleButton>
                      <ToggleButton value={false} aria-label="centered">
                        <LightModeRoundedIcon />
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                  <Typography textAlign="center" sx={{ fontWeight: "bold" }}>
                    Version: 0.0.5
                  </Typography>
                </Menu>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
        <Switch>
          <Route exact path="/">
            {/* Render the home component */}
            {/* <GenerateQRBarcode />  */}
            {/* <BookingToManufacturer /> */}
            {/* <NewCansStockBW /> */}
          </Route>

          <Route
            path="/access-management"
            exact
            // component={AccessManagementTabs}
            component={AccessManagementTabs1}
            // component={AccessManagementTabs2}
          />
          <Route path="authentication" exact component={AuthenticationPage} />
          <Route path="/filling-station" exact component={FillingStation} />

          <Route path="/organisation-order-management" />
          <Route path="/products" exact component={Products} />
          <Route path="/account">{/* Render the account component */}</Route>
          <Route path="/dashboard">
            {/* Render the dashboard component */}
          </Route>
          <Route path="/logout">{/* Render the logout component */}</Route>
        </Switch>
      </Router>
    </Box>
  );
}

export default Dashboard;
