import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Description } from "@mui/icons-material";

export default function CustomConfirmationDialog({
  openConfirmationDialog,
  setOpenConfirmationDialog,
  description,
  setIsConfirmed,
}) {
  const handleNo = () => {
    setOpenConfirmationDialog(false);
    setIsConfirmed(false);
  };

  const handleYes = () => {
    console.log("Yes");
    setIsConfirmed(true);

  };


  return (
    <React.Fragment>
      <Dialog
        open={openConfirmationDialog}
        onClose={handleNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNo}>No</Button>
          <Button onClick={handleYes} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
