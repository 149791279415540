import { Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { AddCratesAction } from "../../Redux/Actions/addCratesAction";

function AddCrates() {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [capacity, setCapacity] = useState("");
  const [price, setPrice] = useState("");
  const [hsnCode, setHsnCode] = useState("");
  const [cgst, setCgst] = useState("");
  const [sgst, setSgst] = useState("");
  const [igst, setIgst] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [error,setError] = React.useState('');
  const [itemCgst, setItemCgst] = useState("");
  const [itemSgst, setItemSgst] = useState("");
  const [itemIgst, setItemIgst] = useState("");
  const [error1,setError1] = React.useState('');
  const [error2,setError2] = React.useState('');
  const [error3,setError3] = React.useState('');

  const handleAddCrates = () => {
    console.log("addCrates");
    const reqObj = {
      name: name,
      capacity: capacity,
      description: description,
      price: price,
      image: image,
      hsn_code: hsnCode,
      cgst_perc: itemCgst,
      sgst_perc: itemSgst,
      igst_perc: itemIgst,
    };
    dispatch(AddCratesAction(reqObj));

 
  };
  

  const handlePriceChange = (event) => {
    const newValue = event.target.value;
    if (newValue < 0) {
      setError2('Negative values are not allowed');
    } else {
      setPrice(newValue);
      setError2('');
    }
  }

  const handleHSNChange = (event) => {
    const newValue = event.target.value;
    if (newValue < 0) {
      setError3('Negative values are not allowed');
    } else {
      setHsnCode(newValue);
      setError3('');
    }
  }
  
  return (
    <Box>
      <TextField
        autoFocus
        margin="dense"
        id="name"
        label="Name"
        type="text"
        fullWidth
        variant="standard"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <TextField
        margin="dense"
        id="capacity"
        label="Capacity"
        type="number"
        fullWidth
        variant="standard"
        value={capacity}
        onChange={(e) => setCapacity(e.target.value)}
      />
      <TextField
        margin="dense"
        id="price"
        label="Price"
        type="number"
        fullWidth
        variant="standard"
        value={price}
        error={error2 !== ''}
        helperText={error2}
        onChange={handlePriceChange}
      />
      <TextField
        margin="dense"
        id="hsn_code"
        label="HSN Code"
        type="text"
        fullWidth
        variant="standard"
        value={hsnCode}
        error={error3 !== ''}
        helperText={error3}
        onChange={handleHSNChange}
      />
      <TextField
        margin="dense"
        id="cgst_perc"
        label="CGST (%)"
        fullWidth
        variant="standard"
        value={itemCgst}
        onChange={(e)=> setItemCgst(e.target.value)}
       
        
      />
      <TextField
        margin="dense"
        id="sgst_perc"
        label="SGST (%)"

        fullWidth
        variant="standard"
        value={itemSgst}
        onChange={(e) => setItemSgst(e.target.value)}
       
        
        
      />
      <TextField
        margin="dense"
        id="igst_perc"
        label="IGST (%)"
        fullWidth
        variant="standard"
        value={itemIgst}
        onChange={(e) => setItemIgst(e.target.value)}

      />
      <TextField
        margin="dense"
        id="description"
        label="Description"
        type="text"
        fullWidth
        variant="standard"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <Button variant="contained" onClick={handleAddCrates}>
        Add Crates
      </Button>
    </Box>
  );
}

export default AddCrates;
