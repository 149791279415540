import { Box, Tab, Tabs, Typography, useTheme } from "@mui/material";
import React from "react";
import AllProductsTable from "../Orders/PurchaseProduct/AllProductsTable";
import { useDispatch, useSelector } from "react-redux";
import ManufacturerTable from "../Products/ManufacturerTable";
import ProductPurchase from "./ProductPurchase";
import PurchaseProductStepper from "./PurchaseProductStepper";
import { GetManuFacturer } from "../../Redux/Actions/ManufacturersAction";
import SKUManagementApp from "./SkuDemo";
import {
  GetComponents,
  GetProductSku,
  GetRawMaterials,
} from "../../Redux/Actions/ProductComponentsAction";
import PurchaseSKU from "./PurchaseSKU";
import { GetSkuStocks } from "../../Redux/Actions/purchaseProductsAction";
import { tokens } from "../../AppMeta/theme";
import { GetFinalProductSKU } from "../../Redux/Actions/productsAction";

// function CustomTabLabel({ label, ...props }) {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   return (
//     <Typography color={colors.primary[400]} {...props}>
//       {label}
//     </Typography>
//   );
// }

function Sku() {
  const productDetails = useSelector((state) => state.productComponentsData);
  const productData = useSelector((state) => state.productData);
  const pincodesAndAreasData = useSelector(
    (state) => state.pincodesAndAreasData
  );
  const purchaseProductDetails = useSelector(
    (state) => state.purchaseProductData
  );
  const manufacturerDetails = useSelector((state) => state.manufacturerData);
  const cratesData = useSelector((state) => state.cratesData);
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);

  const [pageNumber, setPageNumber] = React.useState(1);
  const [lastPageNumber, setLastPageNumber] = React.useState(null);
  const [pageSize, setPageSize] = React.useState(25);
  const [createdAtSort, setCreatedAtSort] = React.useState("ASC");
  const [available, setAvailable] = React.useState(true);
  const [allProducts, setAllProducts] = React.useState([]);

  //   add products variables
  const [productType, setProductType] = React.useState("");
  const [productName, setProductName] = React.useState("");
  const [productPrice, setProductPrice] = React.useState("");
  const [lifeSpan, setLifeSpan] = React.useState("");
  const [productMaterial, setProductMaterial] = React.useState("");
  const [isConfirmed, setIsConfirmed] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState("");

  // manufacturer data
  const [manufacturersList, setManufacturersList] = React.useState([]);
  const [allRawMaterials, setAllRawMaterials] = React.useState([]);
  const [allFinishedGoods, setAllFinishedGoods] = React.useState();

  const [rawMaterialsStock, setRawMaterialsStock] = React.useState([]);
  const [finishedGoodStock, setFinishedGoodStock] = React.useState([]);
  const [finalProductStock, setFinalProductStock] = React.useState([]);

  const [allCrates, setAllCrates] = React.useState([]);
  const [allPincodesAndAreas, setAllPincodesAndAreas] = React.useState([]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  React.useEffect(() => {
    dispatch(GetSkuStocks());
  }, []);

  React.useEffect(() => {
    console.log("pincodesAndAreasData");
    console.log(pincodesAndAreasData);
    setAllPincodesAndAreas(pincodesAndAreasData.pincodesAndAreas);
  }, [pincodesAndAreasData]);

  React.useEffect(() => {
    console.log("cratesData");
    console.log(cratesData);
    setAllCrates(cratesData.crates);
  }, [cratesData]);

  React.useEffect(() => {
    console.log("productData");
    console.log(productData);
    setAllProducts(productData?.finalProduct);
  }, [productData]);

  React.useEffect(() => {
    console.log("Product details");
    console.log(productDetails);
    if (productDetails.error === false) {
      // setAllProducts(productDetails?.products);
      setAllRawMaterials(productDetails?.rawMaterial);
      setAllFinishedGoods(productDetails?.components);
    }
  }, [productDetails]);

  React.useEffect(() => {
    console.log("purchaseProductDetails");
    console.log(purchaseProductDetails);
    purchaseProductDetails?.skuStock?.map((stocks) => {
      if (stocks.product_type === "100") {
        setRawMaterialsStock((prev) => [...prev, stocks]);
      } else if (stocks.productType === "200") {
        setFinishedGoodStock((prev) => [...prev, stocks]);
      } else if (stocks.product_type === "300") {
        setFinalProductStock((prev) => [...prev, stocks]);
      }
    });
  }, [purchaseProductDetails]);

  React.useEffect(() => {
    console.log("rawMaterialsStock");
    console.log(rawMaterialsStock);
    console.log("finishedGoodStock");
    console.log(finishedGoodStock);
    console.log("finalProductStock");
    console.log(finalProductStock);
  }, [rawMaterialsStock, finalProductStock, finishedGoodStock]);

  React.useEffect(() => {
    console.log("manufacturerDetails");
    console.log(manufacturerDetails);
    if (manufacturerDetails.error === false) {
      setManufacturersList(manufacturerDetails?.manufacturers?.data);
    }
  }, [manufacturerDetails]);

  React.useEffect(() => {
    dispatch(GetManuFacturer(pageNumber, pageSize, available, createdAtSort));
    dispatch(GetRawMaterials(pageNumber, pageSize, available, createdAtSort));
    dispatch(GetFinalProductSKU(pageNumber, pageSize, available, createdAtSort));
    // dispatch(GetProductSku(pageNumber, pageSize, available, createdAtSort));
  }, [pageNumber, pageSize, available, createdAtSort]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box width="100%">
      <Header />
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
          padding: "10px",
          marginBottom: "10px",
          width: "100%",
        }}
      >
        <Tabs
          variant="scrollable"
          allowScrollButtonsMobile
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"

        >
          <Tab
            sx={{
              fontSize: { xs: "0.8rem", md: "1.3rem" },
              fontWeight: "bold",
            }}
            label="SKU"
          />
          <Tab
            sx={{
              fontSize: { xs: "0.8rem", md: "1.3rem" },
              fontWeight: "bold",
            }}
            label="Suppliers / Manufacturers"
          />
          <Tab
            sx={{
              fontSize: { xs: "0.8rem", md: "1.3rem" },
              fontWeight: "bold",
            }}
            label="Purchase Products"
          />
        </Tabs>
      </Box>

      {value === 0 && (
        <>
          <SKUManagementApp
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPageNumber={lastPageNumber}
            createdAtSort={createdAtSort}
            available={available}
            setAvailable={setAvailable}
            setCreatedAtSort={setCreatedAtSort}
            allRawMaterials={allRawMaterials}
            allFinishedGoods={allFinishedGoods}
            allProducts={allProducts}
            productType={productType}
            productName={productName}
            productPrice={productPrice}
            lifeSpan={lifeSpan}
            productMaterial={productMaterial}
            setProductType={setProductType}
            setProductName={setProductName}
            setProductPrice={setProductPrice}
            setLifeSpan={setLifeSpan}
            setProductMaterial={setProductMaterial}
            setIsConfirmed={setIsConfirmed}
            isConfirmed={isConfirmed}
            setSelectedProduct={setSelectedProduct}
            allCrates={allCrates}
            allPincodesAndAreas={allPincodesAndAreas}
          />
        </>
      )}

      {value === 1 && (
        <>
          <ManufacturerTable
            manufacturersList={manufacturersList}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPageNumber={lastPageNumber}
            createdAtSort={createdAtSort}
            available={available}
            setAvailable={setAvailable}
            setCreatedAtSort={setCreatedAtSort}
          />
        </>
      )}

      {value === 2 && (
        <>
          <PurchaseProductStepper
            allProducts={allProducts}
            manufacturersList={manufacturersList}
            allRawMaterials={allRawMaterials}
            allFinishedGoods={allFinishedGoods}
            rawMaterialsStock={rawMaterialsStock}
          />
          {/* <Box
            sx={{ 
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PurchaseSKU
              manufacturersList={manufacturersList}
              allProducts={allProducts}
              allRawMaterials={allRawMaterials}
              allFinishedGoods={allFinishedGoods}
            />
          </Box> */}
        </>
      )}
    </Box>
  );
}

export default Sku;

const Header = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box>
      <Typography
        color={colors.grey[100]}
        fontWeight="bold"
        mt="20px"
        ml="10px "
        variant="h2"
      >
        Stock Keeping Unit
      </Typography>
    </Box>
  );
};
