/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description User details table in form of list.
 * @date 26/07/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
} from "@mui/material";
import GroupAddRoundedIcon from "@mui/icons-material/GroupAddRounded";
import CustomButton from "../../../Components/CustomComp/CustomButton";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { EditUserRolesDomainDetails } from "../../../Redux/Actions/addUsersDomainRolesAction";
import EditUserDetailsDialog from "./EditUserDetailsDialog";
import { appColors } from "../../../AppMeta/appColors";

export default function UserDetailsTable({
  userDetailsList,
  setPageNumber,
  setPageSize,
  setAscOrDesc,
  setIsActive,
  pageNumber,
  pageSize,
  ascOrDesc,
  isActive,
  setAddUserDialog,
  lastPageNumber,
}) {
  // Required variables declared here
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);

  React.useEffect(() => {
    console.log(userDetailsList);
  }, [userDetailsList]);

  // Active status toggle function
  const handleChangeStatus = (event, newAlignment) => {
    setIsActive(newAlignment);
  };

  // Ascending and desending toggle function
  const handleChangeAscDesc = (event, newAlignment) => {
    setAscOrDesc(newAlignment);
  };

  // Pagenumber
  const pageNumberForward = () => {
    if (userDetailsList.length > 0) {
      setPageNumber(pageNumber + 1);
      // setSearchOrderFlag(true);
    }
  };

  const pageNumberBackward = () => {
    if (pageNumber - 1 > 0) {
      setPageNumber(pageNumber - 1);
      // setdisableBtn(false);
      // setSearchOrderFlag(true);
    }
  };

  const handleClickRow = (row) => {
    setSelectedRow(row);
    setOpenEditDialog(true);
  };

  // Custom tool bar to show the filter options for the displaying the users data in table
  const CustomToolbar = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
        }}
      >
        <Box sx={{ padding: "10px" }}>
          <CustomButton
            variant="outlined"
            color="primary"
            label="Add New User"
            handleClick={() => setAddUserDialog(true)}
            endIcon={<GroupAddRoundedIcon />}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            // flexDirection: "column",
            gap: "20px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <FilterListRoundedIcon
                sx={{ cursor: "pointer" }}
                onClick={handleFilterOpen}
              /> */}
          <Box>
            <ToggleButtonGroup
              size="small"
              color="primary"
              value={isActive}
              exclusive
              onChange={handleChangeStatus}
              aria-label="Platform"
            >
              <ToggleButton value={true}>Active</ToggleButton>
              <ToggleButton value={false}>Inactive</ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box>
            {" "}
            <ToggleButtonGroup
              size="small"
              color="primary"
              value={ascOrDesc}
              exclusive
              onChange={handleChangeAscDesc}
              aria-label="Platform"
            >
              <ToggleButton value="ASC">Ascending</ToggleButton>
              <ToggleButton value="DESC">Descending</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
      </Box>
    );
  };

 
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Toolbar>
        <CustomToolbar />
      </Toolbar>
      <TableContainer
        component={Paper}
        size="small"
        sx={{ maxWidth: { xs: 350, lg: "100%" } }}
      >
        <Table aria-label="simple table" size="small">
          <TableHead sx={{ backgroundColor: appColors.secondary }}>
            <TableRow>
              <TableCell>Full Name</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role Name</TableCell>
              <TableCell>Organization</TableCell>
              <TableCell>Registered At</TableCell>
              <TableCell>Is Active</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userDetailsList?.map((row) => (
              <TableRow
                key={row.email}
                onClick={() => handleClickRow(row)}
                sx={{ cursor: "pointer" }}
              >
                <TableCell component="th" scope="row">
                  {row.fullname}
                </TableCell>
                <TableCell>{row.phone}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.role_name}</TableCell>
                <TableCell>{row.org_name}</TableCell>
                <TableCell>{row.registered_at}</TableCell>
                <TableCell>{row.is_active ? "Yes" : "No"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination and page size */}

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          gap: "15px",
          padding: "10px",
          marginTop: "10px",
        }}
      >
        <Box>
          <Button
            sx={{
              cursor: "pointer",
            }}
            onClick={pageNumberBackward}
          >
            <ArrowBackIosIcon />
          </Button>
        </Box>
        <Typography
          variant="button"
          display="block"
          gutterBottom
          sx={{
            marginLeft: "10px",
            marginRight: "10px",
            backgroundColor: "#2D81FF",
            color: "white",
            padding: "5px",
            width: "40px",
            height: "35px",
            borderRadius: "15px",
            textAlign: "center",
          }}
        >
          {pageNumber}
        </Typography>
        <Box>
          <Button
            sx={{
              cursor: "pointer",
            }}
            onClick={pageNumberForward}
            disabled={pageNumber < lastPageNumber ? false : true}
          >
            <ArrowForwardIosIcon />
          </Button>
        </Box>
        <Box>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Page size</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={pageSize}
              label="Page size"
              onChange={(e) => {
                setPageSize(e.target.value);
                // setSearchOrderFlag(true);
              }}
            >
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={500}>500</MenuItem>
              <MenuItem value={1000}>1000</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>

      {/* To open edit user dialog box */}
      {openEditDialog && (
        <EditUserDetailsDialog
          openEditDialog={openEditDialog}
          setOpenEditDialog={setOpenEditDialog}
          selectedRow={selectedRow}
          pageNumber={pageNumber}
          pageSize={pageSize}
          ascOrDesc={ascOrDesc}
          isActiveGet={isActive}
        />
      )}
    </Box>
  );
}
