/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React from "react";
import { Route, Redirect } from "react-router-dom";
const ProtectedRoute = ({ children, ...rest }) => {
  // console.log(children)
  // console.log(...rest)
  return (
    <Route
      {...rest}
      render={({ location }) =>
        localStorage.getItem("token") || sessionStorage.getItem("token") ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/authentication",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
export default ProtectedRoute;
