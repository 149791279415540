import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Divider,
  Box,
} from "@mui/material";
import {
  Repeat as RepeatIcon,
  Visibility as ViewIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material";
import OrderDetailsDialog from "./OrderDetailsDialog";

const OrgOrdersListCard = ({ order }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleViewClick = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const { order_id, delivery_date, delivery_address, products, final_price } =
    order;
  const formattedDate = new Date(delivery_date).toLocaleDateString();

  const cardStyles = {
    card: {
      margin: "16px",
      borderRadius: 16,
      padding: 16,
      backgroundColor: "#2c3e50",
    },
    text: {
      color: "#ecf0f1",
    },
    divider: {
      backgroundColor: "#ecf0f1",
      margin: "16px 0",
    },
    button: {
      primary: {
        backgroundColor: "#3498db",
        color: "#ecf0f1",
      },
      secondary: {
        backgroundColor: "#e74c3c",
        color: "#ecf0f1",
      },
    },
  };

  return (
    <Card style={cardStyles.card}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" style={cardStyles.text}>
            Expected Delivery
          </Typography>
          <Typography variant="body2" style={cardStyles.text}>
            Regular
          </Typography>
        </Box>
        <Typography variant="h5" style={cardStyles.text}>
          {formattedDate}
        </Typography>
        <Typography variant="body2" style={cardStyles.text}>
          {delivery_address.area_name} {delivery_address.pincode}
        </Typography>
        <Divider style={cardStyles.divider} />
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1" style={cardStyles.text}>
            Products
          </Typography>
          <Typography variant="body1" style={cardStyles.text}>
            ₹{parseFloat(final_price).toFixed(2)}
          </Typography>
        </Box>
        <Typography variant="body2" style={cardStyles.text}>
          {products.map((product) => (
            <div key={product.name}>
              {product.quantity} x {product.name}
            </div>
          ))}
        </Typography>
        <Divider style={cardStyles.divider} />
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body2" style={cardStyles.text}>
            Order Id
          </Typography>
          <Typography variant="body2" style={cardStyles.text}>
            {order_id}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body2" style={cardStyles.text}>
            Total Price
          </Typography>
          <Typography variant="h6" style={cardStyles.text}>
            ₹{parseFloat(final_price).toFixed(2)}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-around" gap="10px" mt={2}>
          <Button
            variant="contained"
            style={cardStyles.button.primary}
            startIcon={<RepeatIcon />}
          >
            Repeat Order
          </Button>
          <Button
            variant="contained"
            style={cardStyles.button.primary}
            startIcon={<ViewIcon />}
            onClick={handleViewClick}
          >
            View
          </Button>
          <Button
            variant="contained"
            style={cardStyles.button.secondary}
            startIcon={<CancelIcon />}
          >
            Cancel
          </Button>
        </Box>
      </CardContent>
      <OrderDetailsDialog
        open={openDialog}
        onClose={handleCloseDialog}
        order={order}
      />
    </Card>
  );
};

export default OrgOrdersListCard;
