/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Showing the APIs list in form of table
 * @date 26/07/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import * as React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import EditApiDialogBox from "./EditApiDialogBox";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import HttpOutlinedIcon from "@mui/icons-material/HttpOutlined";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useDispatch } from "react-redux";
import { GetApis } from "../../../Redux/Actions/apisDetailsAction";
import { appColors } from "../../../AppMeta/appColors";

export default function ApiList({
  apisData,
  setAddNewApiDialog,
  isActive,
  setIsActive,
  ascOrDesc,
  setAscOrDesc,
  pageNumber,
  setPageNumber,
  setPageSize,
  pageSize,
  lastPageNumber,
}) {
  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          columnHeader: {
            backgroundColor: "#040c45", // Set the desired background color here
            color: "white", // Set the desired text color here
            position: "sticky",
            marginTop: "0 !important",
          },
        },
      },
    },
  });
  console.log(apisData);
  const dispatch = useDispatch();

  // Required variables declared here
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [editApiDialog, setEditApiDialog] = React.useState(false);

  // Ascending and desending toggle button function handling
  const handleChangeAscDesc = (event, newAlignment) => {
    setAscOrDesc(newAlignment);
  };

  // Custom toolbar to show the filter options
  const CustomToolbar = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
        }}
      >
        {/* Add your custom button here */}
        <Box sx={{ padding: "10px" }}>
          <CustomButton
            variant="outlined"
            color="primary"
            label="Add New API"
            handleClick={() => setAddNewApiDialog(true)}
            endIcon={<HttpOutlinedIcon />}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box>
            {" "}
            <ToggleButtonGroup
              size="small"
              color="primary"
              value={ascOrDesc}
              exclusive
              onChange={handleChangeAscDesc}
              aria-label="Platform"
            >
              <ToggleButton value="ASC">Ascending</ToggleButton>
              <ToggleButton value="DESC">Descending</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
      </Box>
    );
  };

  // This function gets executed on user clicks any rows
  const handleRowClick = (row) => {
    setSelectedRow(row);
    console.log(row);
    setEditApiDialog(true);
  };

  // Executes when user reduces the page number
  const pageNumberBackward = () => {
    if (pageNumber - 1 > 0) {
      setPageNumber(pageNumber - 1);
      // setdisableBtn(false);
      // setSearchOrderFlag(true);
    }
  };

  // Executes when user increase the page number
  const pageNumberForward = () => {
    if (apisData.apisList.data.length > 0) {
      setPageNumber(pageNumber + 1);
      // setSearchOrderFlag(true);
    }
  };

  // Hitting the GET Api to get the list of APIs
  React.useEffect(() => {
    dispatch(GetApis(pageNumber, pageSize, ascOrDesc));
  }, [pageNumber, pageSize, ascOrDesc]);
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* APIs list in table form */}
      <Toolbar>
        <CustomToolbar />
      </Toolbar>
      <TableContainer
        component={Paper}
        sx={{ maxWidth: { xs: 350, lg: "100%" } }}
        size="small"
      >
        <Table aria-label="simple table" size="small">
          <TableHead sx={{ backgroundColor: appColors.secondary }}>
            <TableRow>
              <TableCell>API Name</TableCell>
              <TableCell>HTTP Method</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Fullname</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {apisData.apisList.data.map((row) => (
              <TableRow
                key={row.apiName}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  cursor: "pointer",
                }}
                onClick={() => handleRowClick(row)}
              >
                <TableCell component="th" scope="row">
                  {row.api_name}
                </TableCell>
                <TableCell>{row.https_method}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{row.fullname}</TableCell>
                <TableCell>{row.phone}</TableCell>
                <TableCell>{row.email}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Pagination and page size */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          gap: "15px",
          padding: "10px",
          marginTop: "10px",
        }}
      >
        <Box>
          <Button
            sx={{
              cursor: "pointer",
            }}
            onClick={pageNumberBackward}
          >
            <ArrowBackIosIcon />
          </Button>
        </Box>
        <Typography
          variant="button"
          display="block"
          gutterBottom
          sx={{
            marginLeft: "10px",
            marginRight: "10px",
            backgroundColor: "#2D81FF",
            color: "white",
            padding: "5px",
            width: "40px",
            height: "35px",
            borderRadius: "15px",
            textAlign: "center",
          }}
        >
          {pageNumber}
        </Typography>
        <Box>
          <Button
            sx={{
              cursor: "pointer",
            }}
            onClick={pageNumberForward}
            disabled={pageNumber < lastPageNumber ? false : true}
          >
            <ArrowForwardIosIcon />
          </Button>
        </Box>
        <Box>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Page size</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={pageSize}
              label="Page size"
              onChange={(e) => {
                setPageSize(e.target.value);
                // setSearchOrderFlag(true);
              }}
            >
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={500}>500</MenuItem>
              <MenuItem value={1000}>1000</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      {/* To open the edit api dialog */}
      {editApiDialog && (
        <>
          <EditApiDialogBox
            editApiDialog={editApiDialog}
            setEditApiDialog={setEditApiDialog}
            selectedRow={selectedRow}
          />
        </>
      )}
    </Box>
  );
}
