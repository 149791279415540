import React from "react";
import CustomTypography from "../../../Components/CustomComp/CustomTypography";
import { Box, Button, Chip, Paper } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ApiList from "../ApiList/ApiList";
import ApiManagement from "../ApiList/ApiManagement";
import { useDispatch, useSelector } from "react-redux";
import ApiNamesTable from "./ApiNamesTable";
import { GetAccessForRoles } from "../../../Redux/Actions/rolesAccessAction";
import AllRolesAccessDetailsTable from "./AllRolesAccessDetailsTable";
import ApiAccessProviderTable from "./ApiAccessProviderTable";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { GetApis } from "../../../Redux/Actions/apisDetailsAction";
import { GetRoles } from "../../../Redux/Actions/rolesAction";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import Menu from "@mui/material/Menu";
// import MenuItem from '@mui/material/MenuItem';

function ProvideAccessManager() {
  const dispatch = useDispatch();
  const rolesData = useSelector((state) => state.rolesData);
  const apisData = useSelector((state) => state.apiListData);
  const rolesAccessData = useSelector((state) => state.rolesAccessData);
  const [rolesList, setRolesList] = React.useState([]);
  const [selectedRole, setSelectedRole] = React.useState("");
  const [listOfApis, setListOfApis] = React.useState([]);
  const [providedAccessList, setProvidedAccessList] = React.useState([]);
  const [value, setValue] = React.useState("1");

  // Provide Role Access
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(25);
  const [ascOrDesc, setAscOrDesc] = React.useState("ASC");
  const [lastPageNumber, setLastPageNumber] = React.useState(null);

  // Provided Access for roles
  const [pageNumber1, setPageNumber1] = React.useState(1);
  const [pageSize1, setPageSize1] = React.useState(25);
  const [ascOrDesc1, setAscOrDesc1] = React.useState("ASC");
  const [lastPageNumber1, setLastPageNumber1] = React.useState(null);

  const [lastMore, setLastMore] = React.useState(null);
  React.useEffect(() => {
    dispatch(GetApis(pageNumber, pageSize));
  }, [pageNumber, pageSize, ascOrDesc]);

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
    console.log(value);
  };

  React.useEffect(() => {
    dispatch(
      GetAccessForRoles(pageNumber1, pageSize1, ascOrDesc1, lastPageNumber1)
    );
  }, [pageNumber1, pageSize1, ascOrDesc1, lastPageNumber1]);

  React.useEffect(() => {
    console.log(rolesAccessData);
    if (rolesAccessData.error === false) {
      setLastPageNumber1(rolesAccessData.providedAccessData.last_page_number);
      if (rolesAccessData?.providedAccessData?.data?.length > 0) {
        setProvidedAccessList(rolesAccessData.providedAccessData.data);
      }
    }
  }, [rolesAccessData]);

  React.useEffect(() => {
    console.log(rolesData);
    if (rolesData.error === false) {
      if (rolesData?.rolesList?.data?.length > 0) {
        console.log(rolesData.rolesList.data);
        // setRolesList(rolesData.rolesList.data);
        setLastMore(rolesData.rolesList.last_page_number);
        setRolesList((prevRolesList) =>
          prevRolesList
            ? [...prevRolesList, ...rolesData.rolesList.data]
            : rolesData.rolesList.data
        );
      }
    }
  }, [rolesData]);

  React.useEffect(() => {
    console.log(apisData);
    if (apisData.error === false) {
      setLastPageNumber(apisData.apisList.last_page_number);

      if (apisData?.apisList?.data?.length > 0) {
        setListOfApis(apisData.apisList.data);
      }
    }
  }, [apisData]);

  const handleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleChangeMore = (e) => {
    console.log(e.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hasMoreData, setHasMoreData] = React.useState(true);
  const [getMoreRoles, setGetMoreRoles] = React.useState(1);

  React.useEffect(() => {
    dispatch(GetRoles(getMoreRoles, 25, true, "ASC"));
  }, [getMoreRoles]);
  const handleOpenDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };
  const handleLoadMore = () => {
    setGetMoreRoles(getMoreRoles + 1);
    // fetchMoreData(nextPage);
  };
  return (
    <>
      {" "}
      <Box
        sx={
          {
            // display: "flex",
            // alignItems: "center",
            // justifyContent: "center",
            // flexDirection: "column",
          }
        }
      >
        <Tabs
          value={value}
          onChange={handleChangeTabs}
          centered
          sx={{
            padding: "10px",
          }}
        >
          <Tab label="Provide role access" value="1" />
          <Tab label="Provided access for roles" value="2" />
        </Tabs>
        {value === "1" && (
          <div>
            {/* <CustomTypography
            content="Provide role access"
            fontSize="18px"
            fontWeight="bold"
            textAlign="center"
          /> */}
            {/* <Paper elevation={24}> */}
            <Box
              sx={{
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <Box sx={{ minWidth: 120 }}>
                <Button onClick={handleOpenDropdown} variant="outlined">
                  Select Role Name
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleCloseDropdown}
                >
                  {rolesList?.map((list) => (
                    <MenuItem
                      key={list.role_name}
                      onClick={() => {
                        handleCloseDropdown();
                        setSelectedRole(list.role_name);
                      }}
                    >
                      {list.role_name}
                    </MenuItem>
                  ))}
                  {hasMoreData && (
                    <MenuItem
                      onClick={handleLoadMore}
                      sx={{ fontWeight: "bold" }}
                      disabled={getMoreRoles < lastMore ? false : true}
                    >
                      More <ExpandMoreRoundedIcon />
                    </MenuItem>
                  )}
                </Menu>
              </Box>
              {selectedRole !== "" && (
                <Box>
                  <Chip
                    label={`Selected Role Name: ${selectedRole}`}
                    variant="filled"
                    color="success"
                    sx={{ fontWeight: "bold" }}
                  />
                </Box>
              )}

              {/* <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth disabled={true}>
                    <InputLabel id="demo-simple-select-label">
                      Selected Role
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedRole}
                      label="Selected Role"
                      onChange={handleChange}
                    >
                      {rolesList?.map((list) => {
                        return (
                          <MenuItem value={list.role_name}>
                            {list.role_name}
                          </MenuItem>
                        );
                      })}
                      <MenuItem value="more" onClick={handleChangeMore}>
                        More <ExpandMoreRoundedIcon />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box> */}

              <Box>
                {listOfApis.length > 0 && (
                  <ApiAccessProviderTable
                    listOfApis={listOfApis}
                    selectedRole={selectedRole}
                    isEditAccessMode={false}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    ascOrDesc={ascOrDesc}
                    setAscOrDesc={setAscOrDesc}
                    lastPageNumber={lastPageNumber}
                  />
                )}
              </Box>
              {/* <ApiNamesTable
                  listOfApis={listOfApis}
                  selectedRole={selectedRole}
                /> */}
            </Box>
            {/* </Paper> */}
          </div>
        )}
        {value === "2" && (
          <Box sx={{ padding: "10px" }}>
            {/* <CustomTypography
            content="Provided access for roles"
            fontWeight="bold"
            fontSize="18px"
          /> */}
            <AllRolesAccessDetailsTable
              providedAccessList={providedAccessList}
              listOfApis={listOfApis}
              selectedRow={selectedRole}
              pageNumber1={pageNumber1}
              pageSize1={pageSize1}
              ascOrDesc1={ascOrDesc1}
              lastPageNumber1={lastPageNumber1}
              setPageNumber1={setPageNumber1}
              setPageSize1={setPageSize1}
              setAscOrDesc1={setAscOrDesc1}
              setLastPageNumber1={setLastPageNumber1}
            />
          </Box>
        )}
      </Box>
    </>
  );
}

export default ProvideAccessManager;
