/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Reducers for creating, updating and adding roles list
 * @date 26/05/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import { Reducer } from "redux";
import actionType from "../actionTypes";

const initialState = {
  error: false,
  msg: "",
  iotDevices: [],
};

const iotDeviceReducer = (state = initialState, action) => {
  // console.log(`action type: ${action.type}`);
  console.log(action);
  switch (action.type) {
    case actionType.ADD_IOT_DEVICE:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.GET_IOT_DEVICE:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        iotDevices: action.payload.data,
      };
    case actionType.UPDATE_IOT_DEVICE:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        // wfsList: action.payload.wfsList,
      };
    default:
      return state;
  }
};

export default iotDeviceReducer;
