/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description App.js File for Routing the Web URLs
 * @date 26/05/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import AuthenticationPage from "./Pages/Authentication/Authentication";
import Dashboard from "./Pages/Dashboard/Dashboard";
import ProtectedRoute from "./Routes/ProtectedRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createTheme } from "@mui/material/styles";
import { createContext, useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import DashboardTest from "./Pages/Dashboard/DashboardTest";
import DashboardSide from "./Pages/Dashboard/DashboardSide";
import DashboardNew from "./Pages/Dashboard/DashboardNew";
import { ColorModeContext, useMode } from "./AppMeta/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Sample } from "./Pages/Sample";
import { useMediaQuery } from "@mui/material";
import MobileNew from "./Pages/Mobile/MobileNew";
import useScreenSize from "./Hooks/CustomHooks/useScreenSize";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function App() {
  const profileData = useSelector((state) => state.profileData?.profile);
  const location = useLocation();

  const [theme, colorMode] = useMode();
  const isMobile = useScreenSize();
  const history = useHistory();

  useEffect(() => {
    if (
      isMobile &&
      profileData?.is_facility === false &&
      profileData?.domain !== "BOOKWATER.COM"
    ) {
      history.push("/mobile");
    } else {
      if (location?.pathname === "/mobile") {
        history.push("/dashboard");
      }
    }
  }, [isMobile, profileData]);

  useEffect(() => {
    console.log("profileData");
    console.log(profileData);
  }, [profileData]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* {routes} */}
        <Switch>
          <Route exact path="/">
            <Redirect to={isMobile ? "/mobile" : "/dashboard"} />
          </Route>
          <Route path="/authentication">
            <AuthenticationPage />
          </Route>
          <ProtectedRoute path="/dashboard">
            <DashboardNew />
          </ProtectedRoute>
          <ProtectedRoute path="/mobile">
            <MobileNew />
          </ProtectedRoute>
          <Route path="*">
            <Redirect from="/" to={"dashboard"} />
          </Route>
        </Switch>

        <ToastContainer />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
