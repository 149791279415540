/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Reducers for creating, updating and adding APIs list 
 * @date 26/05/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import { Reducer } from "redux";
import actionType from "../actionTypes";

const initialState = {
  error: false,
  msg: "",
  apisList: [],
};

const apisDetailsReducer = (state = initialState, action) => {
  // console.log(`action type: ${action.type}`); 
  console.log(action);
  switch (action.type) {
    // case actionType.ADD_ROLE:
    case actionType.GET_API_LIST:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        apisList: action.payload.data,
      };
    case actionType.UPDATE_API_LIST:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    default:
      return state;
  }
};

export default apisDetailsReducer;
