/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Actions for getting , creating , and updating products
 * @date 01/08/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import axios from "axios";
// import useAxios from "../../Hooks/useAxios";
import useAxios from "../../Hooks/useAxios";
import { Dispatch } from "redux";
import { toast } from "react-toastify";

import actionType from "../actionTypes";

export const AddCratesAction = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  console.log(reqObj);
  return async (dispatch) => {
    try {
      let response = await api.post(`/PSCM/sku/add/crates`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.ADD_CRATES,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};

export const GetCratesSKU = (
  page_number,
  page_size,
  is_active,
  created_at_sort
) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  let reqObj = {};
  if (
    page_size === undefined ||
    is_active === undefined ||
    created_at_sort === undefined
  ) {
    reqObj = {};
  } else {
    reqObj = {
      page_number,
      page_size,
      is_active,
      created_at_sort,
    };
  }
  return async (dispatch) => {
    try {
      let response = await api.get(
        `/PSCM/sku/crates?` + new URLSearchParams(reqObj)
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_CRATES,
          payload: response.data,
        });
      }
    } catch (error) {
      console.log(error);
      // toast.error(error.response.data.msg, {
      //   position: "top-right",
      //   autoClose: 1000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  };
};

export const UpdateCrates = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(`/PSCM/sku/update/crate`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.UPDATE_CRATES,
          payload: response?.data,
        });
        if (response.data.msg) {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};
export const LinkCrates = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(`/PSCM/sku/link/crate`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.LINK_CRATES,
          payload: response?.data,
        });
        if (response.data.msg) {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};
