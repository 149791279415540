/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Actions for getting , creating , and updating the manufacturing orders
 * @date 22/08/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import axios from "axios";
// import useAxios from "../../Hooks/useAxios";
import useAxios from "../../Hooks/useAxios";
import { Dispatch } from "redux";
import { toast } from "react-toastify";
import actionType from "../actionTypes";

// Generate QR Actions

export const GenerateQR = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  console.log(reqObj);

  return async (dispatch) => {
    try {
      let response = await api.post(`/POPM/generate/qr_barcode`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GENERATE_QR,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};

export const GetGeneratedQR = (
  page_number,
  page_size,
  created_at_sort,
  is_qr,
  qr_barcode_Id,
  order_id
) => {
  console.log(
    page_number,
    page_size,
    created_at_sort,
    is_qr,
    qr_barcode_Id,
    order_id
  );
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  let reqObj = {};

  if (order_id === "" && qr_barcode_Id === "") {
    reqObj = {
      page_number,
      page_size,
      created_at_sort,
      is_qr,
    };
  } else if (order_id !== "") {
    reqObj = {
      page_number,
      page_size,
      created_at_sort,
      order_id,
      is_qr,
    };
  } else if (qr_barcode_Id !== "") {
    reqObj = {
      page_number,
      page_size,
      created_at_sort,
      qr_barcode_Id,
      is_qr,
    };
  }
  return async (dispatch) => {
    try {
      // let response = await api.get(
      //   `/POPM/qr_barcode/list?created_at_sort=${createdAtSort}&page_number=${pageNumber}&page_size=${pageSize}&is_qr=${isQr}`
      // );

      let response = await api.get(
        `/POPM/qr_barcode/list?` + new URLSearchParams(reqObj)
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_GENERATED_QR,
          payload: response.data,
        });
        if (response.data.msg) {
          //   toast.success(response.data.msg, {
          //     position: "top-right",
          //     autoClose: 1000,
          //     hideProgressBar: true,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     progress: undefined,
          //     theme: "light",
          //   });
        } else {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};

export const RegenerateQr = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  const obj = {
    is_qr: false,
    quantity: 1,
  };
  return async (dispatch) => {
    try {
      let response = await api.post(`/POPM/regenerate/qr_barcode`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.REGENERATE_QR_CODE,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};
