/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description Custom Text Field (Password Field)
 * @date 01/12/23
 * @version 0.0.1 - Initial Release
 */

import React from "react";
import { Box, Button, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
// import { skuBookingCardsStyle } from "../Cards/SkuCards/BookingCardsStyle";
// import appColors from "../../Assets/AppTheme/appColors";
import { useLocation } from "react-router-dom";
import appColors from "../../Assests/AppTheme/appColors";
export default function RequiredButton({
  data,
  handelAddQuantity,
  handelReduseQuantity,
}) {
  const { pathname, search } = useLocation();
  const [pageName, setPageTitle] = React.useState("");
  let name = "";
  // To Control the Page Heading on Click of Back & froward Button
  React.useLayoutEffect(() => {
    const pageTitle = pathname.split("/dashboard/");
    setPageTitle(pageTitle[1]);
  }, [pathname, search]);

  return (
    <>
      <Stack direction="row">
        <Box
          sx={{
            backgroundColor: appColors.textColorNew,
            height: "1.8rem",
            width: "1.4rem",
            display: "flex",
            justifyContent: "center",
            borderRadius: "0.3rem 0 0 .3rem",
            color: "#fff",
          }}
        >
          <Button size="small" onClick={() => handelReduseQuantity(data)}>
            <RemoveIcon
              sx={{
                fontSize: ".8rem",
                fontWeight: "600",
                color: "#fff",
              }}
            />
          </Button>
        </Box>
        <Box
          sx={{
            backgroundColor: "#bfefff",
            height: "1.8rem",
            width: "2.5rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            fontWeight: "600",
            fontSize: ".8rem",
          }}
        >
          {" "}
          <input
            type="text"
            readOnly
            value={
              pageName === "checkout"
                ? data?.quantity?.selected
                : data?.quantity
            }
            style={{
              wordWrap: "normal",
              border: 0,
              height: "1.8rem",
              fontSize: "1rem",
              minWidth: "1.4rem",
              textAlign: "center",
              backgroundColor: "#bfefff",
              color: "#58595b",
              fontWeight: "bold",
              width: "100%",
              outline: "none",
            }}
          />
        </Box>
        <Box
          sx={{
            backgroundColor: appColors.textColorNew,
            height: "1.8rem",
            width: "1.4rem",
            display: "flex",
            justifyContent: "center",
            borderRadius: "0 0.3rem .3rem 0",
            color: "#fff",
          }}
        >
          <Button size="small" onClick={() => handelAddQuantity(data)}>
            <AddIcon
              sx={{
                fontSize: ".8rem",
                fontWeight: "600",
                color: "#fff",
              }}
            />
          </Button>
        </Box>
      </Stack>
    </>
  );
}
