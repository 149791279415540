/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description Custom Text Field (Password Field)
 * @date 01/12/23
 * @version 0.0.1 - Initial Release
 */

import React from "react";
import { Box, Button, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
// import { skuBookingCardsStyle } from "../Cards/SkuCards/BookingCardsStyle";
// import appColors from "../../Assets/AppTheme/appColors";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import appColors from "../../Assests/AppTheme/appColors";

export default function ReturnButton({
  handelAddReturnQuantity,
  handelReduseReturnQuantity,
  data,
}) {
  const { pathname, search } = useLocation();
  const [pageName, setPageTitle] = React.useState("");
  let name = "";
  // To Control the Page Heading on Click of Back & froward Button
  React.useLayoutEffect(() => {
    const pageTitle = pathname.split("/dashboard/");
    setPageTitle(pageTitle[1]);
  }, [pathname, search]);

  // Define a function to handle the conditional logic
  function getPageData(pageName, data) {
    if (pageName === "checkout") {
      return data?.return?.is_valid ? data?.return?.selected : 0;
    } else {
      return data?.return === undefined ? 0 : data?.return;
    }
  }
  return (
    <>
      <Box sx={{ marginBottom: ".5rem" }}>
        <Stack direction="row">
          <Box
            sx={{
              backgroundColor: appColors.textColorNew,
              height: "1.8rem",
              width: "1.4rem",
              display: "flex",
              justifyContent: "center",
              borderRadius: "0.3rem 0 0 .3rem",
              color: "#fff",
            }}
          >
            <Button
              size="small"
              onClick={() => handelReduseReturnQuantity(data)}
            >
              <RemoveIcon
                sx={{
                  fontSize: ".8rem",
                  fontWeight: "600",
                  color: "#fff",
                }}
              />
            </Button>
          </Box>
          <Box
            sx={{
              backgroundColor: "#bfefff",
              height: "1.8rem",
              width: "2.5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              fontWeight: "600",
              fontSize: ".8rem",
            }}
          >
            {" "}
            <input
              type="text"
              readOnly
              value={getPageData(pageName, data)}
              style={{
                wordWrap: "normal",
                border: 0,
                height: "1.8rem",
                fontSize: "1rem",
                width: "1.2rem",
                textAlign: "center",
                color: "#58595b",
                backgroundColor: "#bfefff",
                fontWeight: "bold",
                width: "100%",
                outline: "none",
              }}
            />
          </Box>
          <Box
            sx={{
              backgroundColor: appColors.textColorNew,
              height: "1.8rem",
              width: "1.4rem",
              display: "flex",
              justifyContent: "center",
              borderRadius: "0 0.3rem .3rem 0",
              color: "#fff",
            }}
          >
            <Button size="small" onClick={() => handelAddReturnQuantity(data)}>
              <AddIcon
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  color: "#fff",
                }}
              />
            </Button>
          </Box>
        </Stack>
      </Box>
    </>
  );
}
