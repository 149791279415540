import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
} from "@mui/material";
import CustomConfirmationDialog from "../../Components/CustomComp/CustomConfirmationDialog";
import { useDispatch } from "react-redux";
import { CancelCreateBatch } from "../../Redux/Actions/adddCreateBatchAction";

export function CancelDialogBatch({ open, onClose, item }) {
  const dispatch = useDispatch();
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    React.useState(false);
  const [isConfirmed, setIsConfirmed] = React.useState(false);
  const [reason, setReason] = React.useState("");
  React.useEffect(() => {
    if (isConfirmed) {
      const reqObj = {
        BatchID: item.batch_id,
        Reason: reason,
      };

      console.log("ReqObj");
      console.log(reqObj);
      dispatch(CancelCreateBatch(reqObj));

      setOpenConfirmationDialog(false);
    }
  }, [isConfirmed]);

  return (
    <Box>
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Cancel</DialogTitle>
        <DialogContent>
          <TextField
            id="outlined-textarea"
            label="Reason"
            placeholder="Reason"
            multiline
            onChange={(e) => setReason(e.target.value)}
            value={reason}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => setOpenConfirmationDialog(true)}
          >
            Cancel
          </Button>
          <Button color="primary" onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {openConfirmationDialog && (
        <CustomConfirmationDialog
          openConfirmationDialog={openConfirmationDialog}
          setOpenConfirmationDialog={setOpenConfirmationDialog}
          description={"Are you sure that you want to cancel?"}
          setIsConfirmed={setIsConfirmed}
        />
      )}
    </Box>
  );
}
