import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Typography } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomDialogMoreDetails({
  openCustomDialog,
  setOpenCustomDialog,
  title,
  moreDetails,
}) {
  console.log(moreDetails);
  console.log(moreDetails.fullname);
  console.log(moreDetails.qr_data);

  const handleClose = () => {
    setOpenCustomDialog(false);
  };

  return (
    <div>
      <Dialog
        open={openCustomDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Typography>
            <strong>Email:</strong> {moreDetails?.email}
          </Typography>
          {moreDetails?.fullname === undefined ? (
            <Typography>
              <strong>Manufacturer Name: </strong>
              {moreDetails?.mft_name}
            </Typography>
          ) : (
            <Typography>
              <strong>Name:</strong> {moreDetails?.fullname}
            </Typography>
          )}
          {moreDetails?.area_name !== undefined && (
            <Typography>
              <strong>Area Name:</strong> {moreDetails?.area_name}
            </Typography>
          )}
          {moreDetails?.pincode !== undefined && (
            <Typography>
              <strong>Pincode:</strong> {moreDetails?.pincode}
            </Typography>
          )}
          {moreDetails?.phone !== undefined && (
            <Typography>
              <strong>Phone:</strong> {moreDetails?.phone}
            </Typography>
          )}

          {moreDetails.qr_data !== undefined && (
            <>
              <Typography>
                <strong>Barcode Series: </strong>{" "}
                {moreDetails?.qr_data.barcode_series}
              </Typography>
              <Typography>
                <strong>QR Code Series: </strong>{" "}
                {moreDetails?.qr_data.qrcode_series}
              </Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          {/* <Button onClick={handleClose}>Agree</Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
