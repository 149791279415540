import { Box } from "@mui/material";
import React from "react";
import CustomButton from "../../Components/CustomComp/CustomButton";

function Linking({ setOpenScannerDialog }) {
  const handleLinking = () => {
    setOpenScannerDialog(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
      }}
    >
      <Box>
        <CustomButton
          label="Start Linking"
          variant="contained"
          handleClick={handleLinking}
        />
      </Box>
 
      {/* <Box>
        <CustomButton label="Re-link" variant="contained" />
      </Box> */}
    </Box>
  );
}

export default Linking;
