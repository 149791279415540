import React from "react";
import appColors from "../../../Assests/AppTheme/appColors";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import WaterCan from "../../../AppMeta/AppImages/WaterCanImg.png";
import DeliveryAddress from "../../../Pages/Orders/OrganizationBooking/OrganisationOrderManagement/Components/DeliveryAddress";
import DeliverDate from "../../../Pages/Orders/OrganizationBooking/OrganisationOrderManagement/Components/DeliverDate";
import appTheme from "../../../Assests/AppTheme/appTheme";
import ImageLoader from "../../Utils/ImageLoader";
import DeleteIcon from "@mui/icons-material/Delete";
import RequiredButton from "../../SkuButtons/SkuRequiredButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDispatch, useSelector } from "react-redux";
import ReturnButton from "../../SkuButtons/SkuReturnButton";
import { LoadingButton } from "@mui/lab";
import {
  AddProductQuantity,
  AddReturnQuantity,
  DeleteProductaction,
  ReduceProductQuantity,
  ReduceReturnQuantity,
  ReduseProductQuantity,
  SelectedProductaction,
} from "../../../Redux/Actions/skuBookingAction";
import { toast } from "react-toastify";

function BookingCardsSku({
  productsOrg,
  setSelectedProduct,
  setInfoCard,
  data,
}) {
  console.log("productsOrg in card");
  console.log(productsOrg);
  const dispatch = useDispatch();
  const productDetails = useSelector(
    (state) => state.skuBookingDetails.productDetails
  );
  const SkuProductDetails = useSelector((state) => state.skuBookingDetails);

  const [products, setProducts] = React.useState({
    products: [], 
    booking_type: 1,
    // address_id: SkuProductDetails?.selectedAddressId,
    org_id: 30,
    coupon_code: "",
    water_drops: 0,
    payment_method: "PAYSHARP",
    is_otp_less: true,
    delivery_date: "2024-06-10",
    is_auto_apply_water_drops: false,
    is_auto_apply_coupon: true,
    secondary_payment_method: "",
  });
  const [cardValue, setCardValue] = React.useState(1);
  const [productsData, setProductsData] = React.useState([]);
  const [returnCanFlags, setReturnCanFlags] = React.useState({});
  const [returnCanFlag, setreturnCanFlag] = React.useState(false);
  const [loadingStates, setLoadingStates] = React.useState({});
  const [pendingFlag, setPendingFlag] = React.useState(false);

  const handelRefillOnetimeSale = (data) => {
    setCardValue(data);
    // dispatch(DeleteAllProductQty());
    // setProducts((prevProducts) => ({
    //   ...prevProducts,
    //   booking_type: data,
    // }));
  };
  React.useEffect(() => {
    // Your product details logic from the reducer
    const updatedProducts = productDetails?.products
      ?.map((item) => {
        // Check if quantity is defined and has changed
        if (
          item?.quantity !== undefined &&
          item?.min_book_qty <= item?.quantity
        ) {
          return {
            quantity: item?.quantity || 0,
            return: item?.return || 0,
            sku_qty: item?.sku_qty[0] || 0,
            booking_type: item?.booking_type || 0,
            capacity: item?.capacity,
            material: item?.material,
          };
        }
        return undefined;
        // Return undefined for items that don't meet the condition
      })
      .filter(Boolean); 
      // Filter out undefined items
    setProducts((prevProducts) => ({
      ...prevProducts,
      booking_type: cardValue,
      products: updatedProducts,
    }));
  }, [productDetails]);

  React.useEffect(() => {
    setProducts((prevProducts) => ({
      ...prevProducts,
      org_id: 30,
      is_auto_apply_water_drops:
        SkuProductDetails?.productDetails?.water_drop_details?.auto_pay,
    }));
  }, [SkuProductDetails?.productDetails]);

  React.useEffect(() => {
    console.log("SkuProductDetails");
    console.log(SkuProductDetails);
  }, [SkuProductDetails]);

  React.useEffect(() => {
    if (products?.products?.length >= 0)
      dispatch(SelectedProductaction(products));
  }, [products]);

  React.useEffect(() => {
    if (cardValue === 1) {
      const refillProducts =
        SkuProductDetails?.productDetails?.products?.filter(
          (data) => data?.booking_type === 1 || data?.booking_type === 3
        );
      setProductsData(refillProducts);
    } else if (cardValue === 2) {
      const oneTimeProducts = productsOrg?.filter(
        (data) => data?.booking_type === 2 || data?.booking_type === 3
      );
      setProductsData(oneTimeProducts);
    }
  }, [SkuProductDetails?.productDetails, cardValue]);

  React.useEffect(() => {
    console.log("productsData");
    console.log(productsData);
  }, [productsData]);
  const handelDeleteProduct = (data) => {
    dispatch(DeleteProductaction(data));
  };
  const handelInfoIcon = (data) => {
    setSelectedProduct(data);
    setInfoCard(true);
  };
  const handelAddQuantity = (data) => {
    // CustomConsole(data);
    console.log(data)
    if (
      //   data?.quantity <
      //     parseInt(data?.filled_stock_with_distributor / data?.sku_qty[0]) ||
      //   pageName[1] === "subscribe"
      true
    ) {
      dispatch(AddProductQuantity(data, cardValue));
    } else {
      dispatch();
      // ToastMsgState({
      //   error: true,
      //   containerFlag: true,
      //   msg: `Distributor not having enough stock`,
      // })
    }
  };

  const handelAddReturnQuantity = (data) => {
    console.log(data);
    console.log(data?.return);
    if (data?.return !== undefined) {
      // if (true) {
      dispatch(AddReturnQuantity(data, cardValue));
    } else if (data?.return === undefined || data?.return === 0) {
      toast.error("Not having enough stock to return", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      //   dispatch();
      // ToastMsgState({
      //   error: true,
      //   containerFlag: true,
      //   msg: `Not having enough stock to return`,
      // })
    }
  };

  const handelReduseReturnQuantity = (data) => {
    dispatch(ReduceReturnQuantity(data, cardValue));
  };
  const handelReduseQuantity = (data) => {
    dispatch(ReduceProductQuantity(data, cardValue));
  };
  const handelOpenReturnButton = (data) => {
    setreturnCanFlag(!returnCanFlag);
    setReturnCanFlags((prevFlags) => ({
      ...prevFlags,
      [data.id]: !prevFlags[data.id],
    }));
  };

  function getMaxReturn(containerCapacity, containerMaterial) {
    const userStock = data?.user_stock;
    if (userStock !== undefined) {
      for (const item of userStock) {
        if (
          item.container_capacity === containerCapacity &&
          item.container_material === containerMaterial
        ) {
          return item.max_return;
        }
      }
    }
    return 0; // Default return value if no match found
  }

  //Function for handiling product adding details
  const handelAddProducts = (data) => {
    // handelLoadingAddButton(data);
    if (!pendingFlag) {
      dispatch(AddProductQuantity(data, "1"));
      if (cardValue === 1) {
        // dispatch(AddReturnQuantity(data));
      }
      //   setCheckOutUi(true);
    } else if (pendingFlag) {
      //   setIsOrderPaymentPending(true);
    }
  };
  return (
    <>
      <Typography sx={{ color: appColors.darkGray, fontWeight: "bold" }}>
        {/* Choose Products */}
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={5} sm={6} xs={12}>
          <DeliveryAddress />
        </Grid>
        <Grid item md={5} sm={6} xs={12}>
          <DeliverDate />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "space-between", sm: "flex-start" },
          gap: 2,
          margin: "1rem 0",
        }}
      >
        <Card
          sx={{
            padding: ".5rem",
            width: { xs: "100%", sm: "11rem" },
            borderRadius: appTheme.fontSizeMedium,
            boxShadow: 5,
            color:
              cardValue === 1
                ? appColors.textColorNew
                : appColors.headerTextColor,
            bgcolor:
              cardValue === 1 ? appColors.lightblue : appColors.cardColor,
            cursor: "pointer",
            fontSize: "0.8rem",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
          onClick={() => handelRefillOnetimeSale(1)}
        >
          <img
            src={WaterCan}
            alt="Bookwater"
            style={{ width: "1.5rem", height: "1.5rem" }}
          />
          <span> Refill Purchase</span>
        </Card>

        <Card
          sx={{
            padding: ".5rem",
            borderRadius: ".9rem",
            boxShadow: 5,
            width: { xs: "100%", sm: "11rem" },
            color:
              cardValue === 2
                ? appColors.textColorNew
                : appColors.headerTextColor,
            bgcolor:
              cardValue === 2 ? appColors.lightblue : appColors.cardColor,
            cursor: "pointer",
            fontSize: "0.8rem",
            alignItems: "center",
            display: "flex",
            justifyContent: "space-evenly",
          }}
          onClick={() => handelRefillOnetimeSale(2)}
        >
          <img
            src={WaterCan}
            alt="Bookwater"
            style={{ width: "1.5rem", height: "1.5rem" }}
          />
          One-Time Purchase
        </Card>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "flex-start",
          width: "100%",
          flexWrap: "wrap",
          gap: { sm: 1 },
          marginBottom: "2rem",
        }}
      >
        {productsData?.map((data, index) => {
          return (
            <Card
              key={index}
              sx={{
                boxShadow: 5,
                elevation: 5,
                margin: ".2rem",
                padding: "0 0.5rem 0 0",
                width: { xs: "calc(100% - 0.6rem)", sm: "23rem" },
                height: "100%",
                padding: ".3rem .5rem 0 0",
                position: "relative",
                bgcolor: appColors.cardColor,
                borderRadius: ".7rem",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={3}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "center",
                      margin: ".1rem",
                      height: "5rem",
                      width: "100%", // Ensures the box takes the full available width
                      overflow: "hidden", // Ensures the image is not displayed outside the box
                    }}
                  >
                    <ImageLoader filename={data.image} />
                  </Box>
                </Grid>
                <Grid item xs={9}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignContent="center"
                    sx={{ height: "100%" }}
                  >
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          color: appColors.headerTextColor,
                          fontSize: ".9rem",
                        }}
                      >
                        <span style={{ fontWeight: "600" }}>{data?.name}</span>
                      </Typography>
                    </Grid>
                    <Grid container justifyContent="space-between">
                      <Grid item xs={8}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            flexDirection: "column",
                            height: "100%",
                            justifyContent: "center",
                          }}
                        >
                          <Typography>
                            {(data.refill.mrp !== data?.refill.sp &&
                              products.booking_type === 1) ||
                            (data.one_time_sale.mrp !==
                              data?.one_time_sale.sp &&
                              products.booking_type === 2) ? (
                              <span
                                style={{
                                  textDecoration: "line-through",
                                  color: appColors.darkGray,
                                  fontWeight: "bold",
                                  fontSize: ".8rem",
                                  marginRight: ".6rem",
                                }}
                              >
                                ₹&nbsp;
                                {products.booking_type === 1
                                  ? data.refill.mrp
                                  : data?.one_time_sale.mrp}
                              </span>
                            ) : null}
                            <span
                              style={{
                                color: appColors.headerTextColor,

                                fontWeight: "bold",
                              }}
                            >
                              ₹&nbsp;
                              {products.booking_type === 1
                                ? data.refill.sp
                                : data?.one_time_sale.sp}
                            </span>
                          </Typography>
                          {(data?.sku_qty[0] > 1 &&
                            data.quantity < data.min_book_qty) ||
                          (data?.quantity === undefined &&
                            data?.sku_qty[0] > 1) ? (
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                color: appColors.darkGray,
                                fontSize: ".9rem",
                              }}
                            >
                              <span>{data.min_book_qty} Pack |</span>
                              <span>
                                &nbsp;{data.sku_qty[0] * data.min_book_qty}{" "}
                                Units
                              </span>
                            </Typography>
                          ) : null}
                          {data?.sku_qty[0] > 1 &&
                            data?.quantity > 0 &&
                            data.quantity >= data.min_book_qty && (
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  color: appColors.darkGray,
                                  fontSize: ".9rem",
                                }}
                              >
                                <span>{data.quantity} Pack |</span>
                                {data.quantity >= data.min_book_qty ? (
                                  <span>
                                    &nbsp;
                                    {data.quantity * data.sku_qty[0]} Units
                                  </span>
                                ) : null}
                              </Typography>
                            )}
                          {data?.sku_qty[0] === 1 && (
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                color: appColors.darkGray,
                                fontSize: ".9rem",
                              }}
                            >
                              <span>Minimum Qty {data.min_book_qty}</span>
                            </Typography>
                          )}
                          {data.quantity >= data.min_book_qty ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                fontSize: ".8rem",
                                justifyContent: "space-between",
                                marginBottom: ".3rem",
                              }}
                            >
                              {/* <Box onClick={() => handelInfoIcon(data)}>
                                <span>
                                  <img src={iIcon} alt="" />
                                </span>
                                &nbsp;
                                <span style={{ color: appColors.darkGray }}>
                                  Description
                                </span>
                              </Box> */}
                              <Box
                                onClick={() => handelDeleteProduct(data)}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <span
                                  style={{
                                    color: appColors.darkGray,
                                    fontSize: ".8rem",
                                  }}
                                >
                                  Remove
                                </span>{" "}
                                <span>
                                  <DeleteIcon
                                    sx={{
                                      color: appColors.textColorNew,
                                      height: "1.2rem",
                                    }}
                                  />
                                </span>
                              </Box>
                            </Box>
                          ) : (
                            <Typography
                              sx={{
                                color: appColors.Textgray,
                                fontSize: ".7rem",
                                marginBottom: ".3rem",
                              }}
                              onClick={() => handelInfoIcon(data)}
                            >
                              <span>Description: </span>
                              <span>
                                {data.description.length > 10
                                  ? data.description.slice(0, 15) + "..."
                                  : data.description}
                              </span>
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        {data.quantity >= data.min_book_qty ? (
                          <Box>
                            {" "}
                            <Typography
                              sx={{
                                fontWeight: 500,
                                color: appColors.darkGray,
                                fontSize: ".8rem",
                              }}
                            >
                              Required
                            </Typography>
                            <Box>
                              <RequiredButton
                                handelAddQuantity={handelAddQuantity}
                                handelReduseQuantity={handelReduseQuantity}
                                data={data}
                              />
                            </Box>
                            {returnCanFlags[data.id] && cardValue === 1 ? (
                              <>
                                <Typography
                                  sx={{
                                    fontWeight: 500,
                                    color: appColors.darkGray,
                                    fontSize: ".8rem",
                                  }}
                                >
                                  Return
                                </Typography>
                                <Box>
                                  <ReturnButton
                                    handelAddReturnQuantity={
                                      handelAddReturnQuantity
                                    }
                                    handelReduseReturnQuantity={
                                      handelReduseReturnQuantity
                                    }
                                    data={data}
                                  />
                                </Box>
                              </>
                            ) : null}
                          </Box>
                        ) : (
                          <Button
                            variant="contained"
                            loading={loadingStates[data.id]}
                            sx={{
                              bgcolor: appColors.textColorNew,
                              height: "1.8rem",
                              color: "#fff",
                              marginTop: ".5rem",
                              fontSize: ".8rem",
                            }}
                            onClick={() => handelAddProducts(data)}
                            size="small"
                          >
                            Add +
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {data.quantity >= data.min_book_qty && cardValue === 1 && (
                <Box
                  sx={{
                    bgcolor: "#bfefff",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "102%",
                    // position: "absolute",
                    bottom: 0,
                  }}
                >
                  <span
                    style={{
                      color: "#58595b",
                      bottom: 0,
                      marginLeft: ".5rem",
                      fontSize: ".8rem",
                      fontWeight: "bold",
                    }}
                  >
                    Return &nbsp;
                    {data?.return === undefined
                      ? getMaxReturn(data?.capacity, data?.material)
                      : data?.return}
                  </span>
                  <span
                    style={{
                      color: "#58595b",
                      bottom: 0,
                      right: 0,
                      fontSize: ".8rem",
                    }}
                  >
                    {!returnCanFlags[data.id] ? "More" : "Close"}
                    &nbsp;
                    <span
                      style={{
                        backgroundColor: appColors.textColorNew,
                        color: "#fff",
                        paading: ".4rem",
                        borderRadius: ".2rem",
                      }}
                      onClick={() => handelOpenReturnButton(data)}
                    >
                      {returnCanFlags[data.id] ? (
                        <KeyboardArrowUpIcon sx={{ fontSize: "20px" }} />
                      ) : (
                        <KeyboardArrowDownIcon sx={{ fontSize: "20px" }} />
                      )}
                    </span>
                  </span>
                </Box>
              )}
              {true && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    bgcolor: appColors.textColorNew,
                    borderRadius: "0", // Set the border radius to 0 (no rounding)
                    borderTopRightRadius: "5px",
                    borderBottomLeftRadius: "5px",
                    boxShadow: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: ".7rem",
                      color: "#fff",
                      padding: ".1rem .1rem 0 .3rem",
                    }}
                  >
                    {cardValue === 1 ? "Refill" : "One Time"} &nbsp; &nbsp;
                  </Typography>
                </Box>
              )}
              <Box
                sx={{
                  position: "absolute",
                  top: -2,
                  left: 6,
                  cursor: "pointer",
                }}
                onClick={() => handelInfoIcon(data)}
              >
                {/* <img src={iIcon} alt="" /> */}
              </Box>
            </Card>
          );
        })}
      </Box>
    </>
  );
}

export default BookingCardsSku;
