import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Tabs,
  Tab,
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
  Paper,
  TextField,
  DialogActions,
  SwipeableDrawer,
} from "@mui/material";
import React, { useContext } from "react";
import Slide from "@mui/material/Slide";
import Scanner from "../../Components/QrCodeScanner/Scanner";
import { batch } from "react-redux";
import { UserContext } from "./BatchDetailsTable";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { Style } from "@mui/icons-material";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export function ScannerDialog({
  open,
  setOpenLinkDialog,
  currentScanned,
  setCurrentScanned,
}) {
  const data = {
    valid: 1,
    invalid: 2,
    invalid_data: [
      { scannedPosition: 2, id1: "BWQ002", id2: "BWC00184" },
      { scannedPosition: 3, id1: "BWQ003", id2: "BWC00185" },
    ],
    valid_data: [
      {
        scannedPosition: 1,
        qrcode: "BWQ00193",
        barcode: "BWC00183",
      },
    ],
  };
  const moreDetails = useContext(UserContext);
  //1.useState
  //1.Opening the camera and scanner
  const [openCamera, setOpenCamera] = React.useState(false);
  const [openScanner, setOpenScanner] = React.useState(false);

  //2.Open Show Scanned
  const [showScanned, setShowScanned] = React.useState(false);
  const [Open, setOpen] = React.useState(false);

  const [value, setValue] = React.useState(0);
  const [codeId, setCodeId] = React.useState("");
  const [qrCodeValue, setQrCodeValue] = React.useState("");
  const [arrayOfQRValue, setArrayOfQRValue] = React.useState([]);
  const [showScannedPositionValid, setShowScannedPositionValid] =
    React.useState(false);
  const [showScannedPositionInvalid, setShowScannedPositionInvalid] =
    React.useState(false);
  const [selectedPosition, setSelectedPosition] = React.useState([]);
  const [validData, setValidData] = React.useState([]);
  const [invalidData, setInvalidData] = React.useState([]);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [deselectedItems, setDeselectedItems] = React.useState([]);
  const [openNotifyDialog, setOpenNotifyDialog] = React.useState(false);

  const StyledBox = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
  }));

  //2.useEffect
  React.useEffect(() => {
    if (qrCodeValue !== "") {
      if (!currentScanned.includes(qrCodeValue)) {
        const newScannedCode = { id: qrCodeValue };
        setCurrentScanned([...currentScanned, newScannedCode]);
      } else {
        console.log("Already scanned");
      }
    }
    setTimeout(() => {
      setQrCodeValue("");
      setCodeId("");
    }, 500);
  }, [qrCodeValue]);

  React.useEffect(() => {
    setValidData(data.valid_data);
    setInvalidData(data.invalid_data);
    localStorage.setItem("valid_data", JSON.stringify(data?.valid_data));
  }, [data]);

  //3.Button Functionality
  // const handleClickScanned = () => {
  //   setShowScanned(true);
  // }

  const handleClickCamera = () => {
    setOpenCamera(true);
  };

  const handleClickScanner = () => {
    setOpenScanner(true);
  };

  const handleClose = () => {
    setOpenLinkDialog(false);
  };

  const handleCloseShow = () => {
    setShowScanned(false);
  };

  const handleCloseCamera = () => {
    setOpenCamera(false);
  };

  const handleCloseScanner = () => {
    setOpenScanner(false);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  //4.Functions

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // const handleChangeQRArray = () => {
  //   const newValue = {id:qrCodeValue}
  //   if(qrCodeValue.trim() !== ""){
  //     setArrayOfQRValue([...arrayOfQRValue,{id:qrCodeValue}]);
  //     setQrCodeValue("")
  //     //console.log("Updated Array :",[...arrayOfQRValue,newValue])
  //   }

  const onNewScanResult = (decodedText, decodedResult) => {
    console.log("App [result]", decodedResult);
  };

  const handleChangeQRCode = (e) => {
    setCodeId(e.target.value);
    setTimeout(() => {
      if (e.target.value !== qrCodeValue) {
        setQrCodeValue(e.target.value);
      } else {
        console.log("Codes not set");
      }
    }, 500);
  };

  const handleItemClick = (scannedPosition, item) => {
    setSelectedPosition((prevSelectedPositions) => {
      if (prevSelectedPositions.includes(scannedPosition)) {
        return prevSelectedPositions.filter(
          (selectedPosition) => selectedPosition !== scannedPosition
        );
      } else {
        return [...prevSelectedPositions, scannedPosition];
      }
    });

    setSelectedItems((prevSelectedItems) => {
      const isSelected = prevSelectedItems.some(
        (selectedItem) => selectedItem.scannedPosition === scannedPosition
      );

      if (isSelected) {
        setDeselectedItems((prevDeselectedItems) => {
          return [
            ...prevDeselectedItems,
            ...(prevDeselectedItems.includes(item) ? [] : [item]),
          ];
        });
        return prevSelectedItems.filter(
          (selectedItem) => selectedItem.scannedPosition !== scannedPosition
        );
      } else {
        setDeselectedItems((prevDeselectedItems) =>
          prevDeselectedItems.filter(
            (deselectedItems) =>
              deselectedItems.scannedPosition !== scannedPosition
          )
        );
        return [...prevSelectedItems, item];
      }
    });
  };

  const handleLinkValidCans = () => {
    setTimeout(() => {
      setCurrentScanned([]);
      setValidData([]);
      setInvalidData([]);
      localStorage.removeItem("valid_data");
      setOpenLinkDialog(false);
    }, 1000);
  };

  const handleReLinkValidCans = () => {
    setTimeout(() => {
      setCurrentScanned([]);
      setValidData([]);
      setInvalidData([]);
      setOpenLinkDialog(false);
    }, 1000);
  };

  const handleCloseNotifyDialog = () => {
    setOpenLinkDialog(false);
  };

  const handleConfirmLink = () => {
    setOpenNotifyDialog(false);
    setShowScanned(true);
  };

  const handleLink = () => {
    setOpenNotifyDialog(true);
  };

  return (
    <>
      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle></DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px",
                marginBottom: "10px",
                //background: 'linear-gradient(to right bottom, #430089, #82ffa1)'
              }}
            >
              <Button
                sx={{
                  width: "100px",
                  height: "50px",
                  backgroundColor: "skyblue",
                  color: "black",
                  borderRadius: "10px",
                }}
                onClick={handleClickCamera}
              >
                Camera
              </Button>
              <Button
                sx={{
                  marginLeft: "10px",
                  width: "100px",
                  height: "50px",
                  backgroundColor: "skyblue",
                  color: "black",
                  borderRadius: "10px",
                }}
                onClick={handleClickScanner}
              >
                Scanner
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
        {openCamera && (
          <Dialog open={openCamera} onClose={handleCloseCamera}>
            <Box>
              <DialogTitle sx={{ textAlign: "center" }}>Camera</DialogTitle>
              <DialogContent>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <Scanner
                    fps={10}
                    qrbox={250}
                    disableFlip={false}
                    qrCodeSuccessCallback={onNewScanResult}
                  />
                  <Typography>Current Scanned : </Typography>
                  {/* <Button sx={{width:"150px",height:"50px",backgroundColor:"seashell",color:"black"}} onClick={handleClickScanned}>Show Scanned</Button> */}
                </Box>
              </DialogContent>
            </Box>
            <DialogActions sx={{ justifyContent: "center" }}>
              <Button
                sx={{
                  width: "150px",
                  height: "50px",
                  backgroundColor: "seashell",
                  color: "black",
                }}
                onClick={handleLink}
              >
                Verify
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {openScanner && (
          <Dialog open={openScanner} onClose={handleCloseScanner}>
            <DialogTitle>Scanner</DialogTitle>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <TextField
                onChange={handleChangeQRCode}
                sx={{ margin: "10px" }}
                InputLabelProps={{
                  shrink: true,
                }}
                label="QR/Barcode:"
                value={codeId}
              />
              <Typography>Current Scanned: {currentScanned.length} </Typography>
            </Box>
            <DialogActions sx={{ justifyContent: "center" }}>
              <Button
                sx={{
                  width: "150px",
                  height: "50px",
                  backgroundColor: "seashell",
                  color: "black",
                }}
                onClick={handleLink}
              >
                Verify
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {showScanned && (
          <Dialog open={showScanned} onClose={handleCloseShow}>
            <DialogTitle sx={{ textAlign: "center" }}>
              Scanned Details
            </DialogTitle>

            <Box sx={{ display: "flex" }}>
              {data?.valid_data.length && currentScanned.length !== 0 ? (
                <Card
                  sx={{
                    minWidth: 275,
                    backgroundColor: "#32a850",
                    margin: "10px",
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <Typography>
                      Valid Cans Scanned: {validData.length}
                    </Typography>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() =>
                        setShowScannedPositionValid(!showScannedPositionValid)
                      }
                      endIcon={
                        showScannedPositionValid ? (
                          <KeyboardArrowDownRoundedIcon />
                        ) : (
                          <KeyboardArrowUpRoundedIcon />
                        )
                      }
                    >
                      Show scanned position
                    </Button>
                    {showScannedPositionValid && (
                      <>
                        <Typography>Scanned Position</Typography>
                        <Grid container spacing={2}>
                          {data?.valid_data?.map((item) => {
                            return (
                              <Grid item key={item.scannedPosition}>
                                <Paper
                                  style={{
                                    width: "50px", // Adjust the width and height as needed
                                    height: "50px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                    background: selectedPosition.includes()
                                      ? "#a69595"
                                      : "black",
                                    opacity: selectedPosition.includes()
                                      ? 0.7
                                      : 1,
                                  }}
                                  elevation={3}
                                >
                                  {item.scannedPosition}
                                </Paper>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={handleLinkValidCans}
                        size="small"
                      >
                        Link Valid Cans
                      </Button>
                      <Button
                        variant="contained"
                        onClick={handleReLinkValidCans}
                        size="small"
                      >
                        Re-Link valid cans
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              ) : (
                ""
              )}

              {data?.invalid_data?.length && currentScanned.length !== 0 ? (
                <Card
                  sx={{
                    minWidth: 275,
                    backgroundColor: "#a83232",
                    margin: "10px",
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <Typography>Invalid Cans Scanned:</Typography>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() =>
                        setShowScannedPositionInvalid(
                          !showScannedPositionInvalid
                        )
                      }
                      endIcon={
                        showScannedPositionInvalid ? (
                          <KeyboardArrowDownRoundedIcon />
                        ) : (
                          <KeyboardArrowUpRoundedIcon />
                        )
                      }
                    >
                      Show Scanned Position
                    </Button>
                  </CardContent>
                </Card>
              ) : (
                ""
              )}
            </Box>
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                setCurrentScanned([]);
              }}
            >
              Link From Start
            </Button>
          </Dialog>
        )}
        {openNotifyDialog && (
          <Dialog
            open={openNotifyDialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
          >
            <DialogContent>
              <Typography>Do you want to verify the scanned cans?</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseNotifyDialog}>Cancel</Button>
              <Button onClick={handleConfirmLink}>Yes</Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    </>
  );
}
