import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Divider,
  Box,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import EditOrderDialog from "./EditOrderDailog";
// import EditOrderDialog from "./EditOrderDialog";

const OrderDetailsDialog = ({ open, onClose, order }) => {
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [orderQty, setOrderQty] = useState(order.products[0].quantity); // Assuming a single product
  const [returnQty, setReturnQty] = useState(0); // Initialize with 0 or from order data if available

  const handleEditClick = () => {
    setEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
  };

  const handleQtyChange = (newOrderQty, newReturnQty) => {
    setOrderQty(newOrderQty);
    setReturnQty(newReturnQty);
  };

  if (!order) return null;

  const {
    order_id,
    delivery_date,
    delivery_address,
    products,
    final_price,
    order_status,
    payment_status,
    delivery_status,
  } = order;

  const formattedDate = new Date(delivery_date).toLocaleDateString();

  const styles = {
    dialogTitle: {
      color: "#ecf0f1",
    },
    sectionTitle: {
      color: "#3498db",
    },
    text: {
      color: "#ecf0f1",
    },
    divider: {
      backgroundColor: "#ecf0f1",
      margin: "16px 0",
    },
    statusBox: {
      color: "#e67e22",
      border: "1px solid #e67e22",
      borderRadius: "4px",
      padding: "4px 8px",
    },
    button: {
      primary: {
        backgroundColor: "#3498db",
        color: "#ecf0f1",
      },
      secondary: {
        backgroundColor: "#e74c3c",
        color: "#ecf0f1",
      },
    },
    dialog: {
      backgroundColor: "#2c3e50",
    },
    row: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "8px 0",
    },
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle style={styles.dialogTitle}>{order_id}</DialogTitle>
      <DialogContent style={styles.dialog}>
        <Typography variant="h6" style={styles.sectionTitle}>
          Delivery Address
        </Typography>
        <Typography style={styles.text}>
          {delivery_address.area_name}, {delivery_address.district},{" "}
          {delivery_address.state}, {delivery_address.pincode}
        </Typography>
        <Divider style={styles.divider} />

        <Box style={styles.row}>
          <Typography variant="h6" style={styles.text}>
            Delivery Status
          </Typography>
          <Typography variant="body1" style={styles.statusBox}>
            {delivery_status === 100 ? "Ongoing" : "Completed"}
          </Typography>
        </Box>

        <Box style={styles.row}>
          <Typography variant="h6" style={styles.text}>
            Delivery Date
          </Typography>
          <Typography style={styles.text}>{formattedDate}</Typography>
        </Box>

        <Box style={styles.row}>
          <Typography variant="h6" style={styles.text}>
            Order Type
          </Typography>
          <Typography style={styles.text}>{order.order_type}</Typography>
        </Box>

        <Box style={styles.row}>
          <Typography variant="h6" style={styles.text}>
            Total Delivery Charge
          </Typography>
          <Typography style={styles.text}>
            ₹{parseFloat(order.total_delivery_charge).toFixed(2)}
          </Typography>
        </Box>

        <Box style={styles.row}>
          <Typography variant="h6" style={styles.text}>
            Discount(s) Applied
          </Typography>
          <Typography style={styles.text}>
            ₹{parseFloat(order.discount).toFixed(2)}
          </Typography>
        </Box>

        <Box style={styles.row}>
          <Typography variant="h6" style={styles.sectionTitle}>
            Order Details
          </Typography>
          <IconButton size="small" onClick={handleEditClick}>
            <Typography style={{ color: "#e74c3c", marginRight: 4 }}>Edit</Typography>
            <EditIcon style={{ color: "#e74c3c", fontSize: 16 }} />
          </IconButton>
        </Box>

        <Typography style={styles.text}>
          {products.map((product, index) => (
            <div key={index}>
              {orderQty} x {product.name} - ₹
              {parseFloat(product.final_price).toFixed(2)}
            </div>
          ))}
        </Typography>

        <Box style={styles.row}>
          <Typography variant="h6" style={styles.text}>
            Total Price
          </Typography>
          <Typography style={styles.text}>
            ₹{parseFloat(final_price).toFixed(2)}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} style={styles.button.primary}>
          CLOSE
        </Button>
      </DialogActions>

      <EditOrderDialog
        open={editDialogOpen}
        onClose={handleEditDialogClose}
        orderQty={orderQty}
        returnQty={returnQty}
        onQtyChange={handleQtyChange}
      />
    </Dialog>
  );
};

export default OrderDetailsDialog;
