/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Actions for getting , creating , and updating the manufacturing orders
 * @date 22/08/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import axios from "axios";
// import useAxios from "../../Hooks/useAxios";
import useAxios from "../../Hooks/useAxios";
import { Dispatch } from "redux";
import { toast } from "react-toastify";
import actionType from "../actionTypes";

export const CreateManufacturingOrders = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  console.log(reqObj);
  const obj = {
    mft_details: {
      phone: "8234567890",
      email: "restavt@acn.co",
      mft_name: "zsds",
      pincode: "127356",
      area_name: "zdf",
    },
    po_file: "sdfs.pdf",
    quantity: 1,
    product_id: 7,
    price: 10,
  };
  return async (dispatch) => {
    try {
      let response = await api.post(`/POPM/mft/order`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.CREATE_MFT_ORDER,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};

export const GetManufacuringOrders = (page_number, page_size) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  let reqObj = {};
  if (page_number === undefined || page_size === undefined) {
    reqObj = {};
  } else {
    reqObj = {
      page_number,
      page_size,
    };
  }
  return async (dispatch) => {
    try {
      let response = await api.get(
        `/POPM/mft/orders?` + new URLSearchParams(reqObj)
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_MFT_ORDERS,
          payload: response.data,
        });
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};

export const MftOrdersApproval = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  const obj = {
    is_po: true,
    is_approved: false,
    order_id: "BWMFT009",
  };

  return async (dispatch) => {
    try {
      let response = await api.put(`/POPM/mft/approval`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.MFT_ORDER_APPROVAL,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};

export const MftEditOrder = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  const obj = {
    order_id: "BWMFT009",
    quantity: 1,
    price: 1,
    po_file: "ahtchk.jpg",
    payment_receipt: "ahtchk.jpg",
  };
  return async (dispatch) => {
    try {
      let response = await api.put(`/POPM/mft/edit_order`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.EDIT_MFT_ORDER,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};
export const MftCompleteOrder = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  const obj = {
    order_id: "BWMFT009",
    is_complete: true,
  };
  return async (dispatch) => {
    try {
      let response = await api.put(`/POPM/mft/cancel/complete`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.COMPLETE_MFT_ORDER,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};

export const ConfirmProcessingPartnerOrder = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  console.log(reqObj);
  return async (dispatch) => {
    try {
      let response = await api.put(
        `/POPM/mft/confirm_processing_partner`,
        reqObj
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.CONFIRM_PROCESSING_PARTNER_ORDER,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};
