import React from "react";
import {
  Box,
  Button,
  Dialog,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  ForgotPasswordNewPassword,
  ResetPasswordEmailVerify,
} from "../../Redux/Actions/authenticationAction";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

function ForgotPasswordDialog({
  forgotPassword,
  setForgotPassword,
  verificationCodeSent,
  setVerificationCodeSent,
}) {
  const dispatch = useDispatch();
  const [isEmailProperForVerify, setIsEmailProperForVerify] =
    React.useState(false);
  const [resetEmail, setResetEmail] = React.useState("");
  const [verificationCode, setVerificationCode] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");

  const [values, setValues] = React.useState({
    showPassword: false,
  });
  const handleCloseDialog = () => {
    setForgotPassword(false);
    setVerificationCodeSent(false);
    setResetEmail("");
    setIsEmailProperForVerify(false);
    setVerificationCode("");
    setNewPassword("");
  };

  const handleResetVerifyEmail = (event) => {
    let reg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{3,7}$/;
    var result = reg.test(event.target.value);
    console.log(result);
    setIsEmailProperForVerify(result);
    setResetEmail(event.target.value);
  };

  const handleChangeVerificationCode = (event) => {
    setVerificationCode(event.target.value);
  };

  const handleChangeNewPassword = (event) => {
    setNewPassword(event.target.value);
  };
  const handleClickShowPassword = () => {
    setValues({ showPassword: !values.showPassword });
  };
  const handleSendEmail = () => {
    if (isEmailProperForVerify === true) {
      dispatch(ResetPasswordEmailVerify(resetEmail));
    } else {
      toast.error("Please enter your email address", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const handleClickNewPassword = () => {
    if (newPassword !== "") {
      dispatch(ForgotPasswordNewPassword(newPassword, verificationCode));
    } else {
      toast.error("Please enter your password", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <div>
      {" "}
      <Dialog open={forgotPassword}>
        <Paper elevation="24" sx={{ borderRadius: "10px" }} className="drawer">
          <Box sx={{ padding: "5px", cursor: "pointer" }}>
            <CloseRoundedIcon onClick={handleCloseDialog} />
          </Box>
          <Box sx={{ padding: "20px" }}>
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "18px",
              }}
            >
              Trouble in signing in?
            </Typography>

            <Typography
              sx={{
                textAlign: "left",
                padding: "10px",
                marginTop: "5px",
                marginBottom: "5px",
              }}
              variant="body2"
              gutterBottom
            >
              Enter the email address associated with your account to change
              your password.
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleResetVerifyEmail}
              error={isEmailProperForVerify === false ? true : false}
              helperText={
                isEmailProperForVerify === false &&
                "Please enter a valid email address"
              }
            />
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              fullWidth
              onClick={handleSendEmail}
            >
              Send Verification code
            </Button>
            {verificationCodeSent && (
              <>
                <Box>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Verification code"
                    autoFocus
                    onChange={handleChangeVerificationCode}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="New Password"
                    type={values.showPassword ? "text" : "password"}
                    id="password"
                    autoComplete="current-password"
                    onChange={handleChangeNewPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            onClick={handleClickShowPassword}
                          >
                            {values.showPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    fullWidth
                    onClick={handleClickNewPassword}
                  >
                    Change password
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Paper>
      </Dialog>
    </div>
  );
}

export default ForgotPasswordDialog;
