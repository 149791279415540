import React from "react";
import OrderStepper1 from "../Orders/OrganizationBooking/OrganisationOrderManagement/OrderStepper1";

function Home() {
  return (
    <div>
      <OrderStepper1 />
    </div>
  );
}

export default Home;
