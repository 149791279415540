import axios from "axios";
import useAxios from "../../Hooks/useAxios";
import { Dispatch } from "redux";
import actionType from "../actionTypes";
import { toast } from "react-toastify";

export const GetProfile = () => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/PSCM/user/profile`);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_PROFILE,
          payload: response.data,
        });
        if (response.data.msg) {

        } else {

        }
      } else {

      }
    } catch (error) {
      console.log(error);
      // toast.error(error.response.data.msg, {
      //   position: "top-right",
      //   autoClose: 1000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  };
};
