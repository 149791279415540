import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CustomInputTextField from "../../../Components/CustomComp/CustomInputTextField";
import appMeta from "../../../AppMeta/appMeta";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  GetGeneratedQR,
  RegenerateQr,
} from "../../../Redux/Actions/GenerateQrAction";
import { toast } from "react-toastify";
import { GetManufacuringOrders } from "../../../Redux/Actions/manufacturingOrdersAction";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RegenerateQrDialog({
  openRegenerateQrDialog,
  setOpenRegenerateQrDialog,
  setIsQr,
  isQr,
  pageNumber,
  pageSize,
  createdAtSort,
  qrBarcodeId,
  orderId,
}) {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = React.useState("");
  const [isValidQuantity, setIsValidQuantity] = React.useState(true);
  const handleClose = () => {
    setOpenRegenerateQrDialog(false);
  };

  const handleAgree = () => {
    console.log(quantity === "0");
    const reqObj = {
      is_qr: isQr,
      quantity: quantity,
    };
    if (isValidQuantity === true && quantity !== "" && quantity !== "0") {
      dispatch(RegenerateQr(reqObj));
      setOpenRegenerateQrDialog(false);

      setTimeout(() => {
        dispatch(
          GetGeneratedQR(
            pageNumber,
            pageSize,
            createdAtSort,
            isQr,
            qrBarcodeId,
            orderId
          )
        );
      }, 1000);
    } else {
      toast.error("Enter Proper Quantity", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  return (
    <div>
      <Dialog
        open={openRegenerateQrDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Regenerate QR Codes"}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              marginTop: "10px",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                QR/Barcode
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                onChange={(event) => setIsQr(event.target.value)}
                value={isQr}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="QR Code"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Barcode"
                />
              </RadioGroup>
            </FormControl>
            <CustomInputTextField
              placeholder="Quantity"
              isRequired={true}
              type={appMeta.textFieldTypes.NUMBER}
              value={quantity}
              setValue={setQuantity}
              isValid={isValidQuantity}
              setIsValid={setIsValidQuantity}
              isHintRequired={true}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleAgree}>Regenerate</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
