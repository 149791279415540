/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Custom input field which is used all over the order
 * @date 26/05/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries
import React from "react";
import TextField from "@mui/material/TextField";
import appMeta from "../../AppMeta/appMeta";

const CustomInputTextField = (props) => {
  // Default values for isValid and setIsValid
  const [hint, setHint] = React.useState(false);
  const isValidDefault = props.isValid !== undefined ? props.isValid : true;
  const setIsValidDefault =
    props.setIsValid !== undefined ? props.setIsValid : () => { };

  const setDataForVariables = (state) => {
    console.log(state);
    setIsValidDefault(state);
    if (props.isHintRequired) setHint(!state);
  };

  const handleChange = (event) => {
    const emailRegEx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    const phoneRegEx = /^[6-9][0-9]{9}$/;
    const pinCodeRegEx = /^[1-9][0-9]{5}$/;
    const numberRegEx = /^[0-9]*$/;

    const data = event.target.value + "";
    if (props.type === appMeta.textFieldTypes.PHONE && data.length < 11) {
      props.setValue(data);
      setDataForVariables(phoneRegEx.test(data));
    } else if (
      props.type === appMeta.textFieldTypes.PINCODE &&
      data.length <= 6
    ) {
      props.setValue(data);
      setDataForVariables(pinCodeRegEx.test(data));
    } else if (props.type === appMeta.textFieldTypes.EMAIL) {
      props.setValue(data);
      // setDataForVariables(emailRegEx.test(data));
    } else if (props.type === appMeta.textFieldTypes.NUMBER) {
      console.log("Number");
      props.setValue(data);
      setDataForVariables(numberRegEx.test(data));
    } else if (
      props.type !== appMeta.textFieldTypes.PINCODE &&
      props.type !== appMeta.textFieldTypes.PHONE &&
      props.type !== appMeta.textFieldTypes.NUMBER
    ) {
      console.log("before else");
      props.setValue(data);
      setDataForVariables(false);
    } else {
      // Max Limit Exceedsc
      console.log("else part");
    }
    switch (props.type) {
      case appMeta.textFieldTypes.EMAIL:
        emailRegEx.test(data)
          ? setDataForVariables(true)
          : setDataForVariables(false);
        break;
      case appMeta.textFieldTypes.PHONE:
      case appMeta.textFieldTypes.PINCODE:
        break;
      case appMeta.textFieldTypes.NUMBER:
        break;
      case appMeta.textFieldTypes.EMAIL_PHONE:
        emailRegEx.test(data)
          ? setDataForVariables(true)
          : phoneRegEx.test(data)
            ? setDataForVariables(true)
            : setDataForVariables(false);
        break;
      default:
        setDataForVariables(props.isRequired && data.length);
        break;
    }
  };

  return (
    <div className="card flex justify-content-center">
      <TextField
        size="small"
        value={props.value}
        onChange={handleChange}
        error={!isValidDefault} // Use default value for isValid
        label={props.placeholder}
        helperText={hint ? "Incorrect entry." : ""}
        inputProps={{
          maxLength:
            props.type === "phone" ? 10 : props.type === "pincode" ? 6 : 35,
        }}
        required={props.isRequired}
      />
    </div>
  );
};

export default CustomInputTextField;
