import { Reducer } from "redux";
import actionType from "../actionTypes";


const initialState = {
    error: false,
    msg: "",
    crates: [],
  };

  
  const emptyCanOrderReducer = (state = initialState, action) => {
    console.log(action);
  switch (action.type) {
    case actionType.GET_EMPTY_NEW_CAN_ORDER:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        crates: action.payload.data,
      };
    case actionType. UPDATE_EMPTY_NEW_CAN_ORDER:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType. DELETE_EMPTY_NEW_CAN_ORDER:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };

    default:
      return state;
  }
  };
  
  export default emptyCanOrderReducer ;
  