import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { SearchRounded } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Popover,
  MenuItem,
  FormControl,
  Select,
  Grid,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "50px",
    width: "250px", // Set a fixed width for the container
    boxShadow: theme.shadows[3],
    borderRadius: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 15px",
    margin: "10px",
    transition: theme.transitions.create("box-shadow"),
    "&:hover": {
      boxShadow: theme.shadows[6],
    },
  },
  textField: {
    width: "100%", // Ensure the TextField takes up the full width of the container
  },
}));

export function FilterOptions(props) {
  const classes = useStyles();
  const [showTextField, setShowTextField] = useState(false);

  const handleMouseEnter = () => {
    setShowTextField(true);
  };

  const handleMouseLeave = () => {
    setShowTextField(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={handleClick} variant="contained">Filter</Button>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box>
            <Box
              className={classes.container}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {showTextField ? (
               
                <TextField
                variant="standard"
                size="small"
                className={classes.textField}
              />
              ) : (
                <Typography>Search By Order ID</Typography>
              )}

              <SearchRounded />
            </Box>
            {[1, 2, 3, 4, 5].map((item) => (
              <Grid item xs={4} key={item}>
                <Box className={classes.container}>
                  <FormControl className={classes.formControl} fullWidth>
                    <Select
                      labelId={`demo-simple-select-label-${item}`}
                      id={`demo-simple-select-${item}`}
                      variant="standard"
                      label="Age"
                      classes={{
                        select: classes.select,
                        icon: classes.selectIcon,
                      }}
                    >
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            ))}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center",margin:"10px" }}>
            <Button variant="contained">Submit</Button>
          </Box>
        </Popover>
      </Box>
    </>
  );
}

// import React, { useState } from "react";
// import {
//   Box,
//   TextField,
//   InputLabel,
//   FormControl,
//   Select,
//   MenuItem,
//   createTheme,
//   Button,
// } from "@mui/material";
// import SearchIcon from "@mui/icons-material/Search";
// import FilterIcon from "@mui/icons-material/FilterAlt";
// import { makeStyles } from "@mui/styles";

// const useStyles = () =>
//   makeStyles((theme) => ({
//     mainBox: {
//       display: "flex",
//       boxShadow: "10px",
//       width: "100%",
//       justifyContent: "space-between",
//       alignItems: "center",
//       "& > *": {
//         marginRight: theme.spacing(2), // Adjust the spacing as needed
//       },
//     },
//   }));

// export function FilterOptions() {
//   const [open, setOpen] = useState(false);
//   const classes = useStyles();

//   const handleToggle = () => {
//     setOpen(!open);
//   };

//   return (
//     <div>
//       <Box className={classes.mainBox}>
//         <TextField
//           label="Search By Order ID"
//           variant="standard"
//           sx={{ width: "220px", margin: "15px" }}
//         />
//         <FormControl
//           variant="standard"
//           sx={{ width: "220px", margin: "15px" }}
//           maxWidth="sm"
//         >
//           <InputLabel id="demo-simple-select-label">
//             Search By Product
//           </InputLabel>
//           <Select
//             labelId="demo-simple-select-label"
//             id="demo-simple-select"
//             label="Search By Product"
//           >
//             <MenuItem value={10}>20L Can</MenuItem>
//             <MenuItem value={20}>1L Bottle</MenuItem>
//             <MenuItem value={30}>500mL Bottle</MenuItem>
//           </Select>
//         </FormControl>
//         <FormControl
//           variant="standard"
//           sx={{ width: "220px", margin: "15px" }}
//           maxWidth="sm"
//         >
//           <InputLabel id="demo-simple-select-label">Payment Type</InputLabel>
//           <Select
//             labelId="demo-simple-select-label"
//             id="demo-simple-select"
//             label="Order Status"
//           >
//             <MenuItem value={10}>PREPAID</MenuItem>
//             <MenuItem value={20}>POSTPAID</MenuItem>
//           </Select>
//         </FormControl>
//         <FormControl
//           variant="standard"
//           sx={{ width: "220px", margin: "15px" }}
//           maxWidth="sm"
//         >
//           <InputLabel id="demo-simple-select-label">Payment Status</InputLabel>
//           <Select
//             labelId="demo-simple-select-label"
//             id="demo-simple-select"
//             label="Payment Status"
//           >
//             <MenuItem value={10}>SUCCESS</MenuItem>
//             <MenuItem value={20}>PENDING</MenuItem>
//           </Select>
//         </FormControl>
//         <FormControl
//           variant="standard"
//           sx={{ width: "220px", margin: "15px" }}
//           maxWidth="sm"
//         >
//           <InputLabel id="demo-simple-select-label">Order Status</InputLabel>
//           <Select
//             labelId="demo-simple-select-label"
//             id="demo-simple-select"
//             label="Payment Status"
//           >
//             <MenuItem value={10}>COMPLETED</MenuItem>
//             <MenuItem value={20}>PENDING</MenuItem>
//           </Select>
//         </FormControl>
//         <Button
//           startIcon={<FilterIcon/>}
//           sx={{ margin: "15px" }}
//           variant="contained"
//           color="primary"
//         >
//           Filter
//         </Button>
//       </Box>
//     </div>

//     //     <div>
//     //       <Button
//     //         onClick={handleToggle}
//     //         variant="contained"
//     //         sx={{
//     //           marginTop: "20px",
//     //           borderRadius: "30px",
//     //           textAlign: "start",
//     //           backgroundColor: "black",
//     //           padding: "8px",
//     //           display: "flex",
//     //           width: "120px",
//     //           height: "40px",
//     //           cursor: "pointer",
//     //           position: "relative",
//     //         }}
//     //       >
//     //         <Filter sx={{ color: "white", marginRight: "10px" }} /> Filter
//     //       </Button>
//     //       <Slide direction="right" in={open} mountOnEnter unmountOnExit>
//     //         <Box
//     //           sx={{
//     //             height: "100px",
//     //             width: "900px",
//     //             backgroundColor: "#27b5f2",
//     //             borderRadius: "50px",
//     //             marginTop: "10px",
//     //             display: "flex",
//     //             //width:{xs:"19rem",md:"100%"}
//     //             overflow:"hidden"
//     //           }}
//     //         >
//     //           <Box
//     //             sx={{
//     //               height: "55px",
//     //               width: "250px",
//     //               backgroundColor: "white",
//     //               borderRadius: "10px",
//     //               marginTop: "20px",
//     //               marginLeft: "20px",
//     //               marginRight: "20px",
//     //             }}
//     //           >
//     //             <TextField sx={{ borderRadius: "20px" }} label="Search By Order ID">
//     //               <SearchIcon />
//     //             </TextField>
//     //           </Box>

//     //           <Box
//     //             sx={{
//     //               height: "55px",
//     //               width: "250px",
//     //               backgroundColor: "white",
//     //               borderRadius: "10px",
//     //               marginTop: "20px",
//     //               marginLeft: "20px",
//     //               marginRight: "20px",
//     //             }}
//     //           >
//     //             <FormControl fullWidth>
//     //   <InputLabel id="demo-simple-select-label">Search By Product</InputLabel>
//     //   <Select
//     //     labelId="demo-simple-select-label"
//     //     id="demo-simple-select"

//     //     label="Search By Product"
//     //   >
//     //     <MenuItem value={10}>20L Can</MenuItem>
//     //     <MenuItem value={20}>1L Bottle</MenuItem>
//     //     <MenuItem value={30}>500mL Bottle</MenuItem>
//     //   </Select>
//     // </FormControl>
//     //           </Box>

//     //           <Box
//     //             sx={{
//     //               height: "55px",
//     //               width: "250px",
//     //               backgroundColor: "white",
//     //               borderRadius: "10px",
//     //               marginTop: "20px",
//     //               marginLeft: "20px",
//     //               marginRight: "20px",
//     //             }}
//     //           >
//     //             <FormControl fullWidth>
//     //               <InputLabel id="demo-simple-select-label">
//     //                 Payment Type
//     //               </InputLabel>
//     //               <Select
//     //                 labelId="demo-simple-select-label"
//     //                 id="demo-simple-select"
//     //                 label="Order Status"
//     //               >
//     //                 <MenuItem value={10}>PREPAID</MenuItem>
//     //                 <MenuItem value={20}>POSTPAID</MenuItem>
//     //               </Select>
//     //             </FormControl>
//     //           </Box>
//     //           <Box
//     //             sx={{
//     //               height: "55px",
//     //               width: "250px",
//     //               backgroundColor: "white",
//     //               borderRadius: "10px",
//     //               marginTop: "20px",
//     //               marginLeft: "20px",
//     //               marginRight: "20px",
//     //             }}
//     //           >
//     //             <FormControl fullWidth>
//     //               <InputLabel id="demo-simple-select-label">
//     //                 Order Status
//     //               </InputLabel>
//     //               <Select
//     //                 labelId="demo-simple-select-label"
//     //                 id="demo-simple-select"
//     //                 label="Order Status"
//     //               >
//     //                 <MenuItem value={10}>SUCCESS</MenuItem>
//     //                 <MenuItem value={20}>PENDING</MenuItem>
//     //                 <MenuItem value={30}>CANCELLED</MenuItem>
//     //               </Select>
//     //             </FormControl>
//     //           </Box>
//     //           <Box
//     //             sx={{
//     //               height: "55px",
//     //               width: "250px",
//     //               backgroundColor: "white",
//     //               borderRadius: "10px",
//     //               marginTop: "20px",
//     //               marginLeft: "20px",
//     //               marginRight: "20px",
//     //             }}
//     //           >
//     //             <FormControl fullWidth>
//     //               <InputLabel id="demo-simple-select-label">
//     //                 Payment Status
//     //               </InputLabel>
//     //               <Select
//     //                 labelId="demo-simple-select-label"
//     //                 id="demo-simple-select"
//     //                 label="Payment Status"
//     //               >
//     //                 <MenuItem value={10}>SUCCESS</MenuItem>
//     //                 <MenuItem value={20}>PENDING</MenuItem>
//     //                 <MenuItem value={30}>CANCELLED</MenuItem>
//     //               </Select>
//     //             </FormControl>
//     //           </Box>
//     //           <Button sx={{width:"300px",height:"50px",backgroundColor:"yellow",marginTop:"25px",marginRight:"20px"}}>Search<SearchIcon/></Button>
//     //         </Box>

//     //       </Slide>
//     //     </div>
//   );
// }
