/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Reducers for creating, updating and adding roles list
 * @date 26/05/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import { Reducer } from "redux";
import actionType from "../actionTypes";

const initialState = {
  error: false,
  msg: "",
  mftOrderId: "",
  mftOrders: [],
  mftApproval: {},
  mftEditOrder: {},
  mftCompleteOrder: {},
};

const manufacturingOrdersReducer = (state = initialState, action) => {
  // console.log(`action type: ${action.type}`);
  console.log(action);
  switch (action.type) {
    case actionType.CREATE_MFT_ORDER:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        mftOrderId: action.payload.data,
      };
    case actionType.GET_MFT_ORDERS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        mftOrders: action.payload.data,
      };
    case actionType.MFT_ORDER_APPROVAL:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        mftApproval: action.payload.data,
      };
    case actionType.EDIT_MFT_ORDER:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        mftEditOrder: action.payload.data,
      };
    case actionType.COMPLETE_MFT_ORDER:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        // mftCompleteOrder: action.payload.data,
      };
    case actionType.CONFIRM_PROCESSING_PARTNER_ORDER:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        mftCompleteOrder: action.payload.data,
      };
    default:
      return state;
  }
};

export default manufacturingOrdersReducer;
