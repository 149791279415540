import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from "@mui/material";

/**
 * @props outScanQty, setOutScanQty, openScanningQrlessDialog, setOpenScanningQrlessDialog
 **/

function ScanningDialogQrLess(props) {
  console.log("props");
  console.log(props);
  const handleClose = () => {
    props.setOpenScanningQrlessDialog(false);
  };

  const handleSubmit = () => {
    console.log("Submitted value:", props.outScanQty);
    props.setSubmitted(true)
    props.setOpenScanningQrlessDialog(false);
  };

  const handleInputChange = (event) => {
    props.setOutScanQty(event.target.value);
  };

  return (
    <div>
      <Dialog open={props.openScanningQrlessDialog} onClose={handleClose}>
        <DialogTitle>Scanning Dialog</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Input Field"
            type="number"
            fullWidth
            value={props.outScanQty}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ScanningDialogQrLess;
