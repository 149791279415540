import useAxios from "../../Hooks/useAxios";

import { toast } from "react-toastify";
import actionType from "../actionTypes";
import { ConstructionOutlined } from "@mui/icons-material";

export const AddLinesFacility = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  console.log(reqObj);

  return async (dispatch) => {
    try {
      let response = await api.post(`/PSCM/addLinesWfs`, reqObj);
      if (response.status == httpStatus.SUCCESS) {
        dispatch({
          type: actionType.ADD_LINK_FACILITY,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};
export const AddIotFacility = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  console.log(reqObj);

  return async (dispatch) => {
    try {
      let response = await api.post(`/PSCM/devices`, reqObj);
      if (response.status == httpStatus.SUCCESS) {
        dispatch({
          type: actionType.ADD_IOT_FACILITY,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};

export const GetLinesFacility = (facility_id) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  console.log("facility_id");
  console.log(facility_id);
  return async (dispatch) => {
    try {
      let response = await api.get(
        `/PSCM/getLineIdandIotDeviceNumber?facility_id=${facility_id}`
      );
      console.log("response");
      console.log(response);
      if (response.status == httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_LINK_FACILITY,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};
export const GetIotDevicesFacility = (facility_id) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  console.log("facility_id");
  console.log(facility_id);
  return async (dispatch) => {
    try {
      let response = await api.get(`/PSCM/devices`);
      console.log("response");
      console.log(response);
      if (response.status == httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_IOT_DEVICES,
          payload: response.data,
        });
        if (response.data.msg) {
          // toast.success(response.data.msg, {
          //   position: "top-right",
          //   autoClose: 1000,
          //   hideProgressBar: true,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "light",
          // });
        } else {
          // toast.success(response.data.msg, {
          //   position: "top-right",
          //   autoClose: 1000,
          //   hideProgressBar: true,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "light",
          // });
        }
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      console.log(error);
      // toast.error(error.response.data.msg, {
      //   position: "top-right",
      //   autoClose: 1000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  };
};

export const UpdateLinesFacility = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  console.log(reqObj);

  return async (dispatch) => {
    try {
      let response = await api.put(`/PSCM/linkIotToFacilityLines`, reqObj);
      if (response.status == httpStatus.SUCCESS) {
        dispatch({
          type: actionType.PUT_LINK_FACILITY,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};
