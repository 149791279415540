import React from 'react'
import { tokens } from '../../../../../AppMeta/theme';
import EditIcon from "@mui/icons-material/Edit";
import { Box, Grid, Typography, useTheme } from '@mui/material';
import Calendar from "../../../../../Assests/Images/calendar.png";

function DeliverDate() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <div>
            <Box m="10px">
                <Typography variant="h4" fontWeight="bold">
                    Delivery Date
                </Typography>
            </Box>

            <Box
                ml="10px"
                mt="30px"
                mr="10px"
                boxShadow={2}
                borderRadius="20px"
                position="relative"
                backgroundColor={colors.primary[400]}
            >
                <Grid container spacing={2}>
                    <Grid item md={4} xs={4} sm={4}>
                        <img
                            alt=""
                            style={{
                                marginLeft: "10px",
                            }}
                            src={Calendar}
                        />
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                        <Box mb="10px">
                            <Box
                                sx={{
                                    position: "absolute",
                                    right: 0,
                                    top: 0,
                                    borderRadius: "12px 0px 10px 0px",
                                    boxShadow: "4",
                                    backgroundColor: colors.greenAccent[500],
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                    padding: "2px",
                                    cursor: "pointer",
                                    zIndex: "4",
                                }}
                            >
                                <EditIcon />
                                <Typography fontWeight="bold">Edit</Typography>
                            </Box>
                            <Typography fontSize={{ xs: "0.8rem", md: "1rem" }}>
                                Tuesday
                            </Typography>
                            <Typography
                                fontWeight="bold"
                                fontSize={{ xs: "0.9rem", md: "1.1rem" }}
                            >
                                21
                            </Typography>
                            <Typography variant="h3" fontWeight="bold">
                                MAY
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default DeliverDate