import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import CachedIcon from "@mui/icons-material/Cached";
import InfoIcon from "@mui/icons-material/Info";
import { tokens } from "../../../AppMeta/theme";
import { Cancel, KeyboardArrowUpOutlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import WaterCan from "../../../AppMeta/AppImages/WaterCanImg.png";

function ProductCards({ name, type, rate }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showMore, setShowMore] = React.useState(false);

  const [buttonChange, setButtonChange] = React.useState(false);
  const handleChangeButton = () => {
    setButtonChange((prevState) => !prevState);
  };
  const handleShowMore = () => {
    setShowMore(!showMore);
  };
  return (
    <Box
      sx={{
        position: "relative",
        padding: "11px",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          right: "1rem",
          top: 0,
          borderRadius: "12px 12px 0px 12px",
          boxShadow: "3",
          backgroundColor: "skyblue",
        }}
        display="flex"
      >
        <CachedIcon />
        <Typography fontWeight="bold" color="black" textAlign="center">
          {type}
        </Typography>
      </Box>
      <Box
        boxShadow="2"
        mt="10px"
        borderRadius={3}
        backgroundColor={colors.primary[400]}
      >
        <Grid container spacing={1}>
          <Grid item md={2} xs={2} sm={2}>
            <Box position="relative">
              <Box color="skyblue" sx={{ position: "absolute", top: "-10px" }}>
                <InfoIcon />
              </Box>
            </Box>
            <img
              alt=""
              src={WaterCan}
              style={{
                width: "100%",
                objectFit: "contain",
                marginLeft: "10px",
                marginTop: "10px",
              }}
            />
          </Grid>
          <Grid item md={7} xs={6} sm={6}>
            <Box ml="10px" padding="5px 0px 5px 0px">
              <Typography
                component="span"
                color={colors.skyBlueAccent[100]}
                sx={{
                  whiteSpace: "nowrap",
                  //overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontWeight: "bold",
                  fontSize: { xs: "1.2rem", sm: "1.2rem", md: "1.3rem" },
                }}
              >
                {name}
              </Typography>
              <Box
                display="flex"
                sx={{
                  lineHeight: "normal",
                  margin: 0,
                  p: 0,
                }}
              >
                <Typography
                  mt="6px"
                  fontSize="1.2rem"
                  sx={{
                    textDecoration: "line-through",
                  }}
                >
                  &#8377;75{rate}
                </Typography>
                <Typography
                  color={colors.skyBlueAccent[100]}
                  fontSize="1.3rem"
                  ml="10px"
                  fontWeight="bold"
                >
                  &#8377;55
                </Typography>
              </Box>
              {!buttonChange && (
                <>
                  <Typography
                    fontWeight="bold"
                    fontSize={{ xs: "1rem", md: "1.2rem" }}
                    sx={{
                      lineHeight: "normal",
                      margin: 0,
                    }}
                  >
                    Minimum Qty: 2
                  </Typography>
                  <Typography variant="subtitle2">Description:</Typography>
                </>
              )}
              {buttonChange && (
                <Box
                  display="flex"
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={handleChangeButton}
                >
                  <Cancel color="error" />
                  <Typography ml="3px" color="error">
                    Remove
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item md={3} xs={4} sm={4}>
            <Box>
              {buttonChange && (
                <>
                  <Box mt="10px">
                    <Typography fontWeight="bold">Required</Typography>
                    <Box
                      display="flex"
                      justifyContent="space-evenly"
                      width="80px"
                      height="30px"
                      borderRadius="10px"
                      boxShadow={2}
                      backgroundColor={colors.primary[500]}
                    >
                      <IconButton
                        aria-label="delete"
                        sx={{
                          color: "skyblue",
                        }}
                      >
                        -
                      </IconButton>
                      <Divider orientation="vertical" flexItem />
                      <input
                        type="text"
                        readOnly
                        value={1}
                        style={{
                          fontSize: "17px",
                          maxWidth: "20px",
                        }}
                      />
                      <Divider orientation="vertical" flexItem />
                      <IconButton
                        aria-label="delete"
                        sx={{
                          color: "skyblue",
                        }}
                      >
                        +
                      </IconButton>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
            <Box>
              {showMore && (
                <>
                  <Box mt="2px">
                    <Typography fontWeight="bold">Return</Typography>
                    <Box
                      display="flex"
                      justifyContent="space-evenly"
                      width="80px"
                      height="30px"
                      borderRadius="10px"
                      boxShadow={2}
                      backgroundColor={colors.primary[500]}
                    >
                      <IconButton
                        aria-label="delete"
                        sx={{
                          color: "skyblue",
                        }}
                      >
                        -
                      </IconButton>
                      <Divider orientation="vertical" flexItem />
                      <input
                        type="text"
                        readOnly
                        value={1}
                        style={{
                          fontSize: "17px",
                          maxWidth: "20px",
                        }}
                      />
                      <Divider orientation="vertical" flexItem />
                      <IconButton
                        aria-label="delete"
                        sx={{
                          color: "skyblue",
                        }}
                      >
                        +
                      </IconButton>
                    </Box>
                  </Box>
                </>
              )}
            </Box>

            <Box mt="50px" mr="10px">
              {!buttonChange && (
                <Button
                  onClick={handleChangeButton}
                  size="medium"
                  //color={colors.skyBlueAccent[100]}
                  variant="contained"
                  startIcon={<AddIcon />}
                >
                  Add
                </Button>
              )}
            </Box>
            {buttonChange && (
              <Box display="flex" justifyContent="flex-end">
                <More handleShowMore={handleShowMore} showMore={showMore} />
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default ProductCards;

const More = ({ handleShowMore, showMore }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <>
      <Box
        boxShadow="2"
        borderRadius="10px"
        display="flex"
        justifyContent="center"
        onClick={handleShowMore}
        sx={{
          cursor: "pointer",
          opacity: "2",
        }}
        backgroundColor="skyblue"
      >
        <Typography fontWeight="bold" color="black" ml="5px">
          More
        </Typography>
        {showMore ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpOutlined />}
      </Box>

      {showMore && (
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            boxShadow: "4",
            zIndex: "3",
            width: "94%",
            justifyContent: "flex-start",
            top: "98%",
            left: 0,
            borderRadius: "10px",
            marginLeft: "10px",
          }}
          backgroundColor={colors.primary[400]}
        >
          <Box ml="10px" display="flex">
            <Typography fontWeight="bold">Return</Typography>
            <Typography fontWeight="bold" ml="5px">
              0
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};
