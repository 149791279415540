import { Button, Grid } from "@mui/material";
import React from "react";
import SkuOrderDetailsCard from "../../../../../Components/Cards/OrgBookingCards/SkuOrderDetailsCard";
import DeliveryAddress from "../Components/DeliveryAddress";
import DeliverDate from "../Components/DeliverDate";
import { SelectedProductaction } from "../../../../../Redux/Actions/skuBookingAction";
import { useDispatch, useSelector } from "react-redux";
import { ResetUpdateOrderError } from "../../../../../Redux/Actions/checkOutAction";

function CheckOutOverView({ products, setProducts, invalidProducts }) {
  const dispatch = useDispatch();
  const checkOutDetails = useSelector(
    (state) => state.checkOutDetails.checkOutDetails
  );
  const SkuSelectedProducts = useSelector((state) => state.skuBookingDetails);
  const priceBreakUp = useSelector(
    (state) => state.checkOutDetails.checkOutDetails
  );
  const handelDeleteProduct = (data) => {
    console.log(data);
    console.log(products);
    const capacity = data.capacity;
    const material = data.material;
    const productIndex = products?.products?.findIndex(
      (currentProduct) =>
        currentProduct.capacity === capacity &&
        currentProduct.material === material
    );
    console.log(`Product Index : ${productIndex}`);
    if (productIndex !== -1 && products?.products?.length > 1) {
      // Ensure the product index is found
      let deletedProduct = [...products.products]; // Create a copy of the products array
      deletedProduct.splice(productIndex, 1); // Remove the product at the found inde
      console.log(deletedProduct);
      setProducts((prev) => ({
        ...prev,
        products: deletedProduct,
      }));
    } else if (products?.products?.length === 1) {
      //   setOpenExitDialog(true);
    }
  };

  //for updating product details
  React.useEffect(() => {
    if (
      checkOutDetails !== undefined &&
      checkOutDetails?.valid_products?.length > 0
    ) {
      // Your product details logic from the reducer
      const updatedValidProducts = (checkOutDetails?.valid_products || [])
        .map((item) => {
          // Check if quantity is defined and has changed
          if (
            item?.quantity !== undefined
            // &&
            // item?.quantity.existing_max_dist_stock >= item?.quantity.selected
            //     ||
            // (item?.quantity !== undefined &&
            //   pageName &&
            //   pageName === "subscribe") ||
            // (item?.quantity !== undefined &&
            //   pageName &&
            //   pageName.includes("editSubscribe"))
          ) {
            return {
              quantity: item?.quantity.selected || 0,
              return: item?.return?.is_valid ? item?.return.selected || 0 : 0,
              sku_qty: item?.sku_qty || 0,
              booking_type: item?.booking_type || 0,
              capacity: item?.capacity,
              material: item?.material,
            };
          }
          return undefined; // Return undefined for items that don't meet the condition
        })
        .filter(Boolean); // Filter out undefined items

      const updatedInvalidProducts = (
        checkOutDetails?.invalid_products || []
      ).map((item) => {
        return {
          quantity: item?.quantity || 0,
          return: item?.return || 0,
          sku_qty: item?.sku_qty || 0,
          booking_type: item?.booking_type || 1,
          capacity: item?.capacity,
          material: item?.material,
        };
      });

      console.log(updatedValidProducts);
      console.log(updatedInvalidProducts);
      setProducts((prevProducts) => ({
        ...prevProducts,
        products: [...updatedValidProducts, ...updatedInvalidProducts],
      }));
      //   dispatch(ResetUpdateOrderError());
    }
  }, [checkOutDetails]);
  //function for updating product details
  React.useEffect(() => {
    if (
      products?.products?.length > 0 &&
      JSON.stringify(products) !==
        JSON.stringify(SkuSelectedProducts.selectedProduct)
    ) {
      dispatch(SelectedProductaction(products));
    }
  }, [products]);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Grid
            container
            justify="flex-end"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid container spacing={2}>
              <Grid item md={5} sm={6} xs={12}>
                <DeliveryAddress />
              </Grid>
              <Grid item md={5} sm={6} xs={12}>
                <DeliverDate />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <SkuOrderDetailsCard
                handelDeleteProduct={handelDeleteProduct}
                invalidProducts={invalidProducts}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default CheckOutOverView;
