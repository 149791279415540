/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Dialog box to edit the user details.
 * @date 26/07/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CustomInputTextField from "../../../Components/CustomComp/CustomInputTextField";
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Tooltip,
  selectClasses,
} from "@mui/material";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import appMeta from "../../../AppMeta/appMeta";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { useDispatch, useSelector } from "react-redux";
import {
  EditUserRolesDomainDetails,
  GetUserRolesDomainDetails,
} from "../../../Redux/Actions/addUsersDomainRolesAction";
import { GetRoles } from "../../../Redux/Actions/rolesAction";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

export default function EditUserDetailsDialog({
  openEditDialog,
  setOpenEditDialog,
  selectedRow,
  pageNumber,
  pageSize,
  ascOrDesc,
  isActiveGet,
}) {
  console.log(selectedRow);
  const dispatch = useDispatch();
  const rolesData = useSelector((state) => state.rolesData);
  // Variables required declared here
  const [rolesList, setRolesList] = React.useState([]);

  const [email, setEmail] = React.useState(selectedRow?.email);
  const [name, setName] = React.useState(selectedRow?.fullname);
  const [phone, setPhone] = React.useState(selectedRow?.phone);
  const [roleName, setRoleName] = React.useState(selectedRow?.role_name);
  const [isForceUpdate, setIsForceUpdate] = React.useState(false);
  const [isActive, setIsActive] = React.useState(selectedRow?.is_active);
  const [isDeleted, setIsDeleted] = React.useState(false);
  // Validation variables
  const [isValidEmail, setIsValidEmail] = React.useState(true);
  const [isValidPhone, setIsValidPhone] = React.useState(true);
  // To check which textfield user is trying to edit
  const [isEditEmail, setIsEditEmail] = React.useState(false);
  const [isEditName, setIdEditName] = React.useState(false);
  const [isEditPhone, setIsEditPhone] = React.useState(false);
  const [isEditRoleName, setIsEditRoleName] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hasMoreData, setHasMoreData] = React.useState(true);
  const [getMoreRoles, setGetMoreRoles] = React.useState(1);
  const [lastMore, setLastMore] = React.useState(null);

  // Hitting API to Get roles list
  React.useEffect(() => {
    dispatch(GetRoles(getMoreRoles, 25, true, "ASC"));
  }, [getMoreRoles]);

  // After hitting roles API trying to get the response stored in redux store
  React.useEffect(() => {
    console.log(rolesData);
    if (rolesData.error === false) {
      if (rolesData?.rolesList?.data?.length > 0) {
        console.log(rolesData.rolesList.data);
        // setRolesList(rolesData.rolesList.data);
        setLastMore(rolesData.rolesList.last_page_number);
        setRolesList((prevRolesList) =>
          prevRolesList
            ? [...prevRolesList, ...rolesData.rolesList.data]
            : rolesData.rolesList.data
        );
      }
    }
  }, [rolesData]);

  const handleOpenDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };
  const handleLoadMore = () => {
    setGetMoreRoles(getMoreRoles + 1);
    // fetchMoreData(nextPage);
  };
  const handleClose = () => {
    setOpenEditDialog(false);
  };

  const handleClickEditEmail = () => {
    setIsEditEmail(!isEditEmail);
  };
  const handleClickEditName = () => {
    setIdEditName(!isEditName);
  };
  const handleClickEditPhone = () => {
    setIsEditPhone(!isEditPhone);
  };
  const handleClickEditRolename = () => {
    setIsEditRoleName(!isEditRoleName);
  };

  // This function will hit the Update User details API with request object
  const handleUpdate = () => {
    const reqObj = {
      email: email,
      is_force_update: isForceUpdate,
      is_active: isActive,
      name: name,
      is_deleted: isDeleted,
      phone: phone,
      role_name: roleName,
    };
    console.log(reqObj);
    dispatch(EditUserRolesDomainDetails(reqObj));
    setTimeout(() => {
      dispatch(
        GetUserRolesDomainDetails(pageNumber, pageSize, ascOrDesc, isActiveGet)
      );
    }, 1000);
    setOpenEditDialog(false);
  };

  // Jsx Starts here...
  return (
    <div>
      <Dialog
        open={openEditDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">{"Edit Details"}</DialogTitle> */}
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              //   marginTop: "10px",
            }}
          >
            <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
              <InputLabel htmlFor="standard-adornment-password">
                Email
              </InputLabel>
              <Input
                id="standard-adornment-password"
                value={selectedRow?.email}
                disabled={true}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickEditEmail}
                      disabled={true}
                      // onMouseDown={handleMouseDownPassword}
                    >
                      {isEditEmail ? (
                        <Tooltip title="Cancel" placement="top">
                          <CancelRoundedIcon />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Edit" placement="top">
                          <EditRoundedIcon />
                        </Tooltip>
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {isEditEmail && (
              <CustomInputTextField
                value={email}
                setValue={setEmail}
                placeholder="Email"
                type={appMeta.textFieldTypes.EMAIL}
                setIsValid={setIsValidEmail}
                isValid={isValidEmail}
                isHintRequired={true}
              />
            )}
            <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
              <InputLabel htmlFor="standard-adornment-password">
                Name
              </InputLabel>
              <Input
                id="standard-adornment-password"
                value={selectedRow?.fullname}
                disabled={true}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickEditName}
                      // onMouseDown={handleMouseDownPassword}
                    >
                      {isEditName ? (
                        <Tooltip title="Cancel" placement="top">
                          <CancelRoundedIcon />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Edit" placement="top">
                          <EditRoundedIcon />
                        </Tooltip>
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {isEditName && (
              <CustomInputTextField
                value={name}
                setValue={setName}
                placeholder="Name"
                type="text"
              />
            )}
            <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
              <InputLabel htmlFor="standard-adornment-password">
                Phone
              </InputLabel>
              <Input
                id="standard-adornment-password"
                value={selectedRow?.phone}
                disabled={true}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickEditPhone}
                      // onMouseDown={handleMouseDownPassword}
                    >
                      {isEditPhone ? (
                        <Tooltip title="Cancel" placement="top">
                          <CancelRoundedIcon />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Edit" placement="top">
                          <EditRoundedIcon />
                        </Tooltip>
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            {isEditPhone && (
              <CustomInputTextField
                value={phone}
                setValue={setPhone}
                placeholder="Phone"
                type={appMeta.textFieldTypes.PHONE}
                isValid={isValidPhone}
                setIsValid={setIsValidPhone}
                isHintRequired={true}
              />
            )}
            <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
              <InputLabel htmlFor="standard-adornment-password">
                Role name
              </InputLabel>
              <Input
                id="standard-adornment-password"
                value={selectedRow?.role_name}
                disabled={true}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickEditRolename}
                      // onMouseDown={handleMouseDownPassword}
                    >
                      {isEditRoleName ? (
                        <Tooltip title="Cancel" placement="top">
                          <CancelRoundedIcon />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Edit" placement="top">
                          <EditRoundedIcon />
                        </Tooltip>
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {isEditRoleName && (
              <Box
                sx={{
                  minWidth: 120,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <Button
                  onClick={handleOpenDropdown}
                  variant="outlined"
                  color="secondary"
                >
                  Select Role Name <ExpandMoreRoundedIcon />
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleCloseDropdown}
                >
                  {rolesList?.map((list) => (
                    <MenuItem
                      key={list.role_name}
                      onClick={() => {
                        handleCloseDropdown();
                        setRoleName(list.role_name);
                      }}
                    >
                      {list.role_name}
                    </MenuItem>
                  ))}
                  {/* Initially maximum 25 roles will be shown to choose, 
                  More option is there to show more data if user clicks on it */}
                  {hasMoreData && (
                    <MenuItem
                      onClick={handleLoadMore}
                      sx={{ fontWeight: "bold" }}
                      disabled={getMoreRoles < lastMore ? false : true}
                    >
                      More <ExpandMoreRoundedIcon />
                    </MenuItem>
                  )}
                </Menu>
                {/* Just showing the selected role name */}
                {roleName && (
                  <Box>
                    <Chip
                      label={`Selected Role Name: ${roleName}`}
                      variant="filled"
                      color="success"
                      sx={{ fontWeight: "bold" }}
                    />
                  </Box>
                )}
              </Box>
            )}

            {/* {isEditRoleName && (
              <CustomInputTextField
                value={roleName}
                setValue={setRoleName}
                placeholder="Role name"
                type="text"
              />
            )} */}

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                //   alignItems: "center",
                //   justifyContent: "center",
              }}
            >
              <FormControlLabel
                required
                control={
                  <Checkbox
                    checked={isForceUpdate}
                    onChange={(event) => setIsForceUpdate(event.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={
                  isForceUpdate
                    ? "You are force updating"
                    : "Do you want to force update"
                }
              />
              <FormControlLabel
                required
                control={
                  <Checkbox
                    checked={isActive}
                    onChange={(event) => setIsActive(event.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={
                  isActive
                    ? "Active now, Uncheck to deactivate."
                    : "This user will be no more active"
                }
              />
              <FormControlLabel
                required
                control={
                  <Checkbox
                    checked={isDeleted}
                    onChange={(event) => {
                      setIsDeleted(event.target.checked);
                      setIsForceUpdate(event.target.checked);
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={
                  isDeleted
                    ? "You are forcing to delete this user"
                    : "Check to delete this user"
                }
              />
            </Box>
          </Box>
        </DialogContent>
        {/* Handling closing and update buttons */}
        <DialogActions>
          <CustomButton
            handleClick={handleClose}
            label="Cancel"
            variant="text"
          />
          <CustomButton
            handleClick={handleUpdate}
            label="Update"
            variant="contained"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
