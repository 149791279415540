/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Tahir Shaik
 * @description In this file, Creating a Single Store For Managing/Storing the State of Data with the help of REDUX
 * @date 29/05/23
 * @version 0.0.1 - Initial Release
 */

import thunk from "redux-thunk";
import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from "redux";
import rootReducer from "./Reducers/RootReducer";

/**
 * @todo: Remove it before production --> Added By Tahir
 */
import { composeWithDevTools } from "redux-devtools-extension";

const initialState = {};
const middlewares = [thunk];

/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Tahir Shaik
 * @description Creating a Single Store For Managing/Storing the State of Data with the help of REDUX
 * @date 29/05/23
 * @version 0.0.1 - Initial Release
 */
export const store = createStore(
  rootReducer,
  initialState,
  /**
   * @todo : Uncomment the compose and comment the composeWithDevTools for the production
   */
  //   compose(applyMiddleware(...middlewares))
  composeWithDevTools(applyMiddleware(...middlewares)) //Only for Staging
);
