import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Card,
  CardContent,
  Chip,
  IconButton,
  Tooltip,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
import { useDispatch, useSelector } from "react-redux";
import {
  UploadFile,
  getFiles,
} from "../../../Redux/Actions/fileHandlingActions";
import {
  GetManufacuringOrders,
  MftCompleteOrder,
  MftEditOrder,
  MftOrdersApproval,
} from "../../../Redux/Actions/manufacturingOrdersAction";
import GetAppRoundedIcon from "@mui/icons-material/GetAppRounded";
import OrderDetailsCard from "./OrderDetailsCard";

export default function MftOrderDetailsDialog({
  openMftOrderDialog,
  setOpenMftOrderDialog,
  selectedOrderData,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const filesData = useSelector((state) => state.filesData);
  const mftOrderDetails = useSelector((state) => state.mftOrdersData);

  const [orderDetailsObj, setOrderDetailsObj] = React.useState({});
  const [uploadedPoFile, setUploadedPoFile] = React.useState(
    selectedOrderData?.po_file !== "" ? selectedOrderData?.po_file : ""
  );
  const [uploadedPrFile, setUploadedPrFile] = React.useState(
    selectedOrderData?.payment_receipt !== ""
      ? selectedOrderData?.payment_receipt
      : ""
  );

  const [poFile, setPoFile] = React.useState("");
  const [uploadPoBtn, setUploadPoBtn] = React.useState(false);
  const [poUploadFlag, setPoUploadFlag] = React.useState(false);
  const [prFile, setPrFile] = React.useState("");
  const [uploadPrBtn, setUploadPrBtn] = React.useState(false);
  const [prUploadFlag, setPrUploadFlag] = React.useState(false);
  const [isPoApproved, setIsPoApproved] = React.useState(false);
  const [isPrApproved, setIsPrApproved] = React.useState(false);

  React.useEffect(() => {
    mftOrderDetails?.mftOrders?.data?.map((order) => {
      if (selectedOrderData.order_id === order.order_id) {
        setOrderDetailsObj(order);
      }
    });
  }, [mftOrderDetails]);

  React.useEffect(() => {
    console.log("Order details Obj");
    console.log(orderDetailsObj);
    if (orderDetailsObj?.po_approved_by_details?.fullname !== null) {
      setIsPoApproved(true);
    } else {
      setIsPoApproved(false);
    }

    if (orderDetailsObj?.pr_approved_by_details?.fullname !== null) {
      setIsPrApproved(true);
    } else {
      setIsPrApproved(false);
    }
  }, [orderDetailsObj]);

  React.useEffect(() => {
    console.log("selectedOrderData");
    console.log(selectedOrderData);
  }, []);
  const handleClose = () => {
    setOpenMftOrderDialog(false);
  };

  const poInputRef = React.useRef(null);
  const prInputRef = React.useRef(null);
  const handleUploadPO = () => {
    poInputRef.current.click();
  };
  const handleUploadPR = () => {
    prInputRef.current.click();
  };

  const handleChangePOUpload = (event) => {
    console.log("Selected PO file:", event.target.files[0]);
    setPoFile(event.target.files[0]);
    setUploadPoBtn(true);
  };
  const handleChangePRUpload = (event) => {
    console.log("Selected PR file:", event.target.files[0]);
    setPrFile(event.target.files[0]);
    setUploadPrBtn(true);
  };

  React.useEffect(() => {
    console.log("Upload PO button useEffect");
    if (uploadPoBtn === true) {
      console.log("Hiiting Upload API");
      dispatch(UploadFile(poFile));
      setUploadPoBtn(false);
      setPoUploadFlag(true);
    }
  }, [uploadPoBtn]);

  React.useEffect(() => {
    console.log("Upload PR button useEffect");
    if (uploadPrBtn === true) {
      console.log("Hiiting Upload API");
      dispatch(UploadFile(prFile));
      setUploadPrBtn(false);
      setPrUploadFlag(true);
    }
  }, [uploadPrBtn]);

  React.useEffect(() => {
    console.log("Files Data");
    console.log(filesData);
    if (filesData.error === false) {
      if (poUploadFlag === true) {
        setUploadedPoFile(filesData.fileName);
        setPoUploadFlag(false);
      } else if (prUploadFlag === true) {
        setUploadedPrFile(filesData.fileName);
        setPrUploadFlag(false);
      }
    }
  }, [filesData]);

  const detailsData = {
    order_status: orderDetailsObj?.order_status,

    po_approved_by_details: {
      email: orderDetailsObj?.po_approved_by_details?.email,
      phone: orderDetailsObj?.po_approved_by_details?.phone,
      fullname: orderDetailsObj?.po_approved_by_details?.fullname,
    },
    pr_approved_by_details: {
      email: orderDetailsObj?.pr_approved_by_details?.email,
      phone: orderDetailsObj?.pr_approved_by_details?.phone,
      fullname: orderDetailsObj?.pr_approved_by_details?.fullname,
    },
    order_initiated_by_details: {
      email: orderDetailsObj?.order_initiated_by_details?.email,
      phone: orderDetailsObj?.order_initiated_by_details?.phone,
      fullname: orderDetailsObj?.order_initiated_by_details?.fullname,
    },
    order_updated_by_details: {
      email: orderDetailsObj?.order_updated_by_details?.email,
      phone: orderDetailsObj?.order_updated_by_details?.phone,
      fullname: orderDetailsObj?.order_updated_by_details?.fullname,
    },
    qr_data: {
      status: orderDetailsObj?.mft_details?.qr_data?.is_qr_generated,
      qrCodeSeries: orderDetailsObj?.mft_details?.qr_data?.qrcode_series,
      barCodeSeries: orderDetailsObj?.mft_details?.qr_data?.barcode_series,
    },
  };
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={openMftOrderDialog}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Manufacturer Order Details
        </DialogTitle>
        <DialogContent>
          <Box>
            {/* Order status card */}

            <Card variant="outlined" sx={{ width: "100%" }}>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Order details
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <Box sx={{ padding: "10px" }}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Order ID:
                    </Typography>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Order Status:
                    </Typography>
                    <Typography sx={{ fontWeight: "bold" }}>
                      PO Approved:
                    </Typography>
                    <Typography sx={{ fontWeight: "bold" }}>
                      PR Approved:
                    </Typography>
                  </Box>
                  <Box sx={{ padding: "10px" }}>
                    {Object.keys(orderDetailsObj).length > 0 && (
                      <>
                        {" "}
                        <Typography>{orderDetailsObj?.order_id}</Typography>
                        <Typography>{orderDetailsObj?.order_status}</Typography>
                        <Typography>
                          {orderDetailsObj?.po_approved_by_details?.fullname !==
                          null
                            ? orderDetailsObj?.po_approved_by_details.fullname
                            : "Not Yet Approved"}
                        </Typography>
                        <Typography>
                          {orderDetailsObj?.pr_approved_by_details?.fullname !==
                          null
                            ? orderDetailsObj?.pr_approved_by_details?.fullname
                            : "Not Yet Approved"}
                        </Typography>
                      </>
                    )}
                  </Box>
                </Box>
              </CardContent>
            </Card>

            {/* Purchase order card */}

            {orderDetailsObj?.order_status === "PO_PROCESSING" ||
            orderDetailsObj?.order_status === "PO_REJECTED" ? (
              <Card variant="outlined">
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Purchase Order
                  </Typography>
                  {uploadedPoFile !== "" && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      <Chip
                        label={`Upload Success (${uploadedPoFile})`}
                        variant="outlined"
                        onClick={() => setUploadedPoFile("")}
                        icon={<CloseRoundedIcon />}
                        color="success"
                      />
                      <Button
                        variant="outlined"
                        onClick={() => dispatch(getFiles(uploadedPoFile))}
                      >
                        <GetAppRoundedIcon />
                      </Button>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          color="success"
                          onClick={() => {
                            const reqObj = {
                              is_po: true,
                              is_approved: true,
                              order_id: selectedOrderData.order_id,
                              is_cancel_order: false,
                            };
                            const reqObjEdit = {
                              order_id: selectedOrderData.order_id,
                              quantity: selectedOrderData.quantity,
                              price: selectedOrderData.price,
                              po_file: uploadedPoFile,
                              // payment_receipt: "ahtchk.jpg",
                            };
                            dispatch(MftOrdersApproval(reqObj));
                            // dispatch(MftEditOrder(reqObjEdit));

                            setTimeout(() => {
                              dispatch(GetManufacuringOrders());
                            }, 1000);
                          }}
                        >
                          Approve
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          color="error"
                          onClick={() => {
                            const reqObj = {
                              is_po: true,
                              is_approved: false,
                              order_id: selectedOrderData.order_id,
                              is_cancel_order: false,
                            };
                            dispatch(MftOrdersApproval(reqObj));
                            setUploadedPoFile("");
                            setTimeout(() => {
                              dispatch(GetManufacuringOrders());
                            }, 1000);
                          }}
                        >
                          Reject
                        </Button>
                      </Box>
                    </Box>
                  )}
                  {uploadedPoFile === "" && (
                    <Box>
                      <Tooltip title="Upload Purchase Order" position="top">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleUploadPO}
                          edge="end"
                        >
                          <UploadRoundedIcon />
                        </IconButton>
                      </Tooltip>
                      <input
                        type="file"
                        ref={poInputRef}
                        style={{ display: "none" }}
                        onChange={handleChangePOUpload}
                      />
                    </Box>
                  )}
                </CardContent>
              </Card>
            ) : (
              ""
            )}

            {/* Payment Receipt Card */}

            {orderDetailsObj?.order_status === "PO_APPROVED" ||
            orderDetailsObj?.order_status === "PR_REJECTED" ||
            orderDetailsObj?.order_status === "PR_PROCESSING" ? (
              <>
                <Card variant="outlined">
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                      Payment Receipt
                    </Typography>
                    {uploadedPrFile !== "" && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <Chip
                          label={`Upload Success (${uploadedPrFile})`}
                          variant="outlined"
                          onClick={() => setUploadedPrFile("")}
                          icon={<CloseRoundedIcon />}
                          color="success"
                        />
                        <Button
                          variant="outlined"
                          onClick={() => dispatch(getFiles(uploadedPrFile))}
                        >
                          <GetAppRoundedIcon />
                        </Button>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10px",
                          }}
                        >
                          <Button
                            variant="outlined"
                            size="small"
                            color="success"
                            onClick={() => {
                              const reqObj = {
                                is_po: false,
                                is_approved: true,
                                order_id: selectedOrderData.order_id,
                                is_cancel_order: false,
                              };
                              const reqObjEdit = {
                                order_id: selectedOrderData.order_id,
                                quantity: selectedOrderData.quantity,
                                price: selectedOrderData.price,
                                // po_file: uploadedPoFile,
                                payment_receipt: uploadedPrFile,
                              };
                              dispatch(MftOrdersApproval(reqObj));
                              // dispatch(MftEditOrder(reqObjEdit));
                              setTimeout(() => {
                                dispatch(GetManufacuringOrders());
                              }, 1000);
                            }}
                          >
                            Approve
                          </Button>
                          <Button
                            variant="outlined"
                            size="small"
                            color="error"
                            onClick={() => {
                              const reqObj = {
                                is_po: false,
                                is_approved: false,
                                order_id: selectedOrderData.order_id,
                                is_cancel_order: false,
                              };
                              dispatch(MftOrdersApproval(reqObj));
                              setUploadedPrFile("");
                              setTimeout(() => {
                                dispatch(GetManufacuringOrders());
                              }, 1000);
                            }}
                          >
                            Reject
                          </Button>
                        </Box>
                      </Box>
                    )}
                    {uploadedPrFile === "" && (
                      <Box>
                        <Tooltip title="Upload Payment Receipt" position="top">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleUploadPR}
                            edge="end"
                          >
                            <UploadRoundedIcon />
                          </IconButton>
                        </Tooltip>
                        <input
                          type="file"
                          ref={prInputRef}
                          style={{ display: "none" }}
                          onChange={handleChangePRUpload}
                        />
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </>
            ) : (
              ""
            )}

            {/* Cancel and complete order buttons */}

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px",
                gap: "10px",
              }}
            >
              {orderDetailsObj?.order_status !== "COMPLETED" ||
              orderDetailsObj?.order_status !== "CANCELLED" ? (
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    const reqObj = {
                      order_id: selectedOrderData.order_id,
                      is_complete: false,
                    };
                    dispatch(MftCompleteOrder(reqObj));
                    setOpenMftOrderDialog(false);
                    setTimeout(() => {
                      dispatch(GetManufacuringOrders());
                    }, 1000);
                  }}
                >
                  Cancel Order
                </Button>
              ) : (
                ""
              )}

              {orderDetailsObj?.order_status === "PR_APPROVED" && (
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    const reqObj = {
                      order_id: selectedOrderData.order_id,
                      is_complete: true,
                    };
                    dispatch(MftCompleteOrder(reqObj));
                    setOpenMftOrderDialog(false);
                    setTimeout(() => {
                      dispatch(GetManufacuringOrders());
                    }, 1000);
                  }}
                >
                  Complete Order
                </Button>
              )}
            </Box>

            {/* Completed order full details  */}
            <OrderDetailsCard orderDetails={detailsData} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenMftOrderDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
