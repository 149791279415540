import { AppBar, Box, Button, Divider, Paper, useMediaQuery } from "@mui/material";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { IconButton, Typography, useTheme, Drawer } from "@mui/material";
import { tokens } from "../../AppMeta/theme";
import { Link, useHistory } from "react-router-dom";
import React from "react";
import { sideBarData } from "./sideBarData";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import bwLogo from "../../Assests/ImagesAndIcons/bwLogo.png";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { GetProfile } from "../../Redux/Actions/profile";
import useScreenSize from "../../Hooks/CustomHooks/useScreenSize";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const history = useHistory();
  const colors = tokens(theme.palette.mode);
  const handleNavigator = () => {
    history.push(to);
    setSelected(title);
  };
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={handleNavigator}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

function SideNavigation() {
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.profileData);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const [selected, setSelected] = React.useState("DashboardMain");

  const [dashboard, setDashboard] = React.useState([]);
  const [domain, setDomain] = React.useState("");
  const [isFacility, setIsFacility] = React.useState(false);
  const [orgFacilityName, setOrgFacilityName] = React.useState("");
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useScreenSize();
  const history = useHistory()

  React.useEffect(() => {
    dispatch(GetProfile());
  }, []);

  React.useEffect(() => {
    console.log("profileData");
    console.log(profileData);
    setIsFacility(profileData?.profile?.is_facility);
    setDomain(profileData?.profile?.domain);
    setOrgFacilityName(profileData?.profile?.org_name);
  }, [profileData]);

  React.useEffect(() => {
    console.log("isFacility", isFacility);
    console.log("domain", domain);
    console.log("orgFacilityName", orgFacilityName);
    if (domain === "BOOKWATER.COM") {
      setDashboard(sideBarData.bookWaterDashboard);
    } else if (isFacility === true) {
      setDashboard(sideBarData.facilityDashBoard);
    } else {
      setDashboard(sideBarData.organisationDashboard);
    }
  }, [isFacility, domain, orgFacilityName]);

  React.useEffect(() => {
    console.log("dashboard");
    console.log(dashboard);
  }, [dashboard]);

  React.useEffect(() => {

  })





  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  React.useEffect(() => {
    console.log("dashboard");
    console.log(dashboard);
  }, [dashboard]);


  return (
    <Box >
      {isDesktop && (

        <Sidebar

          collapsed={isCollapsed}
          backgroundColor={`${colors.primary[500]} !important`}
        >
          <Menu iconShape="square" icon={"transparent !important"}>
            <MenuItem
              onClick={() => setIsCollapsed(!isCollapsed)}
              icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
              menuItemStyles={{
                button: ({ level, active, disabled }) => {
                  // only apply styles on first level elements of the tree
                  if (level === 0)
                    return {
                      color: disabled ? "#f5d9ff" : "#d359ff",
                      backgroundColor: active ? "#eecef9" : undefined,
                    };
                },
              }}
            >
              {!isCollapsed && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  ml="15px"
                >
                  <Typography
                    variant="h3"
                    color={colors.grey[100]}
                  ></Typography>
                  <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                    <MenuOutlinedIcon />
                  </IconButton>
                </Box>
              )}
            </MenuItem>

            {!isCollapsed && (
              <Box mb="20px">
                <Box display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  p={2}>
                  {
                    domain === "BOOKWATER.COM" ? (
                      <>
                        <img
                          alt=""
                          width="100px"
                          height="100px"
                          src={bwLogo}
                          style={{ cursor: "pointer" }}
                        />

                        <Box textAlign="center">
                          <Typography
                            variant="h2"
                            color={colors.skyBlueAccent[100]}
                            fontWeight="bold"
                            sx={{ m: "10px 0 0 0" }}
                          >
                            {orgFacilityName}
                          </Typography>
                        </Box>
                      </>

                    ) : (
                      <>
                        <Typography
                          variant="h2"
                          color={colors.grey[100]}
                          fontWeight="bold"
                        >
                          {orgFacilityName}
                        </Typography>
                        <Box>
                          <Button color="secondary">Powered By BookWater</Button>
                        </Box>
                      </>
                    )
                  }

                </Box>

              </Box>
            )}

            <Box paddingLeft={isCollapsed ? undefined : "10%"}>
              {dashboard?.map((item, index) => (
                <Item
                  key={index}
                  title={item.title}
                  to={item.path}
                  icon={item.icon}
                  selected={selected}
                  setSelected={setSelected}
                />
              ))}
            </Box>
          </Menu>
        </Sidebar>


      )}
    </ Box>
  );
}

export default SideNavigation;