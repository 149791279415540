/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description All the roles and access details for that roles is displayed in form of table
 * @date 26/07/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import * as React from "react";
import EditRoleAccessDialog from "./EditRoleAccessDialog";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { appColors } from "../../../AppMeta/appColors";

export default function AllRolesAccessDetailsTable({
  providedAccessList,
  listOfApis,
  selectedRole,
  setPageNumber1,
  setPageSize1,
  setAscOrDesc1,
  lastPageNumber1,
  pageNumber1,
  pageSize1,
  ascOrDesc1,
}) {
  // Required variable declared
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [editRolesAccessDialog, setEditRolesAccessDialog] =
    React.useState(false);

  // This func executes when user clicks on any rows in table and selected row data is stored in a variable
  const handleRowClick = (row) => {
    console.log(row);
    setSelectedRow(row);
    setEditRolesAccessDialog(true);
  };

  // const rows = providedAccessList.map((item) => ({
  //   id: item.role_id,
  //   role_name: item.role_name,
  //   role_description: item.role_description,
  //   // role_status: item.role_status,
  //   // is_role_deleted: item.is_role_deleted,
  //   api_names: item.api_names,
  //   api_descriptions: item.api_descriptions,
  //   // is_api_deleted: item.is_api_deleted.join(", "),
  //   https_methods: item.https_methods,
  //   // fullnames: item.fullnames.join(", "),
  //   // phones: item.phones.join(", "),
  //   // emails: item.emails.join(", "),
  //   // user_status: item.user_status.join(", "),
  //   // is_user_deleted: item.is_user_deleted.join(", "),
  //   created_at: item.created_at,
  //   // updated_at: item.updated_at,
  // }));

  React.useEffect(() => {
    console.log(providedAccessList);
  }, [providedAccessList]);

  function createData(
    roleId,
    roleName,
    roleDescription,
    roleStatus,
    isRoleDeleted,
    apiNames,
    apiDescriptions,
    isApiDeleted,
    httpsMethods,
    fullnames,
    phones,
    emails,
    userStatus,
    isUserDeleted
  ) {
    return {
      roleId,
      roleName,
      roleDescription,
      roleStatus,
      isRoleDeleted,
      apiNames,
      apiDescriptions,
      isApiDeleted,
      httpsMethods,
      fullnames,
      phones,
      emails,
      userStatus,
      isUserDeleted,
    };
  }

  // Toggles when selected Ascending or descending
  const handleChangeAscDesc = (event, newAlignment) => {
    setAscOrDesc1(newAlignment);
  };

  // Pagenumber will be decremented on user click
  const pageNumberBackward = () => {
    if (pageNumber1 - 1 > 0) {
      setPageNumber1(pageNumber1 - 1);
    }
  };
  // Pagenumber will get incremented on user click
  const pageNumberForward = () => {
    if (listOfApis.length > 0) {
      setPageNumber1(pageNumber1 + 1);
    }
  };
  // Jsx starts here
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* Toolbar to show the filter options */}
      <Toolbar>
        <Box
          sx={{
            display: "flex",
            // flexDirection: "column",
            gap: "20px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box>
            {" "}
            <ToggleButtonGroup
              size="small"
              color="primary"
              value={ascOrDesc1}
              exclusive
              onChange={handleChangeAscDesc}
              aria-label="Platform"
            >
              <ToggleButton value="ASC">Ascending</ToggleButton>
              <ToggleButton value="DESC">Descending</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
      </Toolbar>
      <TableContainer
        component={Paper}
        sx={{ maxWidth: { xs: 350, lg: 750 } }}
        size="small"
      >
        <Table aria-label="simple table" size="small">
          <TableHead sx={{ backgroundColor: appColors.secondary }}>
            <TableRow>
              <TableCell>Role ID</TableCell>
              <TableCell>Role Name</TableCell>
              <TableCell>Role Description</TableCell>
              <TableCell>Role Status</TableCell>
              <TableCell>API Names</TableCell>
              <TableCell>API Descriptions</TableCell>
              {/* <TableCell align="right">Is API Deleted</TableCell> */}
              <TableCell>HTTP Methods</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {providedAccessList.map((row) => (
              <TableRow
                key={row.roleId}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  cursor: "pointer",
                }}
                onClick={() => handleRowClick(row)}
              >
                <TableCell component="th" scope="row">
                  {row.role_id}
                </TableCell>
                <TableCell>{row.role_name}</TableCell>
                <TableCell>{row.role_description}</TableCell>
                <TableCell>{row.role_status ? "Active" : "Inactive"}</TableCell>
                <TableCell>{row.api_names}</TableCell>
                <TableCell>{row.api_descriptions.join(",")}</TableCell>
                {/* <TableCell align="right">{row.isApiDeleted}</TableCell> */}
                <TableCell>{row.https_methods.join(",")}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Pagination and pageSize */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          gap: "15px",
          padding: "10px",
          marginTop: "10px",
        }}
      >
        <Box>
          <Button
            sx={{
              cursor: "pointer",
            }}
            onClick={pageNumberBackward}
          >
            <ArrowBackIosIcon />
          </Button>
        </Box>
        <Typography
          variant="button"
          display="block"
          gutterBottom
          sx={{
            marginLeft: "10px",
            marginRight: "10px",
            backgroundColor: "#2D81FF",
            color: "white",
            padding: "5px",
            width: "40px",
            height: "35px",
            borderRadius: "15px",
            textAlign: "center",
          }}
        >
          {pageNumber1}
        </Typography>
        <Box>
          <Button
            sx={{
              cursor: "pointer",
            }}
            onClick={pageNumberForward}
            disabled={pageNumber1 < lastPageNumber1 ? false : true}
          >
            <ArrowForwardIosIcon />
          </Button>
        </Box>
        <Box>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Page size</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={pageSize1}
              label="Page size"
              onChange={(e) => {
                setPageSize1(e.target.value);
                // setSearchOrderFlag(true);
              }}
            >
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={500}>500</MenuItem>
              <MenuItem value={1000}>1000</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      {/* Edit role access dialog box */}
      {editRolesAccessDialog && (
        <>
          <EditRoleAccessDialog
            editRolesAccessDialog={editRolesAccessDialog}
            setEditRolesAccessDialog={setEditRolesAccessDialog}
            selectedRow={selectedRow}
            providedAccessList={providedAccessList}
            listOfApis={listOfApis}
            selectedRole={selectedRole}
          />
        </>
      )}
    </Box>
  );
}
