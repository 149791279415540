import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
  Box,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const EditOrderDialog = ({ open, onClose, orderQty, returnQty, onQtyChange }) => {
  const [orderQuantity, setOrderQuantity] = useState(orderQty);
  const [returnQuantity, setReturnQuantity] = useState(returnQty);

  const handleIncrement = (type) => {
    if (type === 'order') {
      setOrderQuantity(orderQuantity + 1);
    } else if (type === 'return') {
      setReturnQuantity(returnQuantity + 1);
    }
  };

  const handleDecrement = (type) => {
    if (type === 'order' && orderQuantity > 0) {
      setOrderQuantity(orderQuantity - 1);
    } else if (type === 'return' && returnQuantity > 0) {
      setReturnQuantity(returnQuantity - 1);
    }
  };

  const handleSave = () => {
    const updatedOrder = {
      order_id: "BTORD-214",
      products: [
        {
          capacity: "750ml-3",
          material: "GLASS",
          quantity: orderQuantity.toString(),
          return: returnQuantity.toString(),
          is_crate: false,
          force_update_order: false
        }
      ]
    };
    console.log(updatedOrder)
    // onQtyChange(updatedOrder);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Edit Quantities</DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography>Order Quantity</Typography>
          <Box display="flex" alignItems="center">
            <IconButton onClick={() => handleDecrement('order')}>
              <RemoveIcon />
            </IconButton>
            <Typography>{orderQuantity}</Typography>
            <IconButton onClick={() => handleIncrement('order')}>
              <AddIcon />
            </IconButton>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography>Return Quantity</Typography>
          <Box display="flex" alignItems="center">
            <IconButton onClick={() => handleDecrement('return')}>
              <RemoveIcon />
            </IconButton>
            <Typography>{returnQuantity}</Typography>
            <IconButton onClick={() => handleIncrement('return')}>
              <AddIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditOrderDialog;
